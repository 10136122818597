/* eslint @typescript-eslint/explicit-function-return-type: ['error', { allowExpressions: true } ] */

export const ErrorNoContext = new Error('Could not get context');
export const ErrorWritingToBlob = new Error('Could not write image');

const imageHeaderSize = 64 * 1024;
const compressionLevel = 0.95; // Compression level

const readEXIFOrientationFromBuffer = (arrayBuffer: ArrayBuffer): number | undefined => {
	// See https://www.media.mit.edu/pia/Research/deepview/exif.html for documentation of format
	const view = new DataView(arrayBuffer);
	const length = view.byteLength;

	if (view.getUint16(0, false) !== 0xffd8) {
		return; // File is not a JPEG
	}

	let offset = 2;

	while (offset < length) {
		const marker = view.getUint16(offset, false);
		offset += 2;

		if (marker === 0xffe1) {
			// EXIF marker
			offset += 2;

			if (view.getUint32(offset, false) !== 0x45786966) {
				// Check for 6-byte EXIF-header
				return;
			}
			offset += 6; // Skip 2 null bytes

			// Yes, EXIF supports both little-endian and big-endian
			const littleEndian = view.getUint16(offset, false) === 0x4949;
			offset += view.getUint32(offset + 4, littleEndian); // Skip magic bytes and jump to first IFD Header

			const numberOfTags = view.getUint16(offset, littleEndian);
			offset += 2;

			// Iterate image file directories, each directory has a size of 12 bytes
			for (let i = 0; i < numberOfTags; i += 1) {
				if (view.getUint16(offset + i * 12, littleEndian) === 0x0112) {
					// Check for orientation tag 0x0112
					// eslint-disable-next-line consistent-return
					return view.getUint16(offset + i * 12 + 8, littleEndian);
				}
			}
		} else if (marker === 0xffd9) {
			// End of Image Marker
			return;
		} else {
			offset += view.getUint16(offset, false); // Jump to next marker.
			// Note that data size includes the 2 bytes denoting the size.
		}
	}
};

export const readEXIFOrientation = (file: Blob): Promise<number | undefined> =>
	new Promise<number | undefined>((resolve) => {
		const reader = new FileReader();

		reader.onload = (): void => {
			const arrayBuffer = reader.result as ArrayBuffer;
			const orientation = readEXIFOrientationFromBuffer(arrayBuffer);
			resolve(orientation);
		};

		reader.readAsArrayBuffer(file.slice(0, imageHeaderSize));
	});

const transformContext = (
	image: HTMLImageElement,
	orientation: number,
	imageType: string,
	onSuccess: (blob: Blob) => void,
	onFailure: (reason: Error) => void,
): void => {
	const canvas = document.createElement('canvas');
	const context = canvas.getContext('2d');

	if (!context) {
		onFailure(ErrorNoContext);
		return;
	}

	const { width, height } = image;
	[canvas.width, canvas.height] = [width, height];

	context.drawImage(image, 0, 0, width, height);

	canvas.toBlob(
		(blob) => (blob ? onSuccess(blob) : onFailure(ErrorWritingToBlob)),
		imageType,
		compressionLevel, // Compression level
	);
};

const applyRotation = (imageBlob: Blob, orientation: number, imageType: string): Promise<Blob> =>
	// Based on: https://stackoverflow.com/a/46814952/283851
	new Promise<Blob>((resolve, reject) => {
		if (orientation === 1) {
			resolve(imageBlob);
			return;
		}

		const reader = new FileReader();
		reader.onload = () => {
			const url = reader.result as string;
			const image = new Image();
			image.onload = () => {
				transformContext(image, orientation, imageType, resolve, reject);
			};
			image.src = url;
		};

		reader.readAsDataURL(imageBlob);
	});
// Create an image Blob, with rotation applied to compensate for EXIF orientation, if needed.
export async function rotateImage(imageBlob: Blob, imageType = imageBlob.type): Promise<Blob> {
	const orientation = await readEXIFOrientation(imageBlob);
	return applyRotation(imageBlob, orientation || 1, imageType);
}
