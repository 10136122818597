import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import MUITableCell from '@mui/material/TableCell';
import MUITableRow from '@mui/material/TableRow';
import React from 'react';
import { useSelector } from 'react-redux';

import { assetsGet } from '@/selectors';
import { getByAssetId } from '@/selectors/assetTypes';
import State from '@/store/state';
import { Asset, AssetType, Property } from '@/store/types';

import TableCell from './TableCell';
import { RepeatingAssetTableRow } from './types';

interface TableRowProps {
	assetType: AssetType;
	hasParentAssetType: boolean;
	onAssetDelete(asset: Asset, assetType: AssetType): void;
	onPropertyAnswer(answer: string, property: Property, asset: Asset, assetType: AssetType): void;
	properties: Property[];
	row: RepeatingAssetTableRow;
}

const TableRow: React.FC<TableRowProps> = ({
	assetType,
	hasParentAssetType,
	onAssetDelete,
	onPropertyAnswer,
	properties,
	row,
}) => {
	const asset = useSelector<State, Asset>((state) => assetsGet(state, row.assetId));
	const parentAssetType = useSelector<State, AssetType | null>((state) =>
		row.parentAssetId ? getByAssetId(state, row.parentAssetId) : null,
	);

	const deleteRow = React.useCallback(() => {
		onAssetDelete(asset, assetType);
	}, [asset, assetType, onAssetDelete]);

	return (
		<MUITableRow>
			{hasParentAssetType && <MUITableCell>{parentAssetType?.name ?? 'Unknown'}</MUITableCell>}
			{properties.map((property) => (
				<TableCell
					asset={asset}
					assetType={assetType}
					key={`${assetType.id}-${asset.id}-${property.id}`}
					onPropertyAnswer={onPropertyAnswer}
					property={property}
					value={asset.valueStringByPropertyId[property.id] || ''}
				/>
			))}
			<MUITableCell>
				<IconButton
					data-testid={`form__row__delete--${asset.id}`}
					onClick={() => deleteRow()}
					size="large"
				>
					<CloseIcon />
				</IconButton>
			</MUITableCell>
		</MUITableRow>
	);
};

export default TableRow;
