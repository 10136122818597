import React from 'react';
import { useSelector } from 'react-redux';

import { propertiesGet } from '@/selectors';
import { getInspectionId } from '@/selectors/inspections';
import State from '@/store/state';
import { AssetType, Property, PropertyType, SurveyAsset } from '@/store/types';
import { dateFormatShort } from '@/utils/dateFormat';

import * as Styled from './RepeatingAsset.styled';

interface RepeatingAssetTitleProps {
	asset: SurveyAsset;
	assetType: AssetType;
	index: number;
}

const RepeatingAssetTitle: React.FC<RepeatingAssetTitleProps> = ({ asset, assetType, index }) => {
	const inspection = useSelector(getInspectionId);
	const property = useSelector<State, Property>((state) =>
		propertiesGet(state, asset.surveyPropertyIds[0]),
	);

	const title: string = React.useMemo<string>(() => {
		const valueRaw = asset.valuesByPropertyId[property.id];
		switch (property.type) {
			case PropertyType.DATE: {
				if (valueRaw) {
					const date = new Date(valueRaw);
					return `#${index + 1}: ${dateFormatShort(date, inspection?.assetLibrary.language)}`;
				}
				return `#${index + 1}: ${assetType.name}`;
			}
			case PropertyType.DECIMAL:
			case PropertyType.STRING:
				return `#${index + 1}: ${valueRaw || assetType.name}`;
			default:
				return `#${index + 1}: ${assetType.name}`;
		}
	}, [asset, property]);

	return <Styled.Title>{title}</Styled.Title>;
};

export default RepeatingAssetTitle;
