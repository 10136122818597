import { ApiServiceDto, ApiStudyDto } from 'kes-common';
import React from 'react';

import TemplateChips, { TemplateChipsProps } from '@/components/TemplateChips';

import { useFilters } from '../Filters/context';

interface ChipsProps {
	template: ApiStudyDto;
}

const Chips: React.FC<ChipsProps> = ({ template }) => {
	const { setFilterValue } = useFilters();

	const onBusinessLineClick: TemplateChipsProps['onBusinessLineClick'] = React.useCallback(
		(businessLineName: string) => {
			setFilterValue('businessLine', businessLineName);
		},
		[setFilterValue],
	);

	const onLabelClick: TemplateChipsProps['onLabelClick'] = React.useCallback(
		(label: string) => {
			setFilterValue('label', label);
		},
		[setFilterValue],
	);

	const onServiceClick: TemplateChipsProps['onServiceClick'] = React.useCallback(
		(service: ApiServiceDto) => {
			setFilterValue('service', service.id);
		},
		[setFilterValue],
	);

	return (
		<TemplateChips
			onBusinessLineClick={onBusinessLineClick}
			onLabelClick={onLabelClick}
			onServiceClick={onServiceClick}
			template={template}
		/>
	);
};

export default Chips;
