import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Clear from '@mui/icons-material/Clear';
import Search from '@mui/icons-material/Search';
import { ApiProjectStudy, ApiPublishedStudyDto, useGoogleTagManager } from 'kes-common';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';

import { inspectionsRequest, publishedStudiesGet } from '@/net/api';
import { projectLoadRefresh } from '@/store/actions';
import State from '@/store/state';
import PreviewTemplateDialog from '@/pages/Project/Overview/AddInspectionDrawer/PreviewTemplateDialog';

import * as Styled from './AddInspectionDrawer.styled';
import AvailableStudy from './AvailableStudy';
import { useProject } from '../../context';

interface AddInspectionDrawerProps {
	open: boolean;
	setOpen: (state: boolean) => void;
}

const AddInspectionDrawer: React.FC<AddInspectionDrawerProps> = ({ open, setOpen }) => {
	const { trackViewItem, trackCustomEvent } = useGoogleTagManager();
	const [selectedBusinessLine, setSelectedBusinessLine] = useState<string>('');
	const [filter, setFilter] = useState<string | null>('');

	const { refetchProject } = useProject();

	const projectId = useSelector<State, string>((state) => state.project.currentProject);
	const userId = useSelector<State, string>((state) => state.user.currentUser?.id ?? '');
	const dispatch = useDispatch();

	const { data: publishedStudies } = useQuery(['getPublishedStudies'], () => publishedStudiesGet());
	const { mutate: requestInspectionsMutation, ...requestInspectionsQuery } = useMutation(
		(studies: ApiProjectStudy[]) => inspectionsRequest(projectId, studies),
		{ onSuccess: refetchProject },
	);

	const onAddInspectionToProject = React.useCallback(
		(studyId: string) => {
			requestInspectionsMutation([{ studyId, count: 1 }]);
			trackCustomEvent({ event: 'add-activity-to-project', studyId, projectId, userId });
		},
		[projectId, userId],
	);

	useEffect(() => {
		if (requestInspectionsQuery.status === 'success') {
			dispatch(projectLoadRefresh(true));
			requestInspectionsQuery.reset();
		}
	}, [requestInspectionsQuery, requestInspectionsQuery.status]);

	const onCloseDrawer = React.useCallback(() => {
		setFilter('');
		setOpen(false);
	}, []);

	const studiesList: ApiPublishedStudyDto[] = React.useMemo(() => {
		if (publishedStudies?.status === 200) {
			return publishedStudies.result
				.filter((publishedStudy) => {
					if (filter !== null) {
						const matchesLabels = publishedStudy.labels
							.map((label) => label.toLowerCase())
							.includes(filter.toLowerCase());
						const matchesDescription =
							publishedStudy.description &&
							publishedStudy.description.toLowerCase().includes(filter.toLowerCase());
						const matchesName = publishedStudy.name.toLowerCase().includes(filter.toLowerCase());
						const matchesNumber = publishedStudy.number
							.toString()
							.padStart(4, '0')
							.includes(filter.toLowerCase());
						return matchesDescription || matchesLabels || matchesName || matchesNumber;
					}
					return true;
				})
				.filter(
					(publishedStudy) =>
						selectedBusinessLine === '' ||
						publishedStudy.businessLineNames.includes(selectedBusinessLine),
				);
		}
		return [];
	}, [filter, publishedStudies, selectedBusinessLine]);

	const [studyIdToPreview, setStudyIdToPreview] = useState<string | null>(null);
	const onUseTemplate = React.useCallback(() => {
		if (studyIdToPreview == null) return;

		onAddInspectionToProject(studyIdToPreview);
		setStudyIdToPreview(null);
	}, [studyIdToPreview]);

	return (
		<Drawer anchor="right" onClose={onCloseDrawer} open={open}>
			<Box paddingX={2} sx={{ width: '500px' }}>
				<Box display="flex" justifyContent="space-between" paddingY={2}>
					<Typography variant="h6">Add Project Activities</Typography>
					<IconButton
						data-testid="action-close-drawer"
						onClick={onCloseDrawer}
						sx={{ justifySelf: 'flex-end' }}
					>
						<CloseIcon />
					</IconButton>
				</Box>
				<Alert severity="info">
					A project activity is based on a template. You can search for templates below.
				</Alert>
				<TextField
					InputProps={{
						endAdornment:
							filter !== '' ? (
								<InputAdornment onClick={() => setFilter('')} position="end">
									<Clear />
								</InputAdornment>
							) : (
								<InputAdornment position="end">
									<Search />
								</InputAdornment>
							),
					}}
					label="Search by name, code, tags"
					value={filter}
					onChange={(e) => setFilter(e.target.value)}
					fullWidth
					data-testid="input-study-search"
					margin="normal"
					variant="filled"
				/>

				<TextField
					fullWidth
					label="Business line"
					onChange={(event) => setSelectedBusinessLine(event.target.value)}
					select
					value={selectedBusinessLine}
					variant="filled"
				>
					<MenuItem value="">
						<em>None</em>
					</MenuItem>
					{publishedStudies?.status === 200 &&
						publishedStudies.result
							.reduce(
								(accumulator, publishedStudy) => [
									...accumulator,
									...publishedStudy.businessLineNames.filter((name) => !accumulator.includes(name)),
								],
								[] as string[],
							)
							.map((businessLineName) => (
								<MenuItem key={businessLineName} value={businessLineName}>
									{businessLineName}
								</MenuItem>
							))}
				</TextField>

				<Styled.AvailableStudiesContainer>
					{studiesList.map((publishedStudy) => (
						<AvailableStudy
							key={publishedStudy.id}
							study={publishedStudy}
							onAddInspectionToProject={onAddInspectionToProject}
							onStudySelect={() => {
								trackViewItem({
									items: [
										{
											item_category: publishedStudy?.businessLineNames[0],
											item_id: publishedStudy!.id,
											item_name: publishedStudy!.name,
											item_variant: publishedStudy!.assetLibraryVersion.toString(),
											price: 1,
											quantity: 1,
										},
									],
								});
								setStudyIdToPreview(publishedStudy.id);
							}}
						/>
					))}
				</Styled.AvailableStudiesContainer>
				{requestInspectionsQuery.isError && (
					<Styled.ErrorMessage>
						Something went wrong adding the activities. Try again.
					</Styled.ErrorMessage>
				)}
			</Box>
			<PreviewTemplateDialog
				onClose={() => setStudyIdToPreview(null)}
				onUseTemplate={onUseTemplate}
				template={studiesList.find((study) => study.id === studyIdToPreview)}
			/>
		</Drawer>
	);
};

export default AddInspectionDrawer;
