import { ApiFile, ApiInspectionDto } from 'kes-common';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { inspectionRelatedFileDelete } from '../../api';

const useInspectionRelatedFileDelete = (
	inspectionId: ApiInspectionDto['id'],
	fileId: ApiFile['fileId'],
	options?: UseMutationOptions,
) =>
	useMutation(
		() =>
			inspectionRelatedFileDelete(fileId, { inspectionId }).then((response) =>
				response.expectSuccess(),
			),
		options,
	);

export default useInspectionRelatedFileDelete;
