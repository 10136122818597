import React from 'react';
import { Refresh, OpenInBrowser } from '@mui/icons-material';
import {
	TableRow,
	TableCell,
	Checkbox,
	IconButton,
	Link,
	Stack,
	Typography,
	Tooltip,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { Activity, formatDateTime, disabledRegenerate } from './utils';

export interface ActivityRowProps {
	// input data
	activity: Activity;

	// user actions
	select: (activity: Activity) => void;
	regenerate: (id: string, options: { onSettled: () => void }) => void;

	// links
	activityLink: (projectId: string, inspectionId: string) => string;
	reportLink: (id: string) => string;

	// display options
	showProjectNameColumn?: boolean;
}

function ActivityRow({
	activity,
	select,
	regenerate,
	reportLink,
	activityLink,
	showProjectNameColumn,
}: ActivityRowProps): JSX.Element {
	const [disabled, setDisabled] = React.useState<boolean>(false);

	const onRowClick = () => {
		select(activity);
		// eslint-disable-next-line no-param-reassign
		activity.isRelated = !activity.isRelated;
	};

	const onRegenerate = () => {
		setDisabled(true);
		regenerate(activity.id, {
			onSettled: () => {
				setDisabled(false);
			},
		});
	};
	return (
		<TableRow key={activity.id} aria-checked={activity.isRelated} tabIndex={-1}>
			<TableCell padding="checkbox">
				<Tooltip
					title={
						activity.lastUpdatedDate
							? null
							: 'You need to answers some questions to use this report'
					}
				>
					<span>
						<Checkbox
							disabled={!activity.lastUpdatedDate}
							onChange={onRowClick}
							checked={activity.isRelated}
						/>
					</span>
				</Tooltip>
			</TableCell>

			{showProjectNameColumn && (
				<TableCell>
					<Typography>{activity.projectName}</Typography>
				</TableCell>
			)}

			<TableCell>
				<Link
					component={RouterLink}
					target="_blank"
					to={activityLink(activity.projectId, activity.id)}
				>
					<Typography>{activity.inspectionDescrOrStudyName}</Typography>
				</Link>
			</TableCell>
			<TableCell>{formatDateTime(activity.lastUpdatedDate)}</TableCell>

			<TableCell>
				<Stack direction="row" alignItems="center" gap={1}>
					<Tooltip title="Use the most recent report" placement="top">
						<span>
							<IconButton
								sx={{ p: 0 }}
								color="primary"
								disabled={
									disabledRegenerate(activity.generatedDate, activity.lastUpdatedDate) || disabled
								}
								onClick={onRegenerate}
							>
								<Refresh fontSize="small" />
							</IconButton>
						</span>
					</Tooltip>
					<Tooltip title="Open most recent report preview" placement="top">
						<span>
							<IconButton
								sx={{ p: 0 }}
								href={reportLink(activity.id)}
								target="_blank"
								color="primary"
								disabled={!activity.lastUpdatedDate}
							>
								<OpenInBrowser fontSize="small" />
							</IconButton>
						</span>
					</Tooltip>
				</Stack>
			</TableCell>
		</TableRow>
	);
}

export default ActivityRow;
