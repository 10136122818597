import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Skeleton from '@mui/material/Skeleton';
import { ApiProjectInspectionDto } from 'kes-common';
import React from 'react';

import { useActivityMembers } from '@/net/reactQuery/queries';

import MembersListItem from './MembersListItem';

interface MembersListProps {
	activityId: ApiProjectInspectionDto['id'];
	onMemberRemoveError?(): void;
	onMemberRemoveSuccess?(): void;
}

const MembersList: React.FC<MembersListProps> = ({
	activityId,
	onMemberRemoveError,
	onMemberRemoveSuccess,
}) => {
	const { data, refetch, status } = useActivityMembers(activityId);

	if (status === 'error') {
		return (
			<Alert action={<Button onClick={() => refetch()}>Try again</Button>} severity="error">
				Failed to fetch the list of activity members.
			</Alert>
		);
	}

	if (status === 'loading') {
		return (
			<>
				<Skeleton />
				<Skeleton />
			</>
		);
	}

	if (data.length === 0) {
		return <Alert severity="info">No inspectors have been added to this activity.</Alert>;
	}

	return (
		<List>
			{data.map((user) => (
				<MembersListItem
					activityId={activityId}
					key={user.userId}
					onMemberRemoveError={onMemberRemoveError}
					onMemberRemoveSuccess={onMemberRemoveSuccess}
					user={user}
				/>
			))}
		</List>
	);
};

export default MembersList;
