import React, { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { assetsGetByAssetTypeId } from '@/selectors';
import State from '@/store/state';
import { Asset, Property } from '@/store/types';

import AssetRenderer from './AssetChoice';
import classes from './Choices.module.css';

interface Props {
	toggleChoice(choiceId: string): void;
	answerSelected(choiceId: string): boolean;
	icon: ReactNode;
	iconSelected: ReactNode;
	property: Property;
}

const AssetChoices: FC<Props> = ({
	toggleChoice,
	answerSelected,
	icon,
	iconSelected,
	property,
}): JSX.Element => {
	const assets = useSelector<State, Asset[]>(
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		(state: State) => assetsGetByAssetTypeId(state, property.referencedAssetType!),
	).filter(
		(asset) =>
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			asset.valueStringByPropertyId[property.identifyingProperty!] !== undefined,
	);

	return (
		<div className={classes.root}>
			{assets.length === 0 && (
				<div className={classes.emptyChoice}>
					<div className={classes.emptyChoiceTitle}>There are no options available yet</div>
				</div>
			)}
			{assets.map(
				(asset): ReactNode => (
					<AssetRenderer
						key={asset.id}
						icon={icon}
						asset={asset}
						property={property}
						iconSelected={iconSelected}
						selected={answerSelected(asset.id)}
						onSelect={(): void => toggleChoice(asset.id)}
					/>
				),
			)}
		</div>
	);
};

export default AssetChoices;
