import { Reducer, AnyAction } from 'redux';
import { produce } from 'immer';
import AppAction, { DispatchMap } from './appAction';
import { Draft } from './utils';

export default function makeReducer<S, A extends AnyAction>(
	initialState: S | Reducer<S, A>,
	map: Partial<DispatchMap<S>>,
): Reducer<S, A> {
	let baseReducer: Reducer<S, A>;

	if (initialState instanceof Function) {
		baseReducer = initialState;
	} else {
		baseReducer = (s): S => (s === undefined ? initialState : s);
	}

	return (state, action): S => {
		const newState = baseReducer(state, action);

		const func: ((draft: Draft<S>, arg1: any) => void) | undefined =
			map[(action as AppAction).type];
		if (func) {
			return produce(newState, (draft): void => {
				func(draft, 'payload' in action ? action.payload : undefined);
			});
		}
		return newState;
	};
}
