import RadioButtonChecked from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import React, { FC } from 'react';
import { connect } from 'react-redux';

import { choicesGet } from '@/selectors';
import State from '@/store/state';

import { Choice, Property } from '../../../store/types';
import ChoiceButtons from './internal/ChoiceButtons';
import Choices from './internal/Choices';

interface Props {
	answer: string | undefined;
	onChange(payload?: string | null, otherOptionAnswer?: boolean): void;
	choiceIds: Choice['id'][];
	shouldRenderAsRadioButtons: boolean;
	disabled?: boolean;
	hasOtherOption?: boolean;
	otherOption: boolean;
	property: Property;
}

function toggleChoice(
	answer: Props['answer'],
	onChange: Props['onChange'],
	selectedAnswer: string | null,
	otherOption: boolean,
): void {
	if (answer === selectedAnswer) {
		return onChange(undefined, otherOption);
	}
	return onChange(selectedAnswer, otherOption);
}

function answerSelected(answer: Props['answer'], selectedAnswer: string): boolean {
	return answer === selectedAnswer;
}

const SingleSelect: FC<Props> = ({
	answer,
	onChange,
	choiceIds,
	disabled,
	shouldRenderAsRadioButtons,
	hasOtherOption,
	otherOption,
	property,
}): JSX.Element => {
	if (shouldRenderAsRadioButtons || hasOtherOption) {
		return (
			<Choices
				hasOtherOption={hasOtherOption}
				disabled={disabled}
				toggleChoice={(selectedAnswer, otherOptionAnswer): void =>
					toggleChoice(answer, onChange, selectedAnswer, otherOptionAnswer)
				}
				answerSelected={(selectedAnswer): boolean => answerSelected(answer, selectedAnswer)}
				icon={<RadioButtonUnchecked />}
				iconSelected={<RadioButtonChecked />}
				choiceIds={choiceIds}
				otherOption={otherOption}
				answer={answer}
				property={property}
			/>
		);
	}
	return (
		<ChoiceButtons
			disabled={disabled}
			toggleChoice={(selectedAnswer): void => toggleChoice(answer, onChange, selectedAnswer, false)}
			answerSelected={(selectedAnswer): boolean => answerSelected(answer, selectedAnswer)}
			choiceIds={choiceIds}
			property={property}
		/>
	);
};

function checkShouldRenderAsRadioButtons(state: State, choiceIds: Choice['id'][]): boolean {
	return (
		choiceIds.length > 3 ||
		!!choiceIds.find((choiceId): boolean => {
			const choice = choicesGet(state, choiceId);
			return choice.name.length > 30 || !!choice.description;
		})
	);
}

export default connect(
	(state: State, props: Pick<Props, 'choiceIds'>): Pick<Props, 'shouldRenderAsRadioButtons'> => ({
		shouldRenderAsRadioButtons: checkShouldRenderAsRadioButtons(state, props.choiceIds),
	}),
)(SingleSelect);
