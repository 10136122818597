import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Hidden from '@mui/material/Hidden';
import React, { FC, useEffect, useState } from 'react';
import { home } from '@/routes';
import ProjectForm from '@/components/ProjectForms/ProjectDetailsForm';
import { useDispatch } from 'react-redux';
import { ApiClientDto, PageTitle } from 'kes-common';
import ClientForm from '@/components/ProjectForms/ClientForm';
import { useQuery } from '@tanstack/react-query';
import { projectIndustriesGet } from '@/net/api';
import {
	Box,
	CircularProgress,
	Container,
	Snackbar,
	Step,
	StepLabel,
	Stepper,
} from '@mui/material';
import styled from 'styled-components';
import { projectCreate } from '@/store/actions';
import { useHistory } from 'react-router-dom';
import useProjectStateError, {
	ProjectCreateErrorType,
} from '@/components/hooks/useProjectStateError';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import { createProjectDetails } from './projectModel';

const LoaderContainer = styled.div`
	color: #9f0000;
	width: 100%;
	height: calc(100% - 91px);
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Progress = styled(CircularProgress)`
	color: blue-700;
	width: 100%;
	height: calc(100% - 91px);
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ProjectCreateForm: FC = (): JSX.Element => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { errorDetails, error, fatal } = useProjectStateError();

	const [projectDetails, setProjectDetails] = useState(createProjectDetails());
	const { data: industriesData, isLoading: industriesLoading } = useQuery(['getIndustries'], () =>
		projectIndustriesGet(),
	);

	const [activeStep, setActiveStep] = React.useState(0);
	const steps = ['General information', 'Client information'];

	useEffect(() => {
		if (errorDetails?.step === 0) {
			setActiveStep(0);
		}
	}, [errorDetails, error]);

	useDocumentTitle(
		'Create Project',
		activeStep === 0 ? 'General information' : 'Client information',
	);

	if (industriesLoading) {
		return (
			<LoaderContainer>
				<Progress size={70} color="inherit" />
			</LoaderContainer>
		);
	}
	if (!industriesData?.ok) {
		return <p>Error loading industries</p>;
	}

	return (
		<Container maxWidth="lg">
			<PageTitle
				breadcrumbs={[{ title: 'Projects', url: '/' }, { title: 'Create a new project' }]}
				title="Create a new project"
			/>
			<Container maxWidth="sm">
				<Box marginTop={2} paddingBottom={4}>
					<Card>
						<CardContent>
							<Hidden mdDown>
								<Stepper activeStep={activeStep} alternativeLabel>
									{steps.map((label) => (
										<Step key={label}>
											<StepLabel>{label}</StepLabel>
										</Step>
									))}
								</Stepper>
							</Hidden>
							{activeStep === 0 && (
								<ProjectForm
									projectDetails={projectDetails}
									setProjectDetails={setProjectDetails}
									onSubmit={() => {
										setActiveStep(1);
									}}
									buttons={
										<Box display="flex" flexDirection="row" justifyContent="flex-end">
											<Button onClick={() => history.push(home)}>Cancel</Button>
											<Box marginLeft={1}>
												<Button
													data-testid="action-next"
													color="primary"
													type="submit"
													variant="contained"
												>
													Next
												</Button>
											</Box>
										</Box>
									}
								/>
							)}
							{activeStep === 1 && (
								<ClientForm
									abwLinked={projectDetails.abwLinked}
									industries={industriesData.result}
									client={projectDetails.client}
									setClient={(client: ApiClientDto) =>
										setProjectDetails({ ...projectDetails, client })
									}
									onSubmit={() => {
										dispatch(
											projectCreate({
												history,
												createProject: {
													name: projectDetails.name,
													projectNumber: projectDetails.projectNumber,
													location: projectDetails.location,
													client: projectDetails.client,
													masterProjectId: projectDetails.masterProjectId,
													templates: [],
													admins: [],
												},
											}),
										);
									}}
									buttons={
										<Box display="flex" flexDirection="row" justifyContent="flex-end">
											<Button onClick={() => setActiveStep(0)}>Back</Button>
											<Box marginLeft={1}>
												<Button
													data-testid="action-create"
													color="primary"
													type="submit"
													variant="contained"
												>
													Create
												</Button>
											</Box>
										</Box>
									}
								/>
							)}
							{error &&
								errorDetails?.errorType === ProjectCreateErrorType.PROJECT_CREATE_ERROR &&
								!fatal && <Snackbar open message={error} />}
						</CardContent>
					</Card>
				</Box>
			</Container>
		</Container>
	);
};

export default ProjectCreateForm;
