import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ApiStudyDto, ApiStudyUserDto } from 'kes-common';
import React from 'react';

import AddUser from './AddUser';
import Users from './Users';

export interface TemplateShareDialogProps {
	isOpen: boolean;
	onClose(): void;
	onUserAdded(user: ApiStudyUserDto): void;
	onUserRemoved(userId: string): void;
	template: ApiStudyDto;
}

const TemplateShareDialog: React.FC<TemplateShareDialogProps> = ({
	isOpen,
	onClose,
	onUserAdded,
	onUserRemoved,
	template,
}) => (
	<Dialog fullWidth maxWidth="md" onClose={onClose} open={isOpen}>
		<DialogTitle>Share Template: {template.name}</DialogTitle>
		<DialogContent>
			<Box marginBottom={2}>
				<AddUser onSuccess={onUserAdded} template={template} />
			</Box>
			<Users onUserRemoved={onUserRemoved} template={template} />
		</DialogContent>
		<DialogActions>
			<Button color="primary" onClick={onClose} variant="contained">
				Done
			</Button>
		</DialogActions>
	</Dialog>
);

export default TemplateShareDialog;
