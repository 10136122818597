import { grey } from '@mui/material/colors';
import { ApiFile } from 'kes-common';

export type { ApiFile };

export type Activity = {
	isRelated: boolean;
	id: string;
	projectName: string;
	projectId: string;
	inspectionDescrOrStudyName: string;
	lastUpdatedDate: Date | null; // null when there are no answers yet
	generatedDate: Date | null; // null when there is no report yet
};
export const exampleData = [
	{
		isRelated: true,
		id: '1',
		projectName: 'projectName',
		inspectionDescrOrStudyName: 'studyName',
		lastUpdatedDate: new Date('2024-03-25T19:00:28.022Z'),
		generatedDate: new Date('2024-03-25T17:00:28.022Z'),
	} as Activity,
];

export interface ProjectAndActivitiesData {
	projects: { projectId: string; projectName: string; activities: Activity[] }[];
	relatedActivities: Activity[]; // all these activities have isRelated == true;
	relatedIds: string[];
	projectsNames: string[]; // populate the search input
}

// it needs an ID to be able to pass a key to react inside a map
export interface FileWithId {
	file: Blob;
	fileId: string;
}

export const colors = {
	background: grey[100],
	tabs: grey[50],
};

// *** */
const allowedFileTypes = {
	embeddable: ['.pdf', '.doc', '.docx', '.txt', '.html'],
	notEmbeddable: ['.zip', '.jpeg', '.csv', '.ppt', '.pptx', '.xls', '.xlsx'],
};
// constants used in the ui based on allowedFileTypes
export const embeddableFiles: string = allowedFileTypes.embeddable.join(' ');
export const nonEmbeddableFiles: string = allowedFileTypes.notEmbeddable.join(' ');
export const allowedFilesForInputElement: string = `${allowedFileTypes.embeddable.join(
	',',
)},${allowedFileTypes.notEmbeddable.join(',')}`;
export const isEmbeddable = (name: string): boolean => {
	const extension = name.split('.').pop();
	return extension ? allowedFileTypes.embeddable.includes(`.${extension}`) : false;
};

// *** */

export const splitFilesByType = (files: ApiFile[]) => {
	const embedded: ApiFile[] = [];
	const notEmbedded: ApiFile[] = [];
	files.forEach((file) => {
		if (file.processed) {
			embedded.push(file);
		} else {
			notEmbedded.push(file);
		}
	});
	return { embedded, notEmbedded };
};

export const setScrollbarToTop = (scrollbarRef: React.RefObject<HTMLDivElement>) => {
	if (scrollbarRef.current) {
		// eslint-disable-next-line no-param-reassign
		scrollbarRef.current.scrollTop = 0;
	}
};
export const setScrollbarToBottom = (scrollbarRef: React.RefObject<HTMLDivElement>) => {
	if (scrollbarRef.current) {
		// eslint-disable-next-line no-param-reassign
		scrollbarRef.current.scrollTop = scrollbarRef.current.scrollHeight;
	}
};

// to be moved to the uploading card element
export const getExtension = ({ name }: Blob): string => name.split('.').pop() || '';
export const getByte = ({ size }: Blob): string => {
	const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
	return `${(size / 1024 ** i).toFixed(2)} ${['B', 'KB', 'MB', 'GB', 'TB'][i]}`;
};

/**
 * Checks if the generated date is after the last updated date.
 * If true, there is no reason to regenerate it because it's up to date.
 * @param generatedDate The generated date of the report.
 * @param lastUpdatedDate The last updated date of the activity.
 * @returns True if the generated date is after the last updated date, false otherwise.
 */
export const disabledRegenerate = (
	generatedDate: Date | null,
	lastUpdatedDate: Date | null,
): boolean => {
	if (!generatedDate || !lastUpdatedDate) {
		return true;
	}
	return generatedDate >= lastUpdatedDate;
};

export const formatDateTime = (date: Date | null): string | null => {
	if (!date) {
		return null;
	}
	try {
		return date.toLocaleString('en-NL', {
			year: '2-digit',
			month: 'numeric',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
		});
	} catch (error) {
		return null;
	}
};

export function renderArrayLength<T>(arr: T[] | undefined, prefixedWhiteSpaces = 1): string {
	if (arr === undefined || arr.length === 0) return '';

	return `${'\u00A0'.repeat(prefixedWhiteSpaces)}[ ${arr.length} ]`;
}
