import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { ApiStudyDto, useUser } from 'kes-common';
import React from 'react';
import { Link } from 'react-router-dom';

import useQueryString from '@/hooks/useQueryString';
import { templateCreate } from '@/routes';

import Filters from './Filters';
import { useFilters } from './Filters/context';
import Template from './Template';

interface ListProps {
	refetchTemplates(): void;
	templates: ApiStudyDto[];
}

const templateIsInBusinessLinesList = (businessLine: string) => (template: ApiStudyDto) =>
	template.businessLineNames.includes(businessLine);
const templateIsInLabelsList = (label: string) => (template: ApiStudyDto) =>
	template.labels.includes(label);
const templateIsMine = (userId?: string) => (template: ApiStudyDto) =>
	template.users.some((user) => user.userId === userId);
const templateIsInServicesList = (serviceId: string) => (template: ApiStudyDto) =>
	Boolean(template.services.find((service) => service.id === serviceId));
const templateMatchesName = (name: string) => (template: ApiStudyDto) =>
	template.name.toLowerCase().includes(name.toLowerCase());

const List: React.FC<ListProps> = ({ refetchTemplates, templates }) => {
	const { filters } = useFilters();
	const queryString = useQueryString();
	const { user } = useUser();

	const filteredTemplates = React.useMemo(() => {
		let filteredTemplatesList: ApiStudyDto[] = templates;
		if (filters.businessLine !== '') {
			filteredTemplatesList = filteredTemplatesList.filter(
				templateIsInBusinessLinesList(filters.businessLine),
			);
		}
		if (filters.label !== '') {
			filteredTemplatesList = filteredTemplatesList.filter(templateIsInLabelsList(filters.label));
		}
		if (filters.name !== '') {
			filteredTemplatesList = filteredTemplatesList.filter(templateMatchesName(filters.name));
		}
		if (filters.ownTemplatesOnly === true) {
			filteredTemplatesList = filteredTemplatesList.filter(templateIsMine(user.user.id));
		}
		if (filters.service !== '') {
			filteredTemplatesList = filteredTemplatesList.filter(
				templateIsInServicesList(filters.service),
			);
		}
		return filteredTemplatesList;
	}, [filters, templates, user]);

	React.useEffect(() => {
		const templateId = queryString.get('templateId');
		if (templateId) {
			const element = document.getElementById(templateId);
			if (element) {
				element.scrollIntoView();
			}
		}
	}, [queryString]);

	return (
		<>
			<Filters />
			{filteredTemplates.length === 0 && (
				<Alert
					action={
						<Button color="inherit" component={Link} to={templateCreate}>
							Create template
						</Button>
					}
					severity="warning"
				>
					There are no templates with the current filters applied
				</Alert>
			)}
			{filteredTemplates.length > 0 && (
				<Grid container spacing={2}>
					{filteredTemplates.map((template) => (
						<Grid item key={template.id} xs={12}>
							<Template refetchTemplates={refetchTemplates} template={template} />
						</Grid>
					))}
				</Grid>
			)}
		</>
	);
};

export default List;
