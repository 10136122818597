import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Skeleton from '@mui/material/Skeleton';
import { PageTitle } from 'kes-common';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Route, Switch, useParams } from 'react-router-dom';

import { studyGet } from '@/net/api';
import { buildRoute, home, template } from '@/routes';

import { Provider as TemplateProvider } from './context';
import Overview from './Overview';

const Template: React.FC = () => {
	const { templateId } = useParams<{ templateId: string }>();
	const { data, isError, isLoading, refetch } = useQuery(['templates', templateId], () =>
		studyGet(templateId),
	);

	return (
		<Container maxWidth="lg">
			{isError ||
				(data && data.status !== 200 && (
					<>
						<PageTitle
							breadcrumbs={[{ title: 'Home', url: home }, { title: 'Template' }]}
							title="Template"
						/>
						<Box marginTop={4}>
							<Alert
								action={
									<Button color="inherit" onClick={() => refetch()}>
										Retry
									</Button>
								}
								severity="error"
							>
								Failed to load the Template
							</Alert>
						</Box>
					</>
				))}

			{!isError && isLoading && (
				<>
					<PageTitle
						breadcrumbs={[{ title: 'Home', url: home }, { title: 'Template' }]}
						title="Template"
					/>
					<Skeleton />
					<Skeleton />
					<Skeleton />
				</>
			)}

			{!isError && !isLoading && data && data.status === 200 && (
				<TemplateProvider value={data.result} refetch={refetch}>
					<Switch>
						<Route
							component={Overview}
							exact
							path={buildRoute(template, { templateId: data.result.id })}
						/>
					</Switch>
				</TemplateProvider>
			)}
		</Container>
	);
};

export default Template;
