/* eslint-disable no-param-reassign */
import makeReducer from '@/store/makeReducer';
import { makeUnorderedRepository } from '@/store/repository';
import { Asset, AssetList, UUID } from '@/store/types';
import * as actions from '../actions';
import { Writeable } from '../utils';

const repository = makeUnorderedRepository<AssetList>();

export const { initialState } = repository;

const deleteAsset = (
	draft: typeof initialState,
	payload: {
		assetId: UUID;
		assetTypeId: UUID;
		owningAssetId?: UUID;
		owningPropertyId?: UUID;
	},
) => {
	repository.modify(draft, payload.assetTypeId, (instance) => {
		instance.assets = instance.assets.filter((a) => a !== payload.assetId);
	});
};

const modifyAsset = (assetId: string) => (item: Writeable<AssetList>) => {
	const assetIdIsInAssetList = item.assets.some((asset) => asset === assetId);
	if (!assetIdIsInAssetList) {
		item.assets.push(assetId);
	}
};

export default makeReducer(initialState, {
	[actions.assetCreate.type](draft, payload) {
		repository.modify(draft, payload.assetTypeId, modifyAsset(payload.assetId));
	},
	[actions.assetCreateAndSave.type](draft, payload) {
		repository.modify(draft, payload.assetTypeId, modifyAsset(payload.assetId));
	},
	[actions.updateAssetFileSuccess.type](draft, payload) {
		repository.modify(draft, payload.assetTypeId, modifyAsset(payload.assetId));
	},
	[actions.assetDelete.type]: (draft, payload): void => {
		deleteAsset(draft, payload);
	},
	[actions.assetIdUpdate.type]: (draft, payload): void => {
		const assetList: AssetList | undefined = Object.values(draft.byId).find((assetListItem) =>
			assetListItem.assets.includes(payload.idOld),
		);
		if (assetList) {
			const assetIds: Asset['id'][] = [...assetList.assets, payload.idNew].filter(
				(assetId) => assetId !== payload.idOld,
			);
			repository.modify(draft, assetList.id, (item) => {
				item.assets = assetIds;
			});
		}
	},

	[actions.inspectionLoadSuccess.type]: (draft, payload): void => {
		repository.replaceAll(
			draft,
			payload.mutableAssets.assetIdsByAssetTypeId,
			(entity, id): AssetList => ({
				id,
				assets: entity,
			}),
		);
	},

	[actions.companyActivitiesAssetsSuccess.type]: (draft, payload): void => {
		repository.modify(draft, payload.assetTypeId, (item) => {
			const assetIds = payload.assets.map((asset) => asset.id);
			item.assets.push(...assetIds);
		});
	},

	[actions.companyActivitiesAssetsDeleteSuccess.type]: (draft, payload): void => {
		repository.modify(draft, payload.assetTypeId, (item) => {
			item.assets = [];
		});
	},
});
