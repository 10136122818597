import { ApiInspectionDto } from 'kes-common';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { deleteAssetsAndImagesByInspectionId } from '../../api';

const insectionDeleteMutation = (
	inspectionId: ApiInspectionDto['id'],
	options?: UseMutationOptions,
) =>
	useMutation(
		() =>
			deleteAssetsAndImagesByInspectionId(inspectionId).then((response) =>
				response.expectSuccess(),
			),
		options,
	);
export default insectionDeleteMutation;
