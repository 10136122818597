import { useState, useCallback, useEffect } from 'react';

const useSelectedFilesKaas = (inspectionId: string) => {
	const [selectedFiles, setSelectedFiles] = useState<string[]>([]);

	// load files from localStorage
	useEffect(() => {
		const storedFiles = localStorage.getItem(`selectedFiles_${inspectionId}`);
		if (storedFiles) {
			setSelectedFiles(JSON.parse(storedFiles));
		}
	}, [inspectionId, setSelectedFiles]);

	// save files to localStorage
	useEffect(() => {
		if (selectedFiles.length === 0) {
			localStorage.removeItem(`selectedFiles_${inspectionId}`);
			return;
		}
		localStorage.setItem(`selectedFiles_${inspectionId}`, JSON.stringify(selectedFiles));
	}, [inspectionId, selectedFiles]);

	/**
	 * Toggles the selection of a file and save it in local
	 *
	 * @param {string} fileId - the id of the file to select
	 */
	const selectFile = useCallback(
		(fileId: string) => {
			if (selectedFiles.includes(fileId)) {
				setSelectedFiles(selectedFiles.filter((id) => id !== fileId));
			} else {
				setSelectedFiles((prev) => [...prev, fileId]);
			}
		},
		[selectedFiles, setSelectedFiles],
	);

	/**
	 * Removes files from local storage that are not present in the backend anymore.
	 *
	 * @param {string[]} fileIds - list of files present in the backend
	 */
	const pruneFiles = (fileIds: string[]) => {
		setSelectedFiles(selectedFiles.filter((id) => fileIds.includes(id)));
	};

	return { selectedFiles, selectFile, pruneFiles };
};

export default useSelectedFilesKaas;
