import React from 'react';

interface UseLocalStorageReturnValue {
	getItem(key: string): any;
	setItem(key: string, value: any): void;
}

const useLocalStorage = (): UseLocalStorageReturnValue => {
	const getItem = React.useCallback((key: string) => {
		const item = localStorage.getItem(key);

		if (item === null) return undefined;
		if (item === 'null') return null;
		if (item === 'undefined') return undefined;

		try {
			return JSON.parse(item);
		} catch (error) {
			return item;
		}
	}, []);

	const setItem = React.useCallback((key: string, value: any) => {
		if (value === undefined) {
			localStorage.removeItem(key);
		} else {
			localStorage.setItem(key, JSON.stringify(value));
		}
	}, []);

	const returnValue: UseLocalStorageReturnValue = React.useMemo(
		() => ({ getItem, setItem }),
		[getItem, setItem],
	);

	return returnValue;
};

export default useLocalStorage;
