import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import { ApiExternalUrl, PageTitle } from 'kes-common';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { buildRoute, home, project as projectRoute } from '@/routes';
import { projectCurrent } from '@/selectors';
import { getInspectionId } from '@/selectors/inspections';
import { inspectionExternalURLsUpdate } from '@/store/actions';

import AddURLDialog from './AddURLDialog';
import ListItem from './ListItem';

const InspectionURLs: React.FC = () => {
	const [isAddURLDialogOpen, setIsAddURLDialogOpen] = React.useState<boolean>(false);

	const dispatch = useDispatch();
	const inspection = useSelector(getInspectionId);
	const project = useSelector(projectCurrent);
	const { enqueueSnackbar } = useSnackbar();

	const updateInspectionURLs = React.useCallback(
		(urls: ApiExternalUrl[]) => {
			dispatch(inspectionExternalURLsUpdate(urls));
		},
		[dispatch],
	);

	if (!inspection || !project) {
		return (
			<Box marginY={4}>
				<Container fixed maxWidth="md">
					<Alert severity="error">The project and/or inspection cannot be found.</Alert>
				</Container>
			</Box>
		);
	}

	return (
		<>
			<Box marginBottom={4}>
				<Container fixed maxWidth="md">
					<PageTitle
						breadcrumbs={[
							{ title: 'Projects', url: buildRoute(home, {}) },
							{ title: project.name, url: buildRoute(projectRoute, { projectId: project.id }) },
							{ title: inspection.assetLibrary.studyName },
							{ title: 'Related links' },
						]}
						title="Related links"
					/>

					<Box marginTop={4}>
						<Box marginBottom={2}>
							<Alert severity="info">
								You can add related tools to this activity, for instance link your iReport, Jupyter
								Notebook or Forge cloud link.
							</Alert>
						</Box>
						{inspection.urls.length === 0 && (
							<Alert
								action={
									<Button color="inherit" onClick={() => setIsAddURLDialogOpen(true)}>
										Add related link
									</Button>
								}
								severity="warning"
							>
								No related links for this activity.
							</Alert>
						)}

						{inspection.urls.length > 0 && (
							<>
								<Paper>
									<List>
										{inspection.urls.map((url) => (
											<ListItem
												inspection={inspection}
												key={url.url}
												onError={() => {
													enqueueSnackbar('Failed to delete the related link, please try again');
												}}
												onSuccess={updateInspectionURLs}
												url={url}
											/>
										))}
									</List>
								</Paper>
								<Box marginTop={2} textAlign="right">
									<Button
										color="primary"
										onClick={() => setIsAddURLDialogOpen(true)}
										startIcon={<AddIcon />}
										variant="contained"
									>
										Add related link
									</Button>
								</Box>
							</>
						)}
					</Box>
				</Container>
			</Box>

			<AddURLDialog
				inspection={inspection}
				isOpen={isAddURLDialogOpen}
				onClose={() => setIsAddURLDialogOpen(false)}
				onError={() => {
					enqueueSnackbar('Failed to add the related link, please try again');
				}}
				onSuccess={(newURLs) => {
					updateInspectionURLs(newURLs);
					setIsAddURLDialogOpen(false);
				}}
			/>
		</>
	);
};

export default InspectionURLs;
