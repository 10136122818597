import { ApiAuthorizedProjectDto } from 'kes-common';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { projectCopy } from '../../api';

interface MutationOptions {
	includeActivities: boolean;
}

const useProjectCopy = (
	projectId: string,
	options?: UseMutationOptions<ApiAuthorizedProjectDto, unknown, MutationOptions>,
) =>
	useMutation<ApiAuthorizedProjectDto, unknown, MutationOptions>(
		(mutationOptions) =>
			projectCopy(projectId, mutationOptions).then((response) => response.expectSuccess()),
		options,
	);

export default useProjectCopy;
