import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ApiAuthorizedInspectionDto } from 'kes-common';
import React from 'react';
import { Link } from 'react-router-dom';

import * as Styled from './Inspection.styled';
import InspectionUsersList from './InspectionUsersList';

interface InspectionProps {
	inspection: ApiAuthorizedInspectionDto;
}

const Inspection: React.FC<InspectionProps> = ({ inspection }) => (
	<Box marginY={2}>
		<Card>
			<CardActionArea
				component={Link}
				to={`/project/${inspection.project.id}/inspection/${inspection.id}/category/load`}
			>
				<Box flexGrow={1} marginTop={2} marginX={3}>
					<Grid container>
						<Grid item xs={6}>
							<Typography color="primary.dark" variant="subtitle1">
								{inspection.studyName}
							</Typography>
						</Grid>
						<Grid alignContent="flex-end" item xs={6}>
							<InspectionUsersList users={inspection.users} />
						</Grid>
					</Grid>
				</Box>
				<Box marginX={3}>
					<Typography variant="body1">Project: {inspection.project.name}</Typography>
					<Box marginBottom={1} marginTop={3}>
						<Styled.ProgressText variant="body2">
							{inspection.progress === 0 && 'Not started'}
							{inspection.progress > 0 && `${inspection.progress}% Complete`}
						</Styled.ProgressText>
					</Box>
				</Box>
				<Styled.LinearProgress value={inspection.progress} variant="determinate" />
			</CardActionArea>
		</Card>
	</Box>
);

export default Inspection;
