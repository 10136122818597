import { ApiFlag } from 'kes-common';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';

import { flagCreateRow } from '@/net/api';
import * as actions from '@/store/actions';
import { assetsGet, assetTypesGetOrNull } from '@/selectors';
import { Asset, AssetType } from '@/store/types';
import { flagsGetByAssetAndFlag } from '@/selectors/flags';

function* flagCreate(action: ReturnType<typeof actions.flagCreate>): SagaIterator {
	// The ID of the flag asset (the repeating asset that represents a single flag "row")
	const flagAssetId = uuid();
	// The ID of the flag itself (the flag is the relationship between the flag asset and the asset)
	const flagId = uuid();
	const { assetId, assetTypeId, flagAssetTypeId, inspectionId } = action.payload;
	yield put(actions.assetCreateAndSave({ assetId: flagAssetId, assetTypeId: flagAssetTypeId }));

	const assetType: AssetType | null = yield select(assetTypesGetOrNull, assetTypeId);

	yield call(flagCreateRow, flagId, flagAssetId, inspectionId, {
		assetId: assetType && assetType.repeating ? assetId : null,
		assetTypeId,
		flagAssetTypeId,
	});

	const flag: ApiFlag = {
		asset: assetId,
		flagAsset: flagAssetId,
		flagAssetType: flagAssetTypeId,
		id: flagId,
		// HENDRIK FIX THIS
		position: 0,
	};
	yield put(actions.flagCreateSuccess(flag));
}

function* flagDeleteEmptyRows(
	action: ReturnType<typeof actions.flagDeleteEmptyRows>,
): SagaIterator {
	const assetFlags: ApiFlag[] = yield select(
		flagsGetByAssetAndFlag,
		action.payload.assetId,
		action.payload.flag.id,
	);
	for (const assetFlag of assetFlags) {
		const flagAsset: Asset = yield select(assetsGet, assetFlag.flagAsset);
		const hasValues = Object.values(flagAsset.valueStringByPropertyId).some((valueString) =>
			Boolean(valueString),
		);
		if (!hasValues) {
			yield put(actions.flagDelete({ flagId: assetFlag.id }));
			yield put(
				actions.assetDelete({ assetId: assetFlag.flagAsset, assetTypeId: assetFlag.flagAssetType }),
			);
		}
	}
}

function* flags(): SagaIterator {
	yield takeEvery(actions.flagCreate, flagCreate);
	yield takeEvery(actions.flagDeleteEmptyRows, flagDeleteEmptyRows);
}

export default flags;
