import ArrowLeft from '@mui/icons-material/ArrowLeft';
import ArrowRight from '@mui/icons-material/ArrowRight';
import { Avatar } from '@mui/material';
import { ApiUserProfile } from 'kes-common';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { getUserProfile } from '@/net/api';

import * as Styled from './TestUserSelector.styled';
import { testUsers, TestUserType } from './users';

const TestUser: React.FC<{
	user: TestUserType;
	selected: boolean;
	selectUser: (id: string) => void;
	userProfile?: ApiUserProfile;
}> = ({ user, selected, selectUser, userProfile }) => (
	<Styled.TestUserContainer onClick={() => selectUser(user.id)} selected={selected}>
		<Avatar alt="test-user-avatar" src={user.picture} />

		<Styled.IdAndNameContainer>
			<Styled.P>
				<strong>Id:</strong>
				{user.id}
			</Styled.P>
			<Styled.P>
				<strong>Name:</strong>
				{user.name}
			</Styled.P>
			<Styled.P>
				<strong>Role:</strong>
				{user.role}
			</Styled.P>
			{!userProfile && <Styled.P>Click to load profile</Styled.P>}
		</Styled.IdAndNameContainer>
	</Styled.TestUserContainer>
);

const TestUserSelector = () => {
	const [hidden, setHidden] = useState(true);

	const [selectedUser, setSelectedUser] = useState<string | undefined>(undefined);

	const { data } = useQuery(['getUserProfiles'], () => getUserProfile());

	const setUser = (id: string) => {
		localStorage.setItem('Test-User-Id', id);
		setSelectedUser(id);
		window.location.reload();
	};

	useEffect(() => {
		const userFromLocalStorage = localStorage.getItem('Test-User-Id');
		if (userFromLocalStorage) {
			setSelectedUser(userFromLocalStorage);
		} else {
			localStorage.setItem('Test-User-Id', testUsers[1].id);
			window.location.reload();
		}
	}, []);

	return (
		<Styled.TestUserSelectorContainer>
			{hidden ? (
				<ArrowLeft onClick={() => setHidden(false)} />
			) : (
				<ArrowRight onClick={() => setHidden(true)} />
			)}
			{!hidden && (
				<Styled.TestUsersContainer>
					{testUsers.map((testUser) => (
						<TestUser
							selectUser={(id) => setUser(id)}
							selected={selectedUser === testUser.id}
							key={testUser.id}
							user={testUser}
							userProfile={
								data?.status === 200 && data.result.email === testUser.email
									? data.result
									: undefined
							}
						/>
					))}
				</Styled.TestUsersContainer>
			)}
		</Styled.TestUserSelectorContainer>
	);
};

export default TestUserSelector;
