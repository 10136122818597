import React from 'react';

import { FilterKey, Filters } from './types';

export const defaultFilters: Filters = {
	businessLine: '',
	label: '',
	name: '',
	ownTemplatesOnly: true,
	service: '',
};

export default React.createContext<{
	clearFilters(): void;
	filters: Filters;
	setFilterValue(key: FilterKey, value: boolean | string): void;
}>({
	clearFilters: () => {},
	filters: defaultFilters,
	setFilterValue: () => {},
});
