import { ApiAuthorizeUserForProject, ApiProjectUserDto } from 'kes-common';
import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import IsProjectAdministrator from '@/components/Auth/IsProjectAdministrator';
import ConfirmDialog from '@/components/rhdhv/Dialog/ConfirmDialog';
import { AddRole } from '@/components/rhdhv/RoleManagement/AddRole';
import { UserDto } from '@/components/rhdhv/RoleManagement/RoleListItem';
import RoleManagementDialog from '@/components/rhdhv/RoleManagement/RoleManagementDialog';
import UndoAction from '@/components/rhdhv/RoleManagement/UndoAction';
import { projectUserCreate, projectUserDelete } from '@/net/api';
import { useProjectUsers } from '@/net/reactQuery/queries';
import State from '@/store/state';
import { User } from '@/store/types';
import isRHDHVEmail from '@/utils/isRHDHVEmail';
import { useProject } from '../../context';

import { ProjectAdministratorList } from './ProjectAdministratorList';

const findcurrentUserRoleByUseEmail = (
	user: User | undefined,
	administrators: ApiProjectUserDto[],
	recentlyAdded: ApiProjectUserDto[],
) => {
	if (!user) {
		return undefined;
	}
	return (
		administrators.find((administrator) => administrator.email === user.email) ||
		recentlyAdded.find((administrator) => administrator.email === user.email)
	);
};

interface ProjectAdministratorsDialogProps {
	onClose: () => void;
	show: boolean;
}

const ProjectAdministratorsDialog: React.FC<ProjectAdministratorsDialogProps> = ({
	onClose,
	show,
}) => {
	const history = useHistory();
	const { project } = useProject();
	const [recentlyAdded, setRecentlyAdded] = React.useState<ApiProjectUserDto[]>([]);
	const [recentlyRemoved, setRecentlyRemoved] = React.useState<ApiProjectUserDto>();
	const [isConfirmDeleteYourselfDialogOpen, setConfirmDeleteYourselfDialogOpen] =
		React.useState(false);
	const [addProjectAdminError, setAddProjectAdminError] = React.useState<string>('');

	const currentUser = useSelector<State, User | undefined>((state) => state.user.currentUser);

	const queryClient = useQueryClient();
	const { data: projectAdmins } = useProjectUsers(project.id);
	const currentUserRole = findcurrentUserRoleByUseEmail(
		currentUser,
		projectAdmins ?? [],
		recentlyAdded,
	);

	const { mutate: createMutation } = useMutation(
		(user: ApiAuthorizeUserForProject) => projectUserCreate(project.id, user),
		{
			onSuccess: async (response, params) => {
				if (response.ok) {
					setRecentlyAdded((prevAdmins) => [...prevAdmins, response.expectSuccess()]);
					setAddProjectAdminError('');
					if (params.email === recentlyRemoved?.email) {
						setRecentlyRemoved(undefined);
					}
				} else {
					setAddProjectAdminError(response.result.message);
					await queryClient.invalidateQueries([useProjectUsers.identifier, project.id]);
				}
			},
			onError: () => {
				setAddProjectAdminError('Something went wrong');
			},
		},
	);

	const { mutate: deleteMutation } = useMutation(
		(userId: string) => projectUserDelete(project.id, { userId, roles: ['PROJECT_ADMINISTRATOR'] }),
		{
			onSuccess: (response, removedUserId) => {
				if (response.ok) {
					queryClient.invalidateQueries([useProjectUsers.identifier, project.id]);
					if (removedUserId === currentUserRole?.userId) {
						history.push('/');
					} else {
						const removedProjectAdmin =
							projectAdmins?.find((user) => user.userId === removedUserId) ||
							recentlyAdded.find((user) => user.userId === removedUserId);
						setRecentlyRemoved(removedProjectAdmin);
						setRecentlyAdded((prevAdmins) =>
							prevAdmins.filter((user) => user.userId !== removedUserId),
						);
					}
				}
			},
		},
	);

	React.useEffect(() => {
		const timeout = setTimeout(() => {
			if (recentlyRemoved) {
				setRecentlyRemoved(undefined);
			}
		}, 5000);
		return () => {
			clearTimeout(timeout);
		};
	}, [recentlyRemoved]);

	React.useEffect(() => {
		if (!show && recentlyAdded.length) {
			queryClient.invalidateQueries([useProjectUsers.identifier, project.id]);
			setRecentlyAdded([]);
		}
	}, [show, recentlyAdded]);

	const addProjectAdministrator = (email: string) => {
		createMutation({ email, roles: ['PROJECT_ADMINISTRATOR'] });
	};

	const removeProjectAdministrator = (user: UserDto): void => {
		if (user.userId === currentUserRole?.userId) {
			setConfirmDeleteYourselfDialogOpen(true);
		} else {
			deleteMutation(user.userId);
		}
	};

	const undoDelete = (): void => {
		if (recentlyRemoved) {
			addProjectAdministrator(recentlyRemoved.email);
		}
	};

	const confirmRemoveYourselfFromProject = (): void => {
		if (currentUserRole) {
			deleteMutation(currentUserRole.userId);
			setConfirmDeleteYourselfDialogOpen(false);
		}
	};

	return (
		<>
			<RoleManagementDialog
				name={`Project administrators ${project.projectDetails.name}`}
				onClose={onClose}
				open={show}
			>
				<IsProjectAdministrator>
					<AddRole
						addRole={addProjectAdministrator}
						buttonText="Add Administrator"
						error={addProjectAdminError}
						validate={isRHDHVEmail}
					/>
				</IsProjectAdministrator>
				{recentlyRemoved && (
					<UndoAction undoAction={undoDelete}>
						User {recentlyRemoved.email} was successfully removed.
					</UndoAction>
				)}
				<ProjectAdministratorList
					hasDeletePrivileges={project.hasAdminPrivileges}
					onRemoveClicked={removeProjectAdministrator}
					projectAdministrators={projectAdmins ?? []}
					recentlyAddedAdministrators={recentlyAdded}
				/>
			</RoleManagementDialog>
			<ConfirmDialog
				copy="This action is irreversible."
				handleClose={(): void => {
					setConfirmDeleteYourselfDialogOpen(false);
				}}
				onConfirm={(): void => confirmRemoveYourselfFromProject()}
				open={isConfirmDeleteYourselfDialogOpen}
				title="Are you really sure you want to remove yourself from the project?"
			/>
		</>
	);
};

export default ProjectAdministratorsDialog;
