import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { ApiInspectionDto, ApiRelatedActivityDto } from 'kes-common';

import { relatedActivityDelete } from '@/net/api';

interface MutationOptions {
	relatedActivityId: ApiRelatedActivityDto['id'];
}

const useRelatedActivityDelete = (
	inspectionId: ApiInspectionDto['id'],
	options?: UseMutationOptions<Response, unknown, MutationOptions>,
) =>
	useMutation(
		({ relatedActivityId }) =>
			relatedActivityDelete(relatedActivityId, inspectionId).then((response) =>
				response.expectSuccess(),
			),
		options,
	);

export default useRelatedActivityDelete;
