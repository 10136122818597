import makeReducer from '@/store/makeReducer';
import { initialUnorderedState, makeUnorderedRepository } from '@/store/repository';
import { Choice } from '@/store/types';

import * as actions from '../actions';

const repository = makeUnorderedRepository<Choice>();

export default makeReducer(initialUnorderedState<Choice>(), {
	[actions.inspectionLoadSuccess.type]: (draft, payload): void => {
		repository.replaceAll(draft, payload.assetLibrary.choicesById, (entity): Choice => entity);
	},
});
