import { useGoogleTagManager } from 'kes-common';
import { useCallback } from 'react';

/**
 * This hook is used to track Kaas events.
 *  * It uses the Google Tag Manager hook,
 *  * Can also add backend tracking when needed
 * @returns An object containing functions for tracking various Kaas events.
 *
 */
const useTrackKaasEvents = () => {
	const { trackCustomEvent } = useGoogleTagManager();

	/**
	 * call this to track pressing the assist button
	 */
	const trackKaasAssist = useCallback(
		(delay: number, success: boolean) => {
			trackCustomEvent({
				event: 'kaas-assist',
				delay,
				success,
			});
		},
		[trackCustomEvent],
	);

	/**
	 * call this to track when an user select a suggested answer
	 */
	const trackKaasAcceptedAnswer = useCallback(
		(property: string, question: string, questionType: string, answer: string) => {
			trackCustomEvent({
				event: 'kaas-accepted-answer',
				property,
				question,
				questionType,
				answer,
			});
		},
		[trackCustomEvent],
	);

	// Add more events here if needed.

	return {
		trackKaasAssist,
		trackKaasAcceptedAnswer,
		// Add more events here if needed.
	};
};

export default useTrackKaasEvents;
