import Checkbox from '@mui/material/Checkbox';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ApiFile } from 'kes-common';
import React from 'react';

interface RelatedFileProps {
	isDisabled: boolean;
	onCheckboxChange(relatedFile: ApiFile, checked: boolean): void;
	propertyRelatedFiles?: ApiFile[];
	relatedFile: ApiFile;
}

const RelatedFile: React.FC<RelatedFileProps> = ({
	isDisabled,
	onCheckboxChange,
	propertyRelatedFiles,
	relatedFile,
}) => {
	const isChecked = React.useMemo<boolean>(
		() =>
			Boolean(
				propertyRelatedFiles &&
					propertyRelatedFiles.some((file) => file.fileId === relatedFile.fileId),
			),
		[propertyRelatedFiles],
	);

	const onClick = React.useCallback(() => {
		onCheckboxChange(relatedFile, !isChecked);
	}, [isChecked, onCheckboxChange, relatedFile]);

	return (
		<ListItem onClick={onClick} disablePadding key={relatedFile.fileId}>
			<ListItemButton>
				<ListItemIcon>
					<Checkbox checked={isChecked} disabled={isDisabled} />
				</ListItemIcon>
				<ListItemText primary={relatedFile.name} />
			</ListItemButton>
		</ListItem>
	);
};

export default RelatedFile;
