import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import { ApiProjectDetailsDto } from 'kes-common';
import { useSnackbar } from 'notistack';
import React from 'react';

import { MasterProject } from '@/components/ProjectImport/model';
import SearchProjects from '@/components/ProjectImport/SearchProjects';

interface ProjectNameDialogProps {
	onClose: DialogProps['onClose'];
	open: DialogProps['open'];
	projectDetails: ApiProjectDetailsDto;
	setProjectDetails: (projectDetails: ApiProjectDetailsDto) => void;
}

const ProjectNameDialog: React.FC<ProjectNameDialogProps> = ({
	onClose,
	open,
	projectDetails,
	setProjectDetails,
}) => {
	const [hasMasterProjectID, setHasMasterProjectID] = React.useState<boolean>(
		Boolean(projectDetails.masterProjectId),
	);
	const { enqueueSnackbar } = useSnackbar();

	return (
		<Dialog onClose={onClose} open={open}>
			<DialogTitle>Master Project ID / Project Name</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Select a <em>Master Project ID</em> from ABW, or add a manual project name.
				</DialogContentText>

				<RadioGroup
					name="hasMasterProjectId"
					onChange={(event) => setHasMasterProjectID(event.target.value === 'search')}
					row
				>
					<FormControlLabel
						control={<Radio checked={hasMasterProjectID} />}
						label="Search"
						value="search"
					/>
					<FormControlLabel
						control={<Radio checked={!hasMasterProjectID} />}
						label="Manual"
						value="manual"
					/>
				</RadioGroup>

				{hasMasterProjectID && (
					<SearchProjects
						onError={enqueueSnackbar}
						onNotFound={() => setHasMasterProjectID(false)}
						projectToImport={
							projectDetails.masterProjectId
								? ({
										id: projectDetails.masterProjectId,
										name: projectDetails.name,
								  } as MasterProject)
								: null
						}
						setProjectToImport={(project) => {
							setProjectDetails({
								...projectDetails,
								abwLinked: true,
								masterProjectId: project ? project.id : projectDetails.masterProjectId,
								name: project ? project.name : projectDetails.name,
							});
						}}
					/>
				)}
				{!hasMasterProjectID && (
					<TextField
						data-testid="input-project-name"
						fullWidth
						label="Project name"
						name="name"
						onChange={(event) => {
							setProjectDetails({
								...projectDetails,
								abwLinked: false,
								masterProjectId: null,
								name: event.target.value,
							});
						}}
						value={projectDetails.name}
					/>
				)}
			</DialogContent>
			<DialogActions>
				<Button
					data-testid="project-change-name-done"
					onClick={() => {
						if (onClose) {
							onClose({}, 'escapeKeyDown');
						}
					}}
				>
					Done
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ProjectNameDialog;
