/* eslint-disable no-param-reassign */
import * as actions from '@/store/actions';
import makeReducer from '@/store/makeReducer';
import { StateUser as State } from '@/store/state';

const initialState: State = {
	currentUser: undefined,
};

export default makeReducer(initialState, {
	[actions.projectLoadSuccess.type](draft, payload): void {
		draft.currentUser = payload.user ?? undefined;
	},
});
