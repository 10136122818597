import React, { FC, ReactNode } from 'react';

import { Asset, Property } from '@/store/types';

import classes from './Choice.module.css';

interface Props {
	asset: Asset;
	property: Property;
	icon: ReactNode;
	iconSelected: ReactNode;
	selected: boolean;
	onSelect(): void;
}

const AssetRenderer: FC<Props> = ({
	asset,
	property,
	selected,
	icon,
	iconSelected,
	onSelect,
}): JSX.Element => {
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const name = asset.valueStringByPropertyId[property.identifyingProperty!];
	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
		<div
			className={selected ? classes.rootSelected : classes.root}
			role="button"
			onClick={onSelect}
		>
			{selected ? iconSelected : icon}
			<div className={classes.title}>{name}</div>
		</div>
	);
};

export default AssetRenderer;
