import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import { FormikConfig, useFormik } from 'formik';
import { PageTitle } from 'kes-common';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import useMasterTemplateCreate from '@/net/reactQuery/mutations/useMasterTemplateCreate';
import { home, masterTemplates } from '@/routes';

interface FormValues {
	description: string;
	name: string;
	template: File | null;
}

const MasterTemplateCreate: React.FC = () => {
	const fieldUpload = React.useRef<HTMLInputElement>(null);

	const history = useHistory();
	const masterTemplateCreateQuery = useMasterTemplateCreate();
	const { enqueueSnackbar } = useSnackbar();

	const initialValues: FormValues = {
		description: '',
		name: '',
		template: null,
	};

	const onSubmit: FormikConfig<FormValues>['onSubmit'] = React.useCallback(
		async (values, formikBag) => {
			formikBag.setSubmitting(true);
			try {
				await masterTemplateCreateQuery.mutateAsync({
					description: values.description,
					file: values.template,
					name: values.name,
				});
				enqueueSnackbar('Master template successfully created');
				history.push(masterTemplates);
			} catch (error) {
				enqueueSnackbar('There was an error creating your master template');
			} finally {
				formikBag.setSubmitting(true);
			}
		},
		[enqueueSnackbar, history, masterTemplateCreateQuery],
	);

	const validationSchema = yup.object<FormValues>().shape({
		description: yup
			.string()
			.required(
				'Please add a description for this master template. It helps others a lot in deciding whether they would like to use this master template for their templates.',
			),
		name: yup.string().required('Please provide a name for your master template.'),
		template: yup
			.mixed()
			.default(null)
			.nullable()
			.required('Please select a file for the master template')
			.test(
				'fileType',
				'Please select a Word document for the master template. Other file types cannot be converted to a master template.',
				(value) =>
					value &&
					(value as File).type ===
						'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			),
	});

	const formik = useFormik<FormValues>({
		initialValues,
		onSubmit,
		validationSchema,
	});

	const fieldHasError = React.useCallback(
		(fieldName: keyof FormValues) => Boolean(formik.errors[fieldName] && formik.touched[fieldName]),
		[formik],
	);
	const fieldGetHelperText = React.useCallback(
		(fieldName: keyof FormValues, helperText: string) =>
			fieldHasError(fieldName) ? formik.errors[fieldName]! : helperText,
		[fieldHasError, formik],
	);

	const onFileRemove = React.useCallback(() => {
		formik.setFieldValue('template', null);
		formik.setFieldTouched('template', false);
	}, [formik]);

	const onFileUpload: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
		(event) => {
			if (event.target.files) {
				// It is strange that setting a field's value
				// doesn't automatically mark it as touched.
				formik.setFieldTouched('template');
				formik.setFieldValue('template', event.target.files[0]);
				formik.validateField('template');
			}
		},
		[formik],
	);

	const onFileUploadButtonClick = React.useCallback(() => {
		if (fieldUpload.current) {
			fieldUpload.current.click();
		}
	}, [fieldUpload]);

	return (
		<Container maxWidth="lg">
			<PageTitle
				breadcrumbs={[
					{ title: 'Home', url: home },
					{ title: 'Master Templates', url: masterTemplates },
					{ title: 'Create Master Template' },
				]}
				title="Create Master Template"
			/>
			<Box marginTop={2}>
				<Card>
					<CardContent>
						<form onSubmit={formik.handleSubmit}>
							<TextField
								error={fieldHasError('name')}
								fullWidth
								helperText={fieldGetHelperText(
									'name',
									'A unique name for the Master Template for identification',
								)}
								label="Name"
								margin="normal"
								name="name"
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								value={formik.values.name}
							/>

							<TextField
								error={fieldHasError('description')}
								fullWidth
								helperText={fieldGetHelperText(
									'description',
									'A brief description of the Master Template and what it contains',
								)}
								label="Description"
								margin="normal"
								name="description"
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								value={formik.values.description}
							/>

							<FormControl fullWidth margin="normal">
								{formik.values.template && (
									<List disablePadding>
										<ListItem
											disablePadding
											secondaryAction={
												<IconButton edge="end" onClick={onFileRemove}>
													<ClearIcon />
												</IconButton>
											}
										>
											<ListItemButton role={undefined} selected>
												<ListItemText primary={formik.values.template.name} />
											</ListItemButton>
										</ListItem>
									</List>
								)}

								{!formik.values.template && (
									<>
										<input
											// The most Microsoft thing I have ever seen. WTF is this?
											accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
											name="template"
											onChange={onFileUpload}
											ref={fieldUpload}
											style={{ display: 'none' }}
											type="file"
										/>
										<Button
											color="secondary"
											fullWidth
											onClick={onFileUploadButtonClick}
											variant="contained"
										>
											Choose file
										</Button>
									</>
								)}
								<FormHelperText error={fieldHasError('template')}>
									{fieldGetHelperText(
										'template',
										'The Word document to be used as the Master Template. The Kes Word Add-In will be attached to it.',
									)}
								</FormHelperText>
							</FormControl>

							<Button
								color="primary"
								disabled={formik.isSubmitting || formik.isValidating}
								type="submit"
								variant="contained"
							>
								Save
							</Button>
						</form>
					</CardContent>
				</Card>
			</Box>
		</Container>
	);
};

export default MasterTemplateCreate;
