import React from 'react';
import * as Styled from '@/components/Question/Types/Location/Location.styles';
import pointSelected from '@/assets/point_selected.png';
import pointRed from '@/assets/point_red.png';
import L, { BaseIconOptions } from 'leaflet';
import { ExtendedLocation } from '@/components/Question/Types/Location/MapModal';
import { parseLatLng } from '@/components/Question/Types/Location/location.utils';
import { Marker } from 'react-leaflet';

const leafletIconStandardProps: BaseIconOptions = {
	iconAnchor: [14, 24],
	popupAnchor: [10, -44],
	iconSize: [16, 24],
	shadowUrl: undefined,
	shadowSize: undefined,
	shadowAnchor: undefined,
	className: 'leaflet-div-icon',
};

const leafletMarkerPointSelected = new L.Icon({
	iconUrl: pointSelected,
	iconRetinaUrl: pointSelected,
	...leafletIconStandardProps,
});

const leafletMarkerPointRed = new L.Icon({
	iconUrl: pointRed,
	iconRetinaUrl: pointRed,
	...leafletIconStandardProps,
});

const LeafletMarker: React.FC<ExtendedLocation> = (props) => {
	const { pointString, onClick, active } = props;
	const { lat, lng } = parseLatLng(pointString);

	return (
		<Marker
			eventHandlers={{
				click: () => {
					if (onClick) {
						onClick(props);
					}
				},
			}}
			icon={active ? leafletMarkerPointSelected : leafletMarkerPointRed}
			position={[lat, lng]}
		>
			<Styled.Point src={pointSelected} />
		</Marker>
	);
};

export default LeafletMarker;
