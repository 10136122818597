import CircleIcon from '@mui/icons-material/Circle';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';

import { useProject } from '../context';

const ProjectInformation: React.FC = () => {
	const { project } = useProject();
	const { location } = project.projectDetails;

	return (
		<>
			<Typography color="primary.dark" gutterBottom variant="h6">
				General
			</Typography>
			<Box marginTop={2}>
				<Typography color="textSecondary" gutterBottom variant="overline">
					Master Project ID
				</Typography>
				<Typography data-testid="project__master-project-id">
					<Tooltip title={project.projectDetails.abwLinked ? 'Linked to ABW' : 'Not linked to ABW'}>
						<CircleIcon
							color={project.projectDetails.abwLinked ? 'success' : 'action'}
							fontSize="small"
							sx={{ position: 'relative', marginRight: '4px', top: '4px' }}
						/>
					</Tooltip>
					{project.projectDetails.masterProjectId || '-'}
				</Typography>
			</Box>
			<Box marginTop={3}>
				<Typography color="textSecondary" gutterBottom variant="overline">
					Company name
				</Typography>
				<Typography data-testid="project__company-name">
					{project.projectDetails.client.companyName}
				</Typography>
			</Box>
			<Box marginTop={3}>
				<Typography color="textSecondary" gutterBottom variant="overline">
					Project number
				</Typography>
				<Typography data-testid="project__number">
					{project.projectDetails.projectNumber}
				</Typography>
			</Box>
			<Box marginTop={3}>
				<Typography color="textSecondary" gutterBottom variant="overline">
					Address
				</Typography>
				<Typography>
					<span data-testid="project__address--line-one">
						{location.streetName} {location.houseNumber}, {location.postalCode}
					</span>
					<br />
					<span data-testid="project__address--line-two">
						{location.city}, {location.country}
					</span>
				</Typography>
			</Box>
		</>
	);
};

export default ProjectInformation;
