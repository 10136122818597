import Divider from '@mui/material/Divider';
import MUIDrawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import { useFeatureFlag } from 'kes-common';
import React from 'react';

import Activities from './Activities';
import * as Styled from './Drawer.styled';
import LegalLinks from './LegalLinks';
import MasterTemplates from './MasterTemplates';
import Projects from './Projects';
import Templates from './Templates';
import User from './User';

interface DrawerProps {
	onClose(): void;
	open: boolean;
}

const Drawer: React.FC<DrawerProps> = ({ onClose, open }) => {
	const MASTER_TEMPLATES = useFeatureFlag({ name: 'MASTER_TEMPLATES' });

	return (
		<MUIDrawer anchor="left" onClose={onClose} open={open}>
			<Styled.List>
				<ListItem>
					<Projects />
				</ListItem>
				<ListItem>
					<Activities />
				</ListItem>
				<ListItem>
					<Templates />
				</ListItem>
				{MASTER_TEMPLATES && (
					<ListItem>
						<MasterTemplates />
					</ListItem>
				)}
				<Divider />
				<ListItem>
					<User />
				</ListItem>
				<Divider />
				<ListItem>
					<LegalLinks />
				</ListItem>
			</Styled.List>
		</MUIDrawer>
	);
};

export default Drawer;
