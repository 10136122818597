import React, { SetStateAction } from 'react';
import { ExtendedLocation } from '@/components/Question/Types/Location/MapModal';
import { Box, Card, IconButton, Menu, MenuItem } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { getBoundsForPoints } from '@/components/Question/Types/Location/location.utils';
import Coordinates from '@/components/Question/Types/Location/Coordinates';
import Map from '@/components/Question/Types/Location/Maps';
import Marker from '@/components/Question/Types/Location/Maps/Marker';
import { mapboxEnabled } from '@/components/Question/Types/Location/index';
import { StaticMap } from 'react-map-gl';
import * as Styled from './Location.styles';
import MAP_STYLE from './map-style-basic.json';

interface StaticAnswerProps {
	locations: ExtendedLocation[];
	editLocation: () => void;
	clearAnswer: () => void;
	setShowMapModal: React.Dispatch<SetStateAction<boolean>>;
}

const StaticAnswer: React.FC<StaticAnswerProps> = ({
	locations,
	editLocation,
	clearAnswer,
	setShowMapModal,
}) => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLAnchorElement | HTMLButtonElement | null>(
		null,
	);
	const open = Boolean(anchorEl);
	const bounds = getBoundsForPoints(locations);

	const handleClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Card>
			<Box py={2} px={3} display="flex">
				<Styled.StaticMapContainer onClick={() => setShowMapModal(true)}>
					{mapboxEnabled ? (
						<StaticMap
							{...bounds}
							mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN!}
							width="170px"
							height="170px"
							mapStyle={MAP_STYLE}
						>
							{locations.map((location) => (
								<Marker key={location.id} {...location} />
							))}
						</StaticMap>
					) : (
						<Map viewport={bounds} width="170px" height="170px">
							{locations.map((location) => (
								<Marker key={location.id} {...location} />
							))}
						</Map>
					)}
				</Styled.StaticMapContainer>
				<Box ml={3} display="flex" flexDirection="column" flexGrow={3}>
					{locations.map((location) => (
						<Styled.AddressContainer>
							{location.name && (
								<Styled.LocationName variant="body1">{location.name}</Styled.LocationName>
							)}
							<Styled.Address>|</Styled.Address>
							{location.address && <Styled.Address>{location.address}</Styled.Address>}
							<Styled.Address>|</Styled.Address>
							<Styled.Address>
								<i>
									<Coordinates pointString={location.pointString} />
								</i>
							</Styled.Address>
						</Styled.AddressContainer>
					))}
				</Box>
				<Box justifySelf="flex-end">
					<IconButton
						aria-label="more"
						aria-controls="long-menu"
						aria-haspopup="true"
						onClick={handleClick}
						size="large"
					>
						<MoreHoriz />
					</IconButton>
					<Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
						<MenuItem
							onClick={() => {
								editLocation();
								handleClose();
							}}
						>
							Edit
						</MenuItem>
						<MenuItem
							onClick={() => {
								clearAnswer();
								handleClose();
							}}
						>
							Remove all locations
						</MenuItem>
					</Menu>
				</Box>
			</Box>
		</Card>
	);
};
export default StaticAnswer;
