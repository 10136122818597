import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { NameAvatar, useUser } from 'kes-common';
import React from 'react';
import { useHistory } from 'react-router';

import { TEST_USERS_ENABLED } from '@/constants';
import { buildRoute, userProfile } from '@/routes';

import * as Styled from './Drawer.styled';

const User: React.FC = () => {
	const [userVisible, setUserVisible] = React.useState<boolean>(false);
	const history = useHistory();
	const { signout, user } = useUser();

	return (
		<Box display="flex" flexDirection="column">
			<Styled.Button
				endIcon={<ArrowDropDownIcon color="action" />}
				fullWidth
				onClick={() => setUserVisible(!userVisible)}
			>
				{/*
					This would have been better as a `startIcon` prop on the button.
					Unfortunately mui adds a font size to it that is hard to override.
				*/}
				<NameAvatar
					avatarProps={{ sx: { fontSize: '12px', height: 24, marginRight: 1, width: 24 } }}
					isEmail={!user.name}
					name={user.name || user.email}
				/>
				<Typography color="textPrimary" noWrap textOverflow="ellipsis" variant="subtitle2">
					{user.name}
				</Typography>
			</Styled.Button>
			<Collapse in={userVisible}>
				<List>
					<ListItem onClick={() => history.push(buildRoute(userProfile, { userId: user.user.id }))}>
						<ListItemIcon sx={{ minWidth: 'auto', marginRight: 1 }}>
							<AccountCircleIcon color="secondary" />
						</ListItemIcon>
						<ListItemText color="grey.900" primary="Profile" />
					</ListItem>
					<ListItem disabled={TEST_USERS_ENABLED} onClick={signout}>
						<ListItemIcon sx={{ minWidth: 'auto', marginRight: 1 }}>
							<ExitToAppIcon />
						</ListItemIcon>
						<ListItemText primary="Sign out" />
					</ListItem>
				</List>
			</Collapse>
		</Box>
	);
};

export default User;
