import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Loader } from 'kes-common';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Route, Switch, useParams } from 'react-router';

import { projectGetWithid } from '@/net/api';
import { project, projectSettings, projectSettingsEdit } from '@/routes';

import { Provider as ProjectContextProvider } from './context';
import Edit from './Edit';
import Overview from './Overview';
import Settings from './Settings';

const Project: React.FC = () => {
	const { projectId } = useParams<Record<string, string>>();
	const { data, isError, isFetching, refetch } = useQuery(['project', projectId], () =>
		projectGetWithid(projectId),
	);

	return (
		<Container maxWidth="lg">
			{(isError || (data && data.status !== 200)) && (
				<Box marginTop={2}>
					<Typography>
						There was an error while fetching the project, please try again later.
					</Typography>
				</Box>
			)}
			{isFetching && <Loader />}
			{data?.status === 200 && (
				<ProjectContextProvider refetch={refetch} value={data.result}>
					<Switch>
						<Route component={Edit} exact path={projectSettingsEdit} />
						<Route component={Overview} exact path={project} />
						<Route component={Settings} exact path={projectSettings} />
					</Switch>
				</ProjectContextProvider>
			)}
		</Container>
	);
};

export default Project;
