import React, { useCallback, useState } from 'react';
import StaticAnswer from '@/components/Question/Types/Location/StaticAnswer';
import MapModal, { ExtendedLocation } from '@/components/Question/Types/Location/MapModal';
import { Box, Button, Card } from '@mui/material';
import { ApiPointValue } from 'kes-common';
import { Property } from '@/store/types';
import { useDispatch } from 'react-redux';
import { reloadLocations } from '@/store/actions';
import {
	removeExtendedProperties,
	toMarkers,
} from '@/components/Question/Types/Location/location.utils';

export const mapboxEnabled = process.env.REACT_APP_USE_MAPBOX === 'true';

interface LocationAnswerProps {
	property: Property;
	onChange: (payload?: string, otherOption?: boolean, location?: ApiPointValue[]) => void;
	disabled?: boolean;
	assetId: string | null;
	assetTypeId: string;
	location: ApiPointValue[];
}

const Location: React.FC<LocationAnswerProps> = ({
	onChange,
	location,
	property,
	assetTypeId,
	assetId,
	disabled,
}) => {
	const [showMapModal, setShowMapModal] = useState(false);
	const [currentLocation, setCurrentLocation] = useState<ExtendedLocation>();
	const [locationsState, setLocationsState] = useState<ExtendedLocation[]>(toMarkers(location));
	const dispatch = useDispatch();

	const clearAnswers = useCallback(() => {
		onChange(undefined, undefined, []);
		setLocationsState([]);
		if (assetId) {
			dispatch(
				reloadLocations({
					assetId,
					propertyId: property.id,
					assetTypeId,
					locations: [],
				}),
			);
		}
	}, [assetId]);

	const save = useCallback(
		(newLocations: ExtendedLocation[]) => {
			onChange(undefined, undefined, removeExtendedProperties(newLocations));
			if (assetId) {
				dispatch(
					reloadLocations({
						assetId,
						propertyId: property.id,
						assetTypeId,
						locations: newLocations,
					}),
				);
			}
		},
		[assetId],
	);

	const closeModal = useCallback(
		(newLocations?: ExtendedLocation[]) => {
			if (newLocations && assetId) {
				dispatch(
					reloadLocations({
						assetId,
						propertyId: property.id,
						assetTypeId,
						locations: newLocations,
					}),
				);
			}

			setShowMapModal(false);
		},
		[assetId],
	);

	return (
		<>
			<MapModal
				question={property.question}
				currentLocation={currentLocation}
				setCurrentLocation={setCurrentLocation}
				setLocations={setLocationsState}
				showMapModal={showMapModal}
				onClose={closeModal}
				locations={locationsState}
				save={save}
			/>
			{location.length > 0 && !disabled ? (
				<StaticAnswer
					setShowMapModal={setShowMapModal}
					editLocation={() => setShowMapModal(true)}
					clearAnswer={clearAnswers}
					locations={toMarkers(location)}
				/>
			) : (
				<Card>
					<Box display="flex" justifyContent="center" my={2}>
						<Button
							disabled={disabled}
							variant="contained"
							color="secondary"
							onClick={() => setShowMapModal(true)}
						>
							Select locations
						</Button>
					</Box>
				</Card>
			)}
		</>
	);
};

export default Location;
