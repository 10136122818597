import { ApiActionDto } from 'kes-common';

import * as actions from '@/store/actions';
import makeReducer from '@/store/makeReducer';
import { initialUnorderedState, makeUnorderedRepository } from '@/store/repository';

const repository = makeUnorderedRepository<ApiActionDto>();

export default makeReducer(initialUnorderedState<ApiActionDto>(), {
	[actions.inspectionLoadSuccess.type]: (draft, payload): void => {
		repository.replaceAll(draft, payload.assetLibrary.actionsById, (entity) => entity);
	},
});
