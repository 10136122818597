import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { PageTitle } from 'kes-common';
import React from 'react';
import { Link } from 'react-router-dom';

import { buildRoute, home, project as projectRoute, projectSettingsEdit } from '@/routes';

import ClientInformation from './ClientInformation';
import ProjectInformation from './ProjectInformation';
import * as Styled from './Settings.styled';
import { useProject } from '../context';

const ProjectSettings: React.FC = () => {
	const { project } = useProject();

	return (
		<>
			<PageTitle
				actions={
					<Button
						color="primary"
						component={Link}
						data-testid={`action-project-${project.id}-edit`}
						to={buildRoute(projectSettingsEdit, { projectId: project.id })}
						variant="contained"
					>
						Edit
					</Button>
				}
				breadcrumbs={[
					{ title: 'Projects', url: home },
					{
						title: project.projectDetails.name,
						url: buildRoute(projectRoute, { projectId: project.id }),
					},
					{ title: 'Project settings' },
				]}
				title={project.projectDetails.name}
			/>
			<Box marginTop={2}>
				<Grid container spacing={3}>
					<Grid item md={6} xs={12}>
						<Card>
							<Styled.CardContent>
								<ProjectInformation />
							</Styled.CardContent>
						</Card>
					</Grid>
					<Grid item md={6} xs={12}>
						<Card>
							<Styled.CardContent>
								<ClientInformation />
							</Styled.CardContent>
						</Card>
					</Grid>
				</Grid>
			</Box>
		</>
	);
};

export default ProjectSettings;
