import { SagaIterator } from '@redux-saga/core';
import { put, retry, select, StrictEffect } from 'redux-saga/effects';
import { ApiValueUpsert } from 'kes-common';
import * as actions from '@/store/actions';
import { MAX_ANSWER_UPDATE_WORKERS, MAX_RETRIES, RETRY_DELAY } from '@/constants';
import { answerUpsertForRepeatingAsset, answerUpsertForSingularAsset } from '@/net/api';
import { InspectionId, MULTIPLE_SELECT_SEPERATOR, Property, PropertyType } from '@/store/types';
import { assetTypesGet, propertiesGet } from '@/selectors';
import { collectors, queue } from './internal/queue';

function* storeQuestionAnswer(
	action: ReturnType<typeof actions.assetAnswer>,
	inspectionId: InspectionId,
): SagaIterator {
	yield put(actions.saveStarting());
	try {
		let valueString: string | null = '';

		const property: Property = yield select(propertiesGet, action.payload.propertyId);

		if (property.type === PropertyType.MULTI_SELECT) {
			const split = action.payload.answer
				? action.payload.answer.split(MULTIPLE_SELECT_SEPERATOR).map((a) => a.trim())
				: [];

			const uuidPattern =
				/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
			const isLastItemUUID = uuidPattern.test(split[split.length - 1]);

			if (action.payload.otherOption) {
				valueString = isLastItemUUID ? '' : split.slice(-1).join(MULTIPLE_SELECT_SEPERATOR);
			} else {
				valueString = isLastItemUUID
					? split.join(MULTIPLE_SELECT_SEPERATOR)
					: split.slice(0, -1).join(MULTIPLE_SELECT_SEPERATOR);
			}
		} else {
			valueString = action.payload.answer;
		}

		const apiAnswer: ApiValueUpsert = {
			propertyId: action.payload.propertyId,
			valueString,
			noteText: action.payload.noteText,
			applicable: action.payload.applicable,
			other: action.payload.otherOption,
			location: action.payload.location,
		};
		const assetType = yield select(assetTypesGet, action.payload.assetTypeId);
		const response = assetType.repeating
			? yield retry(
					MAX_RETRIES,
					RETRY_DELAY,
					answerUpsertForRepeatingAsset,
					action.payload.assetId,
					inspectionId,
					apiAnswer,
			  )
			: yield retry(
					MAX_RETRIES,
					RETRY_DELAY,
					answerUpsertForSingularAsset,
					inspectionId,
					apiAnswer,
			  );
		response.expectSuccess();
		yield put(actions.saveDone());
	} catch (e) {
		yield put(actions.saveError(e as Error));
		yield put(actions.assetAnswerError(e as Error));
	}
}

// eslint-disable-next-line require-yield
function* getQueueKey(
	action: ReturnType<typeof actions.assetAnswer>,
): IterableIterator<StrictEffect | string> {
	return action.payload.assetId + action.payload.propertyId;
}

function* answers(inspectionId: InspectionId | null): SagaIterator {
	if (inspectionId === null) {
		return;
	}
	yield queue(
		[
			// Answering a question:
			actions.assetAnswer,
		],
		collectors.takeLatest(),
		{
			keyResolver: getQueueKey,
			maxTasks: MAX_ANSWER_UPDATE_WORKERS,
		},
		storeQuestionAnswer,
		inspectionId,
	);
}

export default answers;
