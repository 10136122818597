import React, { ClipboardEvent, KeyboardEvent, Ref } from 'react';
import classNames from 'classnames';
import MTextField, { TextFieldProps as MTextFieldProps } from '@mui/material/TextField';
import classes from './TextField.module.css';

export type TextFieldProps = MTextFieldProps & {
	pattern?: RegExp;
	forwardRef?: Ref<HTMLInputElement>;
	min?: number;
	max?: number;
	maxLength?: number;
};

let target: HTMLElement;

function checkClipboard(event: ClipboardEvent, pattern?: RegExp): void {
	if (!pattern || !event.clipboardData) {
		return;
	}
	event.preventDefault();

	const newText = event.clipboardData
		.getData('text/plain')
		.split('')
		.filter((char): boolean => pattern.test(char))
		.join('');
	document.execCommand('insertText', false, newText);
}

const onFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
	event.preventDefault();
	target = event.target as HTMLElement;
};

const handleKeyPress = (event: KeyboardEvent, pattern?: RegExp): void => {
	if (!event.shiftKey && event.key === 'Enter' && target) {
		target.blur();
	}
	if (pattern && !pattern.test(event.key)) {
		event.preventDefault();
	}
};

const TextField = (props: TextFieldProps): JSX.Element => {
	const { forwardRef, autoFocus, pattern, ...filteredProps } = props;
	const {
		className,
		InputProps,
		max,
		maxLength,
		min,
		multiline,
		onFocus: onFocusProp,
		onKeyPress,
		onPaste,
	} = filteredProps;

	return (
		<MTextField
			autoFocus={autoFocus}
			InputLabelProps={{
				classes: {
					root: classes.root,
					shrink: classes.shrink,
					error: classes.error,
				},
			}}
			FormHelperTextProps={{
				classes: {
					root: classes.helperRoot,
					error: classes.error,
				},
			}}
			{...filteredProps}
			InputProps={{
				classes: {
					error: classes.textfieldError,
				},
				...InputProps,
				inputRef: forwardRef,
				inputProps: {
					max,
					maxLength,
					min,
					sx: { resize: multiline ? 'vertical' : 'none' },
				},
			}}
			className={classNames(classes.root, className)}
			multiline={multiline}
			onFocus={(e): void => {
				onFocus(e);
				if (onFocusProp) onFocusProp(e);
			}}
			onKeyPress={(e): void => {
				if (onKeyPress) {
					onKeyPress(e);
				} else {
					handleKeyPress(e, pattern);
				}
			}}
			onPaste={(e): void => {
				checkClipboard(e, pattern);
				if (onPaste) onPaste(e);
			}}
		/>
	);
};

export default TextField;
