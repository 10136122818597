import React from 'react';
import { useSelector } from 'react-redux';

import { choicesGet } from '@/selectors';
import State from '@/store/state';
import { Choice } from '@/store/types';

interface SingleSelectChoiceProps {
	choiceId: string;
}

const SingleSelectChoice: React.FC<SingleSelectChoiceProps> = ({ choiceId }) => {
	const choice = useSelector<State, Choice>((state) => choicesGet(state, choiceId));

	return (
		<option key={choice.id} value={choice.id}>
			{choice.name}
		</option>
	);
};

export default SingleSelectChoice;
