import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import React, { FC } from 'react';
import withThrottle from '@/components/hoc/withThrottle';
import withUnwrappedEvent from '@/components/hoc/withUnwrappedEvent';
import { ApiError, ApiProjectAPIError, ApiProjectDetailsDto, useFeatureFlag } from 'kes-common';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import useProjectStateError, {
	ProjectCreateErrorType,
} from '@/components/hooks/useProjectStateError';
import { buildRoute, project } from '@/routes';

import * as Styled from './ProjectForms.styled';
import ProjectNameDialog from './ProjectNameDialog';

interface Props {
	isNameDialogOpen?: boolean;
	projectDetails: ApiProjectDetailsDto;
	setProjectDetails: (projectDetails: ApiProjectDetailsDto) => void;
	onSubmit: () => void;
	buttons: JSX.Element;
}

const ThrottledTextField = withThrottle(withUnwrappedEvent(TextField));

const isApiProjectApiError = (error: ApiError | ApiProjectAPIError): error is ApiProjectAPIError =>
	// @ts-ignore
	error.masterProjectId !== undefined;

const ProjectForm: FC<Props> = ({
	isNameDialogOpen = false,
	projectDetails,
	setProjectDetails,
	onSubmit,
	buttons,
}): JSX.Element => {
	const [isProjectNameDialogOpen, setIsProjectNameDialogOpen] =
		React.useState<boolean>(isNameDialogOpen);
	const { error, errorFull, errorDetails } = useProjectStateError();
	const featureProjectImport = useFeatureFlag({ name: 'PROJECT_IMPORT' });
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();

	React.useEffect(() => {
		const snackbarAction = (url: string) => (
			<Button color="secondary" onClick={() => history.replace(url)}>
				Visit existing project
			</Button>
		);

		if (errorFull && isApiProjectApiError(errorFull)) {
			const existingProjectUrl = buildRoute(project, {
				projectId: errorFull.projectId,
			});
			enqueueSnackbar(
				`A project with that master id (${errorFull.masterProjectId}) already exists`,
				{
					variant: 'info',
					action: snackbarAction(existingProjectUrl),
					autoHideDuration: 5000,
				},
			);
		}
	}, [enqueueSnackbar, errorFull]);

	const onNameChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setProjectDetails({ ...projectDetails, name: event.target.value });
	}, []);

	return (
		<>
			<form
				onSubmit={(e): void => {
					e.preventDefault();
					onSubmit();
				}}
			>
				<Styled.FormGroup>
					{!featureProjectImport && (
						<ThrottledTextField
							error={
								errorDetails?.errorType ===
								ProjectCreateErrorType.PROJECT_NAME_AND_COMPANY_NAME_NOT_UNIQUE
							}
							autoFocus
							fullWidth
							data-testid="input-project-name"
							variant="outlined"
							label="Project name"
							value={projectDetails.name || ''}
							onChange={(name): void => setProjectDetails({ ...projectDetails, name })}
							required
						/>
					)}
					{featureProjectImport && (
						<Grid container spacing={2}>
							<Grid item md={5} xs={12}>
								<TextField
									disabled
									error={
										errorDetails?.errorType ===
										ProjectCreateErrorType.PROJECT_NAME_AND_COMPANY_NAME_NOT_UNIQUE
									}
									fullWidth
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													data-testid="project-change-name"
													onClick={() => setIsProjectNameDialogOpen(true)}
												>
													<InsertLinkIcon />
												</IconButton>
											</InputAdornment>
										),
									}}
									label="Master Project ID"
									value={projectDetails.masterProjectId || ''}
									variant="outlined"
								/>
							</Grid>
							<Grid item md={7} xs={12}>
								<TextField
									error={
										errorDetails?.errorType ===
										ProjectCreateErrorType.PROJECT_NAME_AND_COMPANY_NAME_NOT_UNIQUE
									}
									fullWidth
									data-testid="input-project-name-readonly"
									variant="outlined"
									label="Project name"
									onChange={onNameChange}
									value={projectDetails.name}
									required
								/>
							</Grid>
						</Grid>
					)}

					<ThrottledTextField
						disabled={projectDetails.abwLinked}
						error={errorDetails?.errorType === ProjectCreateErrorType.PROJECT_NUMBER_NOT_UNIQUE}
						helperText={
							errorDetails?.errorType === ProjectCreateErrorType.PROJECT_NUMBER_NOT_UNIQUE
								? error
								: null
						}
						fullWidth
						data-testid="input-project-number"
						variant="outlined"
						label="Project number"
						value={projectDetails.projectNumber || ''}
						onChange={(projectNumber): void =>
							setProjectDetails({ ...projectDetails, projectNumber })
						}
					/>
					<Typography variant="subtitle1">Project location</Typography>
					<ThrottledTextField
						disabled={projectDetails.abwLinked}
						fullWidth
						data-testid="input-project-location-name"
						variant="outlined"
						label="Location name"
						value={projectDetails.location.name || ''}
						onChange={(name): void =>
							setProjectDetails({
								...projectDetails,
								location: { ...projectDetails.location, name },
							})
						}
					/>
					<Styled.FormRow>
						<ThrottledTextField
							disabled={projectDetails.abwLinked}
							fullWidth
							variant="outlined"
							data-testid="input-project-location-street"
							label="Street"
							value={projectDetails.location.streetName || ''}
							onChange={(streetName): void =>
								setProjectDetails({
									...projectDetails,
									location: { ...projectDetails.location, streetName },
								})
							}
						/>
						<ThrottledTextField
							disabled={projectDetails.abwLinked}
							variant="outlined"
							data-testid="input-project-location-street-number"
							label="Number"
							value={projectDetails.location.houseNumber || ''}
							onChange={(houseNumber): void =>
								setProjectDetails({
									...projectDetails,
									location: { ...projectDetails.location, houseNumber },
								})
							}
						/>
					</Styled.FormRow>
					<Styled.FormRow>
						<ThrottledTextField
							disabled={projectDetails.abwLinked}
							fullWidth
							data-testid="input-project-location-postal-code"
							variant="outlined"
							label="Postal code"
							value={projectDetails.location.postalCode || ''}
							onChange={(postalCode): void =>
								setProjectDetails({
									...projectDetails,
									location: { ...projectDetails.location, postalCode },
								})
							}
						/>
						<ThrottledTextField
							disabled={projectDetails.abwLinked}
							fullWidth
							variant="outlined"
							data-testid="input-project-location-city"
							label="City"
							value={projectDetails.location.city || ''}
							onChange={(city): void =>
								setProjectDetails({
									...projectDetails,
									location: { ...projectDetails.location, city },
								})
							}
						/>
					</Styled.FormRow>
					<ThrottledTextField
						disabled={projectDetails.abwLinked}
						fullWidth
						variant="outlined"
						data-testid="input-project-location-country"
						label="Country"
						value={projectDetails.location.country || ''}
						onChange={(country): void =>
							setProjectDetails({
								...projectDetails,
								location: { ...projectDetails.location, country },
							})
						}
					/>

					<Styled.ButtonsContainer>{buttons}</Styled.ButtonsContainer>
				</Styled.FormGroup>
			</form>
			<ProjectNameDialog
				onClose={() => setIsProjectNameDialogOpen(false)}
				open={isProjectNameDialogOpen}
				projectDetails={projectDetails}
				setProjectDetails={setProjectDetails}
			/>
		</>
	);
};

export default ProjectForm;
