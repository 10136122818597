import React, { SetStateAction, useCallback } from 'react';
import { Dialog, DialogTitle } from '@mui/material';
import { v4 } from 'uuid';
import { GeocodeFeature } from '@mapbox/mapbox-sdk/services/geocoding';
import { ApiPointValue } from 'kes-common';
import LocationList from '@/components/Question/Types/Location/LocationList';
import {
	getBoundsForPoints,
	getNewLocations,
	parseLatLng,
	resetLocations,
} from '@/components/Question/Types/Location/location.utils';
import mapboxApi from '@/components/Question/Types/Location/mapboxApi';
import Marker from '@/components/Question/Types/Location/Maps/Marker';
import SaveBox from './SaveBox';
import Map from './Maps/index';

export interface ExtendedLocation extends ApiPointValue {
	id: string;
	active?: boolean;
	onClick?: (marker: ExtendedLocation) => void;
}

interface MapModalProps {
	question: string | null;
	save: (locations: ExtendedLocation[]) => void;
	onClose: (locations: ExtendedLocation[]) => void;
	locations: ExtendedLocation[];
	setLocations: React.Dispatch<SetStateAction<ExtendedLocation[]>>;
	currentLocation?: ExtendedLocation;
	setCurrentLocation: React.Dispatch<SetStateAction<ExtendedLocation | undefined>>;
	showMapModal: boolean;
}

export interface Bounds {
	latitude: number;
	longitude: number;
	zoom: number;
}

const MapModal: React.FC<MapModalProps> = ({
	question,
	onClose,
	save,
	locations,
	showMapModal,
	setLocations,
	setCurrentLocation,
	currentLocation,
}) => {
	const bounds = getBoundsForPoints(locations, true);
	const [viewport, setViewport] = React.useState<Bounds>({
		latitude: bounds.latitude,
		longitude: bounds.longitude,
		zoom: bounds.zoom,
	});

	const replaceCurrentLocation = async (lat: number, lng: number) => {
		if (currentLocation) {
			const features = await mapboxApi.search(`${lat},${lng}`);
			setCurrentLocation({
				...currentLocation,
				pointString: `${lat} ${lng}`,
				address: features.length > 0 ? features[0].place_name : '',
			});
		}
	};

	const addNewLocation = async (lat: number, lng: number) => {
		const newPointId = v4();
		setLocations(locations.map((location) => ({ ...location, active: false })));
		const features = await mapboxApi.search(`${lat},${lng}`);

		setCurrentLocation({
			id: newPointId,
			name: '',
			pointString: `${lat} ${lng}`,
			active: true,
			address: features.length > 0 ? features[0].place_name : '',
		});
	};

	const onClickMap = useCallback(
		(lat: number, lng: number) => {
			if (currentLocation && !locations.find((loc) => loc.id === currentLocation?.id)) {
				replaceCurrentLocation(lat, lng);
			} else {
				addNewLocation(lat, lng);
			}
		},
		[currentLocation, locations],
	);

	const saveLocation = useCallback(() => {
		if (currentLocation) {
			const newLocations = getNewLocations(locations, currentLocation);
			setLocations(newLocations);
			save(newLocations);
			setCurrentLocation(undefined);
			const newBounds = getBoundsForPoints(locations, true);
			setViewport((prevState) => ({
				...prevState,
				lat: newBounds.latitude,
				lng: newBounds.longitude,
			}));
		}
	}, [currentLocation, locations]);

	const onSelectExistingMarker = useCallback(
		(existingLocation: ExtendedLocation) => {
			setCurrentLocation({ ...existingLocation, active: true });
			setLocations(locations.map((loc) => ({ ...loc, active: loc.id === existingLocation.id })));
			const { lat, lng } = parseLatLng(existingLocation.pointString);
			setViewport((prevState) => ({
				...prevState,
				latitude: lat,
				longitude: lng,
				zoom: 12,
			}));
		},
		[locations],
	);

	const deleteLocation = useCallback(
		(existingMarker: ExtendedLocation) => {
			setCurrentLocation(undefined);
			const newLocations = locations.filter((location) => location.id !== existingMarker.id);
			setLocations(newLocations);
			save(newLocations);
		},
		[locations],
	);

	const close = () => {
		setCurrentLocation(undefined);
		setLocations(resetLocations(locations));
		onClose(locations);
	};

	const onSelectLocationFromSearch = useCallback(
		(location: GeocodeFeature) => {
			const newPointId = v4();
			setLocations(locations.map((inactiveLocation) => ({ ...inactiveLocation, active: false })));
			const [lng, lat] = location.geometry.coordinates;
			setCurrentLocation({
				id: newPointId,
				name: '',
				pointString: `${lat} ${lng}`,
				active: true,
				address: location.place_name,
			});

			setViewport((prevState) => ({
				...prevState,
				latitude: lat,
				longitude: lng,
				zoom: 12,
			}));
		},
		[locations],
	);

	const renderMarkers = () => (
		<>
			{currentLocation && <Marker onClick={onSelectExistingMarker} {...currentLocation} />}
			{locations.map((loc) => (
				<Marker key={loc.id} onClick={onSelectExistingMarker} {...loc} />
			))}
		</>
	);

	return (
		<Dialog
			disableAutoFocus
			fullWidth
			maxWidth="lg"
			onClose={close}
			open={showMapModal}
			PaperProps={{
				sx: { height: '80%' },
			}}
		>
			<DialogTitle>{question ?? 'Selecting locations'}</DialogTitle>
			<>
				<LocationList
					onSelectLocationFromSearch={onSelectLocationFromSearch}
					onSelectExistingMarker={onSelectExistingMarker}
					locations={locations}
					viewport={viewport}
				/>
				{currentLocation && (
					<SaveBox
						deleteCurrentLocation={() => deleteLocation(currentLocation)}
						currentLocation={currentLocation}
						setCurrentLocation={setCurrentLocation}
						saveLocation={saveLocation}
					/>
				)}
				<Map
					interactive
					onClickMap={onClickMap}
					viewport={viewport}
					setViewport={setViewport}
					currentLocation={currentLocation}
				>
					{renderMarkers()}
				</Map>
			</>
		</Dialog>
	);
};

export default MapModal;
