import MoreVertIcon from '@mui/icons-material/MoreVert';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { ApiFile } from 'kes-common';
import { useSnackbar } from 'notistack';
import React from 'react';

import { Property } from '@/store/types';

import Delete from './Delete';
import Download from './Download';

interface ContextMenuProps {
	properties: Property[];
	relatedFile: ApiFile;
}

const ContextMenu: React.FC<ContextMenuProps> = ({ properties, relatedFile }) => {
	const [anchorElement, setAnchorElement] = React.useState<HTMLElement | null>(null);

	const { enqueueSnackbar } = useSnackbar();

	const onMenuClose = React.useCallback(() => {
		setAnchorElement(null);
	}, [setAnchorElement]);
	const onMenuOpen: React.MouseEventHandler<HTMLButtonElement> = React.useCallback(
		(event) => {
			setAnchorElement(event.currentTarget);
		},
		[setAnchorElement],
	);

	const onDelete = React.useCallback(() => {
		enqueueSnackbar(`File "${relatedFile.name}" successfully deleted`);
		onMenuClose();
	}, [enqueueSnackbar, onMenuClose]);

	return (
		<>
			<IconButton onClick={onMenuOpen}>
				<MoreVertIcon />
			</IconButton>

			<Menu anchorEl={anchorElement} onClose={onMenuClose} open={Boolean(anchorElement)}>
				<Download onDownload={onMenuClose} relatedFile={relatedFile} />
				<Divider />
				<Delete
					onClose={onMenuClose}
					onDelete={onDelete}
					properties={properties}
					relatedFile={relatedFile}
				/>
			</Menu>
		</>
	);
};

export default ContextMenu;
