import React, { memo } from 'react';
import {
	Accordion,
	AccordionSummary,
	Checkbox,
	Typography,
	AccordionDetails,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListItemButton,
	ListSubheader,
	Stack,
	Alert,
} from '@mui/material';

import { ExpandMore } from '@mui/icons-material';

export interface QuestionsByCategoryAndAsset {
	questions: { question: string | null; name: string; id: string }[];
	categories: {
		categoryName: string;
		categoryId: string;
		assets: {
			assetId: string;
			assetName: string;
			properties: { question: string | null; name: string; id: string }[];
		}[];
	}[];
}

export interface QuestionsTabProps {
	questionsByCategoryAndAsset: QuestionsByCategoryAndAsset;
	selectedQuestions: string[];
	selectQuestion: (questionId: string) => void;
}

const QuestionsTab = ({
	questionsByCategoryAndAsset,
	selectedQuestions,
	selectQuestion,
}: QuestionsTabProps): JSX.Element => {
	if (questionsByCategoryAndAsset.categories.length === 0) {
		return (
			<Alert severity="info">
				There are no questions that can be anwered by the assistant, please create more questions
			</Alert>
		);
	}
	return (
		<div>
			{questionsByCategoryAndAsset.categories.map((c) => (
				<Accordion
					defaultExpanded={questionsByCategoryAndAsset.categories.length === 1}
					id={c.categoryId}
				>
					<AccordionSummary expandIcon={<ExpandMore />}>
						<Typography flexGrow={1}>{c.categoryName}</Typography>
						<Stack direction="row" spacing={2} mr={1}>
							<Typography variant="subtitle2" sx={{ color: 'primary.main' }}>
								{
									c.assets
										.flatMap((asset) => asset.properties)
										.filter((property) => selectedQuestions.includes(property.id)).length
								}
								&nbsp;selected
							</Typography>
							<Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
								[{c.assets.reduce((acc, asset) => acc + asset.properties.length, 0)} &nbsp;
								questions]
							</Typography>
						</Stack>
					</AccordionSummary>
					<AccordionDetails>
						<List dense>
							{c.assets.map((a) => (
								<div id={a.assetId}>
									<ListSubheader sx={{ lineHeight: 2 }}>{a.assetName}</ListSubheader>
									{a.properties.map((p) => (
										<ListItem>
											<ListItemButton id={p.id} onClick={() => selectQuestion(p.id)} dense>
												<ListItemIcon>
													<Checkbox
														edge="start"
														checked={selectedQuestions.includes(p.id)}
														tabIndex={-1}
														disableRipple
													/>
												</ListItemIcon>
												<ListItemText primary={p.question || p.name} />
											</ListItemButton>
										</ListItem>
									))}
								</div>
							))}
						</List>
					</AccordionDetails>
				</Accordion>
			))}
		</div>
	);
};

export default memo(QuestionsTab);
