import UploadBoxBase from '@/components/UploadBox/UploadBase';
import React from 'react';
import { UploadBoxProps } from '@/components/UploadBox';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import { MenuItem } from '@mui/material';

// eslint-disable-next-line no-alert
const fileInUseWarning = (): void => alert('This file is currently in use, and cannot be removed');

interface UploadBoxLockedProps {
	noDownload: boolean;
}

type Props = Pick<UploadBoxProps, 'filename' | 'onDownload'> & UploadBoxLockedProps;

const UploadBoxLocked: React.FC<Props> = ({ filename, noDownload, onDownload }): JSX.Element => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLAnchorElement | HTMLButtonElement | null>(
		null,
	);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
		if (event) {
			setAnchorEl(event.currentTarget);
		}
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<UploadBoxBase filename={filename}>
			<IconButton
				data-testid="image-overflow"
				aria-label="more"
				aria-controls="download-menu"
				aria-haspopup="true"
				onClick={(event) => handleClick(event)}
				size="large"
			>
				<MoreVertIcon />
			</IconButton>

			<Menu id="download-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
				{!noDownload && (
					<MenuItem data-testid="file-download" onClick={onDownload}>
						Download
					</MenuItem>
				)}
				<MenuItem data-testid="file-delete" onClick={fileInUseWarning}>
					Delete
				</MenuItem>
			</Menu>
		</UploadBoxBase>
	);
};
export default UploadBoxLocked;
