import React, { ChangeEvent, Dispatch, FC, ReactNode, Ref, SetStateAction } from 'react';
import { getFileTypes } from '@/components/hooks/useDragAndDropListener';
import * as Styled from './FileSelectorMenu.styles';

interface Props {
	accept: string;
	multiple: boolean;
	children: ReactNode;
	onSelect: (file: File) => void;
	inputRef?: Ref<HTMLInputElement>;
	setError: Dispatch<SetStateAction<string | undefined>>;
}

const FileSelectorMenu: FC<Props> = ({
	onSelect,
	accept,
	multiple,
	children,
	inputRef,
	setError,
}): JSX.Element => {
	const fileTypes = getFileTypes(accept);

	const onChangeFn = React.useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const { files } = event.target;

			if (files && files.length > 0) {
				if (multiple) {
					for (let i = 0; i < files.length; i += 1) {
						if (!fileTypes.some((e) => files[i].type === e)) {
							setError('Not supported');
						} else {
							onSelect(files[i]);
						}
					}
				} else if (!fileTypes.some((e) => files[0].type === e)) {
					setError('Not supported');
				} else {
					onSelect(files[0]);
				}
			}
			// eslint-disable-next-line no-param-reassign
			event.target.value = '';
		},
		[fileTypes, onSelect, setError],
	);

	return (
		<>
			<Styled.HiddenLabel>
				<Styled.HiddenInput
					data-testid="file-selector-menu-file"
					ref={inputRef}
					type="file"
					accept={accept}
					onChange={onChangeFn}
					multiple={multiple}
				/>
			</Styled.HiddenLabel>
			{children}
		</>
	);
};

export default FileSelectorMenu;
