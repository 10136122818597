/* eslint-disable no-param-reassign */
import makeReducer from '@/store/makeReducer';
import * as actions from '../actions';
import { ChangesStatus, StateChanges as State } from '../state';

const initialState: State = {
	status: ChangesStatus.done,
	savedAt: null,
};

export default makeReducer(initialState, {
	[actions.saveStarting.type]: (draft): void => {
		draft.status = ChangesStatus.saving;
	},
	[actions.saveDone.type]: (draft): void => {
		draft.status = ChangesStatus.done;
		draft.savedAt = new Date();
	},
});
