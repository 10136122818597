import React, { FC, ReactNode } from 'react';
import { connect, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';
import { assetTypesGet } from '@/selectors';
import { AssetType, Property, SurveyAsset, UUID } from '@/store/types';
import { ActionDispatcher } from '@/store/utils';
import {
	assetAnswer as assetAnswerAction,
	assetCreate as assetCreateAction,
} from '@/store/actions';
import State from '@/store/state';
import { PropertyIdContextProvider } from '@/kaas/PropertyIdContext';
import { ApiPointValue } from 'kes-common';
import classes from './Asset.module.css';
import AssetWrapper from './AssetWrapper';
import PropertyRenderer from './Property';

interface Props {
	asset: SurveyAsset;
	assetTypeId: UUID;
	assetAnswer: ActionDispatcher<typeof assetAnswerAction>;
	assetCreate: ActionDispatcher<typeof assetCreateAction>;
	blueBackground?: boolean;
	openNoteModal: (property: Property, asset: SurveyAsset) => void;
	onNoteOverflowClicked: (
		anchorEl: (EventTarget & HTMLButtonElement) | null,
		property: Property,
		asset: SurveyAsset,
	) => void;
}

function answerAsset(
	props: Props,
	propertyId: Property['id'],
	answer: string | undefined,
	applicable: boolean,
	otherOption: boolean,
	location?: ApiPointValue[],
): void {
	let id: UUID;
	if (!props.asset.id) {
		id = uuid();
		props.assetCreate({
			assetId: id,
			assetTypeId: props.assetTypeId,
			applicable,
			otherOption,
		});
	} else {
		id = props.asset.id;
	}
	props.assetAnswer({
		answer: answer === undefined ? null : answer,
		assetId: id,
		assetTypeId: props.assetTypeId,
		propertyId,
		noteText: props.asset.notesByPropertyId[propertyId],
		applicable,
		otherOption,
		location: location || null,
	});
}

const AssetRenderer: FC<Props> = ({
	asset,
	assetAnswer,
	assetCreate,
	assetTypeId,
	blueBackground,
	onNoteOverflowClicked,
	openNoteModal,
}): JSX.Element => {
	const assetType = useSelector<State, AssetType>((state) => assetTypesGet(state, assetTypeId));
	return (
		<div
			className={classNames({
				[classes.blueBackground]: blueBackground,
			})}
			id={asset.id || undefined}
		>
			{asset.surveyPropertyIds.map(
				(propertyId, index): ReactNode => (
					<AssetWrapper key={propertyId} isContained={!assetType.repeating}>
						<PropertyIdContextProvider propertyId={propertyId}>
							<PropertyRenderer
								otherOption={asset.otherOptionEnabledByPropertyId[propertyId]}
								propertyId={propertyId}
								note={
									asset.notesByPropertyId &&
									asset.notesByPropertyId[propertyId] &&
									asset.notesByPropertyId[propertyId]
								}
								openNoteModal={(property): void => openNoteModal(property, asset)}
								onNoteOverflowClicked={(anchorEl, property): void => {
									onNoteOverflowClicked(anchorEl, property, asset);
								}}
								number={index + 1}
								key={propertyId}
								answer={asset ? asset.valuesByPropertyId[propertyId] : undefined}
								onChange={(answer, applicable, otherOptionAnswer, location): void =>
									answerAsset(
										{
											asset,
											assetAnswer,
											assetCreate,
											assetTypeId,
											blueBackground,
											onNoteOverflowClicked,
											openNoteModal,
										},
										propertyId,
										answer,
										applicable,
										otherOptionAnswer,
										location,
									)
								}
								applicable={asset?.applicableByPropertyId[propertyId] ?? true}
								assetId={asset.id}
								assetTypeId={assetTypeId}
								location={asset.location ? asset.location[propertyId] : []}
							/>
						</PropertyIdContextProvider>
					</AssetWrapper>
				),
			)}
		</div>
	);
};

export default connect(null, { assetAnswer: assetAnswerAction, assetCreate: assetCreateAction })(
	AssetRenderer,
);
