import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { PageTitle, useUser } from 'kes-common';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { buildRoute, userProfileEdit } from '@/routes';

import BasicInformation from './BasicInformation';
import WorkInformation from './WorkInformation';

const Profile: React.FC = () => {
	const { userId } = useParams<{ userId: string }>();
	const { user } = useUser();

	return (
		<>
			<PageTitle
				actions={
					user.user.id === userId ? (
						<Button
							color="primary"
							component={Link}
							to={buildRoute(userProfileEdit, { userId })}
							variant="contained"
						>
							Edit info
						</Button>
					) : undefined
				}
				title="Profile"
			/>
			<Box marginY={2}>
				<Grid container spacing={{ md: 3, xs: 0 }}>
					<Grid item md={6} xs={12}>
						<Typography color="primary.dark" sx={{ marginBottom: 2, marginTop: 3 }} variant="h6">
							&nbsp;
						</Typography>
						<BasicInformation />
					</Grid>
					<Grid item md={6} xs={12}>
						<Typography color="primary.dark" sx={{ marginBottom: 2, marginTop: 3 }} variant="h6">
							Worked on
						</Typography>
						<WorkInformation />
					</Grid>
				</Grid>
			</Box>
		</>
	);
};

export default Profile;
