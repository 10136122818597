import Typography from '@mui/material/Typography';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { buildRoute, masterTemplates } from '@/routes';

import * as Styled from './Drawer.styled';

const Templates: React.FC = () => {
	const history = useHistory();

	return (
		<Styled.Button disableRipple onClick={() => history.push(buildRoute(masterTemplates, {}))}>
			<Typography color="textPrimary" variant="subtitle2">
				Master templates
			</Typography>
		</Styled.Button>
	);
};

export default Templates;
