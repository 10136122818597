import { cancel, fork, take } from '@redux-saga/core/effects';
import { SagaIterator, Task } from '@redux-saga/types';
import { routerUpdatePath } from '@/store/actions';
import { InspectionId, ProjectId } from '@/store/types';
import { takeEvery } from 'redux-saga/effects';
import * as actions from '@/store/actions';
import inspection from '@/sagas/inspection';
import router from './router';
import fileStorage from './fileStorage';
import project, { loadProjectOverview } from './project';
import assets from './assets';
import answers from './answers';
import error from './error';
import flags from './flags';
import { loadMyInspections } from './myInspections';

function* spawnRootSagas(): SagaIterator {
	yield fork(router);
	yield fork(error);
}

function* spawnProjectSagas(projectId: ProjectId | null): SagaIterator {
	yield fork(project, projectId);
}

function* spawnInspectionSagas(inspectionId: InspectionId | null): SagaIterator {
	yield fork(inspection, inspectionId);
	yield fork(assets, inspectionId);
	yield fork(fileStorage, inspectionId);
	yield fork(answers, inspectionId);
}

function* projectIdWatcher(): SagaIterator {
	let projectId: ProjectId | null | undefined;
	let task: Task | null = null;
	while (true) {
		const {
			payload: { params },
		}: ReturnType<typeof routerUpdatePath> = yield take(routerUpdatePath);
		const newProjectId = params.projectId || null;
		if (!Object.is(projectId, newProjectId)) {
			projectId = newProjectId;
			if (task) {
				yield cancel(task);
			}
			task = yield fork(spawnProjectSagas, newProjectId);
		}
	}
}

function* inspectionIdWatcher(): SagaIterator {
	let inspectionId: InspectionId | null | undefined;
	let task: Task | null = null;
	while (true) {
		const {
			payload: { params },
		}: ReturnType<typeof routerUpdatePath> = yield take(routerUpdatePath);
		const newInspectionId = params.inspectionId || null;

		if (!Object.is(inspectionId, newInspectionId)) {
			inspectionId = newInspectionId;
			if (task) {
				yield cancel(task);
			}
			task = yield fork(spawnInspectionSagas, newInspectionId);
		}
	}
}

export default function* init(): SagaIterator {
	yield fork(flags);
	yield fork(spawnRootSagas);
	yield fork(projectIdWatcher);
	yield fork(inspectionIdWatcher);
	yield takeEvery(actions.projectOverviewLoad, loadProjectOverview);
	yield takeEvery(actions.myInspectionsLoad, loadMyInspections);
}
