import { SagaIterator } from '@redux-saga/core';
import { put, takeLatest, retry, take } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { Result } from '@/store/utils';
import * as actions from '@/store/actions';
import {
	datasetPostSubstance,
	datasetPostTank,
	datasetDeleteSubstance,
	datasetDeleteTank,
	datasetPostAssets,
	datasetDeleteAssets,
} from '@/net/api';
import {
	ApiError,
	ApiTankUploadResult,
	ApiSubstanceUploadResult,
	FetchResponse,
	ApiAsset,
} from 'kes-common';
import { AssetTypeId, InspectionId } from '@/store/types';
import TagManager from 'react-gtm-module';

function* onUpload(
	doRequest: (
		inspectionId: InspectionId,
		payload: { file: Blob },
	) => Promise<
		| FetchResponse<200, ApiTankUploadResult>
		| FetchResponse<200, ApiSubstanceUploadResult>
		| FetchResponse<400, ApiError>
		| FetchResponse<401, ApiError>
	>,
	onSuccess: (p: void) => AnyAction,
	onError: (p: { message: string; details: string[] }) => AnyAction,
	{ payload }: ReturnType<typeof actions.companyActivitiesSubstancesUpload>,
): SagaIterator {
	let res: Result<typeof datasetPostSubstance>;
	try {
		res = yield retry(2, 100, doRequest, payload.inspectionId, {
			file: payload.file,
		});
	} catch (e) {
		yield put(
			onError({
				message: 'Unknown upload error, please try again',
				details: [(e as Error).message],
			}),
		);
		return;
	}
	if (res.status === 400) {
		const response = res.expect(400);
		yield put(
			onError({
				message: response.message,
				details: response.details,
			}),
		);
	} else if (res.status === 401) {
		const response = res.expect(401);
		yield put(
			onError({
				message: response.message,
				details: response.details,
			}),
		);
	}
	if (res.status === 200) {
		yield put(onSuccess());
	}
}

function* onAssetsUpload(
	doRequest: (
		assetTypeId: AssetTypeId,
		inspectionId: InspectionId,
		payload: { file: Blob },
	) => Promise<
		FetchResponse<200, ApiAsset[]> | FetchResponse<400, ApiError> | FetchResponse<401, ApiError>
	>,
	onSuccess: (p: { assetTypeId: AssetTypeId; assets: ApiAsset[] }) => AnyAction,
	onError: (p: { message: string; details: string[]; assetTypeId: AssetTypeId }) => AnyAction,
	{ payload }: ReturnType<typeof actions.companyActivitiesAssetsUpload>,
): SagaIterator {
	try {
		const res: Result<typeof datasetPostAssets> = yield retry(
			2,
			100,
			doRequest,
			payload.assetTypeId,
			payload.inspectionId,
			{ file: payload.file },
		);
		if (res.status === 400) {
			const response = res.expect(400);
			yield put(
				onError({
					message: response.message,
					details: response.details,
					assetTypeId: payload.assetTypeId,
				}),
			);
		} else if (res.status === 401) {
			const response = res.expect(401);
			yield put(
				onError({
					message: response.message,
					details: response.details,
					assetTypeId: payload.assetTypeId,
				}),
			);
		} else {
			const assets = res.expect(200);
			yield put(onSuccess({ assetTypeId: payload.assetTypeId, assets }));
			TagManager.dataLayer({
				dataLayer: {
					event: 'ra_file_uploaded',
					rows: assets.length,
					customEvent: true,
				},
			});
		}
	} catch (e) {
		yield put(
			onError({
				message: 'Unknown upload error, please try again',
				details: [(e as Error).message],
				assetTypeId: payload.assetTypeId,
			}),
		);
	}
}

function* onDelete(
	doRequest: (
		inspectionId: InspectionId,
	) => Promise<
		FetchResponse<200, Response> | FetchResponse<400, ApiError> | FetchResponse<401, ApiError>
	>,
	onSuccess: (p: void) => AnyAction,
	onError: (p: { message: string; details: string[] }) => AnyAction,
	{ payload }: ReturnType<typeof actions.companyActivitiesSubstancesDelete>,
): SagaIterator {
	try {
		const res: Result<typeof doRequest> = yield retry(2, 100, doRequest, payload);
		if (res.status === 400) {
			const response = res.expect(400);
			yield put(
				onError({
					message: response.message,
					details: response.details,
				}),
			);
		} else {
			res.expectSuccess();
			yield put(onSuccess());
		}
	} catch (e) {
		yield put(actions.projectLoadRefresh(false));
		yield take(actions.projectLoadSuccess);
		yield put(
			onError({
				message: 'Onbekende fout tijdens het verwijderen.',
				details: [(e as Error).message],
			}),
		);
	}
}

function* onAssetsDelete(
	doRequest: (
		assetTypeId: AssetTypeId,
		inspectionId: InspectionId,
	) => Promise<
		FetchResponse<204, Response> | FetchResponse<400, ApiError> | FetchResponse<401, ApiError>
	>,
	onSuccess: (p: { assetTypeId: AssetTypeId; assetIds: string[] }) => AnyAction,
	onError: (p: AssetTypeId) => AnyAction,
	{ payload }: ReturnType<typeof actions.companyActivitiesAssetsDelete>,
): SagaIterator {
	try {
		const res: Result<typeof doRequest> = yield retry(
			2,
			100,
			doRequest,
			payload.assetTypeId,
			payload.inspectionId,
		);
		res.expectSuccess();
		yield put(onSuccess({ assetTypeId: payload.assetTypeId, assetIds: payload.assetIds }));
	} catch (e) {
		yield put(actions.projectLoadRefresh(false));
		yield take(actions.projectLoadSuccess);
		yield put(onError(payload.assetTypeId));
	}
}

function* fileStorage(inspectionId: InspectionId | null): SagaIterator {
	if (inspectionId === null) {
		// This saga does not work on the project overview page
		return;
	}
	yield takeLatest(
		actions.companyActivitiesSubstancesUpload,
		onUpload,
		datasetPostSubstance,
		actions.companyActivitiesSubstancesSuccess,
		actions.companyActivitiesSubstancesError,
	);
	yield takeLatest(
		actions.companyActivitiesTanksUpload as unknown as typeof actions.companyActivitiesSubstancesUpload,
		onUpload,
		datasetPostTank,
		actions.companyActivitiesTanksSuccess,
		actions.companyActivitiesTanksError,
	);
	yield takeLatest(
		actions.companyActivitiesAssetsUpload,
		onAssetsUpload,
		datasetPostAssets,
		actions.companyActivitiesAssetsSuccess,
		actions.companyActivitiesAssetsError,
	);

	yield takeLatest(
		actions.companyActivitiesSubstancesDelete,
		onDelete,
		datasetDeleteSubstance,
		actions.companyActivitiesSubstancesDeleteSuccess,
		actions.companyActivitiesSubstancesDeleteError,
	);
	yield takeLatest(
		actions.companyActivitiesTanksDelete as unknown as typeof actions.companyActivitiesSubstancesDelete,
		onDelete,
		datasetDeleteTank,
		actions.companyActivitiesTanksDeleteSuccess,
		actions.companyActivitiesTanksDeleteError,
	);
	yield takeLatest(
		actions.companyActivitiesAssetsDelete,
		onAssetsDelete,
		datasetDeleteAssets,
		actions.companyActivitiesAssetsDeleteSuccess,
		actions.companyActivitiesAssetsDeleteError,
	);
}

export default fileStorage;
