import Typography from '@mui/material/Typography';
import { ApiProjectUserDto } from 'kes-common';
import React from 'react';

import RoleListItem, { UserDto } from '@/components/rhdhv/RoleManagement/RoleListItem';

import * as Styled from './ProjectAdministratorList.styled';

export const sortAlphabetically = (a: UserDto, b: UserDto): -1 | 0 | 1 => {
	if (a.email < b.email) {
		return -1;
	}
	if (a.email > b.email) {
		return 1;
	}
	return 0;
};

interface ProjectAdministratorListProps {
	hasDeletePrivileges: boolean;
	onRemoveClicked: (user: UserDto) => void;
	projectAdministrators: ApiProjectUserDto[];
	recentlyAddedAdministrators: ApiProjectUserDto[];
}

export const ProjectAdministratorList: React.FC<ProjectAdministratorListProps> = ({
	hasDeletePrivileges,
	onRemoveClicked,
	projectAdministrators,
	recentlyAddedAdministrators,
}) => (
	<Styled.UserSection>
		{recentlyAddedAdministrators.length > 0 && (
			<>
				<Typography variant="overline">Just added</Typography>
				{recentlyAddedAdministrators.map((administrator) => (
					<RoleListItem
						hasDeletePrivileges={hasDeletePrivileges}
						key={administrator.userId}
						onRemoveClicked={onRemoveClicked}
						roleDescription="Project Administrator"
						user={administrator}
					/>
				))}
			</>
		)}
		<Typography variant="overline">{`${projectAdministrators.length} Admins`}</Typography>
		<Styled.ExistingUsersList data-testid="project-manager-list">
			<RoleListItem
				hasDeletePrivileges={hasDeletePrivileges}
				key="random-id"
				onRemoveClicked={onRemoveClicked}
				roleDescription="Project Administrator"
				user={{
					email: '',
					globalAdmin: true,
					name: 'RHDHV Admin',
					roles: ['PROJECT_ADMINISTRATOR'],
					userId: 'rhdhv-admin-user-id',
				}}
			/>
			{projectAdministrators.sort(sortAlphabetically).map((administrator) => (
				<RoleListItem
					hasDeletePrivileges={hasDeletePrivileges}
					key={administrator.userId}
					onRemoveClicked={onRemoveClicked}
					roleDescription="Project Administrator"
					user={administrator}
				/>
			))}
		</Styled.ExistingUsersList>
	</Styled.UserSection>
);
