import Avatar from '@mui/material/Avatar';
import styled from 'styled-components';

export const AddAvatar = styled(Avatar)`
	background-color: ${({ theme }) => theme.palette.primary.main};
	font-size: 14px;
	height: 30px;
	width: 30px;
`;

export const Container = styled.div`
	display: flex;
	margin-bottom: ${({ theme }) => theme.spacing(2)};
	margin-top: ${({ theme }) => theme.spacing(2)};
`;
