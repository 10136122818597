import React, { FC } from 'react';
import Text from '@/components/Question/Types/internal/Text';
import useKaasPropertyAssist from '@/kaas/useKaasPropertyAssist';
import { usePropertyIdContext } from '@/kaas/PropertyIdContext';
import KaasSuggestionChips from '@/kaas/KaasSuggestionChips';

// The database uses `numeric(38, 12)` for all decimal values.
// Thus we have a maximum of 25 characters before the separator
// and a maximum of 12 characters after the separator.
// Because JS will use scientific notation for numbers larger than 1e21
// we need to use string manipulation instead of relying on built-in number types.
export const toFixed = (value: string): string => {
	if (value.includes('.')) {
		const [beforeSeparator, afterSeparator] = value.split('.');
		const before = beforeSeparator.substr(0, 25);
		const after = afterSeparator.substr(0, 12).replace(/0+$/, '');
		if (after.length > 0) {
			return `${before}.${after}`;
		}
		return before;
	}
	return value.substr(0, 25);
};

interface Props {
	answer: string | undefined;
	autoFocus?: boolean;
	disabled?: boolean;
	onChange(payload?: string): void;
	// eslint-disable-next-line react/no-unused-prop-types
	kaas?: ReturnType<typeof useKaasPropertyAssist>;
}

const Decimal: FC<Props> = ({ answer, autoFocus, disabled, onChange, kaas }): JSX.Element => {
	const handleChange = (text?: string) => {
		onChange(text && toFixed(text));
	};

	return (
		<>
			<Text
				answer={answer}
				autoFocus={autoFocus}
				disabled={disabled}
				onChange={handleChange}
				pattern={/[-0-9.]/}
				placeholder="Input number"
				type="text"
			/>
			<KaasSuggestionChips onChange={handleChange} kaas={kaas} disabled={disabled} />
		</>
	);
};

export const DecimalWithSuggestions: FC<Props> = ({ answer, onChange, autoFocus, disabled }) => {
	const kaas = useKaasPropertyAssist(usePropertyIdContext());
	return (
		<Decimal
			answer={answer}
			onChange={onChange}
			autoFocus={autoFocus}
			disabled={disabled}
			kaas={kaas}
		/>
	);
};

export default Decimal;
