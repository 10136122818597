import Typography from '@mui/material/Typography';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { buildRoute, home } from '@/routes';

import * as Styled from './Drawer.styled';

const Projects: React.FC = () => {
	const history = useHistory();

	return (
		<Styled.Button disableRipple onClick={() => history.push(buildRoute(home, {}))}>
			<Typography color="textPrimary" variant="subtitle2">
				Projects
			</Typography>
		</Styled.Button>
	);
};

export default Projects;
