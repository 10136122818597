import DeleteIcon from '@mui/icons-material/Delete';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { ApiFile } from 'kes-common';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import useInspectionRelatedFileDelete from '@/net/reactQuery/mutations/useInspectionRelatedFileDelete';
import * as actions from '@/store/actions';
import { Property } from '@/store/types';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';
import { useSnackbar } from 'notistack';

interface DeleteProps {
	onDelete(): void;
	onClose(): void;
	properties: Property[];
	relatedFile: ApiFile;
}

const Delete: React.FC<DeleteProps> = ({ onDelete, properties, relatedFile, onClose }) => {
	const { inspectionId } = useParams<Record<string, string>>();

	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const [openDialog, setOpenDialog] = React.useState(false);

	const handleClickOpen = () => {
		setOpenDialog(true);
	};

	const handleClose = () => {
		setOpenDialog(false);
		onClose();
	};

	const inspectionPropertyRelatedFileGetMutation = useInspectionRelatedFileDelete(
		inspectionId,
		relatedFile.fileId,
		{
			onSuccess: () => {
				dispatch(actions.inspectionRelatedFileDelete({ fileId: relatedFile.fileId }));
				onDelete();
				handleClose();
			},
			onError: () => {
				enqueueSnackbar('Failed to delete the file, please try again.');
				handleClose();
			},
		},
	);

	return (
		<>
			<Tooltip placement="left" title="Delete this file from the related files list">
				{/* The <span> is because a <Tooltip /> doesn't work on disabled elements */}
				<span>
					<MenuItem
						disabled={inspectionPropertyRelatedFileGetMutation.isLoading}
						onClick={
							properties.length === 0
								? () => inspectionPropertyRelatedFileGetMutation.mutate()
								: handleClickOpen
						}
					>
						<ListItemIcon>
							<DeleteIcon />
						</ListItemIcon>
						<ListItemText primary="Delete" />
					</MenuItem>
				</span>
			</Tooltip>
			<Dialog
				open={openDialog}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">Delete file related to questions?</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						This file is related to some questions, are you sure you want to delete it?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Close</Button>
					<Button
						disabled={inspectionPropertyRelatedFileGetMutation.isLoading}
						onClick={() => inspectionPropertyRelatedFileGetMutation.mutate()}
						autoFocus
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default Delete;
