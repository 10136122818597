import AssetTypeRenderer from '@/components/Question/AssetType';
import useRouterSearchParams from '@/components/hooks/useRouterSearchParams';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {
	buildRoute,
	home,
	inspectionFinish,
	projectQuestions,
	project as projectRoute,
} from '@/routes';
import {
	categoriesGet,
	categoriesGetOrNull,
	getSurveyAssetTypeIds,
	surveyCategoryIds,
} from '@/selectors';
import { projectLoad } from '@/store/actions';
import State from '@/store/state';
import { Category, SimpleProject } from '@/store/types';
import { Stack } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { ApiInspectionDto, PageTitle, useFeatureFlag } from 'kes-common';
import React, { FC, ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import KaasTopControls from '@/kaas/KaasTopControls';

const Project: FC = (): JSX.Element => {
	const { categoryId, projectId } = useParams<Record<string, string>>();
	const assetId = useRouterSearchParams().get('assetId');
	const inspection = useSelector<State, ApiInspectionDto | undefined>(
		(state) => state.inspection.currentInspection,
	);

	const categoryIds = useSelector<State, ReturnType<typeof surveyCategoryIds>>((state) =>
		surveyCategoryIds(state),
	);

	const categoryIndex: number = React.useMemo(
		() => categoryIds.indexOf(categoryId),
		[categoryId, categoryIds],
	);
	const categoryNext = useSelector<State, Category>((state) =>
		categoriesGet(
			state,
			categoryIds[categoryIndex + 1 === categoryIds.length ? categoryIndex : categoryIndex + 1],
		),
	);
	const categoryPrevious = useSelector<State, Category>((state) =>
		categoriesGet(state, categoryIds[categoryIndex > 0 ? categoryIndex - 1 : 0]),
	);

	const dispatch = useDispatch();

	const project = useSelector<State, SimpleProject | undefined>((state) => state.project.project);

	useEffect(() => {
		if (!project && projectId) {
			dispatch(projectLoad(projectId));
		}
	}, [projectId]);

	const surveyAssetTypeIds = useSelector<State, ReturnType<typeof getSurveyAssetTypeIds>>((state) =>
		getSurveyAssetTypeIds(state),
	);

	const category = useSelector<State, Category>(
		(state) =>
			(categoryId && categoriesGetOrNull(state, categoryId)) ||
			({
				id: categoryId,
				info: null,
				name: 'Category not found',
				assetTypeIds: [],
				isDefault: false,
				fixed: true,
				type: 'SURVEY',
			} as Category),
	);

	useDocumentTitle(
		project?.testProject ? 'Preview' : project?.projectDetails.name!,
		inspection?.assetLibrary?.studyName!,
		category.name,
	);

	useEffect(() => {
		if (assetId) {
			const element = document.getElementById(assetId);
			if (element) {
				element.style.backgroundColor = '#BADEFE';
				element.scrollIntoView({ behavior: 'smooth', block: 'start' });

				setTimeout(() => {
					element.style.backgroundColor = 'white';
				}, 2000);
			}
		}
	}, [assetId]);

	const disabledNext = categoryIndex + 1 === categoryIds.length;
	const disabledPrevious = categoryIndex === 0;

	const WrapperNext = disabledNext ? 'span' : Tooltip;
	const WrapperPrevious = disabledPrevious ? 'span' : Tooltip;

	const KAAS_FEATURE_FLAG = useFeatureFlag({ name: 'KAAS' });

	return (
		<Container fixed maxWidth="md" sx={{ marginBottom: 2 }}>
			{project && inspection && (
				<Stack direction="row" marginLeft={3} justifyContent="space-between" alignItems="center">
					<PageTitle
						breadcrumbs={[
							{ title: 'Projects', url: buildRoute(home, {}) },
							{ title: project.name, url: buildRoute(projectRoute, { projectId: project.id }) },
							{ title: inspection.assetLibrary.studyName },
						]}
					/>

					{KAAS_FEATURE_FLAG && <KaasTopControls inspectionId={inspection.id} />}
				</Stack>
			)}

			{category.info && (
				<Box marginTop={2}>
					<Alert severity="info" sx={{ whiteSpace: 'pre-line' }}>
						{category.info}
					</Alert>
				</Box>
			)}

			{category?.assetTypeIds
				.filter((id): boolean => surveyAssetTypeIds.has(id))
				.map(
					(assetTypeId): ReactNode => (
						<AssetTypeRenderer assetTypeId={assetTypeId} key={assetTypeId} />
					),
				)}

			<Grid container marginTop={2} spacing={2}>
				<Grid item xs={6}>
					{!disabledPrevious && (
						<WrapperPrevious title={disabledPrevious ? '' : categoryPrevious.name}>
							<Button
								component={Link}
								disabled={disabledPrevious}
								fullWidth
								to={buildRoute(projectQuestions, {
									projectId,
									inspectionId: inspection?.id,
									categoryId: categoryPrevious.id,
								})}
								variant="contained"
							>
								Previous
							</Button>
						</WrapperPrevious>
					)}
				</Grid>
				<Grid item xs={6}>
					{!disabledNext && (
						<WrapperNext title={disabledNext ? '' : categoryNext.name}>
							<Button
								component={Link}
								disabled={disabledNext}
								fullWidth
								to={buildRoute(projectQuestions, {
									projectId,
									inspectionId: inspection?.id,
									categoryId: categoryNext.id,
								})}
								variant="contained"
							>
								Next
							</Button>
						</WrapperNext>
					)}
					{disabledNext && (
						<Button
							fullWidth
							variant="contained"
							to={buildRoute(inspectionFinish, { projectId, inspectionId: inspection?.id })}
							component={Link}
						>
							Next
						</Button>
					)}
				</Grid>
			</Grid>
		</Container>
	);
};

export default Project;
