import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

import { Property } from '@/store/types';

interface AddNoteProps {
	note?: string;
	onNoteModalOpen(property: Property): void;
	property: Property;
}

const AddNote: React.FC<AddNoteProps> = ({ note, onNoteModalOpen, property }) => {
	const onClick = React.useCallback(() => {
		onNoteModalOpen(property);
	}, [onNoteModalOpen, property]);

	return (
		<Tooltip title="Add a note about the answer given to this question" placement="top">
			<MenuItem onClick={onClick}>
				<ListItemIcon>
					<NoteAddIcon />
				</ListItemIcon>
				<ListItemText primary={note ? 'Edit note' : 'Add note'} />
			</MenuItem>
		</Tooltip>
	);
};

export default AddNote;
