import React, { FC, ReactNode } from 'react';
import Linkify from 'react-linkify';

import ComponentDecorator from './ComponentDecorator';

interface Props {
	text: string;
}

const TextWithBreaks: FC<Props> = ({ text }) => {
	const nodes: ReactNode[] = [];
	text.split('\n').forEach((line) => {
		nodes.push(
			<Linkify componentDecorator={ComponentDecorator} key={line}>
				{line}
			</Linkify>,
		);
		nodes.push(<br key={nodes.length} />);
	});
	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{nodes}</>;
};

export default TextWithBreaks;
