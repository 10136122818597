import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import HelpIcon from '@mui/icons-material/Help';
import DownloadIcon from '@mui/icons-material/FileDownload';
import UploadIcon from '@mui/icons-material/FileUpload';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import RemoveIcon from '@mui/icons-material/Remove';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import PublishIcon from '@mui/icons-material/Publish';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { useQueryClient } from '@tanstack/react-query';
import { ApiStudyDto, useUser } from 'kes-common';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router-dom';

import TemplateAssignDialog, { TemplateAssignDialogProps } from '@/components/TemplateAssignDialog';
import TemplateCopyDialog, { TemplateCopyDialogProps } from '@/components/TemplateCopyDialog';
import TemplateDeleteDialog, { TemplateDeleteDialogProps } from '@/components/TemplateDeleteDialog';
import TemplateEditDrawer from '@/components/TemplateEditDrawer';
import TemplatePublishDialog, {
	TemplatePublishDialogProps,
} from '@/components/TemplatePublishDialog';
import TemplateShareDialog, { TemplateShareDialogProps } from '@/components/TemplateShareDialog';
import { KES_BUILDER_URL } from '@/constants';
import useQueryString from '@/hooks/useQueryString';
import useTemplateExport from '@/net/reactQuery/mutations/useTemplateExport';
import { templates } from '@/routes';
import DownloadScript from '@/components/DownloadScript';
import UploadScript from '@/components/UploadScript';
import useTemplateScriptFileUpload from '@/net/reactQuery/mutations/useTemplateScriptFileUpload';
import useTemplateScriptFileDelete from '@/net/reactQuery/mutations/useTemplateScriptFileDelete';

export interface TemplateContextMenuProps {
	anchorElement: HTMLElement | null;
	onClose(): void;
	onTemplateAssignError: TemplateAssignDialogProps['onError'];
	onTemplateAssignSuccess: TemplateAssignDialogProps['onSuccess'];
	onTemplateCopied: TemplateCopyDialogProps['onSuccess'];
	onTemplatePublished: TemplatePublishDialogProps['onSuccess'];
	onTemplateUserAdded: TemplateShareDialogProps['onUserAdded'];
	onTemplateUserRemoved: TemplateShareDialogProps['onUserRemoved'];
	template: ApiStudyDto;
	refetch(): void;
}

const TemplateContextMenu: React.FC<TemplateContextMenuProps> = ({
	anchorElement,
	onClose,
	onTemplateAssignError,
	onTemplateAssignSuccess,
	onTemplateCopied,
	onTemplatePublished,
	onTemplateUserAdded,
	onTemplateUserRemoved,
	template,
	refetch,
}) => {
	const queryString = useQueryString();

	const [isTemplateAssignDialogOpen, setIsTemplateAssignDialogOpen] =
		React.useState<boolean>(false);
	const [isTemplateCopyDialogOpen, setIsTemplateCopyDialogOpen] = React.useState<boolean>(false);
	const [isTemplateDeleteDialogOpen, setIsTemplateDeleteDialogOpen] =
		React.useState<boolean>(false);
	const [isTemplateEditDrawerOpen, setIsTemplateEditDrawerOpen] = React.useState<boolean>(false);
	const [isTemplatePublishDialogOpen, setIsTemplatePublishDialogOpen] = React.useState<boolean>(
		queryString.get('templateId') === template.id && queryString.get('publish') === 'true',
	);
	const [isTemplateShareDialogOpen, setIsTemplateShareDialogOpen] = React.useState<boolean>(false);

	const history = useHistory();
	const queryClient = useQueryClient();
	const { enqueueSnackbar } = useSnackbar();
	const templateExportQuery = useTemplateExport(template.id);
	const { user } = useUser();

	const canAdministerTemplate = React.useMemo<boolean>(() => {
		const isTemplateAdministrator = Boolean(
			template.users.find((templateUser) => templateUser.userId === user.user.id),
		);
		return isTemplateAdministrator || user.isGlobalAdmin;
	}, [template, user]);

	const handleTemplateCopied: TemplateCopyDialogProps['onSuccess'] = React.useCallback(
		(templateId) => {
			setIsTemplateCopyDialogOpen(false);
			onTemplateCopied(templateId);
		},
		[onTemplateCopied, setIsTemplateCopyDialogOpen],
	);

	const handleTemplateDeleted: TemplateDeleteDialogProps['onSuccess'] = React.useCallback(() => {
		queryClient.invalidateQueries(['templates']);
		history.push(templates);
	}, [history, queryClient]);

	const handleTemplateExport = React.useCallback(() => {
		templateExportQuery.mutate(undefined, {
			onError: () => {
				enqueueSnackbar('Failed to download the Template to file. Please try again.');
			},
			onSettled: onClose,
			onSuccess: (data) => {
				const element = document.createElement('a');
				const dataString = `data:text/json;charset=utf-8,${encodeURIComponent(
					JSON.stringify(data),
				)}`;
				element.setAttribute('href', dataString);
				element.setAttribute('download', `${template.name.replace(/[^a-zA-Z0-9]/g, '')}.json`);
				element.style.display = 'none';
				document.body.appendChild(element);
				element.click();
				document.body.removeChild(element);
			},
		});
	}, [enqueueSnackbar, template, templateExportQuery]);

	const handleTemplatePublished: TemplatePublishDialogProps['onSuccess'] = React.useCallback(
		(templateVersions) => {
			setIsTemplatePublishDialogOpen(false);
			onTemplatePublished(templateVersions);
		},
		[onTemplatePublished, setIsTemplatePublishDialogOpen],
	);

	const canDownloadScript = React.useMemo<boolean>(
		() => template.lastPublishedAssetLibrary !== null,
		[template],
	);
	const uploadScriptMutation = useTemplateScriptFileUpload(template.id);
	const uploadScript = React.useCallback(
		(file: File) => {
			uploadScriptMutation.mutate(
				{ file },
				{
					onError: () => {
						enqueueSnackbar('Failed to upload the script file, please try again');
					},
					onSuccess: () => {
						refetch();
						enqueueSnackbar(`Script file (${file.name}) successfully uploaded.`);
					},
				},
			);
		},
		[uploadScriptMutation, enqueueSnackbar, refetch],
	);

	const canRemoveScript = React.useMemo<boolean>(() => template.file !== null, [template]);
	const isWordTemplate = React.useMemo<boolean>(() => template.type === 'WORD', [template]);

	const deleteScriptMutation = useTemplateScriptFileDelete(template.id);
	const deleteScript = React.useCallback(() => {
		deleteScriptMutation.mutate(undefined, {
			onError: () => {
				enqueueSnackbar('Failed to remove the script file, please try again');
			},
			onSuccess: () => {
				refetch();
				enqueueSnackbar(`Script file successfully removed.`);
			},
		});
	}, [deleteScriptMutation, enqueueSnackbar, refetch]);

	const renderScriptDownload = React.useCallback(
		(onDownloadClick: () => void): JSX.Element => (
			<MenuItem disabled={!canDownloadScript} onClick={onDownloadClick}>
				<ListItemIcon>
					<DownloadIcon />
				</ListItemIcon>
				<ListItemText>Download script</ListItemText>
			</MenuItem>
		),
		[canDownloadScript],
	);

	const renderScriptUpload = React.useCallback(
		(onUploadClick: () => void) => (
			<MenuItem disabled={!canDownloadScript} onClick={onUploadClick}>
				<ListItemIcon>
					<UploadIcon />
				</ListItemIcon>
				<ListItemText>Upload script</ListItemText>
			</MenuItem>
		),
		[canDownloadScript],
	);

	return (
		<>
			<Menu anchorEl={anchorElement} onClose={onClose} open={Boolean(anchorElement)}>
				<Tooltip placement="left" title={`Create a copy of ${template.name}`}>
					<MenuItem
						onClick={() => {
							onClose();
							setIsTemplateCopyDialogOpen(true);
						}}
					>
						<ListItemIcon>
							<ContentCopyIcon />
						</ListItemIcon>
						<ListItemText>Copy template</ListItemText>
					</MenuItem>
				</Tooltip>

				<Tooltip
					placement="left"
					title={
						isWordTemplate
							? 'Open in Word online to edit template contents'
							: 'Open in Kes Studio to edit template contents'
					}
				>
					<MenuItem
						component="a"
						disabled={!canAdministerTemplate}
						href={
							isWordTemplate ? template.sharepointUrl || '#' : `${KES_BUILDER_URL}${template.id}`
						}
					>
						<ListItemIcon>
							<OpenInBrowserIcon />
						</ListItemIcon>
						<ListItemText>Open template</ListItemText>
					</MenuItem>
				</Tooltip>

				<Tooltip placement="left" title="Assign the template to projects that you are a part of">
					<MenuItem
						disabled={!template.lastPublishedDate}
						onClick={() => {
							onClose();
							setIsTemplateAssignDialogOpen(true);
						}}
					>
						<ListItemIcon>
							<PlaylistAddIcon />
						</ListItemIcon>
						<ListItemText>Assign to project(s)</ListItemText>
					</MenuItem>
				</Tooltip>

				<Tooltip placement="left" title="Edit basic information like name and description">
					<MenuItem
						disabled={!canAdministerTemplate}
						onClick={() => {
							onClose();
							setIsTemplateEditDrawerOpen(true);
						}}
					>
						<ListItemIcon>
							<EditIcon />
						</ListItemIcon>
						<ListItemText>Edit template info</ListItemText>
					</MenuItem>
				</Tooltip>

				<Tooltip placement="left" title="Publish template, making it available for others to use">
					<MenuItem
						disabled={!canAdministerTemplate || template.type !== 'CLASSIC'}
						onClick={() => {
							onClose();
							setIsTemplatePublishDialogOpen(true);
						}}
					>
						<ListItemIcon>
							<PublishIcon />
						</ListItemIcon>
						<ListItemText>Publish template</ListItemText>
					</MenuItem>
				</Tooltip>

				<Tooltip
					placement="left"
					title="Export the Template to file so it can be imported again in another environment"
				>
					<MenuItem
						disabled={!canAdministerTemplate || templateExportQuery.isLoading || isWordTemplate}
						onClick={handleTemplateExport}
					>
						<ListItemIcon>
							<ImportExportIcon />
						</ListItemIcon>
						<ListItemText>Export template</ListItemText>
					</MenuItem>
				</Tooltip>

				<Tooltip placement="left" title={`Manage who has access to ${template.name}`}>
					<MenuItem
						disabled={!canAdministerTemplate}
						onClick={() => {
							onClose();
							setIsTemplateShareDialogOpen(true);
						}}
					>
						<ListItemIcon>
							<GroupAddIcon />
						</ListItemIcon>
						<ListItemText>Add/Remove Users</ListItemText>
					</MenuItem>
				</Tooltip>

				<Tooltip placement="left" title="Get help with creating templates">
					<MenuItem
						component="a"
						href="https://kes.support.royalhaskoningdhv.com/en/support/solutions/articles/77000501121-creating-templates-"
						rel="noopener noreferrer"
						target="_blank"
					>
						<ListItemIcon>
							<HelpIcon />
						</ListItemIcon>
						<ListItemText>Learn more</ListItemText>
					</MenuItem>
				</Tooltip>

				<Tooltip placement="left" title="Delete this template, leaving published versions intact.">
					<MenuItem
						onClick={() => {
							setIsTemplateDeleteDialogOpen(true);
						}}
					>
						<ListItemIcon>
							<DeleteIcon />
						</ListItemIcon>
						<ListItemText>Delete template</ListItemText>
					</MenuItem>
				</Tooltip>

				<DownloadScript
					templateId={template.id}
					assetLibraryId={template.lastPublishedAssetLibrary ?? ''}
					renderChildren={renderScriptDownload}
				/>

				<UploadScript uploadFile={uploadScript} renderChildren={renderScriptUpload} />

				<MenuItem disabled={!canRemoveScript} onClick={deleteScript}>
					<ListItemIcon>
						<RemoveIcon />
					</ListItemIcon>
					<ListItemText>Remove script</ListItemText>
				</MenuItem>
			</Menu>

			<TemplateAssignDialog
				isOpen={isTemplateAssignDialogOpen}
				onClose={() => setIsTemplateAssignDialogOpen(false)}
				onError={onTemplateAssignError}
				onSuccess={onTemplateAssignSuccess}
				template={template}
			/>

			<TemplateCopyDialog
				isOpen={isTemplateCopyDialogOpen}
				onClose={() => setIsTemplateCopyDialogOpen(false)}
				onSuccess={handleTemplateCopied}
				template={template}
			/>
			<TemplateEditDrawer
				isOpen={isTemplateEditDrawerOpen}
				onClose={() => setIsTemplateEditDrawerOpen(false)}
				template={template}
			/>
			<TemplateDeleteDialog
				isOpen={isTemplateDeleteDialogOpen}
				onClose={() => setIsTemplateDeleteDialogOpen(false)}
				onSuccess={handleTemplateDeleted}
				template={template}
			/>
			<TemplatePublishDialog
				isOpen={isTemplatePublishDialogOpen}
				onClose={() => setIsTemplatePublishDialogOpen(false)}
				onSuccess={handleTemplatePublished}
				template={template}
			/>
			<TemplateShareDialog
				isOpen={isTemplateShareDialogOpen}
				onClose={() => setIsTemplateShareDialogOpen(false)}
				onUserAdded={onTemplateUserAdded}
				onUserRemoved={onTemplateUserRemoved}
				template={template}
			/>
		</>
	);
};

export default TemplateContextMenu;
