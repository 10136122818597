import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import React from 'react';

const CustomInput = React.forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => (
	<TextField
		fullWidth
		inputProps={{ style: { cursor: 'pointer' } }}
		// eslint-disable-next-line react/jsx-no-duplicate-props
		InputProps={{
			endAdornment: (
				<InputAdornment position="end">
					<CalendarTodayIcon />
				</InputAdornment>
			),
		}}
		ref={ref}
		sx={{ backgroundColor: 'white' }}
		{...props}
	/>
));

export default CustomInput;
