import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import { ApiProjectInspectionDto } from 'kes-common';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useMutation } from '@tanstack/react-query';

import { projectInspectionCopy } from '@/net/api';
import { usePublishedStudies } from '@/net/reactQuery/queries';
import { useProject } from '@/pages/Project/context';
import { Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';

interface CopyActivityProps {
	inspection: ApiProjectInspectionDto;
	onClick(): void;
	projectId: string;
}

const CopyActivity: React.FC<CopyActivityProps> = ({ inspection, onClick, projectId }) => {
	const [areAnswersCopied, setAreAnswersCopied] = React.useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

	const { refetchProject } = useProject();
	const { enqueueSnackbar } = useSnackbar();

	const { data: publishedStudies, isError, isFetching } = usePublishedStudies();

	const activitiesCopyQuery = useMutation(
		() => projectInspectionCopy(inspection.id, projectId, { withAnswers: areAnswersCopied }),
		{
			onError: () => {
				enqueueSnackbar('Failed to copy the activity. Please try again.');
			},
			onSuccess: () => {
				enqueueSnackbar('Activity was Copied', {
					anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
				});
				refetchProject();
			},
		},
	);

	return (
		<>
			<MenuItem
				disabled={isFetching || isError || !(publishedStudies && publishedStudies.length > 0)}
				onClick={() => {
					onClick();
					setIsModalOpen(true);
				}}
			>
				Copy activity
			</MenuItem>
			<Dialog onClose={() => setIsModalOpen(false)} open={isModalOpen}>
				<DialogTitle>Copy with answers?</DialogTitle>
				<DialogContent>
					<Typography>Do you want the copied activty to also include answers?</Typography>
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox
									checked={areAnswersCopied}
									onChange={(event) => setAreAnswersCopied(event.target.checked)}
								/>
							}
							label="Also copy answers"
						/>
					</FormGroup>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
					<Button
						color="secondary"
						onClick={() => activitiesCopyQuery.mutate()}
						variant="contained"
					>
						Copy
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default CopyActivity;
