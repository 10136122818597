import { ApiPointValue } from 'kes-common';
import { v4 } from 'uuid';
import { WebMercatorViewport } from 'react-map-gl';
import { ExtendedLocation } from '@/components/Question/Types/Location/MapModal';

export const DEFAULT_LNG = 5.103467529009009;
export const DEFAULT_LAT = 52.085480669187575;
export const COORDINATE_LABEL_PRECISION = 6;

export const parseLatLng = (pointString: string) => {
	const [lat, lng] = pointString
		.replace('(', '')
		.replace(')', '')
		.split(' ')
		.map((x) => Number(x));
	return { lat, lng };
};

export const toPointString = (lat: number, lng: number) => `${lat} ${lng}`;

export const getAdjustedCoordinates = (pointString: string) => {
	const { lat, lng } = parseLatLng(pointString);

	return `${lat.toFixed(COORDINATE_LABEL_PRECISION)}, ${lng.toFixed(COORDINATE_LABEL_PRECISION)}`;
};

export const calculateCenterPoint = (locations: ExtendedLocation[]) => {
	let latXTotal = 0;
	let latYTotal = 0;
	let lonDegreesTotal = 0;

	locations.forEach((loc) => {
		const { lat, lng } = parseLatLng(loc.pointString);

		const latRadians = (Math.PI * lat) / 180;
		latXTotal += Math.cos(latRadians);
		latYTotal += Math.sin(latRadians);

		lonDegreesTotal += lng;
	});

	const finalLatRadians = Math.atan2(latYTotal, latXTotal);
	const finalLatDegrees = (finalLatRadians * 180) / Math.PI;

	const finalLonDegrees = lonDegreesTotal / locations.length;
	return { lat: finalLatDegrees, lng: finalLonDegrees };
};

export const toMarkers = (locations: ApiPointValue[]): ExtendedLocation[] =>
	locations.map((location) => ({
		...location,
		id: v4(),
		active: false,
	}));

// @ts-ignore
const applyToArray = (func, array) => func.apply(Math, array);

export const getBoundsForPoints = (points: ExtendedLocation[], fullWidth?: boolean) => {
	// if there are no points just return default lat lng values
	if (points.length === 0) {
		return { longitude: DEFAULT_LNG, zoom: 8, latitude: DEFAULT_LAT };
	}

	if (points.length > 1) {
		const pointsLong = points.map((point) => parseLatLng(point.pointString).lng);
		const pointsLat = points.map((point) => parseLatLng(point.pointString).lat);
		const cornersLongLat = [
			[applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
			[applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
		];
		const viewport = new WebMercatorViewport({
			width: fullWidth ? 1400 : 170,
			height: fullWidth ? 1400 : 170,
		}).fitBounds(
			// @ts-ignore
			cornersLongLat,
			{
				padding: fullWidth ? 300 : 20,
			},
		);
		const { longitude, latitude, zoom } = viewport;

		return { longitude, latitude, zoom };
	}

	return {
		longitude: parseLatLng(points[0].pointString).lng,
		zoom: 8,
		latitude: parseLatLng(points[0].pointString).lat,
	};
};

export const removeExtendedProperties = (locations: ExtendedLocation[]): ApiPointValue[] =>
	locations.map((loc) => ({
		pointString: loc.pointString,
		name: loc.name,
		address: loc.address,
	}));

export const getNewLocations = (
	locations: ExtendedLocation[],
	currentLocation: ExtendedLocation,
): ExtendedLocation[] => {
	const isExistingLocation = !!locations.find((loc) => loc.id === currentLocation.id);

	if (locations.length === 0) {
		return [currentLocation];
	}

	if (!isExistingLocation) {
		return [...locations, currentLocation];
	}

	return locations.map((loc) => {
		if (loc.id === currentLocation.id) {
			return currentLocation;
		}
		return loc;
	});
};

export const resetLocations = (locations: ExtendedLocation[]) =>
	locations.map((loc) => ({ ...loc, active: false }));
