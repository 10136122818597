import { ApiFlag } from 'kes-common';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RepeatingAssetTable from '@/components/RepeatingAssetTable';
import { RepeatingAssetTableRow } from '@/components/RepeatingAssetTable/types';
import { assetTypesGet } from '@/selectors';
import { getAllByIds } from '@/selectors/properties';
import State from '@/store/state';
import { Asset, AssetType, Property } from '@/store/types';
import { assetAnswer, assetDelete, flagDelete } from '@/store/actions';
import { debounce } from 'lodash';

interface TableProps {
	flag: AssetType;
	flags: ApiFlag[];
	onAssetCreate(assetType: AssetType): void;
}

const Table: React.FC<TableProps> = ({ flag, flags, onAssetCreate }) => {
	const assetType = useSelector<State, AssetType>((state) => assetTypesGet(state, flag.id));
	const properties = useSelector<State, Property[]>(getAllByIds(assetType.propertyIds));

	const rows: RepeatingAssetTableRow[] = React.useMemo(
		() =>
			flags.map((singleFlag) => ({
				assetId: singleFlag.flagAsset,
				parentAssetId: singleFlag.asset,
			})),
		[flags],
	);

	const dispatch = useDispatch();

	const onAssetDelete = React.useCallback(
		(asset: Asset, deleteAssetType: AssetType) => {
			const deleteFlag = flags.find(
				(singleFlag) =>
					singleFlag.flagAsset === asset.id && singleFlag.flagAssetType === deleteAssetType.id,
			);
			if (deleteFlag) {
				dispatch(flagDelete({ flagId: deleteFlag.id }));
				dispatch(
					assetDelete({
						assetId: deleteFlag.flagAsset,
						assetTypeId: deleteFlag.flagAssetType,
					}),
				);
			}
		},
		[dispatch, flags],
	);

	const onPropertyAnswer = React.useCallback(
		debounce((answer: string, property: Property, asset: Asset, answerAssetType: AssetType) => {
			dispatch(
				assetAnswer({
					applicable: true,
					answer,
					assetId: asset.id,
					assetTypeId: answerAssetType.id,
					location: null,
					noteText: null,
					otherOption: false,
					propertyId: property.id,
				}),
			);
		}, 250),
		[],
	);

	return (
		<RepeatingAssetTable
			assetType={assetType}
			onAssetCreate={onAssetCreate}
			onAssetDelete={onAssetDelete}
			onPropertyAnswer={onPropertyAnswer}
			properties={properties}
			rows={rows}
		/>
	);
};

export default Table;
