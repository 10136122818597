import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { ApiAssistRequest } from 'kes-common';

import useTrackKaasEvents from '@/kaas/useTrackKaasEvents';
import { inspectionAssist } from '@/net/api';

import useKaasContext from './useKaasContext';
import { convertToDictionary } from './utils';
import { FilesPerTraceId } from './types';

const useKaasInspectionAssist = () => {
	const { enqueueSnackbar } = useSnackbar();
	const {
		inspectionId,
		setAnswers,
		setFilesPerTraceId,
		setHasKaasAnswered,
		selectedFiles,
		selectedQuestions,
	} = useKaasContext();

	const [responseTime, setResponseTime] = useState<number>(0);
	const { trackKaasAssist } = useTrackKaasEvents();

	const { refetch, isFetching } = useQuery(
		['kaas-inspection-assist', inspectionId, selectedFiles],
		async () => {
			const startTime = performance.now();
			const response = await inspectionAssist(inspectionId, {
				questions: selectedQuestions,
				files: selectedFiles,
			} as ApiAssistRequest);
			const endTime = performance.now();
			setResponseTime(endTime - startTime);
			return response.expectSuccess();
		},
		{
			retry: 0,
			enabled: false,
			onSuccess: (response) => {
				if (response.answers?.length) {
					setHasKaasAnswered(true);
					setAnswers(convertToDictionary(response.answers));

					const filesPerTraceIdNew = response.answers.reduce(
						(accumulator, answer) => ({ ...accumulator, [answer.traceId]: selectedFiles }),
						{} as FilesPerTraceId,
					);
					setFilesPerTraceId(filesPerTraceIdNew);

					trackKaasAssist(responseTime, true);
					enqueueSnackbar('Successfully answered the questions with the assistant', {
						preventDuplicate: true,
					});
				}
			},
			onError: () => {
				trackKaasAssist(responseTime, false);
				enqueueSnackbar('Something went wrong while fetching the answers', {
					preventDuplicate: true,
				});
			},
		},
	);

	return { assist: refetch, isFetching };
};

export default useKaasInspectionAssist;
