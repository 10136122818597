import { inspectionExcelDownload } from '@/net/api';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { ApiInspectionDto } from 'kes-common';

const useInspectionExcelDownload = (
	inspectionId: ApiInspectionDto['id'],
	options?: UseMutationOptions<Response>,
) =>
	useMutation(
		() => inspectionExcelDownload(inspectionId).then((response) => response.expectSuccess()),
		options,
	);

export default useInspectionExcelDownload;
