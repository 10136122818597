import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import React, { useState } from 'react';
import { ApiProjectReportOptionsDto } from 'kes-common';
import { useUpdateProjectReportOptionsMutation } from '@/net/reactQuery/mutations/useUpdateProjectReportOptionsMutation';

import * as Styled from './ProjectOptions.styled';

interface ProjectOptionsProps {
	back: () => void;
	projectReportOptions: ApiProjectReportOptionsDto;
	projectId: string;
}
const ProjectOptions: React.FC<ProjectOptionsProps> = ({
	back,
	projectReportOptions,
	projectId,
}) => {
	const [coverOptionEnabled, setCoverOptionEnabled] = useState(
		projectReportOptions.includeCoverPage,
	);

	const onCoverOptionChange = () => {
		setCoverOptionEnabled((prevState) => !prevState);
	};

	const { mutate: updateProjectReportOptions, isError } = useUpdateProjectReportOptionsMutation(
		projectId,
		coverOptionEnabled,
		() => {
			back();
		},
	);

	const saveProjectReportOptions = () => {
		updateProjectReportOptions({ coverOptionEnabled });
	};

	return (
		<Styled.ProjectOptionsContainer>
			<Typography variant="h3">Display</Typography>

			<Styled.ReportOption>
				<p>Cover</p>
				<Styled.Switch
					name="cover-option"
					data-testid="action-toggle-cover-option"
					checked={coverOptionEnabled}
					onChange={onCoverOptionChange}
				/>
			</Styled.ReportOption>

			<Styled.ActionButtonsContainer>
				<Button onClick={back}>Cancel</Button>
				<Box marginLeft={1}>
					<Button
						color="primary"
						data-testid="action-save"
						onClick={saveProjectReportOptions}
						variant="contained"
					>
						Save
					</Button>
				</Box>
			</Styled.ActionButtonsContainer>
			<Snackbar data-testid="error-message" open={isError}>
				<Alert severity="error">Something went wrong updating your project report options.</Alert>
			</Snackbar>
		</Styled.ProjectOptionsContainer>
	);
};

export default ProjectOptions;
