import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import React from 'react';
import { useSelector } from 'react-redux';

import { choicesGet } from '@/selectors';
import State from '@/store/state';
import { Choice } from '@/store/types';

interface MultipleSelectChoiceProps {
	choiceId: string;
	'data-test-id'?: string;
	onChange(event: React.ChangeEvent<HTMLInputElement>): void;
	values: string[];
}

const MultipleSelectChoice: React.FC<MultipleSelectChoiceProps> = ({
	choiceId,
	'data-test-id': dataTestId,
	onChange,
	values,
}) => {
	const choice = useSelector<State, Choice>((state) => choicesGet(state, choiceId));

	return (
		<FormControlLabel
			control={
				<Checkbox
					checked={values.includes(choiceId)}
					onChange={onChange}
					name={choice.name}
					value={choiceId}
				/>
			}
			data-testid={dataTestId}
			key={choiceId}
			label={choice.name}
		/>
	);
};

export default MultipleSelectChoice;
