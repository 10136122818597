import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { ApiStudyDto } from 'kes-common';

import { templateScriptFileUpload } from '@/net/api';

interface MutationOptions {
	file: Blob;
}

const useTemplateScriptFileUpload = (
	templateId: ApiStudyDto['id'],
	options?: UseMutationOptions<unknown, unknown, MutationOptions>,
) =>
	useMutation(
		(mutationOptions) =>
			templateScriptFileUpload(templateId, mutationOptions).then((response) =>
				response.expectSuccess(),
			),
		options,
	);

export default useTemplateScriptFileUpload;
