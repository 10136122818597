import { ApiClientDto, ApiProjectDetailsDto, ApiProjectLocationDto } from 'kes-common';

export function createProjectLocation(
	partialProjectLocation?: Partial<ApiProjectLocationDto>,
): ApiProjectLocationDto {
	return {
		name: partialProjectLocation?.name ?? '',
		country: partialProjectLocation?.country ?? '',
		houseNumber: partialProjectLocation?.houseNumber ?? '',
		city: partialProjectLocation?.city ?? '',
		postalCode: partialProjectLocation?.postalCode ?? '',
		streetName: partialProjectLocation?.streetName ?? '',
	};
}

export function createClient(partialClient?: Partial<ApiClientDto>): ApiClientDto {
	return {
		companyName: partialClient?.companyName ?? '',
		contactPersonName: partialClient?.contactPersonName ?? '',
		contactPersonRole: partialClient?.contactPersonRole ?? '',
		contactPersonEmail: partialClient?.contactPersonEmail ?? '',
		contactPersonPhoneNumber: partialClient?.contactPersonPhoneNumber ?? '',
		cocNumber: partialClient?.cocNumber ?? '',
		websiteUrl: partialClient?.websiteUrl ?? '',
		location: partialClient?.location ?? {
			streetName: '',
			houseNumber: '',
			postalCode: '',
			city: '',
			country: '',
		},
		industry: null,
	};
}

export function createProjectDetails(
	partialProjectDetails?: Partial<ApiProjectDetailsDto>,
): ApiProjectDetailsDto {
	return {
		abwLinked: Boolean(partialProjectDetails?.masterProjectId),
		name: partialProjectDetails?.name ?? '',
		projectNumber: partialProjectDetails?.projectNumber ?? '',
		masterProjectId: partialProjectDetails?.masterProjectId ?? null,
		location: partialProjectDetails?.location ?? createProjectLocation(),
		client: partialProjectDetails?.client ?? createClient(),
		isTestProject: partialProjectDetails?.isTestProject ?? false,
		reportOptions: partialProjectDetails?.reportOptions ?? { includeCoverPage: false },
	};
}
