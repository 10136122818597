// this file is in common because i need defaultEndnote in the builder and frontend.

export const defaultEndNote = {
	en: `You have reached the end of the survey. If you would like to revisit a section to amend a response or upload evidence, please do so using the "Previous" link below. If you are done, please use the "Complete Activity" button below to indicate that you are done`,
	nl: `U heeft het einde van de vragenlijst bereikt. Als u nog uw antwoorden wilt veranderen gebruik dan de “Previous” link hier beneden.  Als u klaar ben klikt u op “complete activity`,
};

export const alertLoseAccess = {
	en: 'Please note that you will lose access and you will not be able to make any changes after you have completed the survey',
	nl: 'Als u complete activity kiest kunt u geen aanpassingen meer maken. Tevens kunt u niet langer bij het vragen formulier komen.',
};
