import {
	ApiFile,
	ApiInspectionUserDto,
	ApiPointValue,
	ApiProjectDetailsDto,
	ApiProjectInspectionDto,
} from 'kes-common';

export interface FeatureFlags {
	milicense: boolean;
}

export interface SimpleProject {
	readonly id: ProjectId;
	readonly name: string;
	readonly projectNumber: string | null;
	readonly companyName: string;
	createdOn: Date;
	hasAdminPrivileges: boolean;
	testProject: boolean;
	inspections: ApiProjectInspectionDto[];
	readonly projectDetails: ApiProjectDetailsDto;
	readonly industries: string[];
}

export type ProjectId = string;

export type InspectionId = string;

export type ReportTemplateId = string;

export type AssetTypeId = string;

export class MendixUnavailableError extends Error {
	public constructor(message?: string) {
		super(message); // 'Error' breaks prototype chain here
		Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
	}
}

// Survey library types

export type UUID = string;

export interface Category {
	readonly id: UUID;
	readonly name: string;
	readonly assetTypeIds: AssetType['id'][];
	readonly fixed: boolean;
	readonly isDefault: boolean;
	readonly type: 'FLAG' | 'FORM' | 'SURVEY';
	readonly info: string | null;
}

export interface AssetType {
	readonly id: UUID;
	readonly info: string | null;
	readonly stableId: UUID;
	readonly name: string;
	readonly repeating: boolean;
	readonly propertyIds: Property['id'][];
	readonly fixed: boolean;
	readonly survey: boolean;
	readonly type: 'FLAG' | 'FORM' | 'SURVEY';
}

export interface Property {
	readonly id: UUID;
	readonly stableId: UUID;
	readonly name: string;
	readonly question: string | null;
	readonly description: string | null;
	readonly fixed: boolean;
	readonly required: boolean;
	readonly survey: boolean;
	readonly type: PropertyType;
	readonly dependentCombinator: PropertyDependentCombinator;
	// Used by single and multiple select questions
	readonly choiceIds: Choice['id'][];
	readonly parentActionIds: string[];
	readonly parentChoiceIds: string[];
	readonly referencedAssetType: UUID | null;
	readonly identifyingProperty: UUID | null;
	readonly hasNotApplicableOption: boolean;
	readonly allowMultipleAnswers: boolean;
	readonly hasOtherOption: boolean;
}

export enum PropertyType {
	DATE = 'DATE',
	RICH_TEXT = 'RICH_TEXT',
	STRING = 'STRING',
	SINGLE_SELECT = 'SINGLE_SELECT',
	DECIMAL = 'DECIMAL',
	MULTI_SELECT = 'MULTI_SELECT',
	SINGLE_SUBSTANCE = 'SINGLE_SUBSTANCE',
	MULTI_SUBSTANCE = 'MULTI_SUBSTANCE',
	SINGLE_ASSET_REFERENCE = 'SINGLE_ASSET_REFERENCE',
	MULTI_ASSET_REFERENCE = 'MULTI_ASSET_REFERENCE',
	IMAGE = 'IMAGE',
	LOCATIONS = 'LOCATIONS',
}

export enum PropertyDependentCombinator {
	ANY = 'ANY',
	ALL = 'ALL',
}

export interface Choice {
	readonly id: UUID;
	readonly name: string;
	readonly description: string | null;
	readonly reportText: string | null;
	readonly computed: boolean;
}

export interface Asset {
	readonly id: UUID;
	readonly captionsByPropertyId: Record<string, string>;
	readonly valueStringByPropertyId: Record<string, string>;
	readonly applicableByPropertyId: Record<string, boolean>;
	readonly otherValueEnabledByPropertyId: Record<string, boolean>;
	readonly notesByPropertyId: Record<string, string>;
	readonly location: Record<string, ApiPointValue[]>;
	readonly presignedURLs: Record<string, string | undefined>;
	readonly isOpen: boolean;
	readonly filesByPropertyId: Record<string, ApiFile[]>;
}

export interface AssetUpsert {
	readonly valueStringByPropertyId: Record<string, string>;
	readonly notesByPropertyId: Record<string, string>;
	readonly applicableByPropertyId: Record<string, boolean>;
	readonly otherValueEnabledByPropertyId: Record<string, boolean>;
}

export interface AssetList {
	readonly id: AssetType['id'];
	readonly assets: Asset['id'][];
}

export interface SurveyAsset {
	readonly filesByPropertyId: Record<string, ApiFile[]>;
	readonly id: UUID | null;
	readonly surveyPropertyIds: string[];
	readonly valuesByPropertyId: Record<string, string>;
	readonly notesByPropertyId: Record<string, string>;
	readonly applicableByPropertyId: Record<string, boolean>;
	readonly otherOptionEnabledByPropertyId: Record<string, boolean>;
	readonly location: Record<string, ApiPointValue[]>;
}

export interface SurveyAssetList {
	readonly assetTypeId: AssetType['id'];
	readonly assets: SurveyAsset[];
}

export enum UserRole {
	PROJECT_ADMINISTRATOR = 'PROJECT_ADMINISTRATOR',
	PROJECT_EDITOR = 'PROJECT_EDITOR',
}

export interface User {
	id: UUID;
	readonly email: string;
	readonly name: string | null;
	readonly globalAdmin: boolean;
}

export interface ProjectRole {
	readonly id: UUID;
	readonly projectId: UUID;
	role?: UserRole;
	readonly user: User;
}

export const MULTIPLE_SELECT_SEPERATOR = ',';

export interface InspectionUserBody {
	inspectionId: string;
	inspector: ApiInspectionUserDto;
}
