import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ApiAuthorizedProjectDto } from 'kes-common';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { project as projectRoute, buildRoute } from '@/routes';

import classes from './index.module.css';
import ProjectMenu from './ProjectMenu';

interface Props {
	authorizedProject: ApiAuthorizedProjectDto;
}

const ProjectCard: FC<Props> = ({ authorizedProject }): JSX.Element => {
	const [menuElement, setMenuElement] = React.useState<HTMLElement | null>(null);

	const history = useHistory();

	const { project, hasAdminPrivileges } = authorizedProject;

	return (
		<>
			<Card
				className={classes.card}
				data-testid={`project-${project.name}-${project.companyName}`}
				onClick={(): void => history.push(buildRoute(projectRoute, { projectId: project.id }))}
			>
				<CardActionArea style={{ height: '100%' }}>
					<div className={classes.cardData}>
						<Typography className={classes.cardTitle} color="primary" noWrap variant="subtitle1">
							{project.name}
						</Typography>
						<Typography>{project.companyName}</Typography>
						<Typography variant="body1">
							Created on: {new Intl.DateTimeFormat('en-GB').format(new Date(project.createdOn))}
						</Typography>
						<Typography color="textSecondary" variant="body2">
							{`${project.masterProjectId ? project.masterProjectId : ''}${
								project.masterProjectId && project.projectNumber ? `-` : ''
							}${project.projectNumber ? project.projectNumber : ''}`}
						</Typography>
					</div>
					{hasAdminPrivileges && (
						<Button
							data-testid={`action-project-${project.name}-${project.companyName}-context-menu`}
							onClick={(e): void => {
								e.stopPropagation();
								e.preventDefault();
								setMenuElement(e.currentTarget);
							}}
							className={classes.cardMore}
							disableRipple
							disableFocusRipple
						>
							<MoreVertIcon color="action" className={classes.cardMoreIcon} />
						</Button>
					)}
				</CardActionArea>
			</Card>

			<ProjectMenu
				anchorElement={menuElement}
				onClose={() => setMenuElement(null)}
				project={project}
			/>
		</>
	);
};

export default ProjectCard;
