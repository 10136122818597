import styled, { css } from 'styled-components';
import MUIErrorIcon from '@mui/icons-material/Error';

const disabledStyles = css<{ disabled?: boolean }>`
	${({ disabled, theme }) =>
		disabled &&
		`
		border-radius: ${theme.shape.borderRadius}px;
		border: dashed 2px var(--dark-blue-200);
		color: var(--dark-blue-200);
		border: dashed 2px var(--dark-blue-100);
	`}
`;

export const Root = styled.div<{ disabled?: boolean }>`
	min-height: 56px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: ${({ theme }) => theme.spacing(1)};
	column-gap: ${({ theme }) => theme.spacing(1)};
	color: var(--dark-blue);
	border-radius: ${({ theme }) => theme.shape.borderRadius}px;
	border: dashed 2px ${({ theme }) => theme.palette.background.default};
	background-color: white;

	${disabledStyles};
`;

export const RootNormal = styled(Root)`
	background-color: white;
	padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2)}`};
	${disabledStyles};
`;

export const RootError = styled(Root)`
	padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2)}`};
	background-color: var(--error-050);
	height: initial;
	align-items: flex-start;
`;

export const Text = styled.span`
	flex: 1;
	margin-left: ${({ theme }) => theme.spacing(1)};
	align-items: center;
`;

export const ErrorIcon = styled(MUIErrorIcon)`
	color: var(--error-700);
	margin-top: ${({ theme }) => theme.spacing(0)};
`;

export const ErrorList = styled.ul`
	margin: ${({ theme }) => `${theme.spacing(1)} 0`};
	padding-left: ${({ theme }) => theme.spacing(2)};
`;

export const ErrorListItem = styled.li`
	margin: ${({ theme }) => `${theme.spacing(0)} 0 0 0`};
	line-height: 1.5;
	color: black;
`;

export const Thumbnail = styled.img`
	max-height: 64px;
	max-width: 64px;
`;
