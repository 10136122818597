import MUILinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

export const LinearProgress = styled(MUILinearProgress)`
	.MuiLinearProgress-bar {
		background-color: rgba(77, 160, 0, 1);
	}
	&.MuiLinearProgress-colorPrimary {
		background-color: ${({ theme }) => theme.palette.grey[400]};
	}
`;

export const ProgressText = styled(Typography)`
	color: ${({ theme }) => theme.palette.grey[600]};
	font-size: ${({ theme }) => theme.typography.subtitle2.fontSize}px;
`;
