import { UploadState } from '@/components/UploadBox';
import { useReducer } from 'react';

export enum UploadActions {
	UPLOAD_LOADING = 'UPLOAD_LOADING',
	UPLOAD_SUCCESS = 'UPLOAD_SUCCESS',
	UPLOAD_ERROR = 'UPLOAD_ERROR',
	DELETE_LOADING = 'DELETE_LOADING',
	DELETE_SUCCESS = 'DELETE_SUCCESS',
	DELETE_ERROR = 'DELETE_ERROR',
}

type UploadAction =
	| { type: UploadActions.UPLOAD_LOADING }
	| { type: UploadActions.UPLOAD_SUCCESS; fileName: string }
	| { type: UploadActions.UPLOAD_ERROR; errors: string[] }
	| { type: UploadActions.DELETE_LOADING }
	| { type: UploadActions.DELETE_SUCCESS }
	| { type: UploadActions.DELETE_ERROR; errors: string[] };

export type UploadReducerState = {
	fileState: UploadState;
	fileName: string;
	errors: string[];
};

const initialState: UploadReducerState = {
	fileState: 'empty',
	fileName: '',
	errors: [],
};

const reducer = (
	// eslint-disable-next-line @typescript-eslint/default-param-last
	state: UploadReducerState = initialState,
	action: UploadAction,
): UploadReducerState => {
	switch (action.type) {
		case UploadActions.UPLOAD_LOADING:
			return { fileState: 'uploading', fileName: '', errors: [] };
		case UploadActions.UPLOAD_SUCCESS:
			return { ...state, fileState: 'done', fileName: action.fileName };
		case UploadActions.UPLOAD_ERROR:
			return { ...state, fileState: 'error', errors: action.errors };
		case UploadActions.DELETE_LOADING:
			return { ...state, fileState: 'deleting', errors: [] };
		case UploadActions.DELETE_SUCCESS:
			return { ...state, fileState: 'empty', fileName: '' };
		case UploadActions.DELETE_ERROR:
			return { ...state, fileState: 'delete_error', errors: action.errors };
		default:
			return state;
	}
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useUploadReducer = (initial = initialState) => useReducer(reducer, initial);
