/* eslint-disable no-param-reassign */
import makeReducer from '@/store/makeReducer';
import { StateRouter as State } from '@/store/state';

import * as actions from '../actions';

const initialState: State = {
	path: '',
	params: {},
	pageTitle: '',
};

export default makeReducer(initialState, {
	[actions.routerUpdatePath.type]: (draft, payload): void => {
		draft.path = payload.path;
		draft.params = payload.params;
	},
	[actions.routerUpdateTitle.type]: (draft, payload): void => {
		draft.pageTitle = payload;
	},
});
