import { relatedFileUpload } from '@/net/api';
import { Asset } from '@/store/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { ApiProjectDto, ApiInspectionDto, ApiProperty } from 'kes-common';

interface MutationOptions {
	file: Blob | null;
	fileId: null | string;
}

interface Query {
	projectId?: ApiProjectDto['id'];
	inspectionId?: ApiInspectionDto['id'];
	propertyId?: ApiProperty['id'];
	assetId?: Asset['id'];
}

const useRelatedFileUpload = (
	{ projectId, inspectionId, propertyId, assetId }: Query,
	options?: UseMutationOptions<string, unknown, MutationOptions>,
) =>
	useMutation(
		(mutationOptions) =>
			relatedFileUpload(
				{ projectId, inspectionId, propertyId, assetId },
				{ files: mutationOptions.file, fileIds: mutationOptions.fileId },
			).then((response) => response.expectSuccess()),
		options,
	);

export default useRelatedFileUpload;
