import React, { FC, useState } from 'react';
import assertNever from '@/utils/assertNever';
import UploadBoxEmpty from '@/components/UploadBox/UploadBoxEmpty';
import UploadBoxUploading from '@/components/UploadBox/UploadBoxUploading';
import UploadBoxLocked from '@/components/UploadBox/UploadBoxLocked';
import UploadBoxDeleting from '@/components/UploadBox/UploadBoxDeleting';
import UploadBoxError from '@/components/UploadBox/UploadBoxError';
import UploadBoxDone from './UploadBoxDone';

// const disableDownloading = false // Todo disable this when there is a download option

export type UploadState =
	| 'disabled'
	| 'empty'
	| 'uploading'
	| 'done'
	| 'error'
	| 'locked'
	| 'deleting'
	| 'delete_error';

export interface UploadBoxProps {
	caption?: string;
	state: UploadState;
	filename: string;
	onSelect: (file: File) => void;
	onDelete: () => void;
	onDownload?: () => void;
	onEditCaption?(): void;
	errors: string[];
	fileType?: string;
	presignedURL?: string;
}

const UploadBox: FC<UploadBoxProps> = (props): JSX.Element => {
	const [error, setError] = useState<string | undefined>();
	const { filename, fileType, onDownload, onSelect, state } = props;
	const noDownload = onDownload === undefined;
	switch (state) {
		case 'disabled':
			if (filename) {
				return <UploadBoxDone {...props} noDownload={noDownload} disabled />;
			}
			return (
				<UploadBoxEmpty onSelect={onSelect} fileType={fileType} disabled setError={setError} />
			);
		case 'empty':
			return (
				<UploadBoxEmpty onSelect={onSelect} fileType={fileType} setError={setError} error={error} />
			);
		case 'uploading':
			return <UploadBoxUploading filename={filename} />;
		case 'done':
			return <UploadBoxDone {...props} noDownload={noDownload} />;
		case 'locked':
			return <UploadBoxLocked {...props} noDownload={noDownload} />;
		case 'deleting':
			return <UploadBoxDeleting filename={filename} />;
		case 'delete_error':
			return <UploadBoxError {...props} noDownload={noDownload} />;
		case 'error':
			return <UploadBoxError {...props} noDownload={noDownload} />;
		default:
			return assertNever(state);
	}
};

export default UploadBox;
