import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
import useKaasPropertyAssist from '@/kaas/useKaasPropertyAssist';
import { usePropertyIdContext } from '@/kaas/PropertyIdContext';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { KAAS_COLOR } from '@/kaas/utils';

import { Box, Typography } from '@mui/material';
import { Choice, Property } from '../../../../store/types';
import ChoiceRenderer from './ChoiceButton';
import classes from './ChoiceButtons.module.css';

interface Props {
	toggleChoice(choiceId: string): void;
	answerSelected(choiceId: string): boolean;
	choiceIds: Choice['id'][];
	disabled?: boolean;
	property: Property;
}

const ChoiceButtons: FC<Props> = ({
	toggleChoice,
	answerSelected,
	choiceIds,
	disabled,
	property,
}): JSX.Element => {
	const { suggestedAnswers } = useKaasPropertyAssist(usePropertyIdContext());

	return (
		<>
			<div className={classNames(classes.root, disabled && classes.disabled)}>
				{choiceIds.map(
					(id): ReactNode => (
						<ChoiceRenderer
							key={id}
							choiceId={id}
							selected={answerSelected(id)}
							onSelect={(): void => toggleChoice(id)}
							property={property}
						/>
					),
				)}
			</div>
			{suggestedAnswers && suggestedAnswers.numberOfAnswers > 0 && (
				<Box pt={2} color={KAAS_COLOR} display="flex" justifyContent="flex-end" alignItems="center">
					<InfoOutlinedIcon sx={{ marginTop: '4px', marginRight: '4px' }} />
					<Typography color={KAAS_COLOR}>suggested answers</Typography>
				</Box>
			)}
		</>
	);
};

export default ChoiceButtons;
