import React from 'react';
import { mapboxEnabled } from '@/components/Question/Types/Location';
import CustomMapboxMarker from '@/components/Question/Types/Location/Maps/CustomMarker';
import { ExtendedLocation } from '@/components/Question/Types/Location/MapModal';
import LeafletMarker from '@/components/Question/Types/Location/Maps/LeafletMarker';

const Marker: React.FC<ExtendedLocation> = (props) => {
	const { active, address, id, name, onClick, pointString } = props;

	const handleClick = React.useCallback(() => {
		if (onClick) {
			onClick({ active, address, id, name, pointString });
		}
	}, [active, address, id, name, onClick, pointString]);

	if (mapboxEnabled) {
		return <CustomMapboxMarker {...props} onClick={handleClick} />;
	}

	return <LeafletMarker {...props} onClick={handleClick} />;
};

export default Marker;
