import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { downloadBlob } from 'kes-common';
import { useSnackbar } from 'notistack';
import React from 'react';

import useInspectionExcelDownload from '@/net/reactQuery/mutations/useInspectionExcelDownload';

interface Props {
	inspectionId: string;
}

const DownloadExcelReportMenuItem: React.FC<Props> = ({ inspectionId }) => {
	const { enqueueSnackbar } = useSnackbar();

	const inspectionExcelDownloadMutation = useInspectionExcelDownload(inspectionId, {
		onSuccess: async (response) => {
			const blob = await response.blob();
			downloadBlob(blob, `${inspectionId}.xlsx`);
		},
		onError: () => {
			enqueueSnackbar('Failed to download the excel report, please try again.');
		},
	});

	return (
		<Tooltip title="Download the Activity answers in Excel format">
			<div>
				<MenuItem
					disabled={inspectionExcelDownloadMutation.isLoading}
					onClick={() => inspectionExcelDownloadMutation.mutate()}
				>
					Download excel report
				</MenuItem>
			</div>
		</Tooltip>
	);
};

export default DownloadExcelReportMenuItem;
