import makeReducer from '@/store/makeReducer';
import { ApiProperty } from 'kes-common';
import assertNever from '@/utils/assertNever';
import { Property, PropertyDependentCombinator, PropertyType } from '@/store/types';
import { initialUnorderedState, makeUnorderedRepository } from '@/store/repository';
import * as actions from '../actions';

function deserialize(type: ApiProperty['type']): PropertyType {
	switch (type) {
		case 'DATE':
			return PropertyType.DATE;
		case 'RICH_TEXT':
			return PropertyType.RICH_TEXT;
		case 'STRING':
			return PropertyType.STRING;
		case 'DECIMAL':
			return PropertyType.DECIMAL;
		case 'SINGLE_SELECT':
			return PropertyType.SINGLE_SELECT;
		case 'MULTI_SELECT':
			return PropertyType.MULTI_SELECT;
		case 'SINGLE_SUBSTANCE':
			return PropertyType.SINGLE_SUBSTANCE;
		case 'MULTI_SUBSTANCE':
			return PropertyType.MULTI_SUBSTANCE;
		case 'SINGLE_ASSET_REFERENCE':
			return PropertyType.SINGLE_ASSET_REFERENCE;
		case 'MULTI_ASSET_REFERENCE':
			return PropertyType.MULTI_ASSET_REFERENCE;
		case 'IMAGE':
			return PropertyType.IMAGE;
		case 'LOCATIONS':
			return PropertyType.LOCATIONS;
		default:
			return assertNever(type);
	}
}

function mapDependentCombinatorToLocal(
	dependentCombinator: ApiProperty['dependentCombinator'],
): PropertyDependentCombinator {
	switch (dependentCombinator) {
		case 'ANY':
			return PropertyDependentCombinator.ANY;
		case 'ALL':
			return PropertyDependentCombinator.ALL;
		default:
			return assertNever(dependentCombinator);
	}
}

const repository = makeUnorderedRepository<Property>();

export default makeReducer(initialUnorderedState<Property>(), {
	[actions.inspectionLoadSuccess.type]: (draft, payload): void => {
		repository.replaceAll(
			draft,
			payload.assetLibrary.propertiesById,
			(entity): Property => ({
				...entity,
				choiceIds: entity.choiceIds || [],
				type: deserialize(entity.type),
				allowMultipleAnswers: entity.allowMultipleAnswers ?? false,
				dependentCombinator: mapDependentCombinatorToLocal(entity.dependentCombinator),
				hasNotApplicableOption: entity.hasNotApplicableOption,
				hasOtherOption: entity.hasOtherOption,
			}),
		);
	},
});
