import React from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { routes, urlMatch } from '@/routes';
import { routerUpdatePath } from '@/store/actions';
import { useLocation } from 'react-router';

const Pages = (): JSX.Element => {
	const dispatch = useDispatch();
	const location = useLocation();

	React.useEffect(() => {
		const match = urlMatch(location.pathname);
		if (!match) {
			throw new Error('Provided path does not match any routes');
		}
		dispatch(
			routerUpdatePath({
				params: match.params,
				path: location.pathname,
			}),
		);
	}, [location.pathname]);

	return (
		<Switch>
			{routes.map((page) => (
				<Route key={page.title} path={page.path}>
					<page.layout>
						<page.component />
					</page.layout>
				</Route>
			))}
		</Switch>
	);
};

export default Pages;
