import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import Box from '@mui/material/Box';
import * as Styled from './AvatarList.styled';
import NameAvatar from '../NameAvatar';
import { ApiInspectionUserDto, ApiProjectUserDto, ApiStudyUserDto } from '../../net/swagger';

interface Props {
	users: Array<ApiProjectUserDto | ApiInspectionUserDto | ApiStudyUserDto>;
	onShowMoreClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	maxUsersToShow?: number;
}

const AvatarList: React.FC<Props> = ({ users, onShowMoreClick, maxUsersToShow = 5 }) => (
	<>
		{users.slice(0, maxUsersToShow).map((user) => (
			<Box marginRight={1} key={user.email}>
				<NameAvatar name={user.name || user.email} isEmail={!user.name} />
			</Box>
		))}
		{users.length > maxUsersToShow && (
			<>
				{!onShowMoreClick && (
					<Tooltip
						title={users
							.slice(maxUsersToShow)
							.map((user) => user.email)
							.join(', ')}
					>
						<Box marginRight={1}>
							<Styled.AddAvatarNonClickable>
								{`+ ${users.length - maxUsersToShow}`}
							</Styled.AddAvatarNonClickable>
						</Box>
					</Tooltip>
				)}
				{onShowMoreClick && (
					<Tooltip title="Show more">
						<Box marginRight={1} onClick={onShowMoreClick}>
							<Styled.AddAvatar>{`+ ${users.length - maxUsersToShow}`}</Styled.AddAvatar>
						</Box>
					</Tooltip>
				)}
			</>
		)}
	</>
);

export default AvatarList;
