import makeReducer from '@/store/makeReducer';
import { initialUnorderedState, makeUnorderedRepository } from '@/store/repository';
import { AssetType } from '@/store/types';

import * as actions from '../actions';

const repository = makeUnorderedRepository<AssetType>();

export default makeReducer(initialUnorderedState<AssetType>(), {
	[actions.inspectionLoadSuccess.type]: (draft, payload): void => {
		repository.replaceAll(
			draft,
			payload.assetLibrary.assetTypesById,
			(entity): AssetType => entity,
		);
	},
});
