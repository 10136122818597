const buildUrl = (prefix: string, prNumber: string | undefined) =>
	prNumber !== undefined ? `${prefix}-pr-${prNumber}.azurewebsites.net/` : undefined;

const isE2E = Boolean(window.Cypress);

export const backendUrl: string =
	window.environment.REACT_APP_BACKEND_URL ??
	buildUrl('https://kes-backend', process.env.REACT_APP_HEROKU_PR_NUMBER) ??
	'http://localhost:8080/';

export const KES_BUILDER_URL =
	window.environment.REACT_APP_KES_BUILDER_URL ??
	buildUrl('https://kes-builder', process.env.REACT_APP_HEROKU_PR_NUMBER) ??
	'http://localhost:4000/';

export const KES_ABW_CONNECTOR_URL =
	window.environment.REACT_APP_KES_ABW_CONNECTOR_URL ??
	buildUrl('https://kes-abw-connector', process.env.REACT_APP_HEROKU_PR_NUMBER) ??
	'http://localhost:8081/';

export const MAX_ANSWER_UPDATE_WORKERS = 4;
export const MAX_RETRIES = 2;
export const RETRY_DELAY = 100;
export const REPORT_ID = '5ce9c65e-0452-4ebe-a0cf-2c3f85ce1da0';

export const TEST_USERS_ENABLED = window.environment.REACT_APP_TEST_USERS_ENABLED === 'true';

export const STABLE_ASPECT_PROPERTY_ID = '6c9ba772-abde-43b9-b059-3d9ad52d95c6';
export const APPLICATIONINSIGHTS_CONNECTION_STRING =
	window.environment.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING;
export const GOOGLE_TAG_MANAGER_ID = window.environment.REACT_APP_GOOGLE_TAG_MANAGER_ID;
export const GOOGLE_TAG_MANAGER_AUTH = window.environment.REACT_APP_GOOGLE_TAG_MANAGER_AUTH;
export const GOOGLE_TAG_MANAGER_PREVIEW = window.environment.REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW;
export const ENVIRONMENT = window?.environment?.REACT_APP_ENVIRONMENT;
export const TITLE_PREFIX = window?.environment?.REACT_APP_TITLE_PREFIX;

export const AUTH0_AUDIENCE = window.environment.REACT_APP_AUTH0_AUDIENCE;
export const AUTH0_CLIENT_ID = window.environment.REACT_APP_AUTH0_CLIENT_ID;
export const AUTH0_CONNECTION = window.environment.REACT_APP_AUTH0_CONNECTION;
export const AUTH0_DOMAIN = window.environment.REACT_APP_AUTH0_DOMAIN;

export const MSAL_CLIENT_ID = window.environment.REACT_APP_MSAL_CLIENT_ID;
export const MSAL_REDIRECT_URI = window.environment.REACT_APP_MSAL_REDIRECT_URI;
export const MSAL_TENANT_ID = window.environment.REACT_APP_MSAL_TENANT_ID;

export const SPLITIO_AUTHORIZATION_KEY = isE2E
	? 'localhost'
	: window.environment.REACT_APP_SPLITIO_AUTHORIZATION_KEY;
