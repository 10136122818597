import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import { ApiFile } from 'kes-common';

import useInspectionAssetPropertyRelatedFileUpload from '@/net/reactQuery/mutations/useInspectionAssetPropertyRelatedFileUpload';
import * as actions from '@/store/actions';
import { Asset, AssetType, Property } from '@/store/types';
import useRelatedFileUpload from '@/net/reactQuery/mutations/useRelatedFileUpload';

interface UploadFileButtonProps {
	assetId: Asset['id'] | null;
	assetType: AssetType;
	property: Property;
}

const UploadFileButton: React.FC<UploadFileButtonProps> = ({ assetId, assetType, property }) => {
	const uploadInput = React.useRef<HTMLInputElement>(null);

	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const { inspectionId } = useParams<Record<string, string>>();

	const assetPropertyRelatedFileMutation = useInspectionAssetPropertyRelatedFileUpload(
		inspectionId,
		assetId || '',
		property.id,
		{
			onError: () => {
				enqueueSnackbar('There was an error uploading this file, please try again', {
					preventDuplicate: true,
				});
			},
			onSuccess: (_, variables) => {
				enqueueSnackbar('Your file has been uploaded successfully', {
					preventDuplicate: true,
				});
				dispatch(
					actions.inspectionAssetPropertyRelatedFileUpload({
						assetId: assetId!,
						file: {
							fileId: variables.fileId,
							name: variables.file!.name,
						} as ApiFile,
						propertyId: property.id,
					}),
				);
			},
		},
	);

	const propertyRelatedFileMutation = useRelatedFileUpload(
		{ propertyId: property.id, inspectionId },
		{
			onError: () => {
				enqueueSnackbar('There was an error uploading this file, please try again', {
					preventDuplicate: true,
				});
			},
			onSuccess: (responseAssetId, variables) => {
				if (assetId !== responseAssetId) {
					if (assetId === null) {
						dispatch(
							actions.assetCreate({
								assetId: responseAssetId,
								assetTypeId: assetType.id,
							}),
						);
					} else {
						dispatch(actions.assetIdUpdate({ idNew: responseAssetId, idOld: assetId }));
					}
				}

				dispatch(
					actions.inspectionPropertyRelatedFileUpload({
						assetId: responseAssetId,
						file: {
							fileId: variables.fileId,
							name: variables.file!.name,
						} as ApiFile,
						propertyId: property.id,
					}),
				);

				enqueueSnackbar('Your file has been uploaded successfully', {
					preventDuplicate: true,
				});
			},
		},
	);

	const onClick = React.useCallback(() => {
		if (uploadInput.current) {
			uploadInput.current.click();
		}
	}, [uploadInput.current]);

	const onUpload: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
		async (event) => {
			if (event.currentTarget.files && event.currentTarget.files.length > 0) {
				const fileList = event.target.files;
				if (fileList === null) return;
				if (assetType.repeating) {
					for (const file of fileList) {
						const fileId = v4();

						// eslint-disable-next-line no-await-in-loop
						await assetPropertyRelatedFileMutation.mutateAsync({
							file,
							fileId,
						});
					}
				} else {
					for (const file of fileList) {
						const fileId = v4();
						// eslint-disable-next-line no-await-in-loop
						await propertyRelatedFileMutation.mutateAsync({ file, fileId });
					}
				}
			}
		},
		[assetPropertyRelatedFileMutation, assetType, propertyRelatedFileMutation],
	);

	const isDisabled = React.useMemo<boolean>(
		() => assetPropertyRelatedFileMutation.isLoading || propertyRelatedFileMutation.isLoading,
		[assetPropertyRelatedFileMutation, property, propertyRelatedFileMutation],
	);

	return (
		<>
			<input
				accept="text/csv, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpeg, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, text/plain, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				onChange={onUpload}
				ref={uploadInput}
				multiple
				style={{ display: 'none' }}
				type="file"
			/>
			<Button color="primary" disabled={isDisabled} onClick={onClick} variant="text">
				Upload
			</Button>
		</>
	);
};

export default UploadFileButton;
