import React from 'react';
import { getAdjustedCoordinates } from '@/components/Question/Types/Location/location.utils';
import { Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

const Coordinates: React.FC<{ pointString: string; withToolTip?: boolean }> = ({
	pointString,
	withToolTip = false,
}) => {
	const { enqueueSnackbar } = useSnackbar();

	const copyToClipboard = async () => {
		navigator.clipboard.writeText(pointString.replace(' ', ',')).then(
			() => {
				enqueueSnackbar('Coordinates copied to clipboard', { variant: 'success' });
			},
			() => {
				enqueueSnackbar('Could not copy coordinates');
			},
		);
	};
	if (withToolTip) {
		return (
			<Tooltip
				title={
					<Typography style={{ zIndex: 20 }} onClick={copyToClipboard}>
						Copy precise coordinates
					</Typography>
				}
			>
				<span>{getAdjustedCoordinates(pointString)}</span>
			</Tooltip>
		);
	}

	return <span>{getAdjustedCoordinates(pointString)}</span>;
};

export default Coordinates;
