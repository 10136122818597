import { ApiFile, ApiInspectionDto } from 'kes-common';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { inspectionRelatedFileGet } from '../../api';

const useInspectionRelatedFileGet = (
	inspectionId: ApiInspectionDto['id'],
	fileId: ApiFile['fileId'],
	options?: UseMutationOptions<Response>,
) =>
	useMutation(
		() =>
			inspectionRelatedFileGet(fileId, { inspectionId }).then((response) =>
				response.expectSuccess(),
			),
		options,
	);

export default useInspectionRelatedFileGet;
