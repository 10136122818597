import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

export const ErrorMessage = styled(Typography)`
	margin-top: 8px;
	color: #9f0000;
`;

export const RequestInspectionsButton = styled(Button)`
	position: sticky;
	bottom: 8px;
	margin: 8px auto;
`;

export const AvailableStudiesContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 32px;
`;
