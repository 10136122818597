import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { ApiInspectionDto, ApiAssistResponse } from 'kes-common';
import { assistFeedback } from '@/net/api';

interface MutationOptions {
	feedbackType: 'ThumbsDown' | 'ThumbsUp';
	files: string[];
	questions: string[];
	retrieverType: 'FirstFeedback' | 'SecondFeedback' | 'ThirdFeedback';
	traceId: string;
}

const useAssistFeedback = (
	inspectionId: ApiInspectionDto['id'],
	options?: UseMutationOptions<ApiAssistResponse, unknown, MutationOptions>,
) =>
	useMutation(
		({ feedbackType, files, questions, retrieverType, traceId }) =>
			assistFeedback(traceId, inspectionId, {
				feedbackType,
				files,
				questions,
				retrieverType,
			}).then((response) => response.expectSuccess()),
		options,
	);

export default useAssistFeedback;
