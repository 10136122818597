/* eslint-disable no-param-reassign */
import { ApiAuthorizedInspectionDto } from 'kes-common';
import makeReducer from '@/store/makeReducer';
import { StateMyInspections } from '@/store/state';

import * as actions from '../actions';

const initialState: StateMyInspections = {
	error: undefined,
	inspections: [],
	loaded: false,
};

export default makeReducer(initialState, {
	[actions.myInspectionsError.type](draft, payload): void {
		draft.error = payload.message;
		draft.loaded = true;
	},
	[actions.myInspectionsLoad.type](draft): void {
		draft.error = undefined;
		draft.loaded = false;
	},
	[actions.myInspectionsSuccess.type](draft, payload: ApiAuthorizedInspectionDto[]): void {
		draft.error = undefined;
		draft.inspections = payload;
		draft.loaded = true;
	},
});
