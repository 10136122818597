import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import classnames from 'classnames';
import ChangesIndicator from '@/components/ChangesIndicator';
import AppBar from '@/components/rhdhv/AppBar';
import SideBar from '@/components/SideBar';
import { ApiInspectionDto, ThemeProviders } from 'kes-common';
import State from '@/store/state';
import React, { FC, ReactNode, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import classes from './Base.module.css';
import * as Styled from './Base.styled';

interface Props {
	children: ReactNode;
	error: string | null;
}

const Page: FC<Props> = ({ children, error }): JSX.Element => {
	const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(false);
	const history = useHistory();
	const location = useLocation();
	const childContainer = useRef<HTMLDivElement>(null);

	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

	const inspectionLoaded = useSelector<State, boolean>((state) => state.inspection.loaded);
	const currentInspection = useSelector<State, ApiInspectionDto | undefined>(
		(state) => state.inspection.currentInspection,
	);

	const showAppBar = React.useMemo<boolean>(
		() => new URLSearchParams(location.search).get('hideAppBar') !== '1',
		[location],
	);

	history.listen(() => {
		if (childContainer.current && childContainer.current.scrollTop > 0) {
			childContainer.current.scrollTo(0, 0);
		}
	});

	if (!inspectionLoaded || !currentInspection) {
		return (
			<div className={classes.loader}>
				{!error && <CircularProgress size={70} color="inherit" />}
			</div>
		);
	}

	return (
		<ThemeProviders allowColorSchemeOverride={false}>
			{showAppBar && (
				<AppBar
					actionLeft={
						isDesktop ? undefined : (
							<IconButton
								aria-label="open drawer"
								color="secondary"
								data-testid="inspection__categories"
								onClick={() => setIsDrawerOpen(true)}
							>
								<MenuIcon />
							</IconButton>
						)
					}
				>
					<Styled.ChangesAndShareContainer>
						<ChangesIndicator />
					</Styled.ChangesAndShareContainer>
				</AppBar>
			)}
			<Toolbar />
			<div
				className={classnames({
					[classes.container]: true,
					[classes.containerDesktop]: isDesktop,
					[classes.containerFullHeight]: !showAppBar,
				})}
			>
				{isDesktop && (
					<div className={classes.sidebar}>
						<SideBar onClose={() => setIsDrawerOpen(false)} />
					</div>
				)}
				{!isDesktop && (
					<Drawer onClose={() => setIsDrawerOpen(false)} open={isDrawerOpen} variant="temporary">
						<SideBar onClose={() => setIsDrawerOpen(false)} />
					</Drawer>
				)}
				<div ref={childContainer} className={classes.content} id="base-content">
					{children}
				</div>
				<Snackbar open={Boolean(error)}>
					<Alert severity="error">{error}</Alert>
				</Snackbar>
			</div>
		</ThemeProviders>
	);
};

export default Page;
