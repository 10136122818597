import { useQuery } from '@tanstack/react-query';
import { ApiProjectInspectionDto, ApiTemplateDto } from 'kes-common';
import { Activity } from 'kes-common/src/components/AssistPanel/utils';

import {
	businessLinesGet,
	getProjectListAndInspections,
	getProjectSubjects,
	getRelatedActivities,
	getTemplateLanguages,
	getTemplatesBySubject,
	inspectionRolesGet,
	labelsGet,
	masterTemplatesGet,
	projectOverview,
	projectUsersGet,
	publishedStudiesGet,
	servicesGet,
	studiesGet,
	studyReadReportTemplate,
} from '@/net/api';

export const useActivityMembers = (activityId: ApiProjectInspectionDto['id']) =>
	useQuery(['activity-members', activityId], () =>
		inspectionRolesGet(activityId).then((r) => r.expectSuccess()),
	);

export const useBusinessLines = () =>
	useQuery(['business-lines'], () => businessLinesGet().then((r) => r.expectSuccess()));

export const useLabels = () =>
	useQuery(['labels'], () =>
		labelsGet().then((r) => r.expectSuccess().sort((a, b) => (a > b ? 1 : -1))),
	);

export const useMasterTemplates = () =>
	useQuery(['master-templates'], () => masterTemplatesGet().then((r) => r.expectSuccess()));

export const useProjects = () =>
	useQuery(['projects'], () => projectOverview().then((r) => r.expectSuccess()));

export const useProjectSubjects = () =>
	useQuery(['get-project-subjects'], () => getProjectSubjects().then((r) => r.expectSuccess()));
useProjectSubjects.identifier = 'get-project-subjects';

export const useProjectTemplates = (projectSubject: string | undefined) =>
	useQuery(['get-project-templates', projectSubject], () =>
		projectSubject
			? getTemplatesBySubject(projectSubject).then((r) => r.expectSuccess())
			: Promise.resolve([] as ApiTemplateDto[]),
	);

export const useProjectUsers = (projectId: string) =>
	useQuery(['get-project-users', projectId], () =>
		projectUsersGet(projectId).then((r) => r.expectSuccess()),
	);
useProjectUsers.identifier = 'get-project-users';

export const useServices = () =>
	useQuery(['services'], () => servicesGet().then((r) => r.expectSuccess()));

export const useTemplateReportPreview = (templateId: ApiTemplateDto['id']) =>
	useQuery(['template-report-preview', templateId], () =>
		studyReadReportTemplate(templateId).then((r) => r.expectSuccess()),
	);

export const useTemplates = () =>
	useQuery(['templates'], () => studiesGet().then((r) => r.expectSuccess()));

export const useTemplateLanguages = () =>
	useQuery(['template-languages'], () => getTemplateLanguages().then((r) => r.expectSuccess()));

export const usePublishedStudies = () =>
	useQuery(['published-studies'], () => publishedStudiesGet().then((r) => r.expectSuccess()));

export const useRelatedActivities = (activityId: ApiProjectInspectionDto['id']) =>
	useQuery(['related-activities', activityId], () =>
		getRelatedActivities(activityId).then((r) => r.expectSuccess()),
	);

export interface ProjectAndActivitiesData {
	projects: { projectId: string; projectName: string; activities: Activity[] }[];
	relatedActivities: Activity[]; // all these activities have isRelated == true;
	relatedIds: string[];
	projectsNames: string[]; // populate the search input
}

export const useProjectAndActivities = (inspectionId: string) =>
	useQuery(
		['project-list-and-inspections-filtered', inspectionId],
		async () => {
			const [projectsAndInspections, relatedActivities] = await Promise.all([
				getProjectListAndInspections().then((response) => response.expectSuccess()),
				getRelatedActivities(inspectionId).then((response) => response.expectSuccess()),
			]);
			// get a list of all relatedIds to this inspectionId
			const relatedIds = relatedActivities.map((activity) => activity.relatedActivityId);
			const projectsNames: string[] = [];

			// convert projectAndInspections to a list of projectsName and Activity
			const projects = projectsAndInspections
				.map((p) => {
					const activities = p.inspections
						.map((inspection) => {
							// filter out itself
							if (inspection.id === inspectionId) {
								return null;
							}
							return {
								isRelated: relatedIds.includes(inspection.id),
								id: inspection.id,
								projectName: p.projectName,
								projectId: p.id,
								inspectionDescrOrStudyName: inspection.description
									? inspection.description
									: inspection.studyName,
								lastUpdatedDate: inspection.lastAnsweredDate
									? new Date(inspection.lastAnsweredDate)
									: null,
								generatedDate: inspection.reportGeneratedDate
									? new Date(inspection.reportGeneratedDate)
									: null,
							} as Activity;
						})
						.filter((a) => a !== null) as Activity[];
					projectsNames.push(p.projectName);

					return { projectId: p.id, projectName: p.projectName, activities };
				})
				.filter((p) => p.activities.length > 0);

			const ra = projects.flatMap((p) => p.activities).filter((a) => a.isRelated);

			return {
				projects,
				relatedActivities: ra,
				relatedIds,
				projectsNames,
			} as ProjectAndActivitiesData;
		},
		{ refetchOnWindowFocus: true }, // set to true because you can open an activity and change it so when you come back here you must see up to date date,
	);
