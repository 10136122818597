import { ApiFlag } from 'kes-common';

import makeReducer from '@/store/makeReducer';
import { initialUnorderedState, makeUnorderedRepository } from '@/store/repository';

import * as actions from '../actions';

const repository = makeUnorderedRepository<ApiFlag>();

export default makeReducer(initialUnorderedState<ApiFlag>(), {
	[actions.flagCreateSuccess.type]: (draft, payload) => {
		repository.upsert(draft, payload);
	},
	[actions.flagDelete.type]: (draft, payload): void => {
		repository.delete(draft, payload.flagId);
	},
	[actions.inspectionLoadSuccess.type]: (draft, payload): void => {
		payload.flags.forEach((flag) => {
			repository.upsert(draft, flag);
		});
	},
});
