import MUIAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import Toolbar from '@mui/material/Toolbar';
import { KESLogo, UserMenu, useUser } from 'kes-common';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { ENVIRONMENT, TEST_USERS_ENABLED } from '@/constants';
import { buildRoute, home, userProfile } from '@/routes';
import { projectsLoaded } from '@/selectors';
import { projectOverviewLoad } from '@/store/actions';

import LegalLinks from './LegalLinks';
import styles from './AppBar.module.css';

interface Props {
	actionLeft?: React.ReactNode;
	children?: React.ReactNode;
}

const AppBar: FC<Props> = ({ actionLeft, children }) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const loaded = useSelector(projectsLoaded);

	const { signout, user } = useUser();

	React.useEffect(() => {
		if (!loaded) {
			dispatch(projectOverviewLoad());
		}
	}, [loaded]);

	return (
		<MUIAppBar position="fixed">
			<Toolbar className={styles.appbar__toolbar}>
				{actionLeft}

				<Link to={home}>
					<KESLogo environment={ENVIRONMENT} />
				</Link>

				<Box flexGrow={1} display="flex" justifyContent="flex-end">
					{children}
				</Box>

				<Hidden smDown>
					<LegalLinks />
					<UserMenu
						goToProfile={() => history.push(buildRoute(userProfile, { userId: user.user.id }))}
						isEmail={user.name === null}
						signout={signout}
						signoutEnabled={!TEST_USERS_ENABLED}
						userName={user.name ?? user.email}
					/>
				</Hidden>
			</Toolbar>
		</MUIAppBar>
	);
};

export default AppBar;
