import { ApiInspectionDto, ApiProperty } from 'kes-common';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { Asset } from '@/store/types';

import { inspectionAssetPropertyRelatedFileUpload } from '../../api';

interface MutationOptions {
	file: Blob | null;
	fileId: string;
}

const useInspectionAssetPropertyRelatedFileUpload = (
	inspectionId: ApiInspectionDto['id'],
	assetId: Asset['id'],
	propertyId: ApiProperty['id'],
	options?: UseMutationOptions<string, unknown, MutationOptions>,
) =>
	useMutation(
		(mutationOptions) =>
			inspectionAssetPropertyRelatedFileUpload(
				mutationOptions.fileId,
				assetId,
				propertyId,
				inspectionId,
				mutationOptions,
			).then((response) => response.expectSuccess()),
		options,
	);

export default useInspectionAssetPropertyRelatedFileUpload;
