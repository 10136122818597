import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { assetCreateAndSave } from '@/store/actions';
import { AssetType, Property, SurveyAsset, SurveyAssetList } from '@/store/types';
import { Box } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import State from '@/store/state';
import { assetTypesGet } from '@/selectors';
import { useGoogleTagManager } from 'kes-common';
import AddExtraButton from '@/components/rhdhv/AddExtraButton';
import RepeatingAsset from './RepeatingAsset/RepeatingAsset';

type RepeatingAssetListProps = {
	assetList: SurveyAssetList;
	openNoteModal: (property: Property, asset: SurveyAsset) => void;
	onNoteOverflowClicked: (
		anchorEl: HTMLButtonElement | null,
		property: Property,
		asset: SurveyAsset,
	) => void;
	assetTypeId: string;
};

const RepeatingAssetList: React.FC<RepeatingAssetListProps> = ({
	assetList,
	openNoteModal,
	onNoteOverflowClicked,
	assetTypeId,
}) => {
	const dispatch = useDispatch();
	const assetType = useSelector<State, AssetType>((state: State) =>
		assetTypesGet(state, assetTypeId),
	);
	const { trackCustomEvent } = useGoogleTagManager();

	const addNewAsset = () => {
		dispatch(
			assetCreateAndSave({
				assetId: uuid(),
				assetTypeId,
			}),
		);
		trackCustomEvent({ event: 'ra_row_added_manually' });
	};

	if (assetList.assets.length === 0) {
		return (
			<Card>
				<CardContent>
					<Alert
						action={
							<Button color="inherit" data-testid="add-repeatable-asset" onClick={addNewAsset}>
								Add
							</Button>
						}
						severity="info"
					>
						No rows of type &apos;{assetType.name}&apos; yet.
					</Alert>
				</CardContent>
			</Card>
		);
	}

	return (
		<Box px={3} py={4}>
			{assetList.assets.map((asset: SurveyAsset, index: number) => (
				<RepeatingAsset
					assetTypeId={assetList.assetTypeId}
					asset={asset}
					key={asset.id ? asset.id : undefined}
					index={index}
					openNoteModal={openNoteModal}
					onNoteOverflowClicked={onNoteOverflowClicked}
				/>
			))}
			<Box mt={2}>
				<AddExtraButton onClick={addNewAsset} dataTestId="add-repeatable-asset">
					Add {assetType.name}
				</AddExtraButton>
			</Box>
		</Box>
	);
};

export default RepeatingAssetList;
