import { ApiAnswerSet, ApiFile } from 'kes-common';

// *** */
const allowedFileTypes = {
	embeddable: ['.pdf', '.doc', '.docx', '.txt', '.html'],
	notEmbeddable: ['.zip', '.jpeg', '.csv', '.ppt', '.pptx', '.xls', '.xlsx'],
};
// constants used in the ui based on allowedFileTypes
export const embeddableFiles: string = (() => allowedFileTypes.embeddable.join(' '))();
export const notEmbeddableFiles: string = (() => allowedFileTypes.notEmbeddable.join(' '))();
export const allowedFilesForInputElement: string = (() =>
	`${allowedFileTypes.embeddable.join(',')},${allowedFileTypes.notEmbeddable.join(',')}`)();

export const isEmbeddable = (name: string): boolean => {
	const extension = name.split('.').pop();
	return extension ? allowedFileTypes.embeddable.includes(`.${extension}`) : false;
};

// *** */

// one day backend engineers will give us what we deserve

export const splitFilesByType = (files: ApiFile[]) => {
	const embedded: ApiFile[] = [];
	const notEmbedded: ApiFile[] = [];
	files.forEach((file) => {
		if (file.processed) {
			embedded.unshift(file);
		} else {
			notEmbedded.push(file);
		}
	});
	return { embedded, notEmbedded };
};

export const setScrollbarToTop = (scrollbarRef: React.RefObject<HTMLDivElement>) => {
	if (scrollbarRef.current) {
		// eslint-disable-next-line no-param-reassign
		scrollbarRef.current.scrollTop = 0;
	}
};
export const setScrollbarToBottom = (scrollbarRef: React.RefObject<HTMLDivElement>) => {
	if (scrollbarRef.current) {
		// eslint-disable-next-line no-param-reassign
		scrollbarRef.current.scrollTop = scrollbarRef.current.scrollHeight;
	}
};

// to be moved to the uploading card element
export const getExtension = ({ name }: Blob): string => name.split('.').pop() || '';
export const getByte = ({ size }: Blob): string => {
	const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
	return `${(size / 1024 ** i).toFixed(2)} ${['B', 'KB', 'MB', 'GB', 'TB'][i]}`;
};

interface Dictionary {
	[key: string]: ApiAnswerSet;
}
export const convertToDictionary = (questions: ApiAnswerSet[]): Dictionary =>
	questions.reduce(
		(accumulator, question) => ({
			...accumulator,
			[question.id]: question,
		}),
		{} as Dictionary,
	);

export const retrieverTypes: Record<number, 'FirstFeedback' | 'SecondFeedback' | 'ThirdFeedback'> =
	{
		1: 'FirstFeedback',
		2: 'SecondFeedback',
		3: 'ThirdFeedback',
	};

export const KAAS_COLOR = '#008000';
