import React from 'react';

import KaasContext from './context';
import { KaasContextProps } from './types';

const useKaasContext = (): KaasContextProps => {
	const context = React.useContext(KaasContext);
	if (!context) {
		throw new Error('useKaasContext must be used within a KaasContextProvider');
	}
	return context;
};

export default useKaasContext;
