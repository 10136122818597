import React from 'react';
import TextField from '@/components/rhdhv/TextField/TextField';

import * as Styled from './AddRole.styled';

interface Props {
	addRole: (email: string) => void;
	error?: string;
	buttonText?: string;
	loading?: boolean;
	validate?: (email: string) => string;
}

const validateEmail = (email: string): string => {
	const emailRegexp =
		// eslint-disable-next-line no-control-regex
		/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
	if (!emailRegexp.test(email)) {
		return 'Please enter a correct email address';
	}
	return '';
};

// eslint-disable-next-line import/prefer-default-export
export const AddRole: React.FC<Props> = ({
	addRole,
	error,
	buttonText,
	loading,
	validate = validateEmail,
}) => {
	const [email, setEmail] = React.useState('');
	const [isValidEmail, setIsValidEmail] = React.useState(true);
	const emailErrorMessage = validate(email);

	const onSubmit = (newEmail: string) => {
		if (!emailErrorMessage) {
			addRole(newEmail.toLowerCase().trim());
			setIsValidEmail(true);
			setEmail('');
		} else {
			setIsValidEmail(false);
		}
	};

	return (
		<Styled.TextInputContainer>
			<TextField
				data-testid="add-role-input"
				type="email"
				fullWidth
				autoFocus
				onChange={(e): void => setEmail(e.target.value)}
				value={email}
				error={!!error || !isValidEmail}
				helperText={error || emailErrorMessage}
				placeholder="Enter email: email@rhdhv.com"
				variant="outlined"
				onKeyPress={(ev) => {
					if (ev.key === 'Enter') {
						ev.preventDefault();
						onSubmit(email);
					}
				}}
			/>
			<Styled.Button
				data-testid="add-role-submit-button"
				onClick={() => onSubmit(email)}
				color="primary"
				loading={loading}
				variant="contained"
			>
				{buttonText ?? 'Add'}
			</Styled.Button>
		</Styled.TextInputContainer>
	);
};
