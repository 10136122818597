import { Refresh, OpenInBrowser } from '@mui/icons-material';
import { TableRow, TableCell, Checkbox, Skeleton, Stack, IconButton } from '@mui/material';
import React from 'react';

function ActivitiesTableLoading({
	showProjectNameColumn,
}: {
	showProjectNameColumn?: boolean;
}): JSX.Element {
	return (
		<>
			{Array(3)
				.fill(0)
				.map((_, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<TableRow key={index}>
						<TableCell padding="checkbox">
							<Checkbox disabled />
						</TableCell>

						{showProjectNameColumn && (
							<TableCell>
								<Skeleton animation="wave" />
							</TableCell>
						)}
						<TableCell>
							<Skeleton animation="wave" />
						</TableCell>
						<TableCell>
							<Skeleton animation="wave" />
						</TableCell>
						<TableCell>
							<Stack direction="row">
								<IconButton disabled color="primary">
									<Refresh />
								</IconButton>
								<IconButton disabled color="primary">
									<OpenInBrowser />
								</IconButton>
							</Stack>
						</TableCell>
					</TableRow>
				))}
		</>
	);
}

export default ActivitiesTableLoading;
