import { Editor } from '@tinymce/tinymce-react';
import React from 'react';

import { Field } from './types';

const String: React.FC<Field> = ({
	'data-testid': dataTestId,
	property,
	setFieldValue,
	value = '',
}) => {
	const [localValue, setLocalValue] = React.useState<string>(value);
	const handleChange = React.useCallback((newValue: string) => {
		setLocalValue(newValue);
		setFieldValue(newValue);
	}, []);

	return (
		<Editor
			data-testid={dataTestId}
			init={{
				height: '200px',
				width: '100%',
				resize: false,
				menubar: false,
				statusbar: false,
				plugins: ['advlist autolink lists link', 'searchreplace table paste noneditable'],
				advlist_bullet_styles: 'default',
				advlist_number_styles: 'default,lower-alpha,lower-roman,upper-alpha,upper-roman',
				toolbar:
					'formatselect | bold italic underline | subscript superscript |' +
					'bullist numlist | removeformat | table | undo redo',
				table_clone_elements: 'strong em b i font h1 h2 h3 h4 h5 h6 p div',
				extended_valid_elements: 'td[style]',
				table_appearance_options: false,
				table_advtab: false,
				table_cell_advtab: false,
				table_row_advtab: false,
				table_header_type: 'sectionCells',
				table_toolbar:
					'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | tablerowheader',
			}}
			onEditorChange={handleChange}
			textareaName={property.name}
			value={localValue}
		/>
	);
};

export default String;
