import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import { ApiPublishedStudyDto, ApiStudyDto } from 'kes-common';
import React from 'react';

import { useTemplateReportPreview } from '@/net/reactQuery/queries';

interface TemplatePreviewProps {
	template: ApiStudyDto | ApiPublishedStudyDto;
}

const TemplatePreview: React.FC<TemplatePreviewProps> = ({ template }) => {
	const { data, isError, isFetching, refetch } = useTemplateReportPreview(template.id);

	if (isError) {
		return (
			<Alert
				action={
					<Button color="inherit" onClick={() => refetch()}>
						Retry
					</Button>
				}
				severity="error"
			>
				There was an error while loading the Template preview
			</Alert>
		);
	}

	if (isFetching) {
		return (
			<>
				<Skeleton />
				<Skeleton />
				<Skeleton />
			</>
		);
	}

	if (!isError && !isFetching && data) {
		const frameContent = `
			<html>
				<head>
					<meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
					<link rel="stylesheet" type="text/css" href="/template-styles/content.css">
					<link rel="stylesheet" type="text/css" href="/template-styles/custom-content.css">
					<link rel="stylesheet" type="text/css" href="https://fonts.googleapis.com/css?family=Material+Icons">
				</head>
				<body>
					${data.reportText}
				</body>
			</html>
		`;

		return (
			<iframe
				srcDoc={frameContent}
				style={{ border: 0, height: '100%', width: '100%' }}
				title={`'${template.name}' report preview`}
			/>
		);
	}

	return null;
};

export default TemplatePreview;
