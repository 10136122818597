/* eslint-disable no-param-reassign */
import { SagaIterator, Task } from '@redux-saga/core';
import {
	call,
	fork,
	take,
	ForkEffect,
	race,
	ActionPattern,
	join,
	JoinEffect,
	StrictEffect,
} from 'redux-saga/effects';
import { Action } from 'redux';
import { identity } from 'lodash';
import { SagaMap } from '@/store/appAction';

const DEFAULT_MAX_TASK = 4 as const;

/**
 * A collector is a system that manages how multiple states merge together when multiple items are inside the internal
 * queue.
 *
 * When an item is first inserted inside the queue, the system calls the `init` function.
 *
 * When an item needs to be "merged" with another item, the system calls the `reduce` function
 *
 * When an item is ready to be delivered to the "end saga", the system calls finalize
 */
export interface Collector<I, S, O> {
	/**
	 * Creates the initial state from the input, every type is allowed, including null & undefined
	 */
	init(input: I): S;
	/**
	 * Update the existing state based on a new input object, the code is allowed to mutate the original state, but it
	 * needs to make sure to return a reference to it, as forgetting to add a return condition overwrites the state
	 * with undefined.
	 */
	reduce(a: S, input: I): S;
	/**
	 * Converts the state into an object that the final saga can use.
	 */
	finalize(state: S): O;
}

/**
 * Commonly used collectors with the queue
 */
export const collectors = {
	/**
	 * When multiple calls come into this queue, it only returns the last value
	 */
	takeLatest<S>(): Collector<S, S, S> {
		return {
			init: identity,
			reduce: (a, b): S => b,
			finalize: identity,
		};
	},
	/**
	 * This collector bunches up all requests for the same key into an array, and passes that array to the finalizer
	 */
	takeAll<S>(): Collector<S, S[], S[]> {
		return {
			init: (input): S[] => [input],
			reduce: (a, b): S[] => {
				a.push(b);
				return a;
			},
			finalize: identity,
		};
	},
};

enum TaskState {
	/*
	 * The task is pending and has not been picked up by a "worker"
	 * Next state: RUNNING: After the task has been picked up by a "worker"
	 */
	PENDING = 'PENDING',
	/*
	 * The task is after it has been picked up by a "worker"
	 * Next state: CHANGES: If a new update has been done to the state object and it requires "re-running"
	 * Next state: "DONE": If the "slave worker" finishes, the state becomes running
	 */
	RUNNING = 'RUNNING',
	/*
	 * This task has been modified after being picked up by a "worker"
	 * Next state: RUNNING: The worker has picked the task up again
	 */
	CHANGES = 'CHANGES',
}

interface QueueTask<S> {
	value: S;
	state: TaskState;
}

/**
 * Queueu options object.
 */
interface QueueOptions<A, K> {
	/**
	 * Maxium number of task runners for this queue
	 */
	maxTasks?: number;
	/**
	 * Resolves actions into a key, or a saga returning an key.
	 *
	 * The queue prevents activating multiple workers for the same key, and collects pending objects together.
	 */
	keyResolver: (input: A) => K | IterableIterator<K | StrictEffect>;
}

function* worker<R extends any[], K extends string | number, O>(
	key: K | undefined,
	value: O,
	callable: (payload: O, ...extraArgs: R) => SagaIterator,
	args: R,
): SagaIterator {
	yield call(callable as any, value, ...args); // Force casting as redux saga's doesn't like generics
	return key;
}

function* manager<
	L extends ActionPattern<A>,
	A extends Action,
	R extends any[],
	K extends string | number,
	S,
	O,
>(
	action: L,
	collector: Collector<A, S, O>,
	{ keyResolver, maxTasks = DEFAULT_MAX_TASK }: QueueOptions<A, K>,
	callable: (payload: O, ...extraArgs: R) => SagaIterator,
	args: R,
): SagaIterator {
	if (maxTasks <= 0) {
		throw new Error(
			`Invalid number of maxtasks specified, expected it to be higher than 0: ${maxTasks}`,
		);
	}
	// Use a map instead of a plain javascript object, as a map can store every object, which in turns make typescript
	// play along better than with a Partial<Record<K, ...>>
	const map = new Map<K, QueueTask<S>>();
	// We keep a queue of keys, since accesses to the entries of the above map are more expansive, because the map also
	// contains values that don't need to be iterated over, because they are already handles by a worker
	const keysQueue: K[] = [];
	const workers: Task[] = [];

	while (true) {
		const result: {
			action?: A;
			join?: (undefined | K)[];
		} = yield race(
			workers.length === 0
				? {
						action: take(action),
				  }
				: {
						action: take(action),
						join: race(workers.map((task): JoinEffect => join(task))),
				  },
		);

		// We have a new action, we need to add it to the "queue"
		if (result.action) {
			const key: K = yield call(keyResolver, result.action);
			const queueTask = map.get(key);
			if (queueTask !== undefined) {
				switch (queueTask.state) {
					case TaskState.PENDING:
					case TaskState.CHANGES:
						// Update existing value
						queueTask.value = collector.reduce(queueTask.value, result.action);
						break;
					case TaskState.RUNNING:
						// Create a new value object
						queueTask.value = collector.init(result.action);
						queueTask.state = TaskState.CHANGES;
						break;
					default:
						break;
				}
			} else {
				// Create a new full object to replace the old object
				map.set(key, {
					value: collector.init(result.action),
					state: TaskState.PENDING,
				});
				keysQueue.push(key);
			}
		}
		if (result.join) {
			for (let i = 0; i < result.join.length; i += 1) {
				const finishedId = result.join[i];
				if (finishedId !== undefined) {
					workers.splice(i, 1);
					const queueTask = map.get(finishedId);
					if (queueTask) {
						if (queueTask.state === TaskState.RUNNING) {
							map.delete(finishedId);
						} else {
							// Other events for the key have happened, add it back to the queue
							queueTask.state = TaskState.PENDING;
							keysQueue.push(finishedId);
						}
					}
				}
			}
		}
		if (workers.length < maxTasks && keysQueue.length !== 0) {
			const key = keysQueue.shift();
			// Since we are using a map, any key is a valid key, so the key could theoretically also contain
			// undefined. We cannot add a safe check here, as it would limit the values of the <K> type
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const queueTask = map.get(key!);
			if (queueTask === undefined) {
				throw new Error(
					"The queue contained a value not present in the map, this shouldn't happen",
				);
			}

			const finalizedValue = collector.finalize(queueTask.value);
			queueTask.value = undefined as unknown as S; // Force "quick" cleaning up the value
			queueTask.state = TaskState.RUNNING;

			const task: Task = yield fork(
				// @ts-ignore: Typescript upgrade 3.8 does not compile next line; since queue is going away,
				// let's not go down this rabbit-hole
				worker,
				key,
				finalizedValue,
				callable,
				args,
			);
			workers.push(task);
		}
	}
}

type ReturnTypeOrAny<F extends string | ((...args: any[]) => any)> = F extends (
	...args: any[]
) => any
	? ReturnType<F>
	: any;

type ActionListener<A> = (arg: any) => A;

type ActionMap<C extends (string | ((...args: any[]) => any))[]> = {
	[K in Exclude<keyof C, ''>]: K extends number ? ReturnTypeOrAny<C[K]> : never;
};

type ActionFromList<C extends (string | ((...args: any[]) => any))[]> =
	ActionMap<C>[keyof ActionMap<C>];

/**
 * Makes a queue
 *
 * @param actions Action handler list for redux events to capture
 * @param collector Collector class to merge multiple events together
 * @param options Options used for the queue
 * @param callable The saga spawned for consuming an item of the queue
 */
export function queue<
	C extends (ActionListener<Action> | string)[],
	R extends any[],
	K extends string | number,
	S,
	O,
>(
	actions: C,
	collector: Collector<ActionFromList<C>, S, O>,
	options: QueueOptions<ActionFromList<C>, K>,
	callable: (payload: O, ...extraArgs: R) => SagaIterator,
	...args: R
): ForkEffect {
	// Force casting as redux saga's doesn't like generics

	return fork(manager, actions, collector, options as any, callable as any, args);
}

type Arg1<F extends (...args: any) => any> = F extends (arg: infer A, ...args: any[]) => any
	? A
	: never;
type ValuesOfObject<O> = O[keyof O];

type ActionOfSagaMap<D extends Partial<SagaMap<any>>> = ValuesOfObject<{
	[K in keyof D]: D[K] extends (...args: any) => any ? Arg1<D[K]> : never;
}>;
/**
 * Makes a queue
 *
 * @param dispatch Action handler list for redux events to capture
 * @param options Options used for the queue
 */
export function queueWithDispatch<
	D extends Partial<SagaMap<R>>,
	R extends any[],
	K extends string | number,
>(dispatch: D, options: QueueOptions<ActionOfSagaMap<D>, K>, ...args: R): ForkEffect {
	const types = Object.entries(dispatch)
		.filter(([, value]): boolean => !!value)
		.map(([key]): string => key);
	return queue<string[], R, K, ActionOfSagaMap<D>[], ActionOfSagaMap<D>[]>(
		types,
		collectors.takeAll<ActionOfSagaMap<D>>(),
		options,
		function* dispatcher(actions, ...extraArgs): SagaIterator {
			// eslint-disable-next-line no-restricted-syntax
			for (const action of actions) {
				// All the valid actions here have the type property, but typescript doesn't
				// go deep enough in the typetree to discover this
				const { type } = action as unknown as Action<keyof D>;
				const saga = dispatch[type];
				if (saga) {
					// This area requires casting as typescript isn't smart enough to see that the types that the saga
					// expect matches the action

					yield call(saga as any, action, ...extraArgs);
				}
			}
			actions.length = 0;
		},
		...args,
	);
}
