import TextField from '@mui/material/TextField';
import React from 'react';

import { Field } from './types';

const String: React.FC<Field> = ({
	'data-testid': dataTestId,
	property,
	setFieldValue,
	value = '',
}) => {
	const [localValue, setLocalValue] = React.useState<string>(value);
	const handleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		setLocalValue(newValue);
		setFieldValue(newValue);
	}, []);

	return (
		<TextField
			data-testid={dataTestId}
			variant="standard"
			fullWidth
			name={property.name}
			onChange={handleChange}
			placeholder={property.name}
			value={localValue}
		/>
	);
};

export default String;
