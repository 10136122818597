import styled from 'styled-components';

export const ExistingUsersList = styled.div`
	max-height: 500px;
	overflow-x: hidden;
	overflow-y: auto;
`;

export const UserSection = styled.div`
	margin: ${({ theme }) => theme.spacing(4)} 0 0;
`;
