import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import React from 'react';

import MultipleSelectChoice from './MultipleSelectChoice';
import { Field } from './types';

const MultipleSelect: React.FC<Field> = ({
	'data-testid': dataTestId,
	property,
	setFieldValue,
	value = '',
}) => {
	const [values, setValues] = React.useState<string[]>(
		value
			.split(',')
			.map((element) => element.trim())
			.filter((element) => Boolean(element)),
	);

	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const newValues = event.target.checked
				? [...values, event.target.value]
				: values.filter((selectedValue) => selectedValue !== event.target.value);
			setValues(newValues);
			setFieldValue(newValues.join(','));
		},
		[setFieldValue, setValues, values],
	);

	return (
		<FormControl component="fieldset">
			<FormGroup>
				{property.choiceIds.map((choiceId) => (
					<MultipleSelectChoice
						choiceId={choiceId}
						data-test-id={dataTestId}
						onChange={handleChange}
						values={values}
					/>
				))}
			</FormGroup>
		</FormControl>
	);
};

export default MultipleSelect;
