import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Button from '@mui/material/Button';
import { ApiStudyDto, ApiStudyUserDto, useUser } from 'kes-common';
import React from 'react';
import { useMutation } from '@tanstack/react-query';

import { studyRoleDelete } from '@/net/api';

interface RemoveButtonProps {
	onSuccess(userId: string): void;
	template: ApiStudyDto;
	user: ApiStudyUserDto;
}

const RemoveButton: React.FC<RemoveButtonProps> = ({ onSuccess, template, user }) => {
	const { user: loggedInUser } = useUser();

	const removeUser = useMutation(
		() => studyRoleDelete(template.id, { roles: user.roles, userId: user.userId }),
		{
			onSuccess: () => {
				onSuccess(user.userId);
			},
		},
	);

	return (
		<Button
			color="secondary"
			disabled={removeUser.isLoading || loggedInUser.user.id === user.userId}
			onClick={() => removeUser.mutate()}
			startIcon={<RemoveCircleIcon />}
			variant="text"
		>
			Remove
		</Button>
	);
};

export default RemoveButton;
