import { ApiInspectionDto } from 'kes-common';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { inspectionScriptFileDelete } from '../../api';

const useInspectionScriptFileDelete = (
	inspectionId: ApiInspectionDto['id'],
	options?: UseMutationOptions,
) =>
	useMutation(
		() => inspectionScriptFileDelete(inspectionId).then((response) => response.expectSuccess()),
		options,
	);

export default useInspectionScriptFileDelete;
