import React, { FC, useEffect, useRef } from 'react';
import { backendUrl, TEST_USERS_ENABLED } from '@/constants';
import { useParams } from 'react-router-dom';
import * as actions from '@/store/actions';
import { useDispatch } from 'react-redux';

import { useLocation } from 'react-router';
import qs from 'qs';

import { useAccessToken } from '@/providers/Authentication';

const Report: FC = (): JSX.Element => {
	// To avoid tokens in web server logs, send access token as form data.
	const accessToken = useAccessToken();
	const dispatch = useDispatch();
	const { inspectionId } = useParams<Record<string, string>>();
	const location = useLocation();
	const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
	const hiddenForm = useRef<HTMLFormElement>(null);
	const hiddenInput = useRef<HTMLInputElement>(null);
	const hiddenInputForTestUser = useRef<HTMLInputElement>(null);
	const url = `${backendUrl}inspection/${inspectionId}/report/download/${
		queryParams.variant || ''
	}`;

	useEffect((): void => {
		const submitForm = async (): Promise<void> => {
			try {
				hiddenInput.current?.setAttribute('value', accessToken || '');
				if (TEST_USERS_ENABLED) {
					const userFromLocalStorage = localStorage.getItem('Test-User-Id');
					if (userFromLocalStorage) {
						hiddenInputForTestUser.current?.setAttribute('value', userFromLocalStorage);
					}
				}
				hiddenForm.current?.submit();
			} catch (e) {
				dispatch(actions.projectFatalError(e as Error));
			}
		};
		submitForm();
	}, [accessToken, dispatch]);

	return (
		<form ref={hiddenForm} method="post" target="_self" action={url}>
			<input ref={hiddenInput} name="access_token" type="hidden" />
			{TEST_USERS_ENABLED && <input ref={hiddenInputForTestUser} name="test_user" type="hidden" />}
		</form>
	);
};

export default Report;
