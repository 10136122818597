import { Asset, Property } from '@/store/types';

const isNotApplicable = (parentAsset: Asset, parentProperty: Property) => {
	if (
		parentProperty.hasNotApplicableOption &&
		!parentAsset.applicableByPropertyId[parentProperty.id]
	) {
		return true;
	}
	return false;
};

export default isNotApplicable;
