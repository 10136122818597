import { makeUnorderedSelectors } from '@/store/repository';
import State from '@/store/state';
import { Asset, Category } from '@/store/types';

import { getByAssetId as getAssetTypeByAssetId } from './assetTypes';

const selector = makeUnorderedSelectors<Category>();

export const { get, getAll, getOrNull } = selector;
export const getByAssetId = (state: State, assetId: Asset['id']): Category | null => {
	const assetType = getAssetTypeByAssetId(state, assetId);
	if (assetType) {
		return (
			Object.values(state.categories.byId).find((category) =>
				category.assetTypeIds.includes(assetType.id),
			) || null
		);
	}
	return null;
};
