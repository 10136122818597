import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { ApiStudyDto, useFeatureFlag } from 'kes-common';
import React from 'react';

import TemplateCreateDrawer from './TemplateCreateDrawer';
import TemplateImportDialog from './TemplateImportDialog';

const TemplateCreateButton: React.FC = () => {
	const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null);
	const [isTemplateCreateDrawerOpen, setIsTemplateCreateDrawerOpen] =
		React.useState<boolean>(false);
	const [templateCreateType, setTemplateCreateType] =
		React.useState<ApiStudyDto['type']>('CLASSIC');
	const [isTemplateImportDialogOpen, setIsTemplateImportDialogOpen] =
		React.useState<boolean>(false);

	const isMenuOpen = React.useMemo<boolean>(() => Boolean(anchorElement), [anchorElement]);
	const buttonRef = React.useRef<HTMLDivElement | null>(null);

	const WORD_ADDIN = useFeatureFlag({ name: 'WORD_ADDIN' });

	const onMenuClose = React.useCallback(() => {
		setAnchorElement(null);
	}, [setAnchorElement]);

	const onMenuOpen = React.useCallback((): void => {
		setAnchorElement(buttonRef.current);
	}, [buttonRef, setAnchorElement]);

	const templateCreate = React.useCallback(() => {
		setTemplateCreateType('CLASSIC');
		setIsTemplateCreateDrawerOpen(true);
		onMenuClose();
	}, [onMenuClose, setIsTemplateCreateDrawerOpen, setTemplateCreateType]);

	const templateCreateWord = React.useCallback(() => {
		setTemplateCreateType('WORD');
		setIsTemplateCreateDrawerOpen(true);
		onMenuClose();
	}, [onMenuClose, setIsTemplateCreateDrawerOpen, setTemplateCreateType]);

	const templateImport = React.useCallback(() => {
		setIsTemplateImportDialogOpen(true);
		onMenuClose();
	}, [onMenuClose, setIsTemplateImportDialogOpen]);

	return (
		<>
			<ButtonGroup ref={buttonRef} variant="contained">
				<Button onClick={templateCreate}>Create</Button>
				<Button
					size="small"
					aria-controls={isMenuOpen ? 'template-creation-mechanism' : undefined}
					aria-expanded={isMenuOpen ? 'true' : undefined}
					aria-label="Select Template creation mechanism"
					aria-haspopup="menu"
					onClick={isMenuOpen ? onMenuClose : onMenuOpen}
				>
					<ArrowDropDownIcon />
				</Button>
			</ButtonGroup>
			<Menu
				anchorEl={anchorElement}
				id="template-creation-mechanism"
				onClose={onMenuClose}
				open={isMenuOpen}
			>
				<MenuItem onClick={templateCreate} sx={{ alignItems: 'start', flexDirection: 'column' }}>
					<Typography>
						<strong>Create</strong>
					</Typography>
					<Typography>Manually create a new Template</Typography>
				</MenuItem>

				<MenuItem
					disabled={!WORD_ADDIN}
					onClick={templateCreateWord}
					sx={{ alignItems: 'start', flexDirection: 'column' }}
				>
					<Typography>
						<strong>Word</strong>
					</Typography>
					<Typography>Create a Word-based Template using the Kes Word Add-In</Typography>
				</MenuItem>

				<MenuItem onClick={templateImport} sx={{ alignItems: 'start', flexDirection: 'column' }}>
					<Typography>
						<strong>Import</strong>
					</Typography>
					<Typography>Import a Template from file</Typography>
				</MenuItem>
			</Menu>
			<TemplateCreateDrawer
				isOpen={isTemplateCreateDrawerOpen}
				onClose={() => setIsTemplateCreateDrawerOpen(false)}
				templateCreateType={templateCreateType}
			/>
			<TemplateImportDialog
				isOpen={isTemplateImportDialogOpen}
				onClose={() => setIsTemplateImportDialogOpen(false)}
			/>
		</>
	);
};

export default TemplateCreateButton;
