/* eslint-disable no-param-reassign */
import { ApiFile } from 'kes-common';

import makeReducer from '@/store/makeReducer';

import * as actions from '../actions';

const initialState: ApiFile[] = [];

export default makeReducer(initialState, {
	[actions.inspectionAssetPropertyRelatedFileUpload.type]: (draft, payload): void => {
		if (!draft.some((file) => file.fileId === payload.file.fileId)) {
			draft.push(payload.file);
		}
	},
	[actions.inspectionLoadSuccess.type]: (draft, payload): void => {
		draft.splice(0, draft.length, ...payload.files);
	},
	[actions.inspectionPropertyRelatedFileUpload.type]: (draft, payload): void => {
		if (!draft.some((file) => file.fileId === payload.file.fileId)) {
			draft.push(payload.file);
		}
	},
	[actions.inspectionRelatedFileDelete.type]: (draft, payload): void => {
		draft.splice(
			draft.findIndex((file) => file.fileId === payload.fileId),
			1,
		);
	},
	[actions.inspectionRelatedFileUpload.type]: (draft, payload): void => {
		if (!draft.some((file) => file.fileId === payload.fileId)) {
			draft.push({
				fileId: payload.fileId,
				name: payload.fileName,
				processed: payload.processed,
			} as ApiFile);
		}
	},
});
