import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ApiPointValue } from 'kes-common';
import React from 'react';
import { connect, useSelector } from 'react-redux';

import Location from '@/components/Question/Types/Location';
import { propertiesGet } from '@/selectors';
import State from '@/store/state';
import { Property, PropertyType } from '@/store/types';

import assertNever from '../../utils/assertNever';
import ExpandableText from '../rhdhv/ExpandableText';
import Note from './Note';
import classes from './Property.module.css';
import PropertyContextMenu from './PropertyContextMenu';
import { DateTypeWithSuggestion } from './Types/Date';
import { DecimalWithSuggestions } from './Types/Decimal';
import Image from './Types/Image';
import { AnswerListWithSuggestion } from './Types/internal/AnswerList';
import MultipleAssets from './Types/MultipleAssets';
import MultipleSelect from './Types/MultipleSelect';
import SingleAsset from './Types/SingleAsset';
import SingleSelect from './Types/SingleSelect';
import { StringWithSuggestions } from './Types/String';
import RichText from './Types/RichText';

interface AnswerRendererProps {
	property: Property;
	answer: string | undefined;

	onChange(payload?: string, otherOption?: boolean, location?: ApiPointValue[]): void;

	disabled?: boolean;
	otherOption: boolean;
	assetId: string | null;
	assetTypeId: string;
	location: ApiPointValue[];
}

const AnswerRenderer: React.FC<AnswerRendererProps> = ({
	property,
	otherOption,
	assetId,
	assetTypeId,
	...rest
}): JSX.Element => {
	if (property.allowMultipleAnswers) {
		return <AnswerListWithSuggestion {...rest} type={property.type} />;
	}

	switch (property.type) {
		case PropertyType.DATE:
			return <DateTypeWithSuggestion {...rest} />;
		case PropertyType.STRING:
			return <StringWithSuggestions {...rest} />;
		case PropertyType.DECIMAL:
			return <DecimalWithSuggestions {...rest} />;
		case PropertyType.RICH_TEXT:
			return <RichText {...rest} />;
		case PropertyType.SINGLE_SELECT:
			return (
				<SingleSelect
					{...rest}
					choiceIds={property.choiceIds}
					hasOtherOption={property.hasOtherOption}
					otherOption={otherOption}
					property={property}
				/>
			);
		case PropertyType.MULTI_SELECT:
			return (
				<MultipleSelect
					{...rest}
					choiceIds={property.choiceIds}
					hasOtherOption={property.hasOtherOption}
					otherOption={otherOption}
					property={property}
				/>
			);
		case PropertyType.SINGLE_SUBSTANCE:
			return (
				<SingleSelect {...rest} choiceIds={[]} otherOption={otherOption} property={property} />
			);
		case PropertyType.MULTI_SUBSTANCE:
			return (
				<MultipleSelect
					{...rest}
					choiceIds={[]}
					hasOtherOption={property.hasOtherOption}
					otherOption={otherOption}
					property={property}
				/>
			);
		case PropertyType.SINGLE_ASSET_REFERENCE:
			return <SingleAsset {...rest} property={property} />;
		case PropertyType.MULTI_ASSET_REFERENCE:
			return <MultipleAssets {...rest} property={property} />;
		case PropertyType.IMAGE:
			return <Image {...rest} property={property} assetId={assetId} assetTypeId={assetTypeId} />;
		case PropertyType.LOCATIONS:
			return <Location {...rest} property={property} assetId={assetId} assetTypeId={assetTypeId} />;
		default:
			return assertNever(property.type);
	}
};

interface Props {
	property: Property;
	answer: string | undefined;
	number: number;

	onChange(
		payload: string | undefined,
		applicable: boolean,
		otherOptionEnabled: boolean,
		location?: ApiPointValue[],
	): void;

	note?: string;
	openNoteModal: (property: Property) => void;
	onNoteOverflowClicked: (
		anchorEl: (EventTarget & HTMLButtonElement) | null,
		property: Property,
	) => void;
	applicable: boolean;
	otherOption: boolean;
	assetId: string | null;
	assetTypeId: string;
	location?: ApiPointValue[];
}

const PropertyRenderer: React.FC<Props> = ({
	property,
	number,
	answer,
	onChange,
	note,
	openNoteModal,
	onNoteOverflowClicked,
	applicable,
	otherOption,
	assetId,
	assetTypeId,
	location,
}): JSX.Element | null => {
	const hasNotApplicableOption = useSelector<State, boolean>(
		(state) => propertiesGet(state, property.id).hasNotApplicableOption,
	);

	if (!property.survey) {
		return null;
	}
	if (
		property.type === PropertyType.SINGLE_SUBSTANCE ||
		property.type === PropertyType.MULTI_SUBSTANCE
	) {
		// Substance property types shouldn't show in the survey, since Mi.License only uses these as CSV uploads
		return null;
	}
	if (
		(property.type === PropertyType.SINGLE_ASSET_REFERENCE ||
			property.type === PropertyType.MULTI_ASSET_REFERENCE) &&
		property.identifyingProperty == null
	) {
		return null; // Only named asset relationship properties should show in the survey
	}
	return (
		<div className={classes.root}>
			<div className={classes.iconWrapper}>
				<div className={classes.number}>{number}</div>
			</div>
			<div className={classes.title}>
				{property.question || property.name} {property.required && '*'}
				{property.description && (
					<div className={classes.description}>
						<ExpandableText text={property.description} />
					</div>
				)}
			</div>
			<div className={classes.answer}>
				<AnswerRenderer
					disabled={!applicable}
					onChange={(payload, updatedOtherOptionAnswer, newLocation) => {
						onChange(payload, applicable, !!updatedOtherOptionAnswer, newLocation);
					}}
					answer={answer}
					property={property}
					otherOption={otherOption}
					assetId={assetId}
					assetTypeId={assetTypeId}
					location={location ?? []}
				/>
				{hasNotApplicableOption && (
					<Box marginTop={1}>
						<Button
							color="primary"
							onClick={(): void => {
								onChange(answer === '' ? undefined : answer, !applicable, otherOption, location);
							}}
							size="small"
							variant="text"
						>
							{!applicable ? 'Make applicable' : 'Not applicable'}
						</Button>
					</Box>
				)}
			</div>
			<div className={classes.iconWrapper}>
				<div className={classes.actions}>
					<PropertyContextMenu
						assetId={assetId}
						assetTypeId={assetTypeId}
						note={note}
						onNoteModalOpen={openNoteModal}
						property={property}
					/>
				</div>
			</div>

			{note && (
				<Note
					onClick={(): void => {
						openNoteModal(property);
					}}
					title="Note"
					onOverflowClick={(target): void => onNoteOverflowClicked(target, property)}
				>
					{note}
				</Note>
			)}
		</div>
	);
};

export default connect(
	(state: State, ownProps: { propertyId: Property['id'] }): Pick<Props, 'property'> => ({
		property: propertiesGet(state, ownProps.propertyId),
	}),
)(PropertyRenderer);
