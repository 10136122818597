/* eslint-disable no-param-reassign */
import PartialExpect from '../utils/PartialExpect';
import { Writeable } from './utils';

interface Entity {
	readonly id: string | number;
}

export interface UnorderedState<T extends Entity> {
	byId: Record<T['id'], T>;
}

export interface OrderedState<T extends Entity> extends UnorderedState<T> {
	allIds: T['id'][];
}

interface UnorderedSelectors<T extends Entity> {
	get(state: Readonly<Pick<UnorderedState<T>, 'byId'>>, id: T['id']): T;
	getAll(state: Readonly<Pick<UnorderedState<T>, 'byId'>>): Record<T['id'], T>;
	getOrNull(state: Readonly<Pick<UnorderedState<T>, 'byId'>>, id: T['id']): T | null;
}
interface OrderedSelectors<T extends Entity> {
	allIds: (state: Readonly<Pick<OrderedState<T>, 'allIds'>>) => T['id'][];
}
interface UnorderedRepository<T extends Entity> {
	readonly initialState: UnorderedState<T>;
	upsert(draft: UnorderedState<T>, object: T): void;
	update(draft: UnorderedState<T>, newValue: PartialExpect<T, 'id'>): void;
	modify(draft: UnorderedState<T>, id: T['id'], callback: (instance: Writeable<T>) => void): void;
	modifyAll(draft: UnorderedState<T>, callback: (instance: Writeable<T>) => void): void;
	delete(draft: UnorderedState<T>, id: T['id']): void;
	clear(draft: UnorderedState<T>): void;
	replaceAll<I, R>(
		draft: UnorderedState<T>,
		values: Record<T['id'], I>,
		map: (input: I, key: T['id'], ...rest: R[]) => T,
		...rest: R[]
	): void;
}
interface OrderedRepository<T extends Entity> {
	readonly initialState: OrderedState<T>;
	upsert(draft: OrderedState<T>, object: T): void;
	update(draft: OrderedState<T>, newValue: PartialExpect<T, 'id'>): void;
	modify(draft: OrderedState<T>, id: T['id'], callback: (instance: Writeable<T>) => void): void;
	modifyAll(draft: OrderedState<T>, callback: (instance: Writeable<T>) => void): void;
	delete(draft: OrderedState<T>, id: T['id']): void;
	clear(draft: OrderedState<T>): void;
	replaceAll<I, R>(
		draft: OrderedState<T>,
		values: Record<T['id'], I>,
		allIds: string[] | number[],
		map: (input: I, key: T['id'], ...rest: R[]) => T,
		...rest: R[]
	): void;
}

function toWriteable<T>(input: T): Writeable<T> {
	return input as Writeable<T>;
}

export const makeUnorderedSelectors = <T extends Entity>(): UnorderedSelectors<T> => ({
	get(state, id): T {
		const item = state.byId[id];
		if (item === undefined) {
			throw new Error(`Item not found with id ${id}`);
		}
		return item;
	},
	getAll(state): Record<T['id'], T> {
		return state.byId;
	},
	getOrNull(state, id): T | null {
		const item = state.byId[id];
		if (item === undefined) {
			return null;
		}
		return item;
	},
});

export const makeOrderedSelectors = <T extends Entity>(): OrderedSelectors<T> => ({
	allIds: (state): T['id'][] => state.allIds,
});

export const initialUnorderedState = <T extends Entity>(): UnorderedState<T> => ({
	byId: {} as Record<T['id'], T>,
});

export const initialOrderedState = <T extends Entity>(): OrderedState<T> => ({
	...initialUnorderedState<T>(),
	allIds: [],
});

export const makeUnorderedRepository = <T extends Entity>(): UnorderedRepository<T> => {
	const repository: UnorderedRepository<T> = {
		initialState: initialUnorderedState<T>(),
		upsert(draft, object): void {
			const key: T['id'] = object.id;
			draft.byId[key] = object;
		},
		update(draft, newValue): void {
			const item = draft.byId[newValue.id];
			if (item === undefined) {
				throw new Error(`Item not found with id ${newValue.id}`);
			}
			draft.byId[newValue.id] = {
				...item,
				...newValue,
			};
		},
		modify(draft, id, callback): void {
			const item = draft.byId[id];
			if (item === undefined) {
				throw new Error(`Item not found with id ${id}`);
			}
			callback(toWriteable(item));
		},
		modifyAll(draft, callback): void {
			Object.values<T>(draft.byId).forEach((item) => {
				callback(toWriteable(item));
			});
		},
		delete(draft, id): void {
			const item = draft.byId[id];
			if (item === undefined) {
				throw new Error(`Item not found with id ${id}`);
			}
			delete draft.byId[id];
		},
		clear(draft): void {
			draft.byId = {} as Record<T['id'], T>;
		},
		replaceAll<I, R>(
			draft: UnorderedState<T>,
			values: Record<T['id'], I>,
			map: (input: I, key: T['id'], ...rest: R[]) => T,
			...rest: R[]
		): void {
			repository.clear(draft);
			// eslint-disable-next-line guard-for-in, no-restricted-syntax
			for (const id in values) {
				const key: T['id'] = id;
				const value: T = map(values[key], key, ...rest);
				if (!Object.is(key, value.id)) {
					throw new Error(
						`Invalid value encountered, ${id} is not the id of ${JSON.stringify(value)}`,
					);
				}
				draft.byId[key] = value;
			}
		},
	};
	return repository;
};

export const makeOrderedRepository = <T extends Entity>(): OrderedRepository<T> => {
	const repository = makeUnorderedRepository<T>();
	return {
		...repository,
		initialState: initialOrderedState<T>(),
		upsert(draft, object): void {
			const key: T['id'] = object.id;
			const oldValue = draft.byId[key];
			if (!oldValue) {
				draft.allIds.push(object.id);
			}
			repository.upsert(draft, object);
		},
		delete(draft, id): void {
			repository.delete(draft, id);
			const index = draft.allIds.findIndex((e): boolean => e === id);
			draft.allIds.splice(index, 1);
		},
		clear(draft): void {
			repository.clear(draft);
			draft.allIds = [];
		},
		replaceAll<I, R>(
			draft: OrderedState<T>,
			values: Record<T['id'], I>,
			allIds: string[] | number[],
			map: (input: I, key: T['id'], ...rest: R[]) => T,
			...rest: R[]
		): void {
			repository.replaceAll(draft, values, map, ...rest);
			draft.allIds = allIds;
		},
	};
};
