import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { ApiProjectInspectionDto } from 'kes-common';
import React from 'react';
import { useMutation } from '@tanstack/react-query';

import { inspectionUpdate } from '@/net/api';

import * as Styled from './DescriptionModal.styled';
import { useProject } from '../../../../context';

interface DescriptionModalProps {
	handleClose: () => void;
	inspection?: ApiProjectInspectionDto;
	isModalOpen: boolean;
}

const DESCRIPTION_CHARACTER_LIMIT = 60;

const DescriptionModal: React.FC<DescriptionModalProps> = ({
	handleClose,
	inspection,
	isModalOpen,
}) => {
	const [descriptionForInspection, setDescriptionForInspection] = React.useState<string>('');
	const { project, setProject } = useProject();

	const { mutate: updateInspection, ...updateInspectionQuery } = useMutation(
		(inspectionProps: { inspectionId: string; description: string }) =>
			inspectionUpdate(inspectionProps.inspectionId, { description: inspectionProps.description }),
	);

	const createDescription = (): void => {
		if (inspection) {
			updateInspection({ inspectionId: inspection.id, description: descriptionForInspection });
			setProject({
				...project,
				inspections: project.inspections.map((projectInspection) =>
					projectInspection.id === inspection.id
						? { ...inspection, description: descriptionForInspection }
						: projectInspection,
				),
			});
		}
	};

	React.useEffect(() => {
		if (inspection && inspection.description) {
			setDescriptionForInspection(inspection.description);
		}
	}, [inspection]);

	React.useEffect(() => {
		if (updateInspectionQuery.isSuccess) {
			handleClose();
			updateInspectionQuery.reset();
		}
	}, [updateInspectionQuery]);

	return (
		<Dialog
			fullWidth
			onClose={() => {
				handleClose();
				setDescriptionForInspection('');
			}}
			open={isModalOpen}
		>
			<DialogTitle>Add description</DialogTitle>
			<DialogContent>
				<TextField
					autoFocus
					data-testid="input-inspection-description"
					disabled={updateInspectionQuery.isLoading}
					fullWidth
					helperText={`${descriptionForInspection.length}/${DESCRIPTION_CHARACTER_LIMIT}`}
					inputProps={{ maxLength: DESCRIPTION_CHARACTER_LIMIT }}
					onChange={(event) => setDescriptionForInspection(event.target.value)}
					value={descriptionForInspection}
					variant="outlined"
				/>
				{updateInspectionQuery.isError && (
					<Styled.ErrorMessage variant="body1">
						Something went wrong adding a description. Try again.
					</Styled.ErrorMessage>
				)}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						handleClose();
						setDescriptionForInspection('');
					}}
					variant="text"
				>
					Cancel
				</Button>
				<Button
					data-testid="action-save-description"
					disabled={updateInspectionQuery.isLoading}
					onClick={createDescription}
					color="primary"
					variant="contained"
				>
					Done
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DescriptionModal;
