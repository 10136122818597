import React from 'react';
import { GeocodeFeature } from '@mapbox/mapbox-sdk/services/geocoding';
import * as Styled from '@/components/Question/Types/Location/Location.styles';
import { Box } from '@mui/material';
import pointRed from '../../../../assets/point_red.svg';

const SearchResult: React.FC<GeocodeFeature & { onClick: (location: GeocodeFeature) => void }> = ({
	onClick,
	...rest
}) => (
	<Styled.ListItem key={rest.id} onClick={() => onClick(rest)}>
		<Box width="100%" display="flex" justifyContent="space-between">
			<Box display="flex" flexDirection="column">
				<Styled.LocationName>{rest.text}</Styled.LocationName>
				<Styled.Address>{rest.place_name.replace(`${rest.text},`, '')}</Styled.Address>
			</Box>

			<Styled.Point src={pointRed} />
		</Box>
	</Styled.ListItem>
);

export default SearchResult;
