/* eslint-disable react/no-array-index-key */
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface Breadcrumb {
	title: string;
	url?: string;
}

interface PageTitleProps {
	actions?: React.ReactNode;
	breadcrumbs?: Breadcrumb[];
	title?: React.ReactNode;
}

const PageTitle: React.FC<PageTitleProps> = ({ actions, breadcrumbs, title }) => {
	const onTablets = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
	const onMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

	return (
		<Box marginTop={breadcrumbs ? 3 : 6}>
			<Grid container>
				<Grid item xs>
					{breadcrumbs && (
						<Box marginBottom={1}>
							<Breadcrumbs sx={{ textTransform: 'uppercase' }} maxItems={onTablets ? 2 : undefined}>
								{breadcrumbs.map((breadcrumb, index) => {
									if (breadcrumb.url) {
										return (
											<Link
												color="textSecondary"
												component={RouterLink}
												key={index}
												to={breadcrumb.url}
												underline="hover"
												variant="overline"
											>
												{breadcrumb.title}
											</Link>
										);
									}
									return (
										<Typography key={index} variant="overline" noWrap>
											{breadcrumb.title}
										</Typography>
									);
								})}
							</Breadcrumbs>
						</Box>
					)}
					{title && (
						<Typography
							color="primary"
							data-testid="page-title__title"
							lineHeight="1.6"
							variant="h6"
							noWrap
						>
							{title}
						</Typography>
					)}
				</Grid>
				{!onMobile && (
					<Grid item xs="auto">
						{actions}
					</Grid>
				)}
			</Grid>
			{onMobile && <Box marginTop={2}>{actions}</Box>}
		</Box>
	);
};

export default PageTitle;
