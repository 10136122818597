import { ApiProjectInspectionDto, AvatarList } from 'kes-common';
import React from 'react';

import * as Styled from './InspectionUsersList.styled';

interface InspectionUsersListProps {
	inspection: ApiProjectInspectionDto;
}

const InspectionUsersList: React.FC<InspectionUsersListProps> = ({ inspection }) => (
	<Styled.Container data-testid="inspection-role-list">
		<AvatarList users={inspection.users} />
	</Styled.Container>
);

export default InspectionUsersList;
