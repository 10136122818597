import React, { memo, useEffect } from 'react';
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';

import ActivitiesTable from './ActivitiesTable';

import { Activity, ProjectAndActivitiesData } from './utils';

export interface ActivitiesTabProps {
	data: ProjectAndActivitiesData | undefined;
	isInitialLoading: boolean;
	isError: boolean;

	refetch: () => void;
	regenerate: (relatedActivitiesId: string) => void;
	deleteRelatedActivity: (relatedActivityId: string) => void;
	createRelatedActivity: (relatedActivityId: string) => void;

	reportLink: (inspectionId: string) => string;
	activityLink: (projectId: string, inspectionId: string) => string;
}

const ActivitiesTab = ({
	data,
	isInitialLoading,
	isError,
	refetch,
	regenerate,
	reportLink,
	activityLink,
	deleteRelatedActivity,
	createRelatedActivity,
}: ActivitiesTabProps) => {
	// accordion state management
	const [expanded, setExpanded] = React.useState<string | false>(
		'all-selected-activities-accordion',
	);
	const setAccordion = (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};
	// all this state is to have a smoother check/uncheck of the activities
	const [projects, setProjects] = React.useState<ProjectAndActivitiesData['projects'] | undefined>(
		undefined,
	);
	const [relatedActivities, setRelatedActivities] = React.useState<
		ProjectAndActivitiesData['relatedActivities'] | undefined
	>(undefined);
	useEffect(() => {
		if (data) {
			setProjects(data.projects);
			setRelatedActivities(data.relatedActivities);
		}
	}, [data]);

	const select = (activity: Activity) => {
		if (activity.isRelated) {
			deleteRelatedActivity(activity.id);
			setRelatedActivities((prev) => {
				if (!prev) return prev;
				const newRelatedActivities = prev.filter((a) => a.id !== activity.id);
				return newRelatedActivities;
			});
		} else {
			createRelatedActivity(activity.id);
			setRelatedActivities((prev) => {
				if (!prev) return prev;
				const newRelatedActivities = [...prev, activity];
				return newRelatedActivities;
			});
		}
	};
	return (
		<>
			<Accordion
				sx={{ maxHeight: '60vh' }}
				onChange={setAccordion('all-selected-activities-accordion')}
				expanded={expanded === 'all-selected-activities-accordion'}
				TransitionProps={{ unmountOnExit: true }}
			>
				<AccordionSummary expandIcon={<ExpandMore />}>
					<Typography variant="body1" sx={{ flexGrow: 1 }}>
						All selected reports
					</Typography>
					<Typography variant="subtitle2" sx={{ color: 'primary.main', pr: 2 }}>
						{relatedActivities?.length} &nbsp;selected
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<ActivitiesTable
						data={relatedActivities}
						isLoading={isInitialLoading}
						isError={isError}
						select={select}
						regenerate={regenerate}
						refetch={refetch}
						activityLink={activityLink}
						reportLink={reportLink}
						showProjectNameColumn
					/>
				</AccordionDetails>
			</Accordion>
			{projects?.map((project) => (
				<Accordion
					TransitionProps={{ unmountOnExit: true }}
					onChange={setAccordion(project.projectId)}
					expanded={expanded === project.projectId}
				>
					<AccordionSummary expandIcon={<ExpandMore />}>
						<Typography variant="body1" sx={{ flexGrow: 1 }}>
							Project {project.projectName}
						</Typography>
						<Stack direction="row" spacing={2} mr={1}>
							<Typography variant="subtitle2" sx={{ color: 'primary.main' }}>
								{project.activities.filter((a) => a.isRelated).length} &nbsp;selected
							</Typography>
							<Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
								[{project.activities.length} &nbsp;activities]
							</Typography>
						</Stack>
					</AccordionSummary>
					<AccordionDetails>
						<ActivitiesTable
							key={project.projectId}
							data={project.activities}
							isLoading={isInitialLoading}
							isError={isError}
							select={select}
							activityLink={activityLink}
							reportLink={reportLink}
							regenerate={regenerate}
							refetch={refetch}
						/>
					</AccordionDetails>
				</Accordion>
			))}
		</>
	);
};

export default memo(ActivitiesTab);
