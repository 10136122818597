import { PublicClientApplication, AccountInfo } from '@azure/msal-browser';
import buildMSALGraphClient from './buildMSALGraphClient';

interface GetUserFieldsFromADv2Options {
	account: AccountInfo;
	publicClientApplication: PublicClientApplication;
}

const getUserImageFromAD = async (options: GetUserFieldsFromADv2Options): Promise<Blob> => {
	const graphClient = buildMSALGraphClient(options);
	const imageBlob = await graphClient.api('/me/photo/$value').get();
	return imageBlob;
};

export default getUserImageFromAD;
