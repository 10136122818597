import AssetRenderer from '@/components/Question/Asset';
import Alert from '@/components/rhdhv/Alert';
import { assetsGet, assetTypesGet } from '@/selectors';
import { assetDelete, assetSetIsOpen } from '@/store/actions';
import State from '@/store/state';
import { AssetType, Property, SurveyAsset, UUID } from '@/store/types';
import { Box, IconButton } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FlagEditor from '@/components/FlagEditor';
import { useGoogleTagManager } from 'kes-common';
import * as Styled from './RepeatingAsset.styled';
import RepeatingAssetTitle from './RepeatingAssetTitle';

interface Props {
	assetTypeId: UUID;
	asset: SurveyAsset;
	index: number;
	openNoteModal: (property: Property, asset: SurveyAsset) => void;
	onNoteOverflowClicked: (
		anchorEl: HTMLButtonElement | null,
		property: Property,
		asset: SurveyAsset,
	) => void;
}

const RepeatingAsset: FC<Props> = ({
	assetTypeId,
	asset,
	index,
	openNoteModal,
	onNoteOverflowClicked,
}): JSX.Element | null => {
	const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState<boolean>(false);
	const assetType = useSelector<State, AssetType>((state: State) =>
		assetTypesGet(state, assetTypeId),
	);
	const assetId = asset.id;
	const realAsset = assetId ? useSelector((state: State) => assetsGet(state, assetId)) : undefined;
	const { isOpen } = realAsset || { isOpen: false };

	const dispatch = useDispatch();
	const { trackCustomEvent } = useGoogleTagManager();

	function deleteAsset(): void {
		if (asset.id) {
			dispatch(
				assetDelete({
					assetId: asset.id,
					assetTypeId,
				}),
			);
			trackCustomEvent({ event: 'ra_row_deleted_manually' });
		}
	}

	return (
		<Styled.RepeatingAsset>
			<Styled.Expand
				onClick={() => {
					if (assetId) {
						dispatch(assetSetIsOpen({ assetId, isOpen: !isOpen }));
					}
				}}
				id={`${asset.id}-repeating-header`}
			>
				<Box width={40} data-testid="repeatable-asset-expand">
					{isOpen ? <ExpandLess /> : <ExpandMore />}
				</Box>
				<RepeatingAssetTitle asset={asset} assetType={assetType} index={index} />
				<Box alignItems="center" display="flex" ml={2}>
					<FlagEditor assetId={asset.id || undefined} assetTypeId={assetTypeId} />

					<IconButton
						onClick={(event): void => {
							setIsDeleteAlertOpen(true);
							event.stopPropagation();
						}}
						onFocus={(event): void => {
							event.stopPropagation();
						}}
						size="large"
					>
						<Styled.DeleteIcon data-testid="repeating-asset-delete" />
					</IconButton>
				</Box>
			</Styled.Expand>
			<Styled.Collapse isOpen={isOpen} in={isOpen}>
				{isOpen && (
					<AssetRenderer
						// there is always at least one asset, since the surveyAssetListsGet selector ensures that all
						// non-repeating assets types have at least a single survey asset
						asset={asset}
						assetTypeId={assetTypeId}
						blueBackground
						openNoteModal={openNoteModal}
						onNoteOverflowClicked={onNoteOverflowClicked}
					/>
				)}
			</Styled.Collapse>
			<Alert
				open={isDeleteAlertOpen}
				onClose={(): void => {
					setIsDeleteAlertOpen(false);
				}}
				title={`Delete ${assetType.name}?`}
				buttonCloseText="Cancel"
				buttonApproveText="Delete"
				onApprove={(): void => {
					deleteAsset();
					setIsDeleteAlertOpen(false);
				}}
			/>
		</Styled.RepeatingAsset>
	);
};

export default RepeatingAsset;
