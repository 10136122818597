import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React from 'react';

import { helpLinks, legalLinks } from './links';

const LegalLinks: React.FC = () => {
	const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null);
	const onClose = React.useCallback(() => {
		setAnchorElement(null);
	}, []);
	const onOpen = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorElement(event.currentTarget);
	}, []);
	const open = React.useMemo<boolean>(() => Boolean(anchorElement), [anchorElement]);

	return (
		<>
			<IconButton onClick={onOpen} sx={{ marginRight: 2, marginLeft: 2 }}>
				<InfoOutlinedIcon />
			</IconButton>
			<Menu anchorEl={anchorElement} onClose={onClose} open={open}>
				{helpLinks.map((helpLink, index) => (
					<MenuItem
						component="a"
						href={helpLink.href}
						// eslint-disable-next-line react/no-array-index-key
						key={index}
						rel="noopener noreferrer"
						target="_blank"
					>
						{helpLink.title}
					</MenuItem>
				))}
				<Divider />
				{legalLinks.map((legalLink, index) => (
					<MenuItem
						component="a"
						href={legalLink.href}
						// eslint-disable-next-line react/no-array-index-key
						key={index}
						rel="noopener noreferrer"
						target="_blank"
					>
						{legalLink.title}
					</MenuItem>
				))}
			</Menu>
		</>
	);
};

export default LegalLinks;
