import { useState, useCallback, useEffect } from 'react';

const useSelectedQuestionsKaas = (inspectionId: string) => {
	const [selectedQuestions, setSelectedQuestions] = useState<string[]>([]);

	// load questions from localStorage
	useEffect(() => {
		const storedQuestions = localStorage.getItem(`selectedQuestions_${inspectionId}`);
		if (storedQuestions) {
			setSelectedQuestions(JSON.parse(storedQuestions));
		}
	}, [inspectionId, setSelectedQuestions]);

	// save questions to localStorage
	useEffect(() => {
		if (selectedQuestions.length === 0) {
			localStorage.removeItem(`selectedQuestions_${inspectionId}`);
			return;
		}
		localStorage.setItem(`selectedQuestions_${inspectionId}`, JSON.stringify(selectedQuestions));
	}, [inspectionId, selectedQuestions]);

	/**
	 * Toggles the selection of a question and save it in local
	 *
	 * @param {string} questionId - the id of the question to select
	 */
	const selectQuestion = useCallback(
		(questionId: string) => {
			if (selectedQuestions.includes(questionId)) {
				setSelectedQuestions(selectedQuestions.filter((id) => id !== questionId));
			} else {
				setSelectedQuestions((prev) => [...prev, questionId]);
			}
		},
		[selectedQuestions, setSelectedQuestions],
	);

	/**
	 * Removes questions from local storage that are not present in the backend anymore.
	 *
	 * @param {string[]} questionIds - list of Questions present in the backend
	 */
	const pruneQuestions = (questionIds: string[]) => {
		setSelectedQuestions(selectedQuestions.filter((id) => questionIds.includes(id)));
	};

	return { selectedQuestions, selectQuestion, pruneQuestions };
};

export default useSelectedQuestionsKaas;
