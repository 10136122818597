import React, { ComponentType, useState, useMemo, useEffect } from 'react';
import { debounce, DebouncedFunc } from 'lodash';

// eslint-disable-next-line  @typescript-eslint/no-empty-function
const VOID = (): void => {};

const withThrottle =
	<
		P extends {
			value?: unknown;
			onChange?: (payload: string) => void;
			/*
			Disable the warning here, since we need this function to stay compatible with
			the prop definitions of a textfield
		*/

			onBlur?: (event: any) => void;

			onFocus?: (event: any) => void;
		},
	>(
		Component: ComponentType<P>,
		delay = 500,
	): ComponentType<P> =>
	(props): JSX.Element => {
		const { onBlur, onChange, onFocus, value: originalValue } = props;
		const [value, setValue] = useState(originalValue);
		const [replaceValue, setReplaceValue] = useState(true);
		const change = useMemo(
			(): DebouncedFunc<any> & ((p: string) => void) => debounce(onChange || VOID, delay),
			[onChange],
		);

		// Update the value in the text field if it is changed externally
		useEffect(() => {
			if (originalValue !== value) {
				setValue(originalValue);
			}
		}, [originalValue]);

		if (
			replaceValue &&
			(Number.isNaN(Number(value)) ? !Number.isNaN(Number(originalValue)) : value !== originalValue)
		) {
			setValue(originalValue);
		}

		return (
			<Component
				{...props}
				value={value}
				onChange={(e): void => {
					change(e);
					setValue(e);
				}}
				onFocus={(e): void => {
					setReplaceValue(false);
					if (onFocus) {
						onFocus(e);
					}
				}}
				onBlur={(e): void => {
					change.flush();
					setReplaceValue(true);
					if (onBlur) {
						onBlur(e);
					}
				}}
			/>
		);
	};

export default withThrottle;
