import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';

interface ConfirmInspectionDeleteProps {
	copy?: string;
	handleClose: () => void;
	onConfirm: () => void;
	open: boolean;
	title: string;
}

const ConfirmInspectionDelete: React.FC<ConfirmInspectionDeleteProps> = ({
	copy,
	handleClose,
	onConfirm,
	open,
	title,
}): JSX.Element => (
	<Dialog
		aria-describedby="alert-dialog-description"
		aria-labelledby="alert-dialog-title"
		onClose={handleClose}
		open={open}
	>
		<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
		{copy && (
			<DialogContent>
				<DialogContentText id="alert-dialog-description">{copy}</DialogContentText>
			</DialogContent>
		)}
		<DialogActions>
			<Button color="primary" onClick={handleClose}>
				Cancel
			</Button>
			<Button autoFocus color="primary" data-testid="action-confirm" onClick={onConfirm}>
				Ok
			</Button>
		</DialogActions>
	</Dialog>
);

export default ConfirmInspectionDelete;
