import FileUploadIcon from '@mui/icons-material/FileUpload';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FormikConfig, useFormik } from 'formik';
import { ApiInspectionDto, ApiInspectionUserDto } from 'kes-common';
import React from 'react';
import * as yup from 'yup';

import { inspectionRoleCreate } from '@/net/api';
import { Checkbox, FormControlLabel } from '@mui/material';

const fileUploadTypes: string[] = [
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/pdf',
	'application/vnd.ms-powerpoint',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	'text/plain',
];

interface FormValues {
	email: string;
	file?: File;
	note?: string;
	removeAccess: boolean;
	allowReportDownload: boolean;
}

interface AddMemberFormProps {
	activityId: ApiInspectionDto['id'];
	onError(): void;
	onSuccess(user: ApiInspectionUserDto): void;
}

const AddMemberForm: React.FC<AddMemberFormProps> = ({ activityId, onError, onSuccess }) => {
	const uploadInput = React.useRef<HTMLInputElement>(null);

	const initialValues: FormValues = React.useMemo<FormValues>(
		() => ({
			email: '',
			note: '',
			removeAccess: false,
			allowReportDownload: true,
		}),
		[],
	);

	const onSubmit: FormikConfig<FormValues>['onSubmit'] = React.useCallback(
		async (values, formikBag) => {
			formikBag.setSubmitting(true);
			try {
				const response = await inspectionRoleCreate(activityId, {
					email: values.email,
					file: values.file || null,
					note: values.note || null,
					role: 'INSPECTION_EDITOR',
					removeAccess: values.removeAccess.toString(),
					allowReportDownload: values.allowReportDownload.toString(),
				});
				const user = response.expectSuccess();
				onSuccess(user);
				formikBag.resetForm();
			} catch (error) {
				onError();
			} finally {
				formikBag.setSubmitting(false);
			}
		},
		[activityId, onError, onSuccess],
	);

	const validationSchema = yup.object<FormValues>().shape({
		email: yup
			.string()
			.email('Please enter a valid email address')
			.required('An email address is required'),
	});

	const formik = useFormik<FormValues>({
		initialValues,
		onSubmit,
		validationSchema,
	});

	const fieldHasError = React.useCallback(
		(fieldName: keyof FormValues) => Boolean(formik.errors[fieldName] && formik.touched[fieldName]),
		[formik],
	);
	const fieldGetHelperText = React.useCallback(
		(fieldName: keyof FormValues, helperText: string) =>
			fieldHasError(fieldName) ? formik.errors[fieldName]! : helperText,
		[fieldHasError, formik],
	);

	return (
		<form onSubmit={formik.handleSubmit}>
			<TextField
				error={fieldHasError('email')}
				fullWidth
				helperText={fieldGetHelperText(
					'email',
					'The email address of the user to add to the inspection',
				)}
				label="Email address"
				margin="normal"
				name="email"
				onBlur={formik.handleBlur}
				onChange={formik.handleChange}
				value={formik.values.email}
			/>
			<FormControlLabel
				sx={{ mt: 2, ml: 1 }}
				control={
					<Checkbox
						checked={formik.values.allowReportDownload}
						onChange={formik.handleChange}
						name="allowReportDownload"
					/>
				}
				label="Allow report download"
			/>
			<FormHelperText sx={{ mb: 2, ml: 2 }}>
				Allow the report to be downloaded and viewed by the user
			</FormHelperText>
			<FormControlLabel
				sx={{ mt: 2, ml: 1 }}
				control={
					<Checkbox
						checked={formik.values.removeAccess}
						onChange={formik.handleChange}
						name="removeAccess"
					/>
				}
				label="Remove access upon completion"
			/>
			<FormHelperText sx={{ mb: 4, ml: 2 }}>
				You can remove all access after the user finishes the survey
			</FormHelperText>

			<TextField
				error={fieldHasError('note')}
				fullWidth
				helperText={fieldGetHelperText(
					'note',
					'An extra note to send to the user along with the invitation email',
				)}
				label="Note"
				name="note"
				onBlur={formik.handleBlur}
				onChange={formik.handleChange}
				value={formik.values.note}
			/>

			<FormControl sx={{ mt: 3, mb: 6 }} fullWidth margin="normal">
				<input
					accept={fileUploadTypes.join(',')}
					onChange={(event) => {
						formik.setFieldTouched('file');
						if (event.currentTarget.files && event.currentTarget.files.length > 0) {
							formik.setFieldValue('file', event.currentTarget.files[0]);
						}
					}}
					ref={uploadInput}
					style={{ display: 'none' }}
					type="file"
				/>
				<Grid container>
					<Grid item sx={{ width: formik.values.file instanceof File ? '100%' : undefined }} xs={6}>
						<Button
							fullWidth
							sx={{ justifyContent: 'flex-start', pl: 2.5 }}
							onClick={() => uploadInput.current?.click()}
							startIcon={<FileUploadIcon />}
						>
							Choose file
						</Button>
					</Grid>

					{formik.values.file instanceof File && (
						<Grid alignItems="center" display="flex" item xs={6}>
							{/* <Box alignItems="center" display="flex" marginLeft={3}> */}
							{/* <CheckCircleIcon sx={{ marginRight: 1 }} /> */}
							<Typography noWrap sx={{ marginLeft: 1 }}>
								{formik.values.file.name}
							</Typography>
							{/* </Box> */}
						</Grid>
					)}
				</Grid>

				<FormHelperText error={Boolean(formik.touched.file && formik.errors.file)}>
					{fieldGetHelperText(
						'file',
						'Upload a file to attach to the invitation email to the above user',
					)}
				</FormHelperText>
			</FormControl>

			<Button
				color="primary"
				disabled={formik.isSubmitting}
				fullWidth
				type="submit"
				variant="contained"
			>
				Add activity member
			</Button>
		</form>
	);
};

export default AddMemberForm;
