import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ApiProjectInspectionDto } from 'kes-common';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ActivityMembersDialog from '@/components/ActivityMembersDialog';
import IsInternalUser from '@/components/Auth/IsInternalUser';
import DownloadScript from '@/components/DownloadScript';
import { inspectionDelete } from '@/store/actions';

import ConfirmInspectionDelete from './ConfirmInspectionDelete';
import DescriptionModal from './DescriptionModal';
import InspectionUsersList from './InspectionUsersList';
import { useProject } from '../../../context';
import CopyActivity from './CopyActivity';
import DownloadExcelReportMenuItem from './DownloadExcelReportMenuItem';

interface InspectionCardProps {
	inspection: ApiProjectInspectionDto;
}

const InspectionCard: React.FC<InspectionCardProps> = ({ inspection }) => {
	const [anchorElement, setAnchorElement] = React.useState<HTMLElement | null>(null);
	const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState<boolean>(false);
	const [descriptionModalOpen, setDescriptionModalOpen] = React.useState<boolean>(false);
	const [teamMemberDialogOpen, setTeamMemberDialogOpen] = React.useState<boolean>(false);

	const { project, refetchProject, setProject } = useProject();

	const dispatch = useDispatch();
	const history = useHistory();

	const studyNameInLowerCase = React.useMemo(
		() => inspection.studyName.toLowerCase(),
		[inspection],
	);

	const closeMenu = React.useCallback(() => {
		setAnchorElement(null);
	}, [setAnchorElement]);

	const onDeleteInspection = React.useCallback(() => {
		dispatch(inspectionDelete(inspection.id));
		setConfirmDeleteOpen(false);
		setProject({
			...project,
			inspections: project.inspections.filter(
				(projectInspection) => projectInspection.id !== inspection.id,
			),
		});
	}, [dispatch, inspection, project, setConfirmDeleteOpen]);

	const onMemberAddSuccess = React.useCallback(() => {
		refetchProject();
	}, [refetchProject]);

	const onMemberRemoveSuccess = React.useCallback(() => {
		refetchProject();
	}, [refetchProject]);

	return (
		<>
			<Card
				data-testid={`action-inspection-${studyNameInLowerCase}`}
				onClick={() =>
					history.push(`/project/${project.id}/inspection/${inspection.id}/category/load`)
				}
				sx={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', height: '100%' }}
			>
				<CardHeader
					action={
						project.hasAdminPrivileges ? (
							<IconButton
								aria-label="More"
								data-testid={`action-inspection-${studyNameInLowerCase}-context-menu`}
								onClick={(event) => {
									event.stopPropagation();
									setAnchorElement(event.currentTarget);
								}}
								size="medium"
							>
								<MoreVertIcon fontSize="inherit" />
							</IconButton>
						) : undefined
					}
					color="primary"
					subheader={`Template version: ${inspection.version}`}
					title={
						<Typography
							color="primary"
							data-testid={`inspection-name-${studyNameInLowerCase}`}
							variant="subtitle1"
						>
							{inspection.description || inspection.studyName}
						</Typography>
					}
				/>
				<CardContent sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
					<Typography>
						<em>{inspection.sharepointUrl !== null ? 'Word' : 'Kes'}</em>
					</Typography>
					{inspection.deletedTemplate && (
						<Tooltip title="The template that this activity is based on has been deleted.">
							<Alert severity="warning" sx={{ marginBottom: 1 }}>
								Template deleted
							</Alert>
						</Tooltip>
					)}
					<Typography color="textPrimary" variant="body1">
						Date added: {new Intl.DateTimeFormat('en-GB').format(new Date(inspection.dateCreated))}
					</Typography>
					{inspection.description && <Typography>{inspection.studyName}</Typography>}
					<Box sx={{ marginTop: 'auto' }}>
						<IsInternalUser>
							<InspectionUsersList inspection={inspection} />
						</IsInternalUser>
					</Box>
					<Box width="100%">
						<Typography color="textSecondary" variant="caption">
							{inspection.completed && 'Completed'}
							{!inspection.completed &&
								(inspection.progress === 0 ? 'Not started' : `${inspection.progress}% Completed`)}
						</Typography>
						<LinearProgress
							value={inspection.completed ? 100 : inspection.progress}
							variant="determinate"
						/>
					</Box>
				</CardContent>
			</Card>

			{/* All popovers/dialogs from here */}
			<Menu
				anchorEl={anchorElement}
				id="simple-menu"
				keepMounted
				onClose={closeMenu}
				open={Boolean(anchorElement)}
			>
				<MenuItem
					data-testid="action-inspection-context-menu-manage-team-members"
					onClick={() => {
						closeMenu();
						setTeamMemberDialogOpen(true);
					}}
				>
					Manage team members
				</MenuItem>

				<MenuItem
					data-testid="action-inspection-context-menu-edit-description"
					onClick={() => {
						closeMenu();
						setDescriptionModalOpen(true);
					}}
				>
					Edit description
				</MenuItem>

				{inspection.sharepointUrl && (
					<MenuItem
						data-testid="action-inspection-context-menu-word-activity"
						onClick={() => {
							closeMenu();
							window.open(inspection.sharepointUrl!, '_blank');
						}}
					>
						Open Word Activity
					</MenuItem>
				)}

				<CopyActivity inspection={inspection} onClick={closeMenu} projectId={project.id} />

				<MenuItem
					data-testid="action-inspection-context-menu-delete-inspection"
					onClick={() => {
						closeMenu();
						setConfirmDeleteOpen(true);
					}}
				>
					Remove
				</MenuItem>

				<DownloadScript
					templateId={inspection.studyId}
					assetLibraryId={inspection.assetLibraryId}
					renderChildren={(onDownloadClick) => (
						<MenuItem
							data-testid="action-inspection-context-menu-download-script"
							onClick={onDownloadClick}
						>
							Download script
						</MenuItem>
					)}
				/>
				<DownloadExcelReportMenuItem inspectionId={inspection.id} />
			</Menu>

			<ActivityMembersDialog
				activity={inspection}
				isOpen={teamMemberDialogOpen}
				onClose={() => setTeamMemberDialogOpen(false)}
				onMemberAddSuccess={onMemberAddSuccess}
				onMemberRemoveSuccess={onMemberRemoveSuccess}
			/>

			<DescriptionModal
				handleClose={() => setDescriptionModalOpen(false)}
				inspection={inspection}
				isModalOpen={descriptionModalOpen}
			/>

			<ConfirmInspectionDelete
				copy="Are you sure you want to delete this activity?"
				handleClose={() => setConfirmDeleteOpen(false)}
				onConfirm={onDeleteInspection}
				open={confirmDeleteOpen}
				title="Confirm activity deletion"
			/>
		</>
	);
};

export default InspectionCard;
