import Button from '@mui/material/Button';
import { DialogActions } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import React from 'react';

type DialogProps = {
	children: React.ReactNode;
	name: string;
	open: boolean;
	onClose: () => void;
};

const RoleManagementDialog: React.FC<DialogProps> = ({ name, open, onClose, children }) => (
	<Dialog
		open={open}
		onClose={onClose}
		maxWidth="sm"
		fullWidth
		onClick={(e) => {
			e.preventDefault();
			e.stopPropagation();
		}}
	>
		<DialogTitle>{name}</DialogTitle>
		<Divider />
		<DialogContent>{children}</DialogContent>
		<DialogActions>
			<Button onClick={onClose} variant="text" color="primary" data-testid="dialog-done-button">
				Done
			</Button>
		</DialogActions>
	</Dialog>
);

export default RoleManagementDialog;
