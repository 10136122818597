import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useQueryClient } from '@tanstack/react-query';
import { ApiStudyDto } from 'kes-common';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router';

import TemplateForm from '@/components/TemplateForm';
import { buildRoute, template as templateRoute } from '@/routes';

interface TemplateEditDrawerProps {
	isOpen: boolean;
	onClose(): void;
	template: ApiStudyDto;
}

const TemplateEditDrawer: React.FC<TemplateEditDrawerProps> = ({ isOpen, onClose, template }) => {
	const history = useHistory();
	const queryClient = useQueryClient();
	const { enqueueSnackbar } = useSnackbar();

	const onSuccess = React.useCallback(
		(updatedTemplate: ApiStudyDto) => {
			enqueueSnackbar('Template successfully edited');
			queryClient.invalidateQueries(['templates', updatedTemplate.id]);
			history.push(buildRoute(templateRoute, { templateId: updatedTemplate.id }));
		},
		[enqueueSnackbar, history, queryClient],
	);

	return (
		<Drawer anchor="right" onClose={onClose} open={isOpen}>
			<Box sx={{ padding: 3, maxWidth: '100vw', width: '500px' }}>
				<Box display="flex" justifyContent="space-between" marginBottom={2}>
					<Typography variant="h6">Edit Template info</Typography>
					<IconButton onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</Box>
				<TemplateForm
					onSuccess={onSuccess}
					submitButtonLabel="Edit"
					template={template}
					templateCreateType={template.type}
				/>
			</Box>
		</Drawer>
	);
};

export default TemplateEditDrawer;
