import React from 'react';
import styled from 'styled-components';

import withThrottle from '../../../hoc/withThrottle';
import withUnwrappedEvent from '../../../hoc/withUnwrappedEvent';
import TextField, { TextFieldProps } from '../../../rhdhv/TextField/TextField';

interface TextProps {
	answer: string | undefined;
	onChange(payload: string | undefined): void;
	onClick?(): void;
	placeholder: string;
	type: 'number' | 'text';
	pattern?: RegExp;
	disabled?: boolean;
	autoFocus?: boolean;
	multiline?: boolean;
}

const StyledTextField = styled(TextField)`
	background-color: white;
	resize: vertical;
`;

const ThrottledTextField = withThrottle(withUnwrappedEvent<TextFieldProps>(StyledTextField), 1000);

const Text: React.FC<TextProps> = function Text({ answer, onChange, ...rest }): JSX.Element {
	const [value, setValue] = React.useState<string>(answer || '');

	return (
		<div>
			<ThrottledTextField
				// @ts-ignore
				value={value}
				fullWidth
				onBlur={(event) => onChange(event.target.value || undefined)}
				onChange={setValue}
				margin="none"
				variant="outlined"
				{...rest}
			/>
			{rest.multiline ? (
				<span style={{ fontSize: '12px' }}>Use shift-enter for multiple lines</span>
			) : null}
		</div>
	);
};

export default Text;
