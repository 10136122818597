import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

import { useProject } from '../context';

const ClientInformation: React.FC = () => {
	const { project } = useProject();
	const { client } = project.projectDetails;
	const { location } = client;

	return (
		<>
			<Typography color="primary.dark" gutterBottom variant="h6">
				Client information
			</Typography>
			<Box marginTop={2}>
				<Typography color="textSecondary" gutterBottom variant="overline">
					Company name
				</Typography>
				<Typography data-testid="client__company-name">{client.companyName}</Typography>
			</Box>
			<Box marginTop={3}>
				<Typography color="textSecondary" gutterBottom variant="overline">
					Industry
				</Typography>
				<Typography data-testid="client__industry">{client.industry}</Typography>
			</Box>
			<Box marginTop={3}>
				<Typography color="textSecondary" gutterBottom variant="overline">
					Chamber of commerce
				</Typography>
				<Typography data-testid="client__chamber-of-commerce-number">{client.cocNumber}</Typography>
			</Box>
			<Box marginTop={3}>
				<Typography color="textSecondary" gutterBottom variant="overline">
					Contact info
				</Typography>
				<Typography>
					<span data-testid="client__contact-person__name">
						{client.contactPersonName} ({client.contactPersonRole})
					</span>
					<br />
					<span data-testid="client__contact-person__phone-number">
						{client.contactPersonPhoneNumber}
					</span>
					<br />
					<span data-testid="client__contact-person__email">{client.contactPersonEmail}</span>
					<br />
					<span data-testid="client__website-url">{client.websiteUrl}</span>
				</Typography>
			</Box>
			<Box marginTop={3}>
				<Typography color="textSecondary" gutterBottom variant="overline">
					Address
				</Typography>
				<Typography>
					<span data-testid="client__address--line-one">
						{location.streetName} {location.houseNumber}, {location.postalCode}
					</span>
					<br />
					<span data-testid="client__address--line-two">
						{location.city}, {location.country}
					</span>
				</Typography>
			</Box>
		</>
	);
};

export default ClientInformation;
