import { SagaIterator } from '@redux-saga/core';
import { takeEvery } from '@redux-saga/core/effects';
import { MendixUnavailableError } from '@/store/types';
import { AuthenticationActions } from 'react-aad-msal';
import { AnyAction } from 'redux';

// eslint-disable-next-line require-yield
function* onAction(action: AnyAction): SagaIterator {
	if (
		'payload' in action &&
		action.type !== AuthenticationActions.AcquiredAccessTokenError &&
		action.payload instanceof Error
	) {
		const node = document.createElement('style');
		const content =
			action.payload instanceof MendixUnavailableError
				? action.payload.message
				: 'An unexpected error has occurred. ' +
				  'Please refresh the page and contact the administrator if this error persists.';
		const color =
			action.payload instanceof MendixUnavailableError ? '195, 247, 247' : '255,191,191';
		node.innerHTML =
			'#root:after { position: fixed; top: 0; left:0; bottom: 0; right: 0;' +
			`background: rgba(${color}, 1);` +
			'z-index: 100;' +
			'display: flex;' +
			'align-items: center;' +
			'justify-content: center;' +
			'font-size: 2vh;' +
			`content: '${content}';` +
			'}';
		document.body.appendChild(node);
		throw action.payload;
	}
}

function* error(): SagaIterator {
	yield takeEvery('*', onAction);
}

export default error;
