import styled from 'styled-components';
import {
	Box,
	ListItemButton as MUIListItemButton,
	Paper,
	Typography,
	TextField as MUITextField,
	InputAdornment as MUIInputAdornment,
} from '@mui/material';
import { MapContainer as ReactLeafletMapContainer } from 'react-leaflet';
import MUICloseIcon from '@mui/icons-material/Close';

export const LocationListContainer = styled(Paper)`
	z-index: 20;
	left: ${({ theme }) => theme.spacing(1)};
	top: 80px;
	width: 340px;
	position: absolute;
	overflow: auto;
	max-height: calc(100% - 94px);

	.MuiList-root {
		padding: 0;
	}
`;

export const ListItem = styled(MUIListItemButton)<{ active?: boolean }>`
	padding: 12px 24px;
	${({ theme, active }) =>
		active &&
		`
		background-color: ${theme.palette.primary.main};
		color: white;
		&:hover {
			background-color: ${theme.palette.primary.main};
			color: white;
		}
	`}
`;

export const Point = styled.img`
	width: 16px;
`;

export const SaveBoxContainer = styled(Paper)`
	padding: ${({ theme }) => theme.spacing(2)};
	width: 322px;
	z-index: 1;
	right: ${({ theme }) => theme.spacing(1)};
	top: 80px;
	position: absolute;
`;

export const ModalContainer = styled(Paper)`
	background-color: white;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	width: 80%;
	height: 80%;
	display: flex;
	flex-direction: column;
	.mapboxgl-map {
		cursor: pointer !important;
	}

	.leaflet-div-icon {
		background: none;
		border: none;
	}
	outline: none;
`;

export const LocationListHeading = styled(Typography)`
	font-size: 12px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.67;
	letter-spacing: 1px;
	padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
	color: ${({ theme }) => theme.palette.primary.dark};
`;

export const LocationListHeadingContainer = styled(Box)`
	position: sticky;
	z-index: 22;
	top: 0;
	background-color: white;
`;

export const Address = styled(Typography)<{ active?: boolean }>`
	color: ${({ theme, active }) => (active ? 'white' : theme.palette.grey['600'])};
	font-size: 16px;
	padding: 0;
`;

export const InputAdornment = styled(MUIInputAdornment)``;

export const TextField = styled(MUITextField)`
	margin-bottom: ${({ theme }) => theme.spacing(1)};
	${InputAdornment} {
		visibility: hidden;
	}

	:hover {
		${InputAdornment} {
			visibility: visible;
		}
	}
`;

export const AddressContainer = styled.div`
	display: flex;
	align-items: center;
	p {
		margin: 0 ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(1)} 0;
	}
`;

export const MapContainer = styled(ReactLeafletMapContainer)<{ height: string; width: string }>`
	height: ${({ height }) => height};
	width: ${({ width }) => width};
	z-index: 0;
`;

export const StaticMapContainer = styled(Box)`
	cursor: pointer;
	.mapboxgl-map {
		cursor: pointer !important;
	}

	.leaflet-div-icon {
		background: none;
		border: none;
	}
`;

export const LocationName = styled(Typography)`
	font-weight: 600;
`;

export const CloseIcon = styled(MUICloseIcon)`
	cursor: pointer;
`;
