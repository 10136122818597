import { PageTitle, useGoogleTagManager, useUser } from 'kes-common';
import Alert from '@mui/material/Alert';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { buildRoute, project } from '@/routes';
import PreviewIcon from '@mui/icons-material/Preview';
import AppBar from '@/components/rhdhv/AppBar';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import { CloseIcon } from '@/components/Question/Types/Location/Location.styles';
import Link from '@mui/material/Link';
import { useProjectSubjects, useProjectTemplates } from '@/net/reactQuery/queries';
import { SelectChangeEvent } from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import { AddRole } from '@/components/rhdhv/RoleManagement/AddRole';
import isRHDHVEmail from '@/utils/isRHDHVEmail';
import {
	createProject,
	emailToUser,
	getErrorMessage,
	isMasterProject,
	MasterProject,
} from '@/components/ProjectImport/model';
import SearchProjects from '@/components/ProjectImport/SearchProjects';
import { UserDto } from '@/components/rhdhv/RoleManagement/RoleListItem';
import { DocumentPreview } from './index.styled';
import { ProjectAdministratorList } from '../Project/Overview/ProjectAdministratorDialog/ProjectAdministratorList';

const ProjectImport: React.FC = (): JSX.Element => {
	const [activeStep, setActiveStep] = useState(0);
	const [projectToImport, setProjectToImport] = useState<MasterProject | string | null>(null);
	const [mobilePreviewOpen, setMobilePreviewOpen] = useState(false);
	const { trackCustomEvent } = useGoogleTagManager();
	const { data: projectSubjects } = useProjectSubjects();
	const [subject, setSubject] = useState<string | undefined>(undefined);
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const { data: templates } = useProjectTemplates(subject);
	const [selectedTemplates, setSelectedTemplates] = useState(new Set<string>());
	const { user } = useUser();
	const [admins, setAdmins] = useState<string[]>([user.email]);

	const addProjectAdmin = (email: string) => setAdmins((oldAdmins) => [...oldAdmins, email]);

	const goToTrainingEnvironment = React.useCallback(() => {
		window.open('https://training.kes.rhdhv.io/', '_blank');
	}, []);

	const removeProjectAdministrator = (administrator: UserDto) => {
		if (administrator.email !== user.email) {
			setAdmins((oldAdmins) => oldAdmins.filter((admin) => admin !== administrator.email));
		}
	};

	const setTemplateSelected = (event: React.ChangeEvent<HTMLInputElement>) =>
		setSelectedTemplates((oldValue) =>
			event.target.checked
				? new Set(oldValue).add(event.target.name)
				: new Set([...oldValue].filter((id) => id !== event.target.name)),
		);

	const onCreateProject = async () => {
		if (projectToImport === null) throw new Error('No project to create'); // Unrecoverable

		const snackbarAction = (url: string) => (
			<Button color="secondary" onClick={() => history.replace(url)}>
				Visit existing project
			</Button>
		);

		try {
			const result = await createProject(projectToImport, selectedTemplates, admins);
			if (result.tag === 'ALREADY_EXISTS') {
				const existingProjectUrl = buildRoute(project, { projectId: result.projectId });
				enqueueSnackbar(
					`A project with that master id (${result.masterProjectId}) already exists`,
					{
						variant: 'info',
						action: snackbarAction(existingProjectUrl),
						autoHideDuration: 5000,
					},
				);
				return;
			}

			if (isMasterProject(projectToImport))
				trackCustomEvent({ event: 'master-imported', masterProjectId: projectToImport.id });
			const projectSettingsUrl = buildRoute(project, { projectId: result.projectId });
			history.replace(`${projectSettingsUrl}?created`);
		} catch (e) {
			enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
		}
	};

	return (
		<>
			<AppBar>
				<Hidden smUp>
					<IconButton onClick={() => setMobilePreviewOpen(true)}>
						<PreviewIcon />
					</IconButton>
				</Hidden>
			</AppBar>

			<Box sx={{ mx: { sm: 3, xs: 1 }, my: 2, maxWidth: 700 }}>
				<PageTitle
					breadcrumbs={[{ title: 'Projects', url: '/' }, { title: 'Creating a new project' }]}
					title="Create a new project"
				/>
				<Box marginX={1} marginY={1}>
					<Alert
						action={
							<Button color="inherit" onClick={goToTrainingEnvironment}>
								Training
							</Button>
						}
						severity="info"
					>
						Do you just want to take Kes for a test drive? Please use the Training environment
					</Alert>
				</Box>
				<Box sx={{ m: { sm: 1, xs: 0 } }}>
					<Paper elevation={3}>
						<Stepper sx={{ p: 1, pb: 2 }} activeStep={activeStep} orientation="vertical">
							<Step key="Import master project" completed={activeStep > 1}>
								<StepLabel>Import an exisisting master project</StepLabel>
								<StepContent>
									<Box>
										<Typography>
											Enter your master project ID or name and we look it up in ABW. If you just
											created a project in ABW remember that it takes 24 hours before it is
											available to Kes. You can still create the project in Kes and link it to ABW
											when it is available.
										</Typography>
									</Box>
									<Stack mt={3} maxWidth={450} spacing={1}>
										<SearchProjects
											onError={(msg: string) => enqueueSnackbar(msg, { variant: 'error' })}
											onNotFound={() => setActiveStep(1)}
											projectToImport={typeof projectToImport === 'string' ? null : projectToImport}
											setProjectToImport={setProjectToImport}
										/>
										<Link // eslint-disable-line jsx-a11y/anchor-is-valid
											component="button"
											variant="body2"
											onClick={() => setActiveStep(1)}
											alignSelf="flex-end"
											data-testid="button-cannot-find-project"
										>
											I cannot find my project or I don&apos;t have an ABW code yet
										</Link>
									</Stack>
									<Stack mt={1} direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 1 }}>
										<Button
											variant="contained"
											disabled={projectToImport === null}
											onClick={() => setActiveStep(2)}
										>
											Select project subject
										</Button>
										<Button variant="outlined" onClick={() => history.goBack()}>
											Cancel
										</Button>
									</Stack>
								</StepContent>
							</Step>
							<Step key="Create master project" data-testid="step-create-by-name" disabled>
								<StepLabel>Create a new project</StepLabel>
								<StepContent>
									<Typography>
										Enter a name for your project. The master id will be generated for you.
									</Typography>
									<Box sx={{ mt: 2, maxWidth: 350 }}>
										<TextField
											fullWidth
											label="Master project name"
											onChange={(e) => setProjectToImport(e.target.value)}
										/>
									</Box>
									<Stack mt={2} direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 1 }}>
										<Button
											variant="contained"
											disabled={projectToImport === null}
											onClick={() => setActiveStep(2)}
										>
											Select project subject
										</Button>
										<Button variant="outlined" onClick={() => setActiveStep(0)}>
											Go back to search
										</Button>
									</Stack>
								</StepContent>
							</Step>
							<Step key="Select project subject" data-testid="step-select-project-subject" disabled>
								<StepLabel>Select project subject</StepLabel>
								<StepContent>
									<Typography>
										Select your project subject below.
										<br />
										We will recommend templates based on the subject you are working on.
									</Typography>
									<RadioGroup
										data-testid="project-subject-select"
										onChange={(event: SelectChangeEvent<string>) =>
											setSubject(() =>
												event.target.value !== 'NOT_LISTED' ? event.target.value : undefined,
											)
										}
										value={subject ?? 'NOT_LISTED'}
									>
										{projectSubjects?.map(({ id, name }) => (
											<FormControlLabel value={id} label={name} key={id} control={<Radio />} />
										))}
										<FormControlLabel value="NOT_LISTED" label="Not listed" control={<Radio />} />
									</RadioGroup>
									<Stack mt={2} direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 1 }}>
										<Button variant="contained" onClick={() => setActiveStep(3)}>
											Add activities
										</Button>
										<Button variant="outlined" onClick={() => setActiveStep(0)}>
											Go back to project search
										</Button>
									</Stack>
								</StepContent>
							</Step>
							<Step key="Add activities" data-testid="step-add-activities" disabled>
								<StepLabel>Add activities</StepLabel>
								<StepContent>
									<Typography>We recommend the following activites for your project:</Typography>
									<FormGroup>
										{templates?.length === 0 ? (
											<Typography fontStyle="italic">No recommendations found</Typography>
										) : undefined}
										{templates?.map((template) => (
											<FormControlLabel
												key={template.id}
												control={
													<Checkbox
														checked={selectedTemplates.has(template.id)}
														name={template.id}
														onChange={setTemplateSelected}
													/>
												}
												label={template.name}
											/>
										))}
									</FormGroup>
									<Stack mt={2} direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 1 }}>
										<Button variant="contained" onClick={() => setActiveStep(4)}>
											Invite administrators
										</Button>
										<Button variant="outlined" onClick={() => setActiveStep(2)}>
											Go back to subject selection
										</Button>
									</Stack>
								</StepContent>
							</Step>
							<Step key="Add project admins" data-testid="step-add-project-admins" disabled>
								<StepLabel>Invite administrators</StepLabel>
								<StepContent>
									<Typography>Invite project administrators to your project:</Typography>
									<Box mt={2}>
										<AddRole
											addRole={addProjectAdmin}
											buttonText="Add Administrator"
											error=""
											validate={isRHDHVEmail}
										/>
										<ProjectAdministratorList
											hasDeletePrivileges
											onRemoveClicked={removeProjectAdministrator}
											projectAdministrators={admins.map(emailToUser) ?? []}
											recentlyAddedAdministrators={[]}
										/>
									</Box>
									<Stack mt={2} direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 1 }}>
										<Button
											variant="contained"
											disabled={projectToImport === null}
											onClick={() => onCreateProject()}
										>
											Create Project
										</Button>
										<Button variant="outlined" onClick={() => setActiveStep(2)}>
											Go back to activity recommendations
										</Button>
									</Stack>
								</StepContent>
							</Step>
						</Stepper>
					</Paper>
				</Box>
			</Box>
			{/* The preview below can be extracted into a new component as part of https://royalhaskoningdhv.atlassian.net/browse/KES-2065 */}
			<Drawer anchor="right" variant="permanent" sx={{ display: { xs: 'none', lg: 'block' } }}>
				<Box mx={10} mt={6}>
					<DocumentPreview
						width={600}
						src="/images/dummy_project_cover.png"
						alt="Dummy document preview"
					/>
				</Box>
			</Drawer>

			<Dialog fullScreen open={mobilePreviewOpen}>
				<AppBar>
					<Hidden smUp>
						<IconButton onClick={() => setMobilePreviewOpen(false)}>
							<CloseIcon />
						</IconButton>
					</Hidden>
				</AppBar>
				<DocumentPreview
					width="100%"
					src="/images/dummy_project_cover.png"
					alt="Dummy document preview"
				/>
			</Dialog>
		</>
	);
};

export default ProjectImport;
