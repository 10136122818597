import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { ApiMasterTemplate } from 'kes-common';

import { masterTemplateCreate } from '../../api';

interface MutationOptions {
	description: string;
	file: Blob | null;
	name: string;
}

const useMasterTemplateCreate = (
	options?: UseMutationOptions<ApiMasterTemplate, unknown, MutationOptions>,
) =>
	useMutation(
		(mutationOptions) =>
			masterTemplateCreate(mutationOptions).then((response) => response.expectSuccess()),
		options,
	);

export default useMasterTemplateCreate;
