import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import Skeleton from '@mui/material/Skeleton';
import { PageTitle } from 'kes-common';
import React from 'react';
import { Link } from 'react-router-dom';

import { useMasterTemplates } from '@/net/reactQuery/queries';
import { home, masterTemplateCreate } from '@/routes';

import MasterTemplate from './MasterTemplate';

const MasterTemplates: React.FC = () => {
	const { data, isError, isLoading, refetch } = useMasterTemplates();

	return (
		<Container maxWidth="lg">
			<PageTitle
				actions={
					<Button color="primary" component={Link} to={masterTemplateCreate} variant="contained">
						Create
					</Button>
				}
				breadcrumbs={[{ title: 'Home', url: home }, { title: 'Master Templates' }]}
				title="Master Templates"
			/>

			<Box marginTop={2}>
				{isError && (
					<Alert action={<Button onClick={() => refetch()}>Retry</Button>} severity="error">
						Failed to fetch the list of Master Templates. Please try again.
					</Alert>
				)}

				{!isError && isLoading && (
					<>
						<Skeleton />
						<Skeleton />
						<Skeleton />
					</>
				)}

				{!isError && !isLoading && (
					<>
						{data.length === 0 && (
							<Alert
								action={
									<Button component={Link} to={masterTemplateCreate}>
										Create Master Template
									</Button>
								}
								severity="info"
							>
								There are currently no Master Templates, be the first to create one.
							</Alert>
						)}

						{data.length > 0 && (
							<Card>
								<CardContent>
									<List>
										{data.map((masterTemplate) => (
											<MasterTemplate
												key={masterTemplate.id}
												masterTemplate={masterTemplate}
												refetch={refetch}
											/>
										))}
									</List>
								</CardContent>
							</Card>
						)}
					</>
				)}
			</Box>
		</Container>
	);
};

export default MasterTemplates;
