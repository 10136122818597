import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useDragAndDropListener, { VOID_FUNCTION } from '@/components/hooks/useDragAndDropListener';
import React, { Dispatch, SetStateAction, useRef } from 'react';
import FileSelectorMenu from '@/components/FileSelectorMenu';
import { UploadBoxProps } from '@/components/UploadBox';
import * as Styled from '@/components/UploadBox/UploadBox.styles';

const UploadBoxEmpty: React.FC<
	Pick<UploadBoxProps, 'onSelect' | 'fileType'> & {
		disabled?: boolean;
		setError: Dispatch<SetStateAction<string | undefined>>;
		error?: string;
	}
> = ({ onSelect, fileType = 'text/csv, .csv', disabled, setError, error }): JSX.Element => {
	const { uploadRef } = useDragAndDropListener(onSelect, fileType, setError, false, VOID_FUNCTION);
	const inputRef = useRef<HTMLInputElement>(null);
	const fileTypes = fileType.replace(/image\//g, '');
	return (
		<div>
			<Styled.Root disabled={disabled} ref={uploadRef}>
				<span>
					<b>Drag and drop to upload</b>
				</span>
				<span>or</span>

				<FileSelectorMenu
					inputRef={inputRef}
					onSelect={onSelect}
					accept={fileType}
					multiple={false}
					setError={setError}
				>
					{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
					<label htmlFor="raised-button-file">
						<Button
							onClick={(): void => {
								if (inputRef.current) {
									inputRef.current.click();
								}
							}}
							size="small"
							disabled={disabled}
						>
							Choose file
						</Button>
					</label>
				</FileSelectorMenu>
			</Styled.Root>
			{!error && <i>Supported file formats: {fileTypes}</i>}
			{error && (
				<Typography color="error" variant="body1">
					This format is not supported, please upload: {fileTypes}
				</Typography>
			)}
		</div>
	);
};

export default UploadBoxEmpty;
