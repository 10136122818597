import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { templates } from '@/routes';

import { useUser } from '../../context';

const Templates: React.FC = () => {
	const { user } = useUser();

	return (
		<Card sx={{ marginBottom: 2 }}>
			<CardContent sx={{ padding: 3 }}>
				<Typography variant="subtitle1">Activities</Typography>
				{user.recentTemplates.map((template) => (
					<Box data-testid="user__profile__template" key={template.id} marginTop={2}>
						<Link
							color="primary.dark"
							component={RouterLink}
							data-testid="user__profile__template__name"
							display="block"
							noWrap
							textOverflow="ellipsis"
							to={templates}
							underline="hover"
							variant="subtitle1"
						>
							{template.name}
						</Link>
						<Typography data-testid="user__profile__template__description">
							{template.description}
						</Typography>
					</Box>
				))}
			</CardContent>
		</Card>
	);
};

export default Templates;
