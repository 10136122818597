import { styled } from '@mui/material/styles';
import MUITextField from '@mui/material/TextField';

import withThrottle from '@/components/hoc/withThrottle';
import withUnwrappedEvent from '@/components/hoc/withUnwrappedEvent';

const ThrottledTextField = withThrottle(withUnwrappedEvent(MUITextField));

export const InnerChoice = styled('div')`
	font-size: 16px;
	font-weight: 600;
	grid-area: text;
	align-self: center;
	align-items: center;
	display: flex;
`;

export const OtherChoiceButton = styled('div')<{ disabled?: boolean }>`
	margin-bottom: 16px;
	background-color: #fff;
	border-radius: 4px;
	border: solid 2px #aebad0;
	display: grid;
	grid-template:
		'icon text' auto
		/ 24px 1fr;
	grid-column-gap: 12px;
	padding: 12px;
	cursor: pointer;
	align-items: center;

	${({ disabled }) =>
		disabled &&
		`
  		pointer-events: none;
		color: gray-400;
		background-color: #f3f3f3;
		border-color: #f3f3f3;

  `}
`;

export const TextField = styled(ThrottledTextField)`
	width: 100%;
	margin-left: 8px;
`;
