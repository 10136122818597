import React, { memo, useEffect } from 'react';

import { ExpandMore } from '@mui/icons-material';
import {
	Accordion,
	AccordionSummary,
	Typography,
	Stack,
	AccordionDetails,
	Alert,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	Checkbox,
	ListItemText,
	Chip,
	CircularProgress,
	Button,
} from '@mui/material';

import AlertNoData from '../AlertNoData';
import {
	FileWithId,
	allowedFilesForInputElement,
	embeddableFiles,
	getByte,
	nonEmbeddableFiles,
	ApiFile,
} from './utils';

export interface DocumentsTabProps {
	embedded: ApiFile[];
	notEmbedded: ApiFile[];

	selectedFiles: string[];
	fileBeingUploaded: FileWithId[];
	fileErrorState: FileWithId[];
	newFiles: string[]; // add a badge to show freshly uploaded files

	upload: (event: React.ChangeEvent<HTMLInputElement>) => void;
	selectFile: (file: string) => void;
	setFileErrorState: (files: FileWithId[]) => void;

	DownloadButton: React.ComponentType<{ fileId: string; fileName: string }>;

	scrollbarEmbeddedListRef: React.RefObject<HTMLDivElement>;
	scrollbarNotEmbeddedListRef: React.RefObject<HTMLDivElement>;
	scrollbarUploadingListRef: React.RefObject<HTMLDivElement>;
}

type AccordionType = 'EMBEDDED' | 'UPLOADING' | 'NOT_EMBEDDED' | false;

const DocumentsTab = ({
	scrollbarEmbeddedListRef,
	scrollbarNotEmbeddedListRef,
	scrollbarUploadingListRef,
	newFiles,
	fileErrorState,
	setFileErrorState,
	upload,
	selectFile,
	DownloadButton,
	embedded,
	notEmbedded,
	fileBeingUploaded,
	selectedFiles,
}: DocumentsTabProps): JSX.Element => {
	// accordion state management
	const [expanded, setExpanded] = React.useState<AccordionType>('EMBEDDED');
	const setAccordion =
		(panel: AccordionType) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
			setExpanded(isExpanded ? panel : false);
		};
	useEffect(() => {
		if (fileBeingUploaded.length === 0 && fileErrorState.length === 0) {
			setExpanded('EMBEDDED');
		}
		if (fileBeingUploaded.length > 0) {
			setExpanded('UPLOADING');
		}
	}, [fileErrorState, fileBeingUploaded, setExpanded]);

	return (
		<>
			<Accordion expanded={expanded === 'EMBEDDED'} onChange={setAccordion('EMBEDDED')}>
				<AccordionSummary expandIcon={<ExpandMore />}>
					<Typography variant="body1" sx={{ flexGrow: 1 }}>
						Files usable by assistant
					</Typography>
					<Stack direction="row" spacing={2}>
						<Typography variant="subtitle2" sx={{ color: 'primary.main' }}>
							{selectedFiles.length} &nbsp;selected
						</Typography>
						<Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
							[{embedded.length} &nbsp;files]
						</Typography>
						<Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
							{embeddableFiles}
						</Typography>
					</Stack>
				</AccordionSummary>
				<AccordionDetails
					ref={scrollbarEmbeddedListRef}
					sx={{ overflowY: 'scroll', maxHeight: '30vh' }}
				>
					{embedded.length === 0 && (
						<AlertNoData message="No related files, please upload a file" />
					)}
					{embedded.length > 0 && (
						<List>
							{embedded.map((file) => (
								<ListItem
									key={file.fileId}
									disablePadding
									secondaryAction={
										<Stack direction="row">
											<DownloadButton fileId={file.fileId} fileName={file.name} />
										</Stack>
									}
								>
									<ListItemButton onClick={() => selectFile(file.fileId)} role={undefined} dense>
										<ListItemIcon>
											<Checkbox
												edge="start"
												checked={selectedFiles.includes(file.fileId)}
												tabIndex={-1}
												disableRipple
											/>
										</ListItemIcon>
										<ListItemText>
											<Stack direction="row" spacing={2}>
												{newFiles.includes(file.fileId) && (
													<Chip label="NEW" color="primary" variant="outlined" size="small" />
												)}
												<Typography noWrap>{file.name}</Typography>
											</Stack>
										</ListItemText>
									</ListItemButton>
								</ListItem>
							))}
						</List>
					)}
				</AccordionDetails>
			</Accordion>

			{notEmbedded.length > 0 && (
				<Accordion expanded={expanded === 'NOT_EMBEDDED'} onChange={setAccordion('NOT_EMBEDDED')}>
					<AccordionSummary expandIcon={<ExpandMore />}>
						<Typography variant="body1" sx={{ flexGrow: 1 }}>
							Files not usable by assistant
						</Typography>
						<Stack direction="row" spacing={2} sx={{ color: 'text.secondary' }}>
							<Typography variant="subtitle2">[{notEmbedded.length} files]</Typography>
							<Typography variant="subtitle2">{nonEmbeddableFiles}</Typography>
						</Stack>
					</AccordionSummary>
					<AccordionDetails
						ref={scrollbarNotEmbeddedListRef}
						sx={{ overflowY: 'scroll', maxHeight: '30vh' }}
					>
						<List>
							{notEmbedded.map(({ fileId, name }) => (
								<ListItem key={fileId} disablePadding>
									<ListItemButton disableRipple dense>
										<ListItemText>
											<Typography noWrap>{name}</Typography>
										</ListItemText>
									</ListItemButton>
								</ListItem>
							))}
						</List>
					</AccordionDetails>
				</Accordion>
			)}

			{(fileErrorState.length > 0 || fileBeingUploaded.length > 0) && (
				<Accordion expanded={expanded === 'UPLOADING'} onChange={setAccordion('UPLOADING')}>
					<AccordionSummary>
						<Typography variant="body1" sx={{ flexGrow: 1 }}>
							Uploading files
						</Typography>
						<Stack direction="row" spacing={2} sx={{ color: 'text.secondary' }}>
							{fileErrorState.length > 0 && (
								<Typography variant="subtitle2" sx={{ color: 'warning.light' }}>
									{fileErrorState.length}&nbsp; warning
								</Typography>
							)}
							<Typography variant="subtitle2">
								[{(fileBeingUploaded?.length || 0) + (fileErrorState?.length || 0)} documents]
							</Typography>
						</Stack>
					</AccordionSummary>
					<AccordionDetails
						ref={scrollbarUploadingListRef}
						sx={{ overflowY: 'scroll', maxHeight: '30vh' }}
					>
						<List>
							{fileBeingUploaded.map(({ file, fileId }) => (
								<ListItem key={fileId}>
									<ListItemText>
										<Stack direction="row" spacing={5}>
											<CircularProgress size={35} />
											<Stack direction="column" alignItems="stretch">
												<Typography variant="body1">{file.name}</Typography>
											</Stack>
											<Typography variant="body1">{getByte(file)}</Typography>
										</Stack>
									</ListItemText>
								</ListItem>
							))}

							{fileErrorState.map(({ file, fileId }) => (
								<ListItem key={fileId}>
									<ListItemText>
										<Alert
											severity="warning"
											onClose={() =>
												setFileErrorState(fileErrorState.filter((f) => f.fileId !== fileId))
											}
										>
											upload failed for {file.name} --- size {getByte(file)}
										</Alert>
									</ListItemText>
								</ListItem>
							))}
						</List>
					</AccordionDetails>
				</Accordion>
			)}

			<Button variant="outlined" component="label" sx={{ mt: 2 }}>
				Upload
				<input
					onChange={upload}
					type="file"
					multiple
					accept={allowedFilesForInputElement}
					style={{ display: 'none' }}
				/>
			</Button>
		</>
	);
};

export default memo(DocumentsTab);
