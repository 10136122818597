import { ApiFile, ApiInspectionDto, ApiProperty } from 'kes-common';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { inspectionPropertyRelatedFileDelete } from '../../api';

const useInspectionPropertyRelatedFileDelete = (
	inspectionId: ApiInspectionDto['id'],
	propertyId: ApiProperty['id'],
	options?: UseMutationOptions<unknown, unknown, ApiFile['fileId']>,
) =>
	useMutation(
		(fileId: ApiFile['fileId']) =>
			inspectionPropertyRelatedFileDelete(fileId, propertyId, inspectionId).then((response) =>
				response.expectSuccess(),
			),
		options,
	);

export default useInspectionPropertyRelatedFileDelete;
