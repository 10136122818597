import { defaultMemoize } from 'reselect';
import { makeUnorderedSelectors, UnorderedState } from '@/store/repository';
import State from '@/store/state';
import { AssetList, AssetType, UUID } from '@/store/types';

const selector = makeUnorderedSelectors<AssetType>();

export const { get, getAll, getOrNull } = selector;
export const getAllByPropertyId = defaultMemoize(
	(state: UnorderedState<AssetType>): Record<UUID, AssetType> => {
		const propertiesById = selector.getAll(state);
		return Object.values(propertiesById).reduce(
			(acc: Record<UUID, AssetType>, assetType: AssetType): Record<UUID, AssetType> => {
				assetType.propertyIds.forEach((propertyId) => {
					acc[propertyId] = assetType;
				});
				return acc;
			},
			{},
		);
	},
);

export const getByAssetId = (state: State, assetId: AssetList['id']): AssetType | null => {
	const assetListAsset = Object.values(state.assetLists.byId).find((asset) =>
		asset.assets.includes(assetId),
	);
	if (assetListAsset) {
		return state.assetTypes.byId[assetListAsset.id];
	}
	return null;
};

export const getFlagAssetTypes = defaultMemoize((state: UnorderedState<AssetType>): AssetType[] => {
	const flags = Object.values(state.byId).filter((assetType) => assetType.type === 'FLAG');
	return flags;
});
