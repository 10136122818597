import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { ApiKnowledgeGroup, PageTitle } from 'kes-common';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link, useHistory, useParams } from 'react-router-dom';

import { knowledgeGroupsGet, timezonesGet, updateUserProfile } from '@/net/api';
import { buildRoute, userProfile } from '@/routes';

import Avatar from '../components/Avatar';
import { useUser } from '../context';

const Edit: React.FC = () => {
	const history = useHistory();
	const { userId } = useParams<{ userId: string }>();
	const { enqueueSnackbar } = useSnackbar();
	const { setUser, user } = useUser();

	const [jobTitle, setJobTitle] = React.useState<string>(user.userProfileDetails.jobTitle || '');
	const [knowledgeGroupIds, setKnowledgeGroupIds] = React.useState<string[]>(
		user.userProfileDetails.knowledgeGroupIds,
	);
	const [skillsAndExpertise, setSkillsAndExpertise] = React.useState<string>(
		user.userProfileDetails.skillsAndExpertise || '',
	);
	const [timeZoneId, setTimeZoneId] = React.useState<string>(
		user.userProfileDetails.timeZoneId || '',
	);
	const [linkedInLink, setLinkedInLink] = React.useState<string>(
		user.userProfileDetails.linkedInLink || '',
	);
	const [linkedInLinkValidationError, setLinkedInLinkValidationError] =
		React.useState<boolean>(false);

	const queryKnowledgeGroups = useQuery(['knowledgeGroups'], () => knowledgeGroupsGet());
	const queryTimezones = useQuery(['timezones'], () => timezonesGet());

	const updateLinkedInLink = (newLinkedInLink: string) => {
		if (!newLinkedInLink || newLinkedInLink.startsWith('https://www.linkedin.com/in/')) {
			setLinkedInLinkValidationError(false);
		} else {
			setLinkedInLinkValidationError(true);
		}
		setLinkedInLink(newLinkedInLink);
	};

	const groupedKnowledgeGroups = React.useMemo(() => {
		if (queryKnowledgeGroups.isError || queryKnowledgeGroups?.data?.status !== 200) {
			return {};
		}

		return queryKnowledgeGroups.data.expectSuccess().reduce((accumulator, knowledgeGroupItem) => {
			const businessLineName = knowledgeGroupItem.businessLine || 'Cross Business Line';
			return {
				...accumulator,
				[businessLineName]: [...(accumulator[businessLineName] ?? []), knowledgeGroupItem],
			};
		}, {} as Record<string, ApiKnowledgeGroup[]>);
	}, [queryKnowledgeGroups]);

	const saveUserProfile = React.useCallback(
		async (event: React.SyntheticEvent) => {
			event.preventDefault();
			const result = await updateUserProfile(userId, {
				jobTitle: jobTitle || null,
				knowledgeGroupIds: knowledgeGroupIds || [],
				skillsAndExpertise: skillsAndExpertise || null,
				timeZoneId: timeZoneId || null,
				linkedInLink: linkedInLink || null,
			});
			if (result.status === 204) {
				setUser({
					...user,
					userProfileDetails: {
						...user.userProfileDetails,
						jobTitle,
						knowledgeGroupIds,
						skillsAndExpertise,
						timeZoneId,
						linkedInLink,
					},
				});
				history.push(buildRoute(userProfile, { userId }));
				enqueueSnackbar('Profile updated successfully');
			} else {
				enqueueSnackbar('There was an error updating your profile');
			}
		},
		[jobTitle, knowledgeGroupIds, skillsAndExpertise, timeZoneId, linkedInLink],
	);

	return (
		<>
			<PageTitle
				breadcrumbs={[
					{ title: 'Profile', url: buildRoute(userProfile, { userId }) },
					{ title: 'Edit info' },
				]}
				title="Edit profile info"
			/>
			<Container maxWidth="sm">
				<form onSubmit={saveUserProfile}>
					<Typography color="primary.dark" sx={{ marginBottom: 2, marginTop: 3 }} variant="h6">
						&nbsp;
					</Typography>
					<Card sx={{ marginBottom: 2, overflow: 'visible' }}>
						<CardContent sx={{ padding: 3, position: 'relative' }}>
							<Avatar name={user.userProfileDetails.name} />
							<Typography color="primary.dark" gutterBottom sx={{ marginTop: 3 }} variant="h6">
								Personal info
							</Typography>

							<Grid container spacing={2}>
								<Grid item md={6} sx={{ marginBottom: 1 }} xs={12}>
									<Typography color="grey.700" gutterBottom variant="overline">
										Name
									</Typography>
									<Typography>{user.userProfileDetails.name}</Typography>
								</Grid>
								<Grid item md={6} sx={{ marginBottom: 1 }} xs={12}>
									<Typography color="grey.700" gutterBottom variant="overline">
										Email
									</Typography>
									<Typography>{user.userProfileDetails.email}</Typography>
								</Grid>

								<Grid item md={6} sx={{ marginBottom: 1 }} xs={12}>
									<Typography color="grey.700" gutterBottom variant="overline">
										Department
									</Typography>
									<Typography>{user.userProfileDetails.department || '-'}</Typography>
								</Grid>
								<Grid item md={6} sx={{ marginBottom: 1 }} xs={12}>
									<Typography color="grey.700" gutterBottom variant="overline">
										Office
									</Typography>
									<Typography>{user.userProfileDetails.officeLocation || '-'}</Typography>
								</Grid>

								<Grid item md={6} xs={12}>
									<Typography color="grey.700" gutterBottom variant="overline">
										Employee number
									</Typography>
									<Typography>-</Typography>
								</Grid>
								<Grid item md={6} xs={12}>
									<Typography color="grey.700" gutterBottom variant="overline">
										Timezone
									</Typography>
									<br />
									<TextField
										fullWidth
										label="Timezone"
										name="timezone"
										onChange={(event) => setTimeZoneId(event.target.value)}
										placeholder="Your timezone"
										select
										value={timeZoneId}
										variant="filled"
									>
										{queryTimezones.isSuccess && queryTimezones.data.status === 200 ? (
											queryTimezones.data.expectSuccess().map((timezoneItem) => (
												<MenuItem key={timezoneItem.zoneId} value={timezoneItem.zoneId}>
													{timezoneItem.zoneId} ({timezoneItem.offset})
												</MenuItem>
											))
										) : (
											<MenuItem value="">Loading</MenuItem>
										)}
									</TextField>
								</Grid>
							</Grid>

							<Typography color="primary.dark" gutterBottom sx={{ marginTop: 3 }} variant="h6">
								Work
							</Typography>

							<Grid container spacing={2}>
								<Grid item md={6} xs={12}>
									<TextField
										fullWidth
										label="Job title"
										name="job_title"
										onChange={(event) => setJobTitle(event.target.value)}
										placeholder="Job title"
										value={jobTitle}
										variant="filled"
									/>
								</Grid>
								<Grid item md={6} xs={12}>
									<TextField
										fullWidth
										label="Knowledge group"
										name="knowledge_group"
										onChange={(event) => {
											const { value } = event.target;
											if (typeof value === 'string') {
												setKnowledgeGroupIds(value.split(','));
											} else {
												setKnowledgeGroupIds(value as unknown as string[]);
											}
										}}
										placeholder="Knowledge group"
										select
										SelectProps={{
											multiple: true,
											renderValue: (selected) => {
												const knowledgeGroupsFlat = Object.values(groupedKnowledgeGroups).flat();
												return (selected as string[])
													.map((selectedKnowledgeGroupId) =>
														knowledgeGroupsFlat.find(
															(knowledgeGroupItem) =>
																knowledgeGroupItem.id === selectedKnowledgeGroupId,
														),
													)
													.map((knowledgeGroup) => knowledgeGroup?.name)
													.filter((item) => item)
													.join(', ');
											},
										}}
										value={knowledgeGroupIds}
										variant="filled"
									>
										{Object.keys(groupedKnowledgeGroups).length === 0 && (
											<MenuItem value="">Loading</MenuItem>
										)}
										{Object.keys(groupedKnowledgeGroups).length > 0 &&
											Object.keys(groupedKnowledgeGroups).map((knowledgeGroupGroup) => [
												<ListSubheader>{knowledgeGroupGroup}</ListSubheader>,
												groupedKnowledgeGroups[knowledgeGroupGroup].map((knowledgeGroupItem) => (
													<MenuItem key={knowledgeGroupItem.id} value={knowledgeGroupItem.id}>
														<Checkbox checked={knowledgeGroupIds.includes(knowledgeGroupItem.id)} />
														<ListItemText primary={knowledgeGroupItem.name} />
													</MenuItem>
												)),
											])}
									</TextField>
								</Grid>

								<Grid item xs={12}>
									<TextField
										fullWidth
										label="Skills and expertise"
										multiline
										name="skills"
										onChange={(event) => setSkillsAndExpertise(event.target.value)}
										placeholder="Skills and expertise"
										rows={4}
										value={skillsAndExpertise}
										variant="filled"
									/>
								</Grid>
							</Grid>

							<Typography color="primary.dark" gutterBottom sx={{ marginTop: 3 }} variant="h6">
								On the web
							</Typography>

							<Grid container spacing={2}>
								<Grid item md={6} xs={12}>
									<TextField
										fullWidth
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<LinkedInIcon />
												</InputAdornment>
											),
										}}
										label="LinkedIn"
										name="linkedin"
										onChange={(event) => updateLinkedInLink(event.target.value)}
										placeholder="LinkedIn"
										value={linkedInLink}
										variant="filled"
										error={linkedInLinkValidationError}
										helperText={
											linkedInLinkValidationError &&
											'LinkedIn link must start with https://www.linkedin.com/in/'
										}
									/>
								</Grid>
							</Grid>
						</CardContent>

						<CardActions sx={{ padding: 3, paddingTop: 0 }}>
							<Button
								component={Link}
								sx={{ marginLeft: 'auto', marginRight: 2 }}
								to={buildRoute(userProfile, { userId })}
							>
								Cancel
							</Button>
							<Button
								color="primary"
								type="submit"
								variant="contained"
								disabled={linkedInLinkValidationError}
							>
								Save
							</Button>
						</CardActions>
					</Card>
				</form>
			</Container>
		</>
	);
};

export default Edit;
