import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useGoogleTagManager } from 'kes-common';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import SharedRepeatingAssetTable from '@/components/RepeatingAssetTable';
import { assetTypesGet } from '@/selectors';
import { getAllByIds } from '@/selectors/properties';
import { assetAnswer, assetCreateAndSave, assetDelete } from '@/store/actions';
import State from '@/store/state';
import { Asset, AssetType, Property, SurveyAssetList } from '@/store/types';

interface RepeatingAssetTableProps {
	surveyAssetList: SurveyAssetList;
}

const RepeatingAssetTable: React.FC<RepeatingAssetTableProps> = ({ surveyAssetList }) => {
	const assetType = useSelector<State, AssetType>((state) =>
		assetTypesGet(state, surveyAssetList.assetTypeId),
	);
	const properties = useSelector<State, Property[]>(getAllByIds(assetType.propertyIds));

	const dispatch = useDispatch();
	const { trackCustomEvent } = useGoogleTagManager();

	const onAssetCreate = React.useCallback(
		(assetTypeCreate: AssetType) => {
			dispatch(
				assetCreateAndSave({
					assetId: uuid(),
					assetTypeId: assetTypeCreate.id,
				}),
			);
			trackCustomEvent({ event: 'ra_row_added_manually' });
		},
		[dispatch, trackCustomEvent],
	);

	const onAssetDelete = React.useCallback(
		(assetToDelete: Asset, assetTypeDelete: AssetType) => {
			dispatch(
				assetDelete({
					assetId: assetToDelete.id,
					assetTypeId: assetTypeDelete.id,
				}),
			);
			trackCustomEvent({ event: 'ra_row_deleted_manually' });
		},
		[dispatch, trackCustomEvent],
	);

	const onPropertyAnswer = React.useCallback(
		(answer: string, property: Property, assetToAnswer: Asset, assetTypeAnswer: AssetType) => {
			dispatch(
				assetAnswer({
					answer: answer || null,
					assetId: assetToAnswer.id,
					assetTypeId: assetTypeAnswer.id,
					propertyId: property.id,
					noteText: assetToAnswer.notesByPropertyId[property.id],
					applicable: true,
					otherOption: false,
					location: null,
				}),
			);
		},
		[dispatch],
	);

	return (
		<Card>
			<CardContent>
				<SharedRepeatingAssetTable
					assetType={assetType}
					onAssetCreate={onAssetCreate}
					onAssetDelete={onAssetDelete}
					onPropertyAnswer={onPropertyAnswer}
					properties={properties}
					rows={surveyAssetList.assets
						.filter((asset) => asset.id !== null)
						.map((asset) => ({ assetId: asset.id! }))}
				/>
			</CardContent>
		</Card>
	);
};

export default RepeatingAssetTable;
