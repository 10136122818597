import MoreVertIcon from '@mui/icons-material/MoreVert';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import PreviewIcon from '@mui/icons-material/Preview';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { ApiStudyDto, AvatarList, useUser } from 'kes-common';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import TemplateContextMenu, { TemplateContextMenuProps } from '@/components/TemplateContextMenu';
import { KES_BUILDER_URL } from '@/constants';
import { buildRoute, template as templateRoute } from '@/routes';

import Chips from './Chips';
import Version from './Version';

interface TemplateProps {
	refetchTemplates(): void;
	template: ApiStudyDto;
}

const Template: React.FC<TemplateProps> = ({ refetchTemplates, template }) => {
	const [templateContextMenuAnchorElement, setTemplateContextMenuAnchorElement] =
		React.useState<HTMLElement | null>(null);

	const { enqueueSnackbar } = useSnackbar();
	const { user } = useUser();

	const isDisabled = React.useMemo(() => {
		const canView = template.users.some((templateUser) => templateUser.userId === user.user.id);
		return !(user.isGlobalAdmin || canView);
	}, [template, user]);

	const onTemplateAssignError = React.useCallback(() => {
		enqueueSnackbar('Failed to assign the template to the selected project(s)');
	}, [enqueueSnackbar]);

	const onTemplateAssignSuccess = React.useCallback(() => {
		enqueueSnackbar('The template has successfully been assigned to the selected project(s)');
		setTemplateContextMenuAnchorElement(null);
	}, [enqueueSnackbar, setTemplateContextMenuAnchorElement]);

	const onTemplateCopied: TemplateContextMenuProps['onTemplateCopied'] = React.useCallback(() => {
		enqueueSnackbar('Template copied successfully');
		refetchTemplates();
	}, [enqueueSnackbar, refetchTemplates]);

	const onTemplatePublished: TemplateContextMenuProps['onTemplatePublished'] =
		React.useCallback(() => {
			enqueueSnackbar('Template published successfully');
			refetchTemplates();
		}, [enqueueSnackbar, refetchTemplates]);

	const onTemplateUserAdded: TemplateContextMenuProps['onTemplateUserAdded'] =
		React.useCallback(() => {
			refetchTemplates();
		}, [refetchTemplates]);

	const onTemplateUserRemoved: TemplateContextMenuProps['onTemplateUserRemoved'] =
		React.useCallback(() => {
			refetchTemplates();
		}, [refetchTemplates]);

	const Wrapper = isDisabled ? Tooltip : 'span';

	return (
		<>
			<Card data-testid="templates__template" id={template.id} sx={{ minHeight: '100%' }}>
				<CardHeader
					title={
						<Grid container>
							<Grid item xs>
								<Chips template={template} />
								<Wrapper
									placement="bottom-start"
									title={
										isDisabled ? 'Contact the owner for more information about this template' : ''
									}
								>
									<Typography
										color={isDisabled ? 'inherit' : 'disabled'}
										component={isDisabled ? 'p' : Link}
										data-testid="templates__template__name"
										href={
											// eslint-disable-next-line no-nested-ternary
											isDisabled
												? undefined
												: template.type === 'WORD'
												? template.sharepointUrl || '#'
												: `${KES_BUILDER_URL}${template.id}`
										}
										underline="none"
										variant="subtitle1"
									>
										{template.name}
									</Typography>
								</Wrapper>
								<Typography component="p" variant="subtitle2">
									<em>{template.type === 'WORD' ? 'Word' : 'Kes'}</em>
								</Typography>
							</Grid>
							<Grid item xs="auto">
								<Box display="flex" paddingTop={0.5}>
									<AvatarList users={template.users} />
								</Box>
							</Grid>
							<Grid item xs="auto">
								{!isDisabled && (
									<IconButton
										onClick={(event) => setTemplateContextMenuAnchorElement(event.currentTarget)}
									>
										<MoreVertIcon />
									</IconButton>
								)}
							</Grid>
						</Grid>
					}
				/>
				<CardContent>
					<Version template={template} />
					<Typography gutterBottom>
						Used in <strong>{template.useCount}</strong> activities.
					</Typography>
					<Typography data-testid="templates__template__description" gutterBottom>
						{template.description &&
							template.description?.split('\n').map((paragraph, index) => (
								// eslint-disable-next-line react/no-array-index-key
								<span key={index}>
									{paragraph}
									<br />
								</span>
							))}
						{!template.description && <em>No description</em>}
					</Typography>
				</CardContent>
				<CardActions>
					<Tooltip
						title={
							template.type === 'WORD'
								? 'Open in Word online to edit template contents'
								: 'Open in Kes Studio to edit template contents'
						}
					>
						<Button
							component="a"
							color="primary"
							href={
								template.type === 'WORD'
									? template.sharepointUrl || '#'
									: `${KES_BUILDER_URL}${template.id}`
							}
							startIcon={<OpenInBrowserIcon />}
							variant="text"
						>
							Open template
						</Button>
					</Tooltip>
					<Tooltip title="Preview the template and view additional information">
						<Button
							color="primary"
							component={RouterLink}
							startIcon={<PreviewIcon />}
							to={buildRoute(templateRoute, { templateId: template.id })}
							variant="text"
						>
							Preview
						</Button>
					</Tooltip>
				</CardActions>
			</Card>
			{!isDisabled && (
				<TemplateContextMenu
					anchorElement={templateContextMenuAnchorElement}
					onClose={() => setTemplateContextMenuAnchorElement(null)}
					onTemplateAssignError={onTemplateAssignError}
					onTemplateAssignSuccess={onTemplateAssignSuccess}
					onTemplateCopied={onTemplateCopied}
					onTemplatePublished={onTemplatePublished}
					onTemplateUserAdded={onTemplateUserAdded}
					onTemplateUserRemoved={onTemplateUserRemoved}
					template={template}
					refetch={refetchTemplates}
				/>
			)}
		</>
	);
};

export default Template;
