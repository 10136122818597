import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import HyperLink from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import StorageIcon from '@mui/icons-material/Storage';
import DownloadIcon from '@mui/icons-material/Download';
import KeyIcon from '@mui/icons-material/VpnKey';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { buildRoute, home, projectSettings, project as projectRoute } from '@/routes';
import { createAccessToken as apiCreateAccessToken, projectReportsDownload } from '@/net/api';
import useFetchAccessString from '@/hooks/useFetchAccessString';
import ProjectCopyDialog from '@/components/ProjectCopyDialog';
import ProjectDeleteDialog from '@/components/ProjectDeleteDialog';
import { useFeatureFlag } from 'kes-common';
import ProjectAdministratorsDialog from '../ProjectAdministratorDialog';
import { useProject } from '../../context';

export default function HeaderActions({ onAddInspection }: { onAddInspection: () => void }) {
	const [isCopyDialogOpen, setIsCopyDialogOpen] = React.useState<boolean>(false);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState<boolean>(false);
	const [isProjectAdministratorsOpen, setIsProjectAdministratorsOpen] =
		React.useState<boolean>(false);

	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const { fetchAccessString } = useFetchAccessString();
	const hasShareData = useFeatureFlag({ name: 'PROJECT_SHARE_DATA' });
	const { project } = useProject();

	const activitiesDownloadAll = React.useCallback(() => {
		projectReportsDownload(project.id)
			.then((response) => {
				enqueueSnackbar(
					response.status === 204
						? 'Processing started. You will receive an email when the download is ready.'
						: 'Processing bulk download failed, please try again.',
				);
			})
			.catch(() => {
				enqueueSnackbar('Processing bulk download failed, please try again.');
			});
	}, [enqueueSnackbar, project.id]);

	const createAccessToken = React.useCallback(async () => {
		try {
			const response = await apiCreateAccessToken(project.id);
			const token = response.expectSuccess();
			await navigator.clipboard.writeText(token);
			enqueueSnackbar('Access token copied to clipboard');
		} catch (e) {
			enqueueSnackbar('Error creating access token');
		}
	}, [enqueueSnackbar, project.id]);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const onOpenMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
	const onCloseMenu = () => setAnchorEl(null);

	return (
		<>
			<Grid container marginBottom={1} spacing={1}>
				<Grid item style={{ textAlign: 'end' }} xs="auto">
					<IconButton data-testid="secondary-actions-btn" onClick={onOpenMenu}>
						<MoreVertIcon />
					</IconButton>

					<Menu anchorEl={anchorEl} open={!!anchorEl} onClose={onCloseMenu}>
						<Tooltip title="Download all activies' reports" placement="left" arrow>
							<MenuItem
								onClick={() => {
									activitiesDownloadAll();
									onCloseMenu();
								}}
								data-testid="action-download-reports"
								disabled={project.inspections.length === 0}
							>
								<ListItemIcon>
									<DownloadIcon />
								</ListItemIcon>
								<ListItemText>Download</ListItemText>
							</MenuItem>
						</Tooltip>
						<Tooltip title="Project administrators" placement="left" arrow>
							<MenuItem
								onClick={() => {
									setIsProjectAdministratorsOpen(true);
									onCloseMenu();
								}}
								data-testid="action-open-dialog"
							>
								<ListItemIcon>
									<PeopleIcon />
								</ListItemIcon>
								<ListItemText>Administrators</ListItemText>
							</MenuItem>
						</Tooltip>
						<Tooltip title="Obtain script access token" placement="left" arrow>
							<MenuItem
								onClick={() => {
									createAccessToken();
									onCloseMenu();
								}}
								data-testid="action-script-access-token"
							>
								<ListItemIcon>
									<KeyIcon />
								</ListItemIcon>
								<ListItemText>Obtain script token</ListItemText>
							</MenuItem>
						</Tooltip>
						{hasShareData && (
							<Tooltip
								title={
									<>
										Obtain database access string to project data.{' '}
										<HyperLink
											color="inherit"
											href="https://kes.support.royalhaskoningdhv.com/en/support/solutions/articles/77000529441-how-to-get-your-data-in-tableau-powerbi-excel-etc-"
											target="_blank"
										>
											Learn more.
										</HyperLink>
									</>
								}
								placement="left"
								arrow
							>
								<MenuItem
									onClick={() => {
										fetchAccessString(project.id);
										onCloseMenu();
									}}
									data-testid="action-database-access-string"
								>
									<ListItemIcon>
										<StorageIcon />
									</ListItemIcon>
									<ListItemText>Obtain database access</ListItemText>
								</MenuItem>
							</Tooltip>
						)}
						<Tooltip title="Make a copy of the project" placement="left" arrow>
							<MenuItem
								onClick={() => {
									setIsCopyDialogOpen(true);
									onCloseMenu();
								}}
								data-testid="action-copy-project"
							>
								<ListItemIcon>
									<ContentCopyIcon />
								</ListItemIcon>
								<ListItemText>Copy Project</ListItemText>
							</MenuItem>
						</Tooltip>
						<Tooltip title="Delete project" placement="left" arrow>
							<MenuItem
								onClick={() => {
									setIsDeleteDialogOpen(true);
									onCloseMenu();
								}}
								data-testid="action-project-delete"
							>
								<ListItemIcon>
									<DeleteIcon />
								</ListItemIcon>
								<ListItemText>Delete Project</ListItemText>
							</MenuItem>
						</Tooltip>
						<Divider />
						<Tooltip title="Project settings" placement="left" arrow>
							<MenuItem
								onClick={() => {
									history.push(buildRoute(projectSettings, { projectId: project.id }));
									onCloseMenu();
								}}
								data-testid="project-settings"
							>
								<ListItemIcon>
									<SettingsIcon />
								</ListItemIcon>
								<ListItemText>Settings</ListItemText>
							</MenuItem>
						</Tooltip>
					</Menu>
				</Grid>

				<Grid item style={{ textAlign: 'end' }} xs>
					<Tooltip title="Add activity">
						<Button
							color="primary"
							data-testid="add-inspection-button"
							onClick={onAddInspection}
							startIcon={<AddIcon />}
							variant="contained"
						>
							Add Project Activity
						</Button>
					</Tooltip>
				</Grid>
			</Grid>

			<ProjectAdministratorsDialog
				show={isProjectAdministratorsOpen}
				onClose={() => setIsProjectAdministratorsOpen(false)}
			/>

			<ProjectCopyDialog
				isOpen={isCopyDialogOpen}
				onClose={() => setIsCopyDialogOpen(false)}
				onSuccess={(newProject) => {
					enqueueSnackbar('Project copied successfully');
					history.push(buildRoute(projectRoute, { projectId: newProject.project.id }));
				}}
				projectId={project.id}
				projectName={project.projectDetails.name}
			/>

			<ProjectDeleteDialog
				isOpen={isDeleteDialogOpen}
				onClose={() => setIsDeleteDialogOpen(false)}
				onSuccess={() => {
					enqueueSnackbar(`The ${project.projectDetails.name} project was deleted successfully.`);
					history.push(buildRoute(home, {}));
				}}
				projectId={project.id}
				projectName={project.projectDetails.name}
			/>
		</>
	);
}
