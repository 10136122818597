import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ApiFlag } from 'kes-common';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { flagsGetByAssetAndFlag, flagsGetByFlag } from '@/selectors/flags';
import { assetCreate, flagCreate } from '@/store/actions';
import State from '@/store/state';
import { AssetType } from '@/store/types';

import Table from './Table';

interface FlagEditorProps {
	assetId?: string;
	assetTypeId: string;
	flag: AssetType;
	closeDialog: () => void;
}

const FlagEditor: React.FC<FlagEditorProps> = ({ assetId, assetTypeId, flag, closeDialog }) => {
	const [showAllFlags, setShowAllFlags] = React.useState<boolean>(false);
	const dispatch = useDispatch();
	const { inspectionId } = useParams<Record<string, string>>();

	const allFlags = useSelector<State, ApiFlag[]>(flagsGetByFlag(flag.id));
	const assetFlags = useSelector<State, ApiFlag[]>((state) =>
		assetId ? flagsGetByAssetAndFlag(state, assetId, flag.id) : [],
	);

	const flags = React.useMemo(
		() => (showAllFlags ? allFlags : assetFlags),
		[allFlags, assetFlags, showAllFlags],
	);

	const onRowAdd = React.useCallback(() => {
		if (!assetId) {
			const newAssetId = uuid();
			dispatch(assetCreate({ assetId: newAssetId, assetTypeId }));
			dispatch(
				flagCreate({ assetId: newAssetId, assetTypeId, flagAssetTypeId: flag.id, inspectionId }),
			);
			return;
		}
		dispatch(flagCreate({ assetId, assetTypeId, flagAssetTypeId: flag.id, inspectionId }));
	}, [assetId, assetTypeId, dispatch, flag, inspectionId]);

	return (
		<Dialog fullWidth maxWidth="lg" onClose={closeDialog} open>
			<DialogTitle>{flag.name}</DialogTitle>
			<DialogContent>
				<Table flag={flag} flags={flags} onAssetCreate={onRowAdd} />
			</DialogContent>
			<DialogActions>
				{!showAllFlags && <Button onClick={() => setShowAllFlags(true)}>All flags</Button>}
				{showAllFlags && <Button onClick={() => setShowAllFlags(false)}>Local flags only</Button>}
				<Button onClick={closeDialog}>Close</Button>
			</DialogActions>
		</Dialog>
	);
};

export default FlagEditor;
