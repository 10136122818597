import React from 'react';
import { Dialog, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { green } from '@mui/material/colors';
import { AutoAwesome } from '@mui/icons-material';

import KaasAssistPanelWrapper from './KaasAssistPanelWrapper';
import useKaasInspectionAssist from './useKaasInspectionAssist';

interface IProps {
	inspectionId: string;
}

function KaasTopControls({ inspectionId }: IProps): JSX.Element {
	// modal
	const [isKaasOpen, setIsKaasOpen] = React.useState<boolean>(false);
	const handleClose = () => setIsKaasOpen(false);
	const handleOpen = () => setIsKaasOpen(true);

	const { assist, isFetching } = useKaasInspectionAssist();

	return (
		<>
			<Stack direction="row" justifyContent="flex-end" spacing={1} pt={5}>
				<LoadingButton
					loading={isFetching}
					onClick={handleOpen}
					variant="contained"
					sx={{
						'.MuiLoadingButton-loadingIndicator': {
							color: green[500],
						},
					}}
				>
					<AutoAwesome fontSize="small" /> &nbsp;Assist
				</LoadingButton>
			</Stack>
			<Dialog fullWidth maxWidth="md" open={isKaasOpen} onClose={handleClose}>
				<KaasAssistPanelWrapper
					inspectionId={inspectionId}
					handleClose={handleClose}
					isFetching={isFetching}
					assist={assist}
				/>
			</Dialog>
		</>
	);
}

export default KaasTopControls;
