import React from 'react';
import { Box, Button } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ExtendedLocation } from '@/components/Question/Types/Location/MapModal';
import Coordinates from '@/components/Question/Types/Location/Coordinates';
import * as Styled from './Location.styles';

interface SaveBoxProps {
	currentLocation: ExtendedLocation;
	setCurrentLocation: React.Dispatch<React.SetStateAction<ExtendedLocation | undefined>>;
	saveLocation: () => void;
	deleteCurrentLocation: () => void;
}

const SaveBox: React.FC<SaveBoxProps> = ({
	currentLocation,
	setCurrentLocation,
	saveLocation,
	deleteCurrentLocation,
}) => {
	const inputRef = React.useRef<HTMLInputElement>();
	const onKeyPressed = (event?: React.KeyboardEvent<HTMLDivElement>) => {
		if (event?.keyCode === 13) {
			saveLocation();
		}
	};

	React.useEffect(() => {
		const timeout = setTimeout(() => {
			if (inputRef && inputRef.current) {
				inputRef.current.focus();
			}
		}, 100);

		return () => {
			clearTimeout(timeout);
		};
	}, [currentLocation]);

	return (
		<Styled.SaveBoxContainer>
			<Box display="flex" justifyContent="space-between" mb={2}>
				<Styled.LocationListHeading variant="body2" color="primary">
					SAVE LOCATION
				</Styled.LocationListHeading>
				<DeleteOutlineIcon onClick={deleteCurrentLocation} />
			</Box>
			<Styled.TextField
				size="small"
				inputRef={inputRef}
				value={currentLocation.name || ''}
				fullWidth
				variant="outlined"
				label="Location name"
				onChange={(e) => setCurrentLocation({ ...currentLocation, name: e.target.value })}
				onKeyDown={onKeyPressed}
			/>
			<Box my={2}>
				<Styled.Address>{currentLocation.address}</Styled.Address>
				<Styled.Address variant="body2">
					<Coordinates withToolTip pointString={currentLocation.pointString} />
				</Styled.Address>
			</Box>
			<Box display="flex" justifyContent="flex-end">
				<Button variant="contained" color="secondary" onClick={saveLocation}>
					Save
				</Button>
			</Box>
		</Styled.SaveBoxContainer>
	);
};

export default SaveBox;
