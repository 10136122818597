import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { projectQuestions, buildRoute } from '@/routes';
import State from '@/store/state';
import { currentProject } from '@/selectors/project';
import { SimpleProject } from '@/store/types';

const Project: FC = () => {
	const { projectId } = useParams<Record<string, string>>();
	const loadedProject = useSelector<State, SimpleProject | undefined>((state) =>
		currentProject(state.project),
	);

	const location = useLocation();

	const path = React.useMemo<string>(() => {
		const rawPath = buildRoute(projectQuestions, {
			projectId,
			inspectionId: loadedProject?.inspections[0].id ?? '',
			categoryId: 'load',
		});
		const hideAppBar = new URLSearchParams(location.search).get('hideAppBar') || '0';
		return `${rawPath}?hideAppBar=${hideAppBar}`;
	}, [loadedProject, location, projectId]);

	if (loadedProject?.id === projectId) {
		return <Redirect to={path} />;
	}

	return null;
};

export default Project;
