import React, { memo } from 'react';

import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

import AlertNoData from '../AlertNoData';
import AlertRefetch from '../AlertRefetch';
import ActivityRow from './ActivityRow';
import ActivitiesTableLoading from './ActivitiesTableLoading';

import { Activity } from './utils';

export interface ActivitiesTableProps {
	// input data
	data: Activity[] | undefined;
	isLoading: boolean;
	isError: boolean;

	// user actions
	select: (activity: Activity) => void;
	regenerate: (id: string, options: { onSettled: () => void }) => void;
	refetch: () => void;

	// links
	activityLink: (projectId: string, inspectionId: string) => string;
	reportLink: (id: string) => string;

	// display options
	showProjectNameColumn?: boolean;
}

function ActivitiesTable({
	activityLink,
	reportLink,
	showProjectNameColumn,
	select,

	regenerate,
	isError,
	isLoading,
	data,
	refetch,
}: ActivitiesTableProps): JSX.Element {
	if (isError) {
		return <AlertRefetch refetch={refetch} />;
	}

	if (data?.length === 0) {
		return <AlertNoData message="There are no selected reports" />;
	}

	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell padding="checkbox" />
					{showProjectNameColumn && <TableCell>Project</TableCell>}
					<TableCell>Activity</TableCell>
					<TableCell>Last modified</TableCell>
					<TableCell>Actions</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{isLoading ? (
					<ActivitiesTableLoading showProjectNameColumn={showProjectNameColumn} />
				) : (
					data &&
					data.map((activity) => (
						<ActivityRow
							activity={activity}
							key={activity.id}
							select={select}
							regenerate={regenerate}
							activityLink={activityLink}
							reportLink={reportLink}
							showProjectNameColumn={showProjectNameColumn}
						/>
					))
				)}
			</TableBody>
		</Table>
	);
}

export default memo(ActivitiesTable);
