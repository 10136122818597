import { styled } from '@mui/material/styles';
import MUISwitch from '@mui/material/Switch';

export const ActionButtonsContainer = styled('div')`
	display: flex;
	align-self: flex-end;
`;

export const ProjectOptionsContainer = styled('div')`
	display: flex;
	flex-direction: column;
	margin-top: ${({ theme }) => theme.spacing(3)};
`;

export const ReportOption = styled('div')`
	display: flex;
	width: 50%;
	align-items: center;
	justify-content: space-between;
	margin: 8px 0;
`;

export const Switch = styled(MUISwitch)`
	.MuiSwitch-track {
		background-color: gray !important;
	}
`;
