import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { ApiStudyDto } from 'kes-common';

import { templateScriptFileDelete } from '@/net/api';

const useTemplateScriptFileDelete = (templateId: ApiStudyDto['id'], options?: UseMutationOptions) =>
	useMutation(
		() => templateScriptFileDelete(templateId).then((response) => response.expectSuccess()),
		options,
	);

export default useTemplateScriptFileDelete;
