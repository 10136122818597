import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ApiPublishedStudyDto } from 'kes-common';
import React from 'react';
import { Link } from 'react-router-dom';

import TemplatePreview from '@/components/TemplatePreview';
import { buildRoute, template as templateRoute } from '@/routes';

interface PreviewTemplateDialogProps {
	onClose: () => void;
	onUseTemplate: () => void;
	template?: ApiPublishedStudyDto;
}

const PreviewTemplateDialog: React.FC<PreviewTemplateDialogProps> = ({
	onClose,
	onUseTemplate,
	template,
}) => (
	<Dialog open={Boolean(template)} onClose={onClose} fullWidth maxWidth="md">
		<DialogTitle>&apos;{template?.name}&apos; report preview</DialogTitle>
		<DialogContent sx={{ backgroundColor: 'white' }}>
			{template && (
				<Box sx={{ height: '60vh' }}>
					<TemplatePreview template={template} />
				</Box>
			)}
		</DialogContent>
		<DialogActions>
			<Button onClick={onClose}>Close</Button>
			<Button
				component={Link}
				to={template ? buildRoute(templateRoute, { templateId: template.id }) : ''}
			>
				Full preview
			</Button>
			<Button
				color="primary"
				variant="contained"
				onClick={onUseTemplate}
				data-testid={`action-add-inspection-from-preview-${template?.name.toLocaleLowerCase()}`}
			>
				Use template
			</Button>
		</DialogActions>
	</Dialog>
);

export default PreviewTemplateDialog;
