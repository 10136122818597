import Box from '@mui/material/Box';
import React from 'react';

import Projects from './Projects';
import Templates from './Templates';

const WorkInformation: React.FC = () => (
	<>
		<Projects />
		<Box marginTop={2}>
			<Templates />
		</Box>
	</>
);

export default WorkInformation;
