import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { ApiInspectionDto, ApiRelatedActivityDto } from 'kes-common';
import { relatedActivityCreate } from '@/net/api';

interface MutationOptions {
	relatedActivityId: ApiRelatedActivityDto['id'];
}

const useRelatedActivityCreate = (
	inspectionId: ApiInspectionDto['id'],
	options?: UseMutationOptions<ApiRelatedActivityDto, unknown, MutationOptions>,
) =>
	useMutation(
		({ relatedActivityId }) =>
			relatedActivityCreate(relatedActivityId, inspectionId).then((response) =>
				response.expectSuccess(),
			),
		options,
	);

export default useRelatedActivityCreate;
