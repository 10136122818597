import { useMutation } from '@tanstack/react-query';
import { projectReportOptionsUpdate } from '@/net/api';

// eslint-disable-next-line import/prefer-default-export
export function useUpdateProjectReportOptionsMutation(
	projectId: string,
	coverOptionEnabled: boolean,
	onSuccessCallback?: () => void,
) {
	return useMutation(
		(inspectionProps: { coverOptionEnabled: boolean }) =>
			projectReportOptionsUpdate(projectId, {
				includeCoverPage: inspectionProps.coverOptionEnabled,
			}),
		{
			onSuccess: onSuccessCallback,
		},
	);
}
