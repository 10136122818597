import React, { useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { Alert, Button, Container, Grid, Paper, Typography } from '@mui/material';
import { ApiInspectionDto, useUser, defaultEndNote, alertLoseAccess } from 'kes-common';
import { useSelector } from 'react-redux';
import State from '@/store/state';
import ButtonInspectionComplete from './ButtonInspectionComplete';

const InspectionFinish = () => {
	const history = useHistory();
	const { projectId, inspectionId } = useParams<Record<string, string>>();
	const error = useSelector<State, string | null>((state) => state.inspection.error);
	const currentInspection = useSelector<State, ApiInspectionDto | undefined>(
		(state) => state.inspection.currentInspection,
	);
	const { user } = useUser();

	const removeAccess: boolean = useMemo(() => {
		const userId = user?.user.id;
		const users = currentInspection?.users;
		if (users) {
			const matchingUser = users.find((u) => u.userId === userId);
			if (matchingUser?.removeAccess === true) return true;
		}
		return false;
	}, [currentInspection, user]);

	if (error) {
		return <p>Something went wrong loading the activity</p>;
	}

	return (
		<Container maxWidth="md">
			<Paper sx={{ my: 10, p: 5 }}>
				<Typography mb={8} color="primary" variant="h5">
					Thank you for completing the survey
				</Typography>
				<Typography sx={{ minHeight: '20vh' }} m={2} variant="body1">
					{currentInspection?.endNote ||
						(currentInspection?.assetLibrary.language === 'EN'
							? defaultEndNote.en
							: defaultEndNote.nl)}
				</Typography>
				{removeAccess && (
					<Alert severity="warning">
						{currentInspection?.assetLibrary.language === 'EN'
							? alertLoseAccess.en
							: alertLoseAccess.nl}
					</Alert>
				)}
				<Grid container mt={8} spacing={2}>
					<Grid item xs={12} sm={6}>
						<Button fullWidth variant="contained" color="primary" onClick={() => history.goBack()}>
							Previous
						</Button>
					</Grid>
					<Grid item xs={12} sm={6}>
						<ButtonInspectionComplete inspectionId={inspectionId} projectId={projectId} />
					</Grid>
				</Grid>
			</Paper>
		</Container>
	);
};

export default InspectionFinish;
