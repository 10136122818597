import { SagaIterator } from '@redux-saga/core';
import { takeEvery } from 'redux-saga/effects';
import * as actions from '@/store/actions';

// eslint-disable-next-line require-yield
function* setDocumentTitle(action: { payload: string }): SagaIterator {
	document.title = `Kes ${action.payload}`;
}

function* router(): SagaIterator {
	yield takeEvery(actions.routerUpdateTitle, setDocumentTitle);
}

export default router;
