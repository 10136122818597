import React, { ReactNode, useMemo } from 'react';

/**
 * Context used to retrieve the propertyId. This is useful because there are many components
 * between where the list of property is mapped and used, making it inconvenient to pass the
 * propertyId down many levels
 */
const PropertyIdContext = React.createContext<{ propertyId: string }>({ propertyId: '' });

interface PropertyIdContextProviderProps {
	children: ReactNode;
	propertyId: string;
}

function PropertyIdContextProvider({
	propertyId,
	children,
}: PropertyIdContextProviderProps): JSX.Element {
	const value = useMemo(() => ({ propertyId }), [propertyId]);

	return <PropertyIdContext.Provider value={value}>{children}</PropertyIdContext.Provider>;
}

/**
 * Hook to retrieve the propertyId string from the context
 */
const usePropertyIdContext = (): string => {
	const { propertyId } = React.useContext(PropertyIdContext);

	if (!propertyId) {
		throw new Error('usePropertyIdContext must be used within a PropertyIdContext');
	}

	return propertyId;
};

export { usePropertyIdContext, PropertyIdContext, PropertyIdContextProvider };
