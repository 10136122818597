import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { UploadReducerState } from '@/hooks/useUploadReducer';
import { inspectionImageUpdateCaption, inspectionImageUpdateCaptionRepeating } from '@/net/api';
import State from '@/store/state';
import { assetTypesGet } from '@/selectors';
import { AssetType } from '@/store/types';
import { inspectionImageCaptionSave } from '@/store/actions';

interface ImageCaptionModalProps {
	assetId: string | null;
	assetTypeId: string;
	caption: string;
	isOpen: boolean;
	onClose(): void;
	onError(): void;
	onSuccess(): void;
	propertyId: string;
	state: UploadReducerState;
}

const ImageCaptionModal: React.FC<ImageCaptionModalProps> = ({
	assetId,
	assetTypeId,
	caption,
	isOpen,
	onClose,
	onError,
	onSuccess,
	propertyId,
	state,
}) => {
	const [value, setValue] = React.useState<string>(caption);

	const dispatch = useDispatch();
	const { inspectionId } = useParams<Record<string, string>>();
	const assetType = useSelector<State, AssetType>((storeState) =>
		assetTypesGet(storeState, assetTypeId),
	);

	const updateCaptionInStore = React.useCallback(() => {
		if (assetId) {
			dispatch(inspectionImageCaptionSave({ assetId, caption: value, propertyId }));
		}
	}, [assetId, propertyId, value]);

	const saveImageCaption = React.useCallback(() => {
		if (assetId) {
			if (assetType.repeating) {
				inspectionImageUpdateCaptionRepeating(assetId, propertyId, inspectionId, { caption: value })
					.then(() => {
						updateCaptionInStore();
						onSuccess();
					})
					.catch(() => onError());
			} else {
				inspectionImageUpdateCaption(propertyId, inspectionId, { caption: value })
					.then(() => {
						updateCaptionInStore();
						onSuccess();
					})
					.catch(() => onError());
			}
		}
	}, [
		assetId,
		assetType,
		inspectionId,
		onError,
		onSuccess,
		propertyId,
		updateCaptionInStore,
		value,
	]);

	return (
		<Dialog onClose={onClose} open={isOpen}>
			<DialogTitle>Update caption for {state.fileName}</DialogTitle>
			<DialogContent>
				<TextField
					fullWidth
					label="Caption"
					name="caption"
					onChange={(event) => setValue(event.target.value)}
					value={value}
				/>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={onClose} variant="text">
					Cancel
				</Button>
				<Button color="primary" disabled={!assetId} onClick={saveImageCaption} variant="contained">
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ImageCaptionModal;
