/* eslint-disable no-param-reassign */
import makeReducer from '@/store/makeReducer';
import { StateUI } from '@/store/state';
import * as actions from '../actions';

const initialState: StateUI = {
	targetAssetTypeId: null,
	targetAssetId: null,
};

const resetState = (draft: StateUI) => {
	draft.targetAssetTypeId = null;
	draft.targetAssetId = null;
	draft.relationshipPropertyId = undefined;
};

export default makeReducer(initialState, {
	[actions.routerUpdateTitle.type]: (draft): void => {
		resetState(draft);
	},
	[actions.setRelationshipPropertyId.type]: (draft, payload): void => {
		draft.relationshipPropertyId = payload;
	},
});
