import React from 'react';
import { Dialog } from '@mui/material';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

interface ConfirmDialogProps {
	title: string;
	copy?: string;
	open: boolean;
	handleClose: () => void;
	onConfirm: () => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
	title,
	copy,
	open,
	handleClose,
	onConfirm,
}): JSX.Element => (
	<Dialog
		open={open}
		onClose={handleClose}
		aria-labelledby="alert-dialog-title"
		aria-describedby="alert-dialog-description"
		onClick={(e) => {
			e.stopPropagation();
			e.preventDefault();
		}}
	>
		<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
		{copy && (
			<DialogContent>
				<DialogContentText id="alert-dialog-description">{copy}</DialogContentText>
			</DialogContent>
		)}
		<DialogActions>
			<Button onClick={handleClose} color="primary">
				Cancel
			</Button>
			<Button data-testid="confirm-dialog-ok" onClick={onConfirm} color="primary" autoFocus>
				Ok
			</Button>
		</DialogActions>
	</Dialog>
);

export default ConfirmDialog;
