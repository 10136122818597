import Alert from '@mui/material/Alert';
import AddIcon from '@mui/icons-material/Add';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import { LoadingButton } from '@mui/lab';

import { PageTitle } from 'kes-common';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { buildRoute, home, project as projectRoute } from '@/routes';
import { projectCurrent } from '@/selectors';
import { getAllRelatedFiles } from '@/selectors/files';
import { getInspectionId } from '@/selectors/inspections';

import { allowedFilesForInputElement, isEmbeddable } from '@/kaas/utils';
import { v4 } from 'uuid';
import useRelatedFileUpload from '@/net/reactQuery/mutations/useRelatedFileUpload';
import { inspectionRelatedFileUpload } from '@/store/actions';
import RelatedFile from './RelatedFile';

const InspectionRelatedFiles: React.FC = () => {
	const inspection = useSelector(getInspectionId);
	const project = useSelector(projectCurrent);
	const relatedFiles = useSelector(getAllRelatedFiles);
	const dispatch = useDispatch();

	const uploadMutation = useRelatedFileUpload(
		{ inspectionId: inspection?.id },
		{
			onSuccess: (_, { file: f, fileId }) => {
				if (fileId === null || f?.name === undefined) return;

				dispatch(
					inspectionRelatedFileUpload({
						fileName: f.name,
						fileId,
						processed: isEmbeddable(f.name),
					}),
				);
			},
		},
	);

	const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files === null) return;

		for (const file of event.target.files) {
			uploadMutation.mutate({ file, fileId: v4() });
		}
	};

	if (!inspection || !project) {
		return (
			<Box marginY={4}>
				<Container fixed maxWidth="md">
					<Alert severity="error">The project and/or inspection cannot be found.</Alert>
				</Container>
			</Box>
		);
	}

	return (
		<Box marginBottom={4}>
			<Container fixed maxWidth="md">
				<PageTitle
					breadcrumbs={[
						{ title: 'Projects', url: buildRoute(home, {}) },
						{ title: project.name, url: buildRoute(projectRoute, { projectId: project.id }) },
						{ title: inspection.assetLibrary.studyName },
						{ title: 'Related files' },
					]}
					title="Related files"
				/>

				{relatedFiles.length === 0 && (
					<Alert sx={{ my: 3 }} severity="info">
						There are no related files at this moment. Related files can also be added to questions
						as additional information or proofs
					</Alert>
				)}

				{relatedFiles.length > 0 && (
					<Box marginTop={4}>
						<Card>
							<CardContent>
								<List>
									{relatedFiles.map((relatedFile) => (
										<RelatedFile key={relatedFile.fileId} relatedFile={relatedFile} />
									))}
								</List>
							</CardContent>
						</Card>
					</Box>
				)}
				<Box marginTop={2} textAlign="right">
					<LoadingButton
						loading={uploadMutation.isLoading}
						startIcon={<AddIcon />}
						variant="contained"
						component="label"
					>
						Upload related files
						<input
							onChange={handleFileUpload}
							type="file"
							multiple
							accept={allowedFilesForInputElement}
							style={{ display: 'none' }}
						/>
					</LoadingButton>
				</Box>
			</Container>
		</Box>
	);
};

export default InspectionRelatedFiles;
