import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { ApiStudyDto } from 'kes-common';
import { useSnackbar } from 'notistack';
import React from 'react';

import useTemplateDelete from '@/net/reactQuery/mutations/useTemplateDelete';

export interface TemplateDeleteDialogProps {
	isOpen: boolean;
	onClose(): void;
	onSuccess(): void;
	template: ApiStudyDto;
}

const TemplateDeleteDialog: React.FC<TemplateDeleteDialogProps> = ({
	isOpen,
	onClose,
	onSuccess,
	template,
}) => {
	const { enqueueSnackbar } = useSnackbar();
	const { isLoading, mutate } = useTemplateDelete(template.id, {
		onError: () => {
			enqueueSnackbar('Failed to delete the template, please try again.');
		},
		onSuccess,
	});

	const handleClose = React.useCallback(() => {
		if (isLoading) {
			return;
		}
		onClose();
	}, [isLoading, onClose]);

	const handleDelete = React.useCallback(() => {
		mutate();
	}, [mutate]);

	return (
		<Dialog onClose={handleClose} open={isOpen}>
			<DialogTitle>Delete template</DialogTitle>
			<DialogContent>
				<Typography>
					Are you sure you would like to delete the template &quot;{template.name}&quot;?
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button disabled={isLoading} onClick={handleClose}>
					Cancel
				</Button>
				<Button disabled={isLoading} onClick={handleDelete} variant="contained">
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default TemplateDeleteDialog;
