import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { ApiInspectionDto, ApiScript, Loader } from 'kes-common';
import React from 'react';

import useInspectionScriptFileRun from '@/net/reactQuery/mutations/useInspectionScriptFileRun';

interface RunOptions {
	inspectionId: ApiInspectionDto['id'];
	script: ApiScript;
}

const Run: React.FC<RunOptions> = ({ inspectionId, script }) => {
	const [isOpen, setIsOpen] = React.useState<boolean>(false);

	const runInspectionScriptFile = useInspectionScriptFileRun(inspectionId);
	const { data, isError, isLoading, isSuccess, mutate, reset } = runInspectionScriptFile;

	const onClose: DialogProps['onClose'] = React.useCallback(
		(event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
			if (!(reason === 'backdropClick' && isLoading)) {
				const refresh = isSuccess;
				setIsOpen(false);
				reset();
				if (refresh) {
					window.location.reload();
				}
			}
		},
		[isLoading, reset, setIsOpen],
	);

	const onRunScript = React.useCallback(() => {
		mutate(script);
	}, [mutate, script]);

	return (
		<>
			<Tooltip title="Start a run of this script file.">
				<IconButton onClick={() => setIsOpen(true)}>
					<PlayArrowIcon color="secondary" />
				</IconButton>
			</Tooltip>

			<Dialog disableEscapeKeyDown fullWidth maxWidth="md" onClose={onClose} open={isOpen}>
				<DialogTitle>Run script file</DialogTitle>
				<DialogContent>
					{isError && (
						<Alert
							action={
								<Button color="inherit" onClick={onRunScript}>
									Retry
								</Button>
							}
							severity="error"
						>
							Failed to run the script, please try again.
						</Alert>
					)}
					{!isError && isLoading && <Loader />}
					{!isError && !isLoading && isSuccess && (
						<TextField
							fullWidth
							inputProps={{ readOnly: true }}
							label="Logs"
							multiline
							rows={10}
							value={data.text}
						/>
					)}
					{!isError && !isLoading && !isSuccess && (
						<Alert
							action={
								<Button color="inherit" onClick={onRunScript}>
									Run script
								</Button>
							}
							severity="info"
						>
							Once the script is started, please wait for output before closing this dialog or
							browser.
						</Alert>
					)}
				</DialogContent>
				<DialogActions>
					<Button
						color="primary"
						disabled={isLoading || isSuccess}
						onClick={onRunScript}
						variant="contained"
					>
						Run
					</Button>
					<Button disabled={isLoading} onClick={(event) => onClose(event, 'escapeKeyDown')}>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default Run;
