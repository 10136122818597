import { SagaIterator } from '@redux-saga/core';
import { call, fork, put, takeEvery } from 'redux-saga/effects';
import * as actions from '@/store/actions';
import { Result } from '@/store/utils';
import { inspectionDelete, inspectionGet } from '@/net/api';
import { InspectionId } from '@/store/types';
import { ApiError } from 'kes-common';

export function* getInspection(inspectionId: InspectionId): SagaIterator {
	yield put(actions.inspectionLoad(inspectionId));
	try {
		const response: Result<typeof inspectionGet> = yield call(inspectionGet, inspectionId);
		if (response.status === 200) {
			yield put(actions.inspectionLoadSuccess(response.result));
		}
	} catch (e) {
		yield put(actions.inspectionLoadError(e as ApiError));
	}
}

export function* deleteInspection(
	action: ReturnType<typeof actions.inspectionDelete>,
): SagaIterator {
	try {
		const response: Result<typeof inspectionDelete> = yield call(inspectionDelete, action.payload);

		if (response.status === 204) {
			yield put(actions.inspectionDeleteSuccess());
			yield put(actions.projectLoadRefresh(true));
		} else {
			yield put(actions.inspectionDeleteError(response.result));
		}
	} catch (e) {
		yield put(actions.inspectionDeleteError(e as ApiError));
	}
}

function* inspection(inspectionId: InspectionId | null): SagaIterator {
	yield takeEvery(actions.inspectionDelete, deleteInspection);

	if (inspectionId === null) {
		return;
	}
	yield fork(getInspection, inspectionId);
}

export default inspection;
