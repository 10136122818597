import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import WorkIcon from '@mui/icons-material/Work';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import React from 'react';
import { Link } from 'react-router-dom';

import AppBar from '@/components/rhdhv/AppBar';
import MobileDrawer from '@/components/rhdhv/AppBar/Drawer';
import { home, activities, templates, masterTemplates } from '@/routes';
import { useFeatureFlag } from 'kes-common';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const DesktopDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
		...(open ? openedMixin(theme) : closedMixin(theme)),
		'& .MuiDrawer-paper': {
			...(open ? openedMixin(theme) : closedMixin(theme)),
			backgroundColor: theme.palette.primary.light,
			zIndex: 1000,
			marginTop: '64px',
		},
	}),
);

interface PageWithTopAndSideBarsProps {
	children: React.ReactNode;
}

const PageWithTopAndSideBars: React.FC<PageWithTopAndSideBarsProps> = ({ children }) => {
	const [isOpen, setIsOpen] = React.useState<boolean>(false);

	const MASTER_TEMPLATES = useFeatureFlag({ name: 'MASTER_TEMPLATES' });

	const onToggle = React.useCallback(
		() => setIsOpen((currentlyIsOpen) => !currentlyIsOpen),
		[setIsOpen],
	);

	return (
		<>
			<AppBar
				actionLeft={
					<>
						<Hidden smDown>
							<IconButton
								aria-label={isOpen ? 'Close drawer' : 'Open drawer'}
								color="secondary"
								onClick={onToggle}
							>
								{isOpen ? <ChevronLeftIcon /> : <MenuIcon />}
							</IconButton>
						</Hidden>
						<Hidden smUp>
							<IconButton color="secondary" aria-label="open drawer" onClick={onToggle}>
								<MenuIcon />
							</IconButton>
						</Hidden>
					</>
				}
			/>
			<Box sx={{ display: 'flex' }}>
				<Hidden smDown>
					<DesktopDrawer variant="permanent" open={isOpen}>
						<List>
							<ListItemButton
								component={Link}
								sx={{
									justifyContent: isOpen ? 'initial' : 'center',
									paddingX: 2.5,
								}}
								to={home}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: isOpen ? 3 : 'auto',
										justifyContent: 'center',
									}}
								>
									<WorkIcon />
								</ListItemIcon>
								<ListItemText primary="Projects" sx={{ opacity: isOpen ? 1 : 0 }} />
							</ListItemButton>

							<ListItemButton
								component={Link}
								sx={{
									justifyContent: isOpen ? 'initial' : 'center',
									paddingX: 2.5,
								}}
								to={activities}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: isOpen ? 3 : 'auto',
										justifyContent: 'center',
									}}
								>
									<SearchIcon />
								</ListItemIcon>
								<ListItemText primary="Activities" sx={{ opacity: isOpen ? 1 : 0 }} />
							</ListItemButton>

							<ListItemButton
								component={Link}
								sx={{
									justifyContent: isOpen ? 'initial' : 'center',
									paddingX: 2.5,
								}}
								to={templates}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: isOpen ? 3 : 'auto',
										justifyContent: 'center',
									}}
								>
									<FileCopyIcon />
								</ListItemIcon>
								<ListItemText primary="Templates" sx={{ opacity: isOpen ? 1 : 0 }} />
							</ListItemButton>

							{MASTER_TEMPLATES && (
								<ListItemButton
									component={Link}
									sx={{
										justifyContent: isOpen ? 'initial' : 'center',
										paddingX: 2.5,
									}}
									to={masterTemplates}
								>
									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: isOpen ? 3 : 'auto',
											justifyContent: 'center',
										}}
									>
										<FolderCopyIcon />
									</ListItemIcon>
									<ListItemText primary="Master templates" sx={{ opacity: isOpen ? 1 : 0 }} />
								</ListItemButton>
							)}
						</List>
					</DesktopDrawer>
				</Hidden>
				<Hidden smUp>
					<MobileDrawer onClose={onToggle} open={isOpen} />
				</Hidden>

				<Box component="main" sx={{ flexGrow: 1 }}>
					<Toolbar />
					{children}
				</Box>
			</Box>
		</>
	);
};

export default PageWithTopAndSideBars;
