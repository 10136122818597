import React from 'react';
import { useSnackbar } from 'notistack';
import { fetchAccessString as apiFetchAccessString } from '@/net/api';
import { Link } from '@mui/material';

const useFetchAccessString = () => {
	const { enqueueSnackbar } = useSnackbar();
	const fetchAccessString = async (projectId: string) => {
		try {
			const response = await apiFetchAccessString(projectId);
			const accessStringDto = response.expectSuccess();

			if (accessStringDto.newUser && accessStringDto.accessString !== null) {
				await navigator.clipboard.writeText(accessStringDto.accessString);
				enqueueSnackbar(
					<span>
						The connection information is copied to your clipboard, keep it somewhere safe because
						we cannot show or generate it again.
						<Link
							display="block"
							color="inherit"
							href="https://kes.support.royalhaskoningdhv.com/en/support/solutions/articles/77000529441-how-to-get-your-data-in-tableau-powerbi-excel-etc-"
							target="_blank"
						>
							Learn more
						</Link>
					</span>,
					{
						autoHideDuration: 10000,
						anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
					},
				);
			} else {
				enqueueSnackbar(
					<span>
						Access to Project data has been granted. The connection string was already shared with
						you. If you would like to reset it contact us at
						<Link
							display="block"
							color="inherit"
							href="https://kes.support.royalhaskoningdhv.com/support/home"
							target="_blank"
						>
							Kes support
						</Link>
					</span>,
					{
						autoHideDuration: 10000,
						anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
					},
				);
			}
		} catch (e) {
			enqueueSnackbar('Error fetching access string');
		}
	};
	return { fetchAccessString };
};

export default useFetchAccessString;
