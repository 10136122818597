import { Tooltip, Typography } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import TextWithBreaks from '@/components/rhdhv/TextWithBreaks';
import { choicesGet } from '@/selectors';
import State from '@/store/state';
import { usePropertyIdContext } from '@/kaas/PropertyIdContext';
import useKaasPropertyAssist from '@/kaas/useKaasPropertyAssist';
import { KAAS_COLOR } from '@/kaas/utils';
import useAssistFeedback from '@/net/reactQuery/mutations/useAssistFeedback';

import { Choice, Property } from '../../../../store/types';
import classes from './Choice.module.css';

interface Props {
	choice: Choice;
	icon: ReactNode;
	iconSelected: ReactNode;
	selected: boolean;
	onSelect(): void;
	property: Property;
}

const ChoiceRenderer: FC<Props> = ({
	choice,
	selected,
	icon,
	iconSelected,
	onSelect,
	property,
}): JSX.Element => {
	const { inspectionId } = useParams<Record<string, string>>();
	const assistFeedback = useAssistFeedback(inspectionId);
	const { suggestedAnswers, trackKaasAcceptedAnswer } = useKaasPropertyAssist(
		usePropertyIdContext(),
	);

	const isThisChoiceSuggested = suggestedAnswers && suggestedAnswers.answer.includes(choice.name);
	const color = isThisChoiceSuggested ? KAAS_COLOR : undefined;
	const boldness = isThisChoiceSuggested ? '500' : undefined;

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
		<div
			data-testid="choice-button"
			className={selected ? classes.rootSelected : classes.root}
			role="button"
			onClick={() => {
				onSelect();
				if (isThisChoiceSuggested) {
					trackKaasAcceptedAnswer?.(choice.name);
					assistFeedback.mutate({
						feedbackType: 'ThumbsUp',
						files: [],
						questions: [property.id],
						retrieverType: 'FirstFeedback',
						traceId: suggestedAnswers.traceId,
					});
				}
			}}
		>
			{selected ? iconSelected : icon}
			{suggestedAnswers && suggestedAnswers.fileCitation ? (
				<Tooltip title={`Source ${suggestedAnswers.fileCitation}`} placement="bottom-start">
					<Typography className={classes.title} color={color} style={{ fontWeight: boldness }}>
						{choice.name}
					</Typography>
				</Tooltip>
			) : (
				<Typography className={classes.title} color={color} style={{ fontWeight: boldness }}>
					{choice.name}
				</Typography>
			)}

			{choice.description && (
				<div className={classes.description}>
					<TextWithBreaks text={choice.description} />
				</div>
			)}
		</div>
	);
};

export default connect(
	(state: State, ownProps: { choiceId: Choice['id'] }): Pick<Props, 'choice'> => ({
		choice: choicesGet(state, ownProps.choiceId),
	}),
)(ChoiceRenderer);
