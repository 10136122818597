import MoreVertIcon from '@mui/icons-material/MoreVert';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import { PageTitle, useUser } from 'kes-common';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router-dom';

import TemplateChips from '@/components/TemplateChips';
import TemplateContextMenu, { TemplateContextMenuProps } from '@/components/TemplateContextMenu';
import TemplatePreview from '@/components/TemplatePreview';
import TemplatePublishDialog from '@/components/TemplatePublishDialog';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import { buildRoute, home, template as templateRoute, templatesWithSelected } from '@/routes';

import { useTemplate } from '../context';

const Overview: React.FC = () => {
	const [isTemplatePublishDialogOpen, setIsTemplatePublishDialogOpen] =
		React.useState<boolean>(false);
	const [templateContextMenuAnchorElement, setTemplateContextMenuAnchorElement] =
		React.useState<HTMLElement | null>(null);

	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const { setTemplate, template, refetch } = useTemplate();
	const { user } = useUser();

	useDocumentTitle('Project Manager', 'Templates', template.name);

	const canCurrentUserPublishTemplate = React.useMemo<boolean>(() => {
		const isTemplateAdministrator = Boolean(
			template.users.find((templateUser) => templateUser.userId === user.user.id),
		);
		return isTemplateAdministrator || user.isGlobalAdmin;
	}, [template, user]);

	const onTemplateAssignError = React.useCallback(() => {
		enqueueSnackbar('Failed to assign the template to the selected project(s)');
	}, [enqueueSnackbar]);

	const onTemplateAssignSuccess = React.useCallback(() => {
		enqueueSnackbar('The template has successfully been assigned to the selected project(s)');
		setTemplateContextMenuAnchorElement(null);
	}, [enqueueSnackbar, setTemplateContextMenuAnchorElement]);

	const onTemplateCopied: TemplateContextMenuProps['onTemplateCopied'] = React.useCallback(
		(templateId) => {
			history.push(buildRoute(templateRoute, { templateId }));
			enqueueSnackbar('Template copied successfully');
		},
		[enqueueSnackbar, history],
	);

	const onTemplatePublished: TemplateContextMenuProps['onTemplatePublished'] =
		React.useCallback(() => {
			enqueueSnackbar('Template published successfully', {
				anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
			});
			setIsTemplatePublishDialogOpen(false);
			setTemplate((currentTemplate) => ({
				...currentTemplate,
				lastPublishedDate: new Date().toLocaleString(),
			}));
		}, [enqueueSnackbar, setIsTemplatePublishDialogOpen, setTemplate]);

	const onTemplateUserAdded: TemplateContextMenuProps['onTemplateUserAdded'] = React.useCallback(
		(newTemplateUser) => {
			setTemplate((currentTemplate) => ({
				...currentTemplate,
				users: [...currentTemplate.users, newTemplateUser],
			}));
		},
		[setTemplate],
	);

	const onTemplateUserRemoved: TemplateContextMenuProps['onTemplateUserRemoved'] =
		React.useCallback(
			(userId) => {
				setTemplate((currentTemplate) => ({
					...currentTemplate,
					users: currentTemplate.users.filter((templateUser) => templateUser.userId !== userId),
				}));
			},
			[setTemplate],
		);

	return (
		<>
			<PageTitle
				actions={
					<IconButton onClick={(event) => setTemplateContextMenuAnchorElement(event.currentTarget)}>
						<MoreVertIcon />
					</IconButton>
				}
				breadcrumbs={[
					{ title: 'Home', url: home },
					{
						title: 'Templates',
						url: buildRoute(templatesWithSelected, { templateId: template.id }),
					},
					{ title: template.name },
				]}
				title={template.name}
			/>

			<Box marginTop={4}>
				<Box marginBottom={2}>
					<TemplateChips template={template} />
				</Box>

				{template.type === 'CLASSIC' && (
					<>
						{!template.lastPublishedDate && (
							<Alert
								action={
									canCurrentUserPublishTemplate ? (
										<Button color="inherit" onClick={() => setIsTemplatePublishDialogOpen(true)}>
											Publish
										</Button>
									) : undefined
								}
								severity="info"
							>
								Only published templates can be previewed. This template has not been published yet.
							</Alert>
						)}

						{template.lastPublishedDate && (
							<Box sx={{ height: '60vh' }}>
								<Card sx={{ height: '100%' }}>
									<CardContent sx={{ backgroundColor: 'white', height: '100%' }}>
										<TemplatePreview template={template} />
									</CardContent>
								</Card>
							</Box>
						)}
					</>
				)}

				{template.type === 'WORD' && (
					<Alert
						action={
							<Button component="a" href={template.sharepointUrl || '#'}>
								Open
							</Button>
						}
						severity="info"
					>
						Word based templates cannot be previewed. Open the template in Word online to preview
						it.
					</Alert>
				)}
			</Box>

			<TemplatePublishDialog
				isOpen={isTemplatePublishDialogOpen}
				onClose={() => setIsTemplatePublishDialogOpen(false)}
				onSuccess={onTemplatePublished}
				template={template}
			/>

			<TemplateContextMenu
				anchorElement={templateContextMenuAnchorElement}
				onClose={() => setTemplateContextMenuAnchorElement(null)}
				onTemplateAssignError={onTemplateAssignError}
				onTemplateAssignSuccess={onTemplateAssignSuccess}
				onTemplateCopied={onTemplateCopied}
				onTemplatePublished={onTemplatePublished}
				onTemplateUserAdded={onTemplateUserAdded}
				onTemplateUserRemoved={onTemplateUserRemoved}
				template={template}
				refetch={refetch}
			/>
		</>
	);
};

export default Overview;
