import { ApiUserProfileDto } from 'kes-common';
import React from 'react';

import UserContext from './context';

interface UserProviderProps {
	children: React.ReactNode;
	refetch: (options?: any) => any;
	value: ApiUserProfileDto;
}

const UserProvider: React.FC<UserProviderProps> = ({ children, refetch, value }) => {
	const [user, setUser] = React.useState<ApiUserProfileDto>(value);
	const providerValue = React.useMemo(
		() => ({ user, refetchUser: refetch, setUser }),
		[user, refetch, setUser],
	);
	React.useEffect(() => {
		if (value !== user) {
			setUser(value);
		}
	}, [value]);

	return <UserContext.Provider value={providerValue}>{children}</UserContext.Provider>;
};

export default UserProvider;
