import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '@/store/actions';
import insectionDeleteMutation from '@/net/reactQuery/mutations/useInspectionDelete';
import { InspectionId } from '@/store/types';

interface ClearDataDialogProps {
	inspectionId: InspectionId;
	isOpen: boolean;
	onClose(): void;
	onDelete(): void;
	onError(): void;
}
const ClearDataDialog: React.FC<ClearDataDialogProps> = ({
	inspectionId,
	isOpen,
	onClose,
	onDelete,
	onError,
}) => {
	const dispatch = useDispatch();
	const handleDelete = insectionDeleteMutation(inspectionId, {
		onError,
		onSuccess: () => {
			dispatch(actions.deleteAssetsAndImages({ inspectionId }));
			onDelete();
			onClose();
		},
	});
	return (
		<Dialog onClose={onClose} open={isOpen}>
			<DialogTitle>Clear all data</DialogTitle>
			<DialogContent>
				<Typography>Are you sure you want to clear all data? This can not be undone.</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button color="primary" onClick={() => handleDelete.mutate()} variant="contained">
					Clear
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ClearDataDialog;
