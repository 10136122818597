import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React from 'react';

import * as Styled from './Drawer.styled';
import { helpLinks, legalLinks } from '../links';

const LegalLinks: React.FC = () => {
	const [legalLinksVisible, setLegalLinksVisible] = React.useState<boolean>(false);

	return (
		<Box display="flex" flexDirection="column" width="100%">
			<Styled.Button
				endIcon={<ArrowDropDownIcon color="action" />}
				onClick={() => setLegalLinksVisible(!legalLinksVisible)}
			>
				<Typography color="textPrimary" variant="subtitle2">
					Help
				</Typography>
			</Styled.Button>
			<Collapse in={legalLinksVisible}>
				<List sx={{ width: '100%' }}>
					{helpLinks.map((helpLink, index) => (
						<ListItemButton
							component="a"
							href={helpLink.href}
							// eslint-disable-next-line react/no-array-index-key
							key={index}
							rel="noopener noreferrer"
							target="_blank"
						>
							{helpLink.title}
						</ListItemButton>
					))}
					<Divider />
					{legalLinks.map((legalLink, index) => (
						<ListItemButton
							component="a"
							href={legalLink.href}
							// eslint-disable-next-line react/no-array-index-key
							key={index}
							rel="noopener noreferrer"
							target="_blank"
						>
							{legalLink.title}
						</ListItemButton>
					))}
				</List>
			</Collapse>
		</Box>
	);
};

export default LegalLinks;
