import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import React from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { assetAnswer, assetCreate } from '@/store/actions';
import { Property, SurveyAsset, UUID } from '@/store/types';

interface NoteModalProps {
	asset: SurveyAsset;
	assetTypeId: UUID;
	isModalOpen: boolean;
	property: Property;
	setIsModalOpen: (open: boolean) => void;
}

const NoteModal: React.FC<NoteModalProps> = ({
	isModalOpen,
	property,
	asset,
	assetTypeId,
	setIsModalOpen,
}) => {
	const [noteText, setNoteText] = React.useState<string>(asset.notesByPropertyId[property.id]);
	const dispatch = useDispatch();

	const createNote = React.useCallback(() => {
		const assetId = asset.id || uuid();
		if (!asset.id) {
			dispatch(assetCreate({ assetId, assetTypeId }));
		}
		dispatch(
			assetAnswer({
				answer: asset.valuesByPropertyId[property.id],
				propertyId: property.id,
				assetId,
				assetTypeId,
				noteText,
				applicable: asset.applicableByPropertyId[property.id],
				otherOption: asset.otherOptionEnabledByPropertyId[property.id],
				location: asset.location[property.id],
			}),
		);
		setIsModalOpen(false);
	}, [asset, assetTypeId, dispatch, noteText, property]);

	return (
		<Dialog fullWidth maxWidth="md" onClose={() => setIsModalOpen(false)} open={isModalOpen}>
			<DialogTitle>Add notes</DialogTitle>
			<DialogContent>
				<DialogContentText gutterBottom>{property.question}</DialogContentText>
				<TextField
					autoFocus
					fullWidth
					multiline
					onChange={(event) => setNoteText(event.target.value)}
					rows={10}
					value={noteText}
					variant="outlined"
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={createNote}>Done</Button>
			</DialogActions>
		</Dialog>
	);
};

export default NoteModal;
