import State from '@/store/state';
import { SimpleProject } from '@/store/types';
import React from 'react';
import { useSelector } from 'react-redux';

interface IsProjectAdministratorProps {
	children: React.ReactNode;
}

const IsProjectAdministrator: React.FC<IsProjectAdministratorProps> = ({ children }) => {
	const project = useSelector<State, SimpleProject | undefined>((state) => state.project.project);

	if (!project?.hasAdminPrivileges) {
		return null;
	}

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{children}</>;
};

export default IsProjectAdministrator;
