import useTrackKaasEvents from '@/kaas/useTrackKaasEvents';

import useKaasContext from './useKaasContext';

const useKaasPropertyAssist = (propertyId: string) => {
	const { answers } = useKaasContext();
	const { trackKaasAcceptedAnswer } = useTrackKaasEvents();

	return {
		suggestedAnswers: answers[propertyId] || undefined,
		trackKaasAcceptedAnswer: (answer: string) =>
			trackKaasAcceptedAnswer(
				propertyId,
				answers[propertyId].question,
				answers[propertyId].questionType,
				answer,
			),
	};
};

export default useKaasPropertyAssist;
