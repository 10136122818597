import { AssetType, Choice, Property, PropertyType, UUID } from '@/store/types';
import csvTemplates from '@/pages/csvTemplates.json';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

function toUnicodeBlob(text: string): Blob {
	return new Blob(
		[
			new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8 BOM
			text,
		],
		{ type: 'text/plain;charset=utf-8' },
	);
}

function propertyTypeSupported(propertyType: PropertyType): boolean {
	return [
		PropertyType.STRING,
		PropertyType.DECIMAL,
		PropertyType.SINGLE_SELECT,
		PropertyType.MULTI_SELECT,
		PropertyType.SINGLE_SUBSTANCE,
		PropertyType.MULTI_SUBSTANCE,
	].includes(propertyType);
}

function getPropertyTypeString(property: Property): string {
	const typeStrings: Record<string, string> = {
		[PropertyType.RICH_TEXT]: 'Rich text',
		[PropertyType.STRING]: 'Text',
		[PropertyType.DECIMAL]: 'Number',
		[PropertyType.SINGLE_SELECT]: 'Single select',
		[PropertyType.MULTI_SELECT]: 'Multiple select',
		[PropertyType.SINGLE_SUBSTANCE]: 'Single substance',
		[PropertyType.MULTI_SUBSTANCE]: 'Multi substance',
	};
	const returnString: string | undefined = typeStrings[property.type];
	if (returnString) {
		return returnString;
	}
	throw Error('Invalid property type encountered.');
}

function getChoicesCsv(
	propertyIds: string[],
	properties: Record<string, Property>,
	choices: Record<string, Choice>,
	choicesSectionHeight: number,
): string {
	let choicesCsv = '';
	for (let rowIndex = 0; rowIndex < choicesSectionHeight; rowIndex += 1) {
		const choicesRowArray = propertyIds.map((id) =>
			rowIndex < properties[id].choiceIds.length
				? choices[properties[id].choiceIds[rowIndex]].name
				: '',
		);
		choicesCsv += `${choicesRowArray.join(';')}\n`;
	}
	return choicesCsv;
}

function getFilteredPropertyIds(
	assetType: AssetType,
	properties: Record<string, Property>,
): UUID[] {
	return assetType.propertyIds.filter((propertyId) => {
		const property = properties[propertyId];
		return property.survey && !property.fixed && propertyTypeSupported(property.type);
	});
}

// eslint-disable-next-line import/prefer-default-export
export function getCsvTemplates(
	assetTypes: AssetType[],
	properties: Record<string, Property>,
	choices: Record<string, Choice>,
	featureMilicense: boolean,
): void {
	const zip = new JSZip();
	if (featureMilicense) {
		zip.file('substance.csv', toUnicodeBlob(csvTemplates.substance));
		zip.file('tank.csv', toUnicodeBlob(csvTemplates.tank));
	}
	assetTypes.forEach((assetType) => {
		const propertyIds = getFilteredPropertyIds(assetType, properties);
		if (propertyIds.length === 0) return;
		const propertyNames = propertyIds.map((propertyId) => properties[propertyId].name);
		const propertyNamesCsv = `${propertyNames.join(';')}\n`;
		const propertyTypes = propertyIds.map((propertyId) =>
			getPropertyTypeString(properties[propertyId]),
		);
		const propertyTypesCsv = `${propertyTypes.join(';')}\n`;
		const propertyExplanations = propertyIds.map((propertyId) =>
			properties[propertyId].description?.replace(/\r\n|\r|\n/g, ' '),
		);
		const propertyExplanationsCsv = `${propertyExplanations.join(';')}\n`;
		const choicesSectionHeight = Math.max(
			...propertyIds.map((id) => properties[id]).map((prop) => prop.choiceIds.length),
		);
		const choicesCsv = getChoicesCsv(propertyIds, properties, choices, choicesSectionHeight);

		const printPropertyExplanations =
			propertyExplanations.filter((el) => el !== null && el !== '').length > 0;
		const printChoices = choicesSectionHeight > 0;
		let skip = 14;
		if (printPropertyExplanations) skip += 3;
		if (printChoices) skip += 2;

		let csvContent = `Please do not modify the contents of the first ${
			choicesSectionHeight + skip
		} rows of this file.\n`;
		csvContent += 'Enter multi-select values in a single cell, separated by commas.\n';
		csvContent +=
			'To fix column widths: select all cells, use Format -> AutoFit column width, and readjust ' +
			'columns that are too wide.\n\n';
		csvContent += `Asset type name: ${assetType.name}\n\n`;
		csvContent += `Property names:\n${propertyNamesCsv}\n`;
		csvContent += `Property types:\n${propertyTypesCsv}\n`;
		if (printPropertyExplanations)
			csvContent += `Property explanations:\n${propertyExplanationsCsv}\n`;
		if (printChoices) csvContent += `Choices:\n${choicesCsv}\n`;
		csvContent += 'Fill in data under next row.\n';
		csvContent += propertyNamesCsv;
		const blob = toUnicodeBlob(csvContent);
		zip.file(`${assetType.name}.csv`, blob);
	});
	zip.generateAsync({ type: 'blob' }).then((content) => saveAs(content, 'csv-templates.zip'));
}
