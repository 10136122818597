import { ApiInspectionDto, ApiUserDto } from 'kes-common';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { inspectionRoleDelete } from '../../api';

const useActivityMemberRemove = (
	inspectionId: ApiInspectionDto['id'],
	userId: ApiUserDto['id'],
	options?: UseMutationOptions,
) =>
	useMutation(
		() =>
			inspectionRoleDelete(inspectionId, { roles: ['INSPECTION_EDITOR'], userId }).then(
				(response) => response.expectSuccess(),
			),
		options,
	);
export default useActivityMemberRemove;
