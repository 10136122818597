import { Box, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as Styled from '@/components/Question/Types/Location/Location.styles';
import SearchResult from '@/components/Question/Types/Location/SearchResult';
import { GeocodeFeature } from '@mapbox/mapbox-sdk/services/geocoding';
import { useDebounced } from 'kes-common';
import mapboxApi from '@/components/Question/Types/Location/mapboxApi';
import CloseIcon from '@mui/icons-material/Close';
import { Bounds } from '@/components/Question/Types/Location/MapModal';

const Search: React.FC<{
	onClickedLocation: (location: GeocodeFeature) => void;
	viewport: Bounds;
}> = ({ onClickedLocation, viewport }) => {
	const [search, setSearch] = useState('');
	const [results, setResults] = useState<GeocodeFeature[]>([]);

	const getResults = async (keyword: string) => {
		if (keyword) {
			const result = await mapboxApi.search(keyword, {
				lat: viewport.latitude,
				lng: viewport.longitude,
			});
			setResults(result);
		}
	};

	const debouncedOnUpdate = useDebounced(search, 1000);

	useEffect(() => {
		getResults(debouncedOnUpdate);
	}, [debouncedOnUpdate]);

	useEffect(() => {
		if (!search) {
			setResults([]);
		}
	}, [search]);

	return (
		<Box style={{ zIndex: 20 }}>
			<Box px={1} pt={2} pb={1}>
				<Styled.TextField
					size="small"
					InputProps={{
						endAdornment: (
							<Styled.InputAdornment position="end">
								<IconButton
									focusRipple={false}
									centerRipple={false}
									disableRipple
									onClick={() => setSearch('')}
									edge="end"
									size="large"
								>
									<CloseIcon />
								</IconButton>
							</Styled.InputAdornment>
						),
					}}
					value={search}
					fullWidth
					variant="outlined"
					label="Search"
					onChange={(e) => setSearch(e.target.value)}
				/>
			</Box>

			{results.length > 0 && (
				<Styled.LocationListHeadingContainer px={3}>
					<Styled.LocationListHeading variant="body2" color="primary">
						SEARCH RESULTS
					</Styled.LocationListHeading>
				</Styled.LocationListHeadingContainer>
			)}
			{results.map((locationResult) => (
				<SearchResult onClick={onClickedLocation} {...locationResult} />
			))}
		</Box>
	);
};

export default Search;
