import Alert from '@mui/material/Alert';
import List from '@mui/material/List';
import { ApiFile } from 'kes-common';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import useInspectionAssetPropertyRelatedFileDelete from '@/net/reactQuery/mutations/useInspectionAssetPropertyRelatedFileDelete';
import useInspectionAssetPropertyRelatedFileUpload from '@/net/reactQuery/mutations/useInspectionAssetPropertyRelatedFileUpload';
import useInspectionPropertyRelatedFileDelete from '@/net/reactQuery/mutations/useInspectionPropertyRelatedFileDelete';
import useInspectionPropertyRelatedFileUpload from '@/net/reactQuery/mutations/useInspectionPropertyRelatedFileUpload';
import { getAllRelatedFiles } from '@/selectors/files';
import { getAllRelatedFilesForProperty } from '@/selectors/properties';
import * as actions from '@/store/actions';
import { Asset, AssetType, Property } from '@/store/types';

import RelatedFile from './RelatedFile';

interface FilesListProps {
	assetId: Asset['id'] | null;
	assetType: AssetType;
	propertyId: Property['id'];
}

const FilesList: React.FC<FilesListProps> = ({ assetId, assetType, propertyId }) => {
	const dispatch = useDispatch();
	const { inspectionId } = useParams<Record<string, string>>();

	const allRelatedFiles = useSelector(getAllRelatedFiles);
	const propertyRelatedFiles = useSelector(
		assetId === null ? () => undefined : getAllRelatedFilesForProperty(assetId, propertyId),
	);

	const assetPropertyRelatedFileDeleteMutation = useInspectionAssetPropertyRelatedFileDelete(
		inspectionId,
		assetId || '',
		propertyId,
	);
	const assetPropertyRelatedFileMutation = useInspectionAssetPropertyRelatedFileUpload(
		inspectionId,
		assetId || '',
		propertyId,
	);
	const propertyRelatedFileDeleteMutation = useInspectionPropertyRelatedFileDelete(
		inspectionId,
		propertyId,
	);
	const propertyRelatedFileMutation = useInspectionPropertyRelatedFileUpload(
		inspectionId,
		propertyId,
	);

	const relatedFileDelete = React.useCallback(
		async (file: ApiFile) => {
			if (
				propertyRelatedFiles &&
				propertyRelatedFiles.some(
					(propertyRelatedFile) => propertyRelatedFile.fileId === file.fileId,
				)
			) {
				if (assetType.repeating) {
					await assetPropertyRelatedFileDeleteMutation.mutateAsync(file.fileId);
					dispatch(
						actions.inspectionAssetPropertyRelatedFileDelete({ assetId: assetId!, propertyId }),
					);
				} else {
					await propertyRelatedFileDeleteMutation.mutateAsync(file.fileId);
					dispatch(
						actions.inspectionPropertyRelatedFileDelete({
							assetId: assetId!,
							fileId: file.fileId,
							propertyId,
						}),
					);
				}
			}
		},
		[
			assetId,
			assetPropertyRelatedFileDeleteMutation,
			assetType,
			dispatch,
			propertyId,
			propertyRelatedFiles,
			propertyRelatedFileDeleteMutation,
		],
	);

	const relatedFileLink = React.useCallback(
		(relatedFile: ApiFile) => {
			if (assetType.repeating) {
				assetPropertyRelatedFileMutation.mutate({
					file: null,
					fileId: relatedFile.fileId,
				});
				dispatch(
					actions.inspectionAssetPropertyRelatedFileUpload({
						assetId: assetId!,
						file: relatedFile,
						propertyId,
					}),
				);
			} else {
				propertyRelatedFileMutation.mutate(
					{ file: null, fileId: relatedFile.fileId },
					{
						onSuccess: (responseAssetId) => {
							if (assetId !== responseAssetId) {
								dispatch(
									actions.assetCreate({
										assetId: responseAssetId,
										assetTypeId: assetType.id,
									}),
								);
							}
							dispatch(
								actions.inspectionPropertyRelatedFileUpload({
									assetId: responseAssetId,
									file: relatedFile,
									propertyId,
								}),
							);
						},
					},
				);
			}
		},
		[
			assetPropertyRelatedFileMutation,
			assetType,
			dispatch,
			propertyId,
			propertyRelatedFileMutation,
		],
	);

	const onCheckboxChange = React.useCallback(
		async (relatedFile: ApiFile, checked: boolean) => {
			await relatedFileDelete(relatedFile);
			if (checked) {
				relatedFileLink(relatedFile);
			}
		},
		[relatedFileDelete, relatedFileLink],
	);

	const isDisabled = React.useMemo(
		() =>
			assetPropertyRelatedFileDeleteMutation.isLoading ||
			assetPropertyRelatedFileMutation.isLoading ||
			propertyRelatedFileDeleteMutation.isLoading ||
			propertyRelatedFileMutation.isLoading,
		[
			assetPropertyRelatedFileDeleteMutation,
			assetPropertyRelatedFileMutation,
			propertyRelatedFileDeleteMutation,
			propertyRelatedFileMutation,
		],
	);

	return (
		<List>
			{allRelatedFiles.length === 0 && <Alert severity="info">There are currently no files.</Alert>}
			{allRelatedFiles.length > 0 &&
				allRelatedFiles.map((relatedFile) => (
					<RelatedFile
						isDisabled={isDisabled}
						key={relatedFile.fileId}
						onCheckboxChange={onCheckboxChange}
						propertyRelatedFiles={propertyRelatedFiles}
						relatedFile={relatedFile}
					/>
				))}
		</List>
	);
};

export default FilesList;
