import React from 'react';
import { useParams } from 'react-router-dom';

import { IconButton } from '@mui/material';
import { Download } from '@mui/icons-material';

import useInspectionRelatedFileGet from '@/net/reactQuery/mutations/useInspectionRelatedFileGet';
import { downloadBlob } from 'kes-common';

interface Props {
	fileName: string;
	fileId: string;
}

const DownloadButton: React.FC<Props> = ({ fileName, fileId }) => {
	const { inspectionId } = useParams<Record<string, string>>();

	const inspectionPropertyRelatedFileGetMutation = useInspectionRelatedFileGet(
		inspectionId,
		fileId,
		{
			onSuccess: async (response) => {
				const blob = await response.blob();
				downloadBlob(blob, fileName);
			},
		},
	);

	return (
		<IconButton
			disabled={inspectionPropertyRelatedFileGetMutation.isLoading}
			onClick={() => inspectionPropertyRelatedFileGetMutation.mutate()}
			edge="end"
		>
			<Download />
		</IconButton>
	);
};

export default DownloadButton;
