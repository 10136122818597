import Typography from '@mui/material/Typography';
import React from 'react';

import TextWithBreaks from '../TextWithBreaks';
import * as Styled from './ExpandableText.styled';

const ExpandableText: React.FC<{ maxChars?: number; text: string }> = ({
	maxChars = 400,
	text,
}) => {
	const [expandText, setExpandText] = React.useState(false);
	const formattedText =
		!expandText && text.length > maxChars ? `${text.substr(0, maxChars)}...` : text;

	return (
		<Typography variant="body1">
			<TextWithBreaks text={formattedText} />
			{text.length > maxChars && (
				<Styled.ShowMoreText onClick={() => setExpandText((prevExpanded) => !prevExpanded)}>
					{expandText ? 'See less' : 'See more'}
				</Styled.ShowMoreText>
			)}
		</Typography>
	);
};

export default ExpandableText;
