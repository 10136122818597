import { SagaIterator } from '@redux-saga/core';
import { call, fork, put, retry, take, takeEvery } from 'redux-saga/effects';
import { ApiError, ApiProjectAPIError } from 'kes-common';
import { Result } from '@/store/utils';
import * as actions from '@/store/actions';
import {
	projectCreate,
	projectDelete,
	projectDetailsUpdate,
	projectGetWithid,
	projectOverview,
} from '@/net/api';
import { MendixUnavailableError, ProjectId } from '@/store/types';
import { project, buildRoute } from '@/routes';

export function* loadProjectOverview(): SagaIterator {
	try {
		const response: Result<typeof projectOverview> = yield call(projectOverview);
		yield put(actions.projectOverviewSuccess(response.expectSuccess()));
	} catch (e) {
		yield put(actions.projectFatalError(e as Error));
	}
}

export function* createProject(action: ReturnType<typeof actions.projectCreate>): SagaIterator {
	try {
		const { history } = action.payload;
		const response: Result<typeof projectCreate> = yield call(
			projectCreate,
			action.payload.createProject,
		);

		if (response.status !== 200) yield put(actions.projectCreateError(response.result));
		else {
			yield put(actions.projectCreateSuccess(response.result));
			history.push({
				pathname: buildRoute(project, { projectId: response.result.project.id }),
				search: 'openDrawer=true',
			});
		}
	} catch (e) {
		yield put(actions.projectFatalError(e as Error));
	}
}

export function* updateProject(action: ReturnType<typeof actions.projectUpdate>): SagaIterator {
	try {
		const { history } = action.payload;
		const response: Result<typeof projectDetailsUpdate> = yield call(
			projectDetailsUpdate,
			action.payload.projectId,
			action.payload.project,
		);

		if (response.status === 409) {
			yield put(actions.projectUpdateError(response.result as ApiProjectAPIError));
		} else if (response.status !== 204) {
			yield put(actions.projectUpdateError(response.result as ApiError));
		} else {
			yield put(
				actions.projectUpdateSuccess(
					response.expectSuccess() && {
						projectId: action.payload.projectId,
						projectDetails: action.payload.project,
					},
				),
			);
			history.push(action.payload.redirectRoute);
			if (action.payload.onComplete) {
				action.payload.onComplete();
			}
		}
	} catch (e) {
		yield put(actions.projectFatalError(e as Error));
	}
}

export function* deleteProject(action: ReturnType<typeof actions.projectDelete>): SagaIterator {
	try {
		const projectId = action.payload;
		const response: Result<typeof projectDelete> = yield call(projectDelete, projectId);
		yield put(actions.projectDeleteSuccess(response.expectSuccess() && projectId));
	} catch (e) {
		yield put(actions.projectFatalError(e as Error));
	}
}

export function* loadProject(projectId: ProjectId): SagaIterator {
	yield put(actions.projectLoad(projectId));
	while (true) {
		try {
			const response: Result<typeof projectGetWithid> = yield retry(
				2,
				100,
				projectGetWithid,
				projectId,
			);
			if (response.status === 503) throw new MendixUnavailableError(response.result.message);
			else yield put(actions.projectLoadSuccess(response.expectSuccess()));
		} catch (e) {
			yield put(actions.projectFatalError(e as Error));
		}
		yield take(actions.projectLoadRefresh);
	}
}

function* projectSaga(projectId: ProjectId | null): SagaIterator {
	yield takeEvery(actions.projectCreate, createProject);
	yield takeEvery(actions.projectUpdate, updateProject);
	yield takeEvery(actions.projectDelete, deleteProject);

	if (projectId === null) {
		// This saga does not work on the project overview page
		return;
	}

	yield fork(loadProject, projectId);
}

export default projectSaga;
