import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { ApiStudyDto } from 'kes-common';
import { useSnackbar } from 'notistack';
import React from 'react';

import TemplatePublishDialog from '@/components/TemplatePublishDialog';
import { useTemplates } from '@/net/reactQuery/queries';
import { dateFormatShort } from '@/utils/dateFormat';

interface VersionProps {
	template: ApiStudyDto;
}

const Version: React.FC<VersionProps> = ({ template }) => {
	const [isTemplatePublishDialogOpen, setIsTemplatePublishDialogOpen] =
		React.useState<boolean>(false);

	const { enqueueSnackbar } = useSnackbar();
	const { refetch } = useTemplates();

	const onTemplatePublished = React.useCallback(() => {
		enqueueSnackbar('Template published successfully', {
			anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
		});
		setIsTemplatePublishDialogOpen(false);
		refetch();
	}, [enqueueSnackbar, refetch, setIsTemplatePublishDialogOpen]);

	return (
		<>
			<Typography gutterBottom variant="body1">
				{template.draftVersion === 1 && (
					<span>
						Not published yet.{' '}
						{template.type === 'CLASSIC' && (
							<Tooltip title="Publish template, making it available for others to use">
								{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
								<Link
									onClick={() => setIsTemplatePublishDialogOpen(true)}
									sx={{ cursor: 'pointer' }}
								>
									Publish?
								</Link>
							</Tooltip>
						)}
					</span>
				)}
				{template.draftVersion > 1 && (
					<>
						<span>
							Version: {template.draftVersion}.{' '}
							{template.type === 'CLASSIC' && (
								<Tooltip title="Publish template, making it available for others to use">
									{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
									<Link
										onClick={() => setIsTemplatePublishDialogOpen(true)}
										sx={{ cursor: 'pointer' }}
									>
										Publish.
									</Link>
								</Tooltip>
							)}
						</span>
						{template.lastPublishedDate && (
							<>
								<br />
								Last Published: {dateFormatShort(new Date(template.lastPublishedDate))}
							</>
						)}
					</>
				)}
			</Typography>
			<TemplatePublishDialog
				isOpen={isTemplatePublishDialogOpen}
				onClose={() => setIsTemplatePublishDialogOpen(false)}
				onSuccess={onTemplatePublished}
				template={template}
			/>
		</>
	);
};

export default Version;
