import { OrderedState, UnorderedState } from '@/store/repository';
import {
	ApiActionDto,
	ApiAuthorizedInspectionDto,
	ApiAuthorizedProjectDto,
	ApiError,
	ApiFile,
	ApiFlag,
	ApiInspectionDto,
	ApiProjectAPIError,
	ApiRuleSetDto,
} from 'kes-common';
import {
	SimpleProject,
	ProjectId,
	Category,
	AssetType,
	Property,
	Choice,
	Asset,
	AssetList,
	UUID,
	User,
} from './types';

export interface StateRouter {
	path: string;
	params: Record<string, string>;
	pageTitle: string;
}

export interface StateFileUploader {
	state:
		| 'disabled'
		| 'empty'
		| 'uploading'
		| 'done'
		| 'error'
		| 'locked'
		| 'deleting'
		| 'delete_error';
	error: string[];
	filename: string;
	scrollTo: string | undefined;
}

export interface StateFileStorage {
	substances: StateFileUploader;
	tanks: StateFileUploader;
	assetTypes: Record<string, StateFileUploader>;
}

export interface StateMyInspections {
	error?: string;
	inspections: ApiAuthorizedInspectionDto[];
	loaded: boolean;
}

export interface StateProject {
	currentProject: ProjectId;
	project?: SimpleProject;
	loaded: boolean;
	state: 'ready' | 'error';
	error: string | null;
	errorFull: ApiError | ApiProjectAPIError | null;
	fatal: boolean;
	projects: ApiAuthorizedProjectDto[];
	projectsLoaded: boolean;
}

export interface StateInspection {
	currentInspection?: ApiInspectionDto;
	loaded: boolean;
	error: string | null;
}

export interface StateUI {
	targetAssetTypeId?: UUID | null;
	relationshipPropertyId?: UUID | null;
	targetAssetId?: UUID | null;
}

export interface StateUser {
	currentUser?: User;
}

export interface AuthState {
	currentUser?: {};
}
export default interface State {
	actions: UnorderedState<ApiActionDto>;
	files: ApiFile[];
	flags: UnorderedState<ApiFlag>;
	ruleSets: UnorderedState<ApiRuleSetDto>;
	categories: OrderedState<Category>;
	assetTypes: UnorderedState<AssetType>;
	properties: UnorderedState<Property>;
	choices: UnorderedState<Choice>;
	assets: UnorderedState<Asset>;
	assetLists: UnorderedState<AssetList>;
	router: StateRouter;
	substanceStorage: StateFileStorage;
	myInspections: StateMyInspections;
	project: StateProject;
	inspection: StateInspection;
	user: StateUser;
	ui: StateUI;
	changes: StateChanges;
}
export enum ChangesStatus {
	saving = 'saving',
	done = 'done',
}
export interface StateChanges {
	status: ChangesStatus;
	savedAt?: Date | null;
}
