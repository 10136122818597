import CircularProgress from '@mui/material/CircularProgress';
import Hidden from '@mui/material/Hidden';
import Icon from '@mui/material/Icon';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import State, { ChangesStatus } from '@/store/state';

import classes from './ChangesIndicator.module.css';

const ChangesIndicator: FC = (): JSX.Element => {
	const [ready, setReady] = useState(false);
	let status = null;

	const statusState = useSelector<State, ChangesStatus>((state) => state.changes.status);
	const savedAt = useSelector<State, Date | null | undefined>((state) => state.changes.savedAt);

	useEffect(() => {
		const timer = setTimeout(() => {
			setReady(true);
		}, 1500);
		return () => {
			setReady(false);
			clearTimeout(timer);
		};
	}, [statusState]);

	switch (statusState) {
		case ChangesStatus.done:
			if (!ready && savedAt)
				status = (
					<div data-testid="changes-indicator-saving-done" className={classes.saving}>
						<Icon color="secondary" className={classes.progress}>
							done
						</Icon>
						<p className={classes.statusText}>Saved</p>
					</div>
				);
			else
				status = (
					<>
						<p data-testid="changes-indicator-saving-done" className={classes.statusText}>
							<Hidden mdUp>Saved</Hidden>
							<Hidden smDown>All changes saved</Hidden>
						</p>
						{savedAt !== null && (
							<p data-testid="changes-indicator-saving-done-date" className={classes.savedText}>
								<Hidden smDown>Last updated </Hidden>
								{new Intl.DateTimeFormat('en-GB', {
									hour: '2-digit',
									minute: '2-digit',
								}).format(savedAt)}
							</p>
						)}
					</>
				);
			break;
		case ChangesStatus.saving:
			status = (
				<div data-testid="changes-indicator-saving" className={classes.saving}>
					<CircularProgress color="secondary" size={20} className={classes.progress} />
					<p className={classes.statusText}>Saving</p>
				</div>
			);
			break;
		default:
			return <p>Something went wrong</p>;
	}

	return <div className={classes.changes}>{status}</div>;
};

export default ChangesIndicator;
