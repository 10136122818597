import TextField from '@mui/material/TextField';
import React from 'react';

import { Field } from './types';

export const toFixed = (value: string): string => {
	if (value.includes('.')) {
		const [beforeSeparator, afterSeparator] = value.split('.');
		const before = beforeSeparator.substr(0, 25);
		const after = afterSeparator.substr(0, 12).replace(/0+$/, '');
		return `${before}.${after}`;
	}
	return value.substr(0, 25);
};

const Decimal: React.FC<Field> = ({
	'data-testid': dataTestId,
	property,
	setFieldValue,
	value = '',
}) => {
	const [localValue, setLocalValue] = React.useState<string>(value);
	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const newValue = toFixed(event.target.value);
			setLocalValue(newValue);
			setFieldValue(newValue);
		},
		[setFieldValue],
	);

	const handleKeyPress = React.useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
		if (!/[0-9.]/.test(event.key)) {
			event.preventDefault();
		}
	}, []);

	return (
		<TextField
			data-testid={dataTestId}
			fullWidth
			name={property.name}
			onChange={handleChange}
			onKeyPress={handleKeyPress}
			placeholder={property.name}
			value={localValue}
			variant="standard"
		/>
	);
};

export default Decimal;
