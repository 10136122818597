import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { ApiFile } from 'kes-common';
import React from 'react';
import { useSelector } from 'react-redux';

import { getPropertiesForFile } from '@/selectors/properties';

import ContextMenu from './ContextMenu';

interface RelatedFileProps {
	relatedFile: ApiFile;
}

const RelatedFile: React.FC<RelatedFileProps> = ({ relatedFile }) => {
	const properties = useSelector(getPropertiesForFile(relatedFile.fileId));

	const secondaryText = React.useMemo(() => {
		if (properties.length === 0) {
			return 'None';
		}
		const property = properties[0];
		let returnText: string = property.question || property.name;
		if (properties.length > 1) {
			returnText += ` and ${properties.length - 1} more`;
		}
		return returnText;
	}, [properties]);

	return (
		<ListItem
			disablePadding
			secondaryAction={<ContextMenu properties={properties} relatedFile={relatedFile} />}
		>
			<ListItemButton>
				<ListItemText primary={relatedFile.name} secondary={secondaryText} />
			</ListItemButton>
		</ListItem>
	);
};

export default RelatedFile;
