import CheckIcon from '@mui/icons-material/Check';
import Button from '@mui/material/Button';
import { ApiInspectionDto, ApiProjectDto } from 'kes-common';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { inspectionComplete } from '@/net/api';
import { buildRoute, project } from '@/routes';
import { surveyAllRequiredFieldsCompleted } from '@/selectors';

interface ButtonInspectionCompleteProps {
	inspectionId: ApiInspectionDto['id'];
	projectId: ApiProjectDto['id'];
}

const ButtonInspectionComplete: React.FC<ButtonInspectionCompleteProps> = ({
	inspectionId,
	projectId,
}) => {
	const [isCompleting, setIsCompleting] = React.useState<boolean>(false);

	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const allRequiredFieldsCompleted = useSelector(surveyAllRequiredFieldsCompleted);

	const { isLoading, refetch } = useQuery(
		['set-inspection-complete', inspectionId],
		() => inspectionComplete(inspectionId),
		{
			enabled: isCompleting,
			onError: () => {
				enqueueSnackbar('Could not mark activity as complete.', { variant: 'error' });
			},
			onSuccess: (response) => {
				switch (response.status) {
					case 204:
						history.push(buildRoute(project, { projectId }));
						break;
					case 400:
						enqueueSnackbar('Please fill in all required fields.', { variant: 'warning' });
						break;
					default:
						enqueueSnackbar('Could not mark activity as complete.', { variant: 'error' });
						break;
				}
			},
		},
	);

	const onClick = React.useCallback(() => {
		if (isCompleting) {
			refetch();
		} else {
			setIsCompleting(true);
		}
	}, [isCompleting, refetch, setIsCompleting]);

	return (
		<Button
			color="secondary"
			disabled={!allRequiredFieldsCompleted || (isLoading && isCompleting)}
			onClick={onClick}
			startIcon={<CheckIcon />}
			fullWidth
			variant="contained"
		>
			Complete activity
		</Button>
	);
};

export default ButtonInspectionComplete;
