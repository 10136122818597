import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { ApiStudyDto, NameAvatar } from 'kes-common';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { buildRoute, userProfile } from '@/routes';

import RemoveButton from './RemoveButton';
import RoleDropdown from './RoleDropdown';

interface UsersProps {
	onUserRemoved(userId: string): void;
	template: ApiStudyDto;
}

const Users: React.FC<UsersProps> = ({ onUserRemoved, template }) => (
	<List>
		{template.users.map((user) => (
			<ListItem key={user.userId}>
				<Grid alignItems="center" container spacing={2}>
					<Grid item xs={1}>
						<NameAvatar isEmail={!user.name} name={user.name || user.email} />
					</Grid>
					<Grid item xs={5}>
						<Link
							color="primary"
							component={RouterLink}
							to={buildRoute(userProfile, { userId: user.userId })}
							underline="none"
							variant="body1"
						>
							{user.name || user.email}
						</Link>
						<Typography variant="body2">{user.name ? user.email : ''}</Typography>
					</Grid>
					<Grid item xs={4}>
						<RoleDropdown template={template} user={user} />
					</Grid>
					<Grid item xs={2}>
						<RemoveButton onSuccess={onUserRemoved} template={template} user={user} />
					</Grid>
				</Grid>
			</ListItem>
		))}
	</List>
);

export default Users;
