import { ApiInspectionDto, ApiRunResponse, ApiScript } from 'kes-common';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { inspectionScriptFileRun } from '../../api';

const useInspectionScriptFileRun = (
	inspectionId: ApiInspectionDto['id'],
	options?: UseMutationOptions<ApiRunResponse, unknown, ApiScript>,
) =>
	useMutation(
		(script) =>
			inspectionScriptFileRun(inspectionId, script).then((response) => response.expectSuccess()),
		options,
	);

export default useInspectionScriptFileRun;
