import MUIButton from '@mui/material/Button';
import MUIList from '@mui/material/List';
import styled from 'styled-components';

export const Button = styled(MUIButton)`
	justify-content: flex-start;
	text-transform: none;
`;

export const List = styled(MUIList)`
	width: 250px;
`;
