import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ApiProjectDetailsDto, ApiProjectUpdateDto, PageTitle } from 'kes-common';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useProjectStateError, {
	ProjectCreateErrorType,
} from '@/components/hooks/useProjectStateError';
import ClientForm from '@/components/ProjectForms/ClientForm';
import ProjectForm from '@/components/ProjectForms/ProjectDetailsForm';
import ProjectOptions from '@/components/ProjectOptions';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import useQueryString from '@/hooks/useQueryString';
import { buildRoute, project as projectRoute, projectSettings } from '@/routes';
import { projectUpdate } from '@/store/actions';

import { useProject } from '../context';

const ProjectInformation: React.FC = (): JSX.Element => {
	const history = useHistory();
	const { project, refetchProject } = useProject();
	const queryString = useQueryString();
	const backRoute = buildRoute(projectSettings, { projectId: project.id });

	const { error, errorDetails, fatal } = useProjectStateError();

	const [projectDetails, setProjectDetails] = React.useState<ApiProjectDetailsDto>(
		project.projectDetails,
	);
	const [tabValue, setTabValue] = React.useState(0);
	const dispatch = useDispatch();

	const back = () => {
		history.push(backRoute);
	};

	const handleTabChange = (event: React.ChangeEvent<any>, newValue: number) => {
		setTabValue(newValue);
	};

	const submitProjectDetails = () => {
		const updatedProjectDetails: ApiProjectUpdateDto = {
			name: projectDetails.name,
			projectNumber: projectDetails.projectNumber,
			location: projectDetails.location,
			client: projectDetails.client,
			masterProjectId: projectDetails.masterProjectId,
			templates: [],
			admins: [],
		};
		dispatch(
			projectUpdate({
				history,
				onComplete: refetchProject,
				projectId: project.id,
				project: updatedProjectDetails,
				redirectRoute: backRoute,
			}),
		);
	};

	const tabName = React.useMemo(() => {
		const tabNames = ['General information', 'Client information', 'Report options'];
		return tabNames[tabValue];
	}, [tabValue]);

	useDocumentTitle('Edit', project.testProject ? 'Preview' : project.projectDetails.name!, tabName);

	return (
		<>
			<PageTitle
				breadcrumbs={[
					{ title: 'Projects', url: '/' },
					{ title: projectDetails.name, url: buildRoute(projectRoute, { projectId: project.id }) },
					{
						title: 'Project settings',
						url: buildRoute(projectSettings, { projectId: project.id }),
					},
					{ title: 'Edit' },
				]}
				title="Project settings"
			/>
			<Box marginTop={2} paddingBottom={4}>
				<Container maxWidth="sm">
					<Card>
						<CardContent>
							<Tabs
								value={tabValue}
								onChange={handleTabChange}
								centered
								indicatorColor="primary"
								variant="fullWidth"
							>
								<Tab label="General" />
								<Tab label="Client" />
								<Tab label="Report options" />
							</Tabs>
							{tabValue === 0 && (
								<ProjectForm
									isNameDialogOpen={queryString.get('editName') === 'true'}
									projectDetails={projectDetails}
									setProjectDetails={(pd) => {
										if (projectDetails) {
											setProjectDetails(() => ({
												abwLinked: pd.abwLinked,
												reportOptions: projectDetails.reportOptions,
												client: projectDetails.client,
												masterProjectId: pd.masterProjectId,
												projectNumber: pd.projectNumber,
												name: pd.name,
												location: pd.location,
												isTestProject: false,
											}));
										}
									}}
									onSubmit={submitProjectDetails}
									buttons={
										<Box display="flex" flexDirection="row" justifyContent="flex-end">
											<Button onClick={back}>Cancel</Button>
											<Box marginLeft={1}>
												<Button
													color="primary"
													data-testid="action-save"
													type="submit"
													variant="contained"
												>
													Save
												</Button>
											</Box>
										</Box>
									}
								/>
							)}
							{tabValue === 1 && (
								<ClientForm
									abwLinked={projectDetails.abwLinked}
									client={projectDetails.client}
									setClient={(client) => setProjectDetails({ ...projectDetails, client })}
									industries={project.industries}
									onSubmit={submitProjectDetails}
									buttons={
										<Box display="flex" flexDirection="row" justifyContent="flex-end">
											<Button onClick={back}>Cancel</Button>
											<Box marginLeft={1}>
												<Button
													color="primary"
													data-testid="action-save"
													type="submit"
													variant="contained"
												>
													Save
												</Button>
											</Box>
										</Box>
									}
								/>
							)}
							{tabValue === 2 && (
								<ProjectOptions
									projectReportOptions={projectDetails.reportOptions}
									projectId={project.id}
									back={back}
								/>
							)}
							{error &&
								errorDetails?.errorType === ProjectCreateErrorType.PROJECT_CREATE_ERROR &&
								!fatal && <Snackbar open message={error} />}
						</CardContent>
					</Card>
				</Container>
			</Box>
		</>
	);
};

export default ProjectInformation;
