import { styled } from '@mui/material/styles';

export const ButtonsContainer = styled('div')`
	align-self: flex-end;
	width: 100%;
`;

export const FormGroup = styled('div')`
	align-items: flex-start;
	display: flex;
	flex-flow: column;
	margin-top: ${({ theme }) => theme.spacing(4)};
	width: 100%;

	> * {
		margin-top: ${({ theme }) => theme.spacing(2)};
	}
`;

export const FormRow = styled('div')`
	display: flex;
	flex-flow: row;
	width: 100%;

	> *:not(:last-child) {
		margin-right: ${({ theme }) => theme.spacing(2)};
	}
`;
