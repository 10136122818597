import { StateProject as State, StateInspection } from '@/store/state';
import { SimpleProject, FeatureFlags } from '@/store/types';
import makeSlicer from '@/utils/makeSlicer';
import { ApiAuthorizedProjectDto } from 'kes-common';

const rootSlicer = makeSlicer<State>();

export function currentProject(state: State): SimpleProject | undefined {
	return state.project;
}

export function getAuthorizedProjectById(
	state: State,
	projectId: string,
): ApiAuthorizedProjectDto | undefined {
	return state.projects.find(
		(authorizedProject): boolean => authorizedProject.project.id === projectId,
	);
}

export function projects(state: State): ApiAuthorizedProjectDto[] {
	return state.projects;
}

export const projectsLoaded = (state: State): boolean => state.projectsLoaded;

export function getFeatureFlags(state: StateInspection): FeatureFlags {
	return {
		milicense: state.currentInspection?.assetLibrary.featureMilicense || false,
	};
}

export const loaded = rootSlicer('loaded');
