/* eslint-disable @typescript-eslint/no-empty-function */
import { useState, useEffect, Dispatch, SetStateAction } from 'react';

export const VOID_FUNCTION = (): void => {};

const EMPTY_FILE_LIST: DataTransferItemList = {
	length: 0,
	add(): never {
		throw new Error('This FileList is empty');
	},
	clear(): void {},
	remove(): void {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	*[Symbol.iterator](): IterableIterator<DataTransferItem> {},
};

export const getFileTypes = (accept: string) =>
	accept.split(',').map((acceptEntry) => acceptEntry.replace('.', '').replace(' ', ''));

function setUpEvents(
	onSelect: (file: File) => void,
	multiple: boolean,
	setDragging: (dragging: boolean, files: DataTransferItemList) => void,
	currentElement: Element | null,
	accept: string,
	setError: Dispatch<SetStateAction<string | undefined>>,
): () => void {
	let dragCounter = 0;
	const fileTypes = getFileTypes(accept);
	function handleDragIn(e: DragEvent): void {
		e.preventDefault();
		e.stopPropagation();
		dragCounter += 1;

		if (e.dataTransfer && e.dataTransfer.items && e.dataTransfer.items.length > 0) {
			setDragging(true, e.dataTransfer.items);
		}
	}
	function handleDragOut(e: DragEvent): void {
		e.preventDefault();
		e.stopPropagation();
		if (dragCounter > 0) {
			dragCounter -= 1;
			return;
		}
		dragCounter = 0;
		if (setDragging) {
			setDragging(false, EMPTY_FILE_LIST);
		}
	}
	function handleDrag(e: DragEvent): void {
		e.preventDefault();
		e.stopPropagation();
	}
	function handleDrop(event: DragEvent): void {
		event.preventDefault();
		event.stopPropagation();
		if (event.dataTransfer && event.dataTransfer.items && event.dataTransfer.items.length > 0) {
			// eslint-disable-next-line no-restricted-syntax
			for (const entry of event.dataTransfer.items) {
				const file = entry.getAsFile();
				if (file) {
					if (!fileTypes.some((fileType) => entry.type === fileType)) {
						setError('Not supported');
						break;
					}

					onSelect(file);
					setError(undefined);
					if (!multiple) {
						break;
					}
				}
			}
			try {
				// Try to clean up data, but ignore any errors if the browser doesn't support it.
				event.dataTransfer.clearData();
			} catch (error) {
				setError('Unknown');
			}
		}
		if (setDragging) {
			setDragging(false, EMPTY_FILE_LIST);
		}
		dragCounter = 0;
	}

	if (currentElement) {
		currentElement.addEventListener('dragenter', handleDragIn as EventListener);
		currentElement.addEventListener('dragleave', handleDragOut as EventListener);
		currentElement.addEventListener('dragover', handleDrag as EventListener);
		currentElement.addEventListener('drop', handleDrop as EventListener);
		return (): void => {
			currentElement.removeEventListener('dragenter', handleDragIn as EventListener);
			currentElement.removeEventListener('dragleave', handleDragOut as EventListener);
			currentElement.removeEventListener('dragover', handleDrag as EventListener);
			currentElement.removeEventListener('drop', handleDrop as EventListener);
		};
	}
	return VOID_FUNCTION;
}

export default function useDragAndDropListener(
	onSelect: (file: File) => void,
	fileType: string,
	setError: Dispatch<SetStateAction<string | undefined>>,
	multiple = true,
	setDragging: (dragging: boolean) => void = VOID_FUNCTION,
): { uploadRef: <T extends Element | null>(element: T) => void } {
	const [currentElement, setCurrentElement] = useState<Element | null>(null);

	useEffect(
		(): (() => void) =>
			setUpEvents(onSelect, multiple, setDragging, currentElement, fileType, setError),
		[onSelect, multiple, setDragging, currentElement],
	);
	return { uploadRef: setCurrentElement };
}
