import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { buildRoute, project as projectRoute } from '@/routes';

import { useUser } from '../../context';

const Projects: React.FC = () => {
	const { user } = useUser();

	const parseRole = React.useCallback((role: 'PROJECT_ADMINISTRATOR') => {
		const roleNames = { PROJECT_ADMINISTRATOR: 'Project administrator' };
		return roleNames[role];
	}, []);

	return (
		<Card>
			<CardContent sx={{ padding: 3 }}>
				<Typography variant="subtitle1">Projects</Typography>
				{user.recentProjects.map((project) => (
					<Box data-testid="user__profile__project" key={project.name} marginTop={2}>
						<Link
							component={RouterLink}
							color="primary.dark"
							data-testid="user__profile__project__name"
							display="block"
							noWrap
							textOverflow="ellipsis"
							to={buildRoute(projectRoute, { projectId: project.id })}
							underline="hover"
							variant="subtitle1"
						>
							{project.name}
						</Link>
						<Typography data-testid="user__profile__project__company-name">
							{project.companyName}
						</Typography>
						<Typography data-testid="user__profile__project__role">
							Role: {project.projectRoles.map(parseRole).join(', ')}
						</Typography>
						<Typography data-testid="user__profile__project__activities">
							Activities:{' '}
							{project.activityNames.length > 0 ? project.activityNames.join(', ') : '-'}
						</Typography>
					</Box>
				))}
			</CardContent>
		</Card>
	);
};

export default Projects;
