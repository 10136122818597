import { PropertyType } from '@/store/types';
import React, { FC } from 'react';
import { v4 as uuid } from 'uuid';
import String from '@/components/Question/Types/String';
import Decimal, { toFixed } from '@/components/Question/Types/Decimal';
import AddExtraButton from '@/components/rhdhv/AddExtraButton';
import useKaasPropertyAssist from '@/kaas/useKaasPropertyAssist';
import { usePropertyIdContext } from '@/kaas/PropertyIdContext';
import KaasSuggestionChips from '@/kaas/KaasSuggestionChips';

import * as Styled from './AnswerList.styled';

// Answers are saved in the db without an id. We need an id in the front-end to handle re-rendering when deleting items
// from the list. So, we add unique id's to the answers in this components.
interface AnswerWithId {
	answer: string;
	id: string;
}

interface Props {
	answer: string | undefined;
	onChange(payload: string | undefined): void;
	type: PropertyType;
	disabled?: boolean;

	// eslint-disable-next-line react/no-unused-prop-types
	kaas?: ReturnType<typeof useKaasPropertyAssist>;
}

// This is overkill, but it might be nice to create a file which included (tested) utility functions like this.
// We should discuss this with the whole team.
const updateValueOnIndex =
	<Value extends unknown>(indexToUpdate: number, newValue: Value) =>
	(list: Array<Value>): Array<Value> =>
		list.map((oldValue, index) => {
			if (index === indexToUpdate) return newValue;
			return oldValue;
		});

export const parseAnswerToArray = (answer: string | undefined): Array<AnswerWithId> => {
	if (!answer) return [{ answer: '', id: uuid() }];
	try {
		const parsedAnswer = JSON.parse(answer) as string[];
		const answersWithIds = parsedAnswer.map((answerWithId) => ({
			answer: answerWithId,
			id: uuid(),
		}));

		return answersWithIds.length ? answersWithIds : [{ answer: '', id: uuid() }];
	} catch (e) {
		return [{ answer, id: uuid() }];
	}
};

export const AnswerList: FC<Props> = ({ onChange, answer, type, disabled, kaas }) => {
	const [answers, setAnswers] = React.useState<Array<AnswerWithId>>(() =>
		parseAnswerToArray(answer),
	);
	const [lastAdded, setLastAdded] = React.useState<string>();

	React.useEffect(() => {
		if (answers && answers[0]) {
			const answersWithoutId = answers.map((answerWithoutId) => answerWithoutId.answer);
			const parsedAnswer = JSON.stringify(answersWithoutId);
			if (parsedAnswer !== answer) {
				onChange(parsedAnswer);
			}
		}
	}, [answers]);

	const addEmptyAnswer = () => {
		const id = uuid();
		setAnswers((prevAnswers) => [...prevAnswers, { answer: '', id }]);
		setLastAdded(id);
	};

	const removeAnswer = (indexToRemove: number) => {
		setAnswers((prevAnswer) => prevAnswer.filter((_, index) => index !== indexToRemove));
	};

	const updateValueInAnswersState = (index: number, answerToUpdate: AnswerWithId) => {
		setAnswers(updateValueOnIndex(index, answerToUpdate));
	};

	const addNewAnswer = (newAnswer: string) => {
		if (type === PropertyType.DECIMAL) {
			// eslint-disable-next-line no-param-reassign
			newAnswer = toFixed(newAnswer);
		}

		// if only 1 box and its not filled, fill it, otherwise add a new box and fill that box
		if (answers.length === 1 && answers[0].answer === '') {
			setAnswers([{ answer: newAnswer, id: answers[0].id }]);
		} else {
			const id = uuid();
			setAnswers((prevAnswers) => [...prevAnswers, { answer: newAnswer, id }]);
			setLastAdded(id);
		}
	};

	return (
		<>
			{answers.map((answerWithId, index) => (
				<Styled.QuestionWrapper key={answerWithId.id}>
					{type === PropertyType.DECIMAL && (
						<Decimal
							disabled={disabled}
							onChange={(newAnswer) =>
								updateValueInAnswersState(index, {
									...answerWithId,
									answer: newAnswer ? toFixed(newAnswer) : '',
								})
							}
							answer={answerWithId.answer}
							autoFocus={lastAdded === answerWithId.id}
						/>
					)}
					{type === PropertyType.STRING && (
						<String
							disabled={disabled}
							onChange={(newAnswer) =>
								updateValueInAnswersState(index, { ...answerWithId, answer: newAnswer ?? '' })
							}
							answer={answerWithId.answer}
							autoFocus={lastAdded === answerWithId.id}
						/>
					)}
					{answers.length > 1 && !disabled && (
						<Styled.CloseIcon onClick={() => removeAnswer(index)} data-testid="remove-answer" />
					)}
				</Styled.QuestionWrapper>
			))}

			{!disabled && (
				<AddExtraButton dataTestId="add-extra-button" onClick={addEmptyAnswer}>
					ADD ANOTHER ANSWER
				</AddExtraButton>
			)}

			<KaasSuggestionChips onChange={addNewAnswer} disabled={disabled} kaas={kaas} />
		</>
	);
};

export const AnswerListWithSuggestion: FC<Props> = ({
	onChange,
	answer,
	type,
	disabled,
}): JSX.Element => {
	const kaas = useKaasPropertyAssist(usePropertyIdContext());
	return (
		<AnswerList onChange={onChange} answer={answer} type={type} disabled={disabled} kaas={kaas} />
	);
};
