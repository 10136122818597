import React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import classes from './Note.module.css';

interface NoteProps {
	children: React.ReactNode;
	title: string;
	onOverflowClick: (target: (EventTarget & HTMLButtonElement) | null) => void;
	onClick: () => void;
}
const Note: React.FC<NoteProps> = ({ title, children, onOverflowClick, onClick }) => {
	const contextMenuButton = (): JSX.Element | undefined => (
		<IconButton
			className={classes.overflowButton}
			onClick={(event: React.MouseEvent<HTMLButtonElement>): void => {
				event.stopPropagation();
				onOverflowClick(event.currentTarget);
			}}
			aria-label="open-menu"
			color="inherit"
			size="large"
		>
			<MoreVertIcon />
		</IconButton>
	);
	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
		<div className={classes.container} onClick={onClick}>
			<div className={classes.topSection}>
				<h3 className={classes.noteTitle}>{title}</h3>
				<div className={classes.overflowButtonContainer}>{contextMenuButton()}</div>
			</div>

			<div className={classes.noteContent}>{children}</div>
		</div>
	);
};

export default Note;
