/* eslint-disable no-param-reassign */
import makeReducer from '@/store/makeReducer';
import { StateInspection } from '@/store/state';

import * as actions from '../actions';

const initialState: StateInspection = {
	loaded: false,
	error: null,
};

export default makeReducer(initialState, {
	[actions.inspectionExternalURLsUpdate.type](draft, payload): void {
		if (draft.currentInspection) {
			draft.currentInspection.urls = payload;
		}
	},
	[actions.inspectionLoad.type](draft): void {
		draft.loaded = false;
		draft.currentInspection = undefined;
	},
	[actions.inspectionLoadSuccess.type](draft, payload): void {
		draft.currentInspection = payload;
		draft.loaded = true;
	},
	[actions.inspectionDelete.type](draft): void {
		draft.error = null;
		draft.loaded = false;
	},
	[actions.inspectionDeleteSuccess.type](draft): void {
		draft.error = null;
		draft.loaded = true;
	},
	[actions.inspectionDeleteError.type](draft, payload): void {
		draft.error = payload.message;
		draft.loaded = true;
	},
});
