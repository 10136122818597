import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { ApiServiceDto } from 'kes-common';
import React from 'react';

import { useBusinessLines, useLabels, useServices } from '@/net/reactQuery/queries';

import { useFilters } from './context';
import { defaultFilters } from './context/context';

const Filters: React.FC = () => {
	const { data: businessLines } = useBusinessLines();
	const { data: labels } = useLabels();
	const { data: services } = useServices();

	const { clearFilters, filters, setFilterValue } = useFilters();

	const hasFilter = React.useMemo(
		() =>
			!(
				filters.businessLine === defaultFilters.businessLine &&
				filters.label === defaultFilters.label &&
				filters.name === defaultFilters.name &&
				filters.ownTemplatesOnly === defaultFilters.ownTemplatesOnly &&
				filters.service === defaultFilters.service
			),
		[filters],
	);

	const servicesGroupedByMarket: Record<string, ApiServiceDto[]> = React.useMemo(
		() =>
			services?.reduce((accumulator, service) => {
				const marketName = service.marketName || 'All markets';
				return {
					...accumulator,
					[marketName]: [...(accumulator[marketName] || []), service],
				};
			}, {} as Record<string, ApiServiceDto[]>) ?? {},
		[services],
	);

	return (
		<Box marginBottom={2}>
			<Card>
				<CardContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								fullWidth
								label="Template name"
								name="name"
								onChange={(event) => setFilterValue('name', event.target.value)}
								value={filters.name}
							/>
						</Grid>
						<Grid item lg={3} md={6} xs={12}>
							<TextField
								fullWidth
								label="Business Line"
								name="businessLine"
								onChange={(event) => setFilterValue('businessLine', event.target.value)}
								select
								SelectProps={{
									displayEmpty: true,
								}}
								value={businessLines ? filters.businessLine : ''}
							>
								<MenuItem value="">&nbsp;</MenuItem>
								{businessLines &&
									businessLines
										.sort((a, b) => (a > b ? 1 : -1))
										.map((businessLine) => (
											<MenuItem key={businessLine} value={businessLine}>
												{businessLine}
											</MenuItem>
										))}
							</TextField>
						</Grid>

						<Grid item lg={3} md={6} xs={12}>
							<TextField
								fullWidth
								label="Label"
								name="label"
								onChange={(event) => setFilterValue('label', event.target.value)}
								select
								SelectProps={{
									displayEmpty: true,
								}}
								value={labels ? filters.label : ''}
							>
								<MenuItem value="">&nbsp;</MenuItem>
								{labels &&
									labels.map((label) => (
										<MenuItem key={label} value={label}>
											{label}
										</MenuItem>
									))}
							</TextField>
						</Grid>

						<Grid item lg={3} md={6} xs={12}>
							<TextField
								fullWidth
								label="Service"
								name="service"
								onChange={(event) => setFilterValue('service', event.target.value)}
								select
								SelectProps={{
									displayEmpty: true,
								}}
								value={services ? filters.service : ''}
							>
								<MenuItem value="">&nbsp;</MenuItem>
								{Object.keys(servicesGroupedByMarket).map((marketName) => [
									<ListSubheader>{marketName}</ListSubheader>,
									...(servicesGroupedByMarket[marketName] || []).map((service) => (
										<MenuItem value={service.id}>{service.serviceName}</MenuItem>
									)),
								])}
							</TextField>
						</Grid>

						<Grid display="flex" item lg={3} md={6} xs={12}>
							<FormControlLabel
								control={
									<Switch
										checked={filters.ownTemplatesOnly}
										color="primary"
										onChange={(event) => setFilterValue('ownTemplatesOnly', event.target.checked)}
									/>
								}
								label="View my templates only"
								labelPlacement="start"
							/>
						</Grid>
					</Grid>
				</CardContent>
				<CardActions sx={{ justifyContent: 'end' }}>
					<Button disabled={!hasFilter} onClick={clearFilters} size="small" variant="text">
						Clear filters
					</Button>
				</CardActions>
			</Card>
		</Box>
	);
};

export default Filters;
