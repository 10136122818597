import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { ApiStudyDto } from 'kes-common';

import { studyExport } from '../../api';

const useTemplateExport = (templateId: ApiStudyDto['id'], options?: UseMutationOptions<string>) =>
	useMutation<string, unknown>(
		() => studyExport(templateId).then((response) => response.expectSuccess()),
		options,
	);

export default useTemplateExport;
