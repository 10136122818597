import React from 'react';
import { Box, List } from '@mui/material';
import { Bounds, ExtendedLocation } from '@/components/Question/Types/Location/MapModal';
import Coordinates from '@/components/Question/Types/Location/Coordinates';
import Search from '@/components/Question/Types/Location/Search';
import { GeocodeFeature } from '@mapbox/mapbox-sdk/services/geocoding';
import pointRed from '../../../../assets/point_red.svg';
import pointInverted from '../../../../assets/point_inverted.svg';
import * as Styled from './Location.styles';

const LocationList: React.FC<{
	locations: ExtendedLocation[];
	onSelectExistingMarker: (existingMarker: ExtendedLocation) => void;
	onSelectLocationFromSearch: (location: GeocodeFeature) => void;
	viewport: Bounds;
}> = ({ locations, onSelectExistingMarker, onSelectLocationFromSearch, viewport }) => (
	<Styled.LocationListContainer>
		<Search viewport={viewport} onClickedLocation={onSelectLocationFromSearch} />
		{locations.length > 0 && (
			<Styled.LocationListHeadingContainer px={3}>
				<Styled.LocationListHeading variant="body2" color="primary">
					SAVED LOCATIONS ({locations.length})
				</Styled.LocationListHeading>
			</Styled.LocationListHeadingContainer>
		)}

		<List>
			{locations.map((location) => (
				<Styled.ListItem
					key={location.id}
					active={location.active}
					onClick={() => onSelectExistingMarker(location)}
				>
					<Box width="100%" display="flex" justifyContent="space-between">
						<Box display="flex" flexDirection="column">
							<Styled.LocationName>{location.name}</Styled.LocationName>
							<Styled.Address active={location.active}>{location.address}</Styled.Address>
							<Styled.Address active={location.active} variant="body2">
								<Coordinates pointString={location.pointString} />
							</Styled.Address>
						</Box>

						{location.active ? (
							<Styled.Point src={pointInverted} />
						) : (
							<Styled.Point src={pointRed} />
						)}
					</Box>
				</Styled.ListItem>
			))}
		</List>
	</Styled.LocationListContainer>
);

export default LocationList;
