import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

import OtherChoice from '@/components/rhdhv/OtherChoice';

import { Box, Typography } from '@mui/material';
import { KAAS_COLOR } from '@/kaas/utils';
import { usePropertyIdContext } from '@/kaas/PropertyIdContext';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useKaasPropertyAssist from '@/kaas/useKaasPropertyAssist';
import { Choice, Property } from '../../../../store/types';

import ChoiceRenderer from './Choice';
import classes from './Choices.module.css';

interface Props {
	toggleChoice(choiceId: string | null, otherOptionEnabled: boolean): void;
	answerSelected(choiceId: string): boolean;
	icon: ReactNode;
	iconSelected: ReactNode;
	choiceIds: Choice['id'][];
	disabled?: boolean;
	hasOtherOption?: boolean;
	otherOption?: boolean;
	answer?: string | null;
	multiple?: boolean;
	property: Property;
}

const Choices: FC<Props> = ({
	toggleChoice,
	answerSelected,
	icon,
	iconSelected,
	hasOtherOption,
	disabled,
	choiceIds,
	otherOption,
	answer,
	multiple = false,
	property,
}): JSX.Element => {
	const { suggestedAnswers } = useKaasPropertyAssist(usePropertyIdContext());
	return (
		<div className={classNames(classes.root, disabled && classes.disabled)}>
			{choiceIds.map(
				(id): ReactNode => (
					<ChoiceRenderer
						key={id}
						choiceId={id}
						icon={icon}
						iconSelected={iconSelected}
						selected={answerSelected(id)}
						onSelect={(): void => toggleChoice(id, false)}
						property={property}
					/>
				),
			)}
			{hasOtherOption && (
				<OtherChoice
					disabled={disabled}
					answer={otherOption ? answer : null}
					selected={otherOption}
					onSelect={(selectedAnswer, otherOptionEnabled) =>
						toggleChoice(selectedAnswer, otherOptionEnabled)
					}
					onChangeOtherAnswer={(newAnswer, otherOptionEnabled) =>
						toggleChoice(newAnswer, otherOptionEnabled)
					}
					multiple={multiple}
				/>
			)}
			{suggestedAnswers && suggestedAnswers.numberOfAnswers > 0 && (
				<Box color={KAAS_COLOR} display="flex" justifyContent="flex-end" alignItems="center">
					<InfoOutlinedIcon sx={{ marginTop: '4px', marginRight: '4px' }} />
					<Typography color={KAAS_COLOR}>suggested answers</Typography>
				</Box>
			)}
		</div>
	);
};

export default Choices;
