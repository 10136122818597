import React, { FC } from 'react';
import useKaasPropertyAssist from '@/kaas/useKaasPropertyAssist';
import { usePropertyIdContext } from '@/kaas/PropertyIdContext';
import KaasSuggestionChips from '@/kaas/KaasSuggestionChips';

import Text from '../internal/Text';

interface Props {
	answer: string | undefined;
	onChange(payload: string | undefined): void;
	autoFocus?: boolean;
	disabled?: boolean;
	// eslint-disable-next-line react/no-unused-prop-types
	kaas?: ReturnType<typeof useKaasPropertyAssist>;
}

const String: FC<Props> = ({ answer, onChange, autoFocus, disabled, kaas }): JSX.Element => (
	<>
		<Text
			disabled={disabled}
			onChange={onChange}
			answer={answer}
			placeholder="Input text"
			type="text"
			autoFocus={autoFocus}
			multiline
		/>

		<KaasSuggestionChips onChange={onChange} kaas={kaas} disabled={disabled} />
	</>
);

export const StringWithSuggestions: FC<Props> = ({ answer, onChange, autoFocus, disabled }) => {
	const kaas = useKaasPropertyAssist(usePropertyIdContext());

	return (
		<String
			answer={answer}
			onChange={onChange}
			autoFocus={autoFocus}
			disabled={disabled}
			kaas={kaas}
		/>
	);
};

export default String;
