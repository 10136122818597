import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import MUIButton from '@mui/material/Button';
import styled from 'styled-components';

export const Button = styled(MUIButton)`
	width: 107px;
`;

export const Email = styled.p<{ name: string | null }>`
	color: ${({ name }): string => (name ? '#aaaaaa' : 'inherit')};
	padding: 0 4px;
	margin: 0;
	font-size: ${({ name }): string => (name ? '14px' : '16px')};
`;

export const RoleListItemContainer = styled.div`
	display: grid;
	grid-template-columns: auto 1fr auto auto;
	grid-column-gap: 8px;
	height: 72px;
	align-items: center;
	padding: 8px 0;
	transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
`;

export const RemoveIcon = styled(RemoveCircleIcon)`
	margin-right: 8px;
`;
