import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Loader, PageTitle } from 'kes-common';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { myInspectionsGet, myInspectionsLoaded } from '@/selectors';
import { myInspectionsLoad } from '@/store/actions';
import useDocumentTitle from '@/hooks/useDocumentTitle';

import Inspection from './Inspection';

const Activities: React.FC = () => {
	const dispatch = useDispatch();
	useDocumentTitle('Project Manager', 'Activities');

	const inspections = useSelector(myInspectionsGet);
	const loaded = useSelector(myInspectionsLoaded);

	React.useEffect(() => {
		if (!loaded) {
			dispatch(myInspectionsLoad());
		}
	}, [loaded]);

	return (
		<Container maxWidth="lg">
			<PageTitle title="Activities" />
			<Box marginTop={2}>
				{!loaded && <Loader />}
				{inspections.length > 0 ? (
					<>
						{inspections.map((inspection) => (
							<Inspection inspection={inspection} key={inspection.id} />
						))}
					</>
				) : (
					loaded && <Typography>You currently have no activities.</Typography>
				)}
			</Box>
		</Container>
	);
};

export default Activities;
