import makeReducer from '@/store/makeReducer';
import { initialOrderedState, makeOrderedRepository } from '@/store/repository';
import { Category } from '@/store/types';

import * as actions from '../actions';

const repository = makeOrderedRepository<Category>();

export default makeReducer(initialOrderedState<Category>(), {
	[actions.inspectionLoadSuccess.type]: (draft, payload): void => {
		repository.replaceAll(
			draft,
			payload.assetLibrary.categoriesById,
			payload.assetLibrary.categoryIds,
			(entity): Category => entity,
		);
	},
});
