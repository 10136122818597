import UploadBox from '@/components/UploadBox';
import { updateAssetFileSuccess } from '@/store/actions';
import State from '@/store/state';
import { Property } from '@/store/types';
import { downloadBlob } from 'kes-common';

import { useSnackbar } from 'notistack';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { UploadActions, UploadReducerState, useUploadReducer } from '@/hooks/useUploadReducer';
import { assetTypesGet } from '@/selectors';
import { getCaption } from '@/selectors/properties';
import {
	useDeleteImageMutation,
	useGetImageMutation,
	useUploadImageMutation,
} from './imageMutations';
import { rotateImage } from './rotateImage';
import ImageCaptionModal from '../../ImageCaptionModal';

interface Props {
	answer: string | undefined;
	disabled?: boolean;
	property: Property;
	assetId: string | null;
	assetTypeId: string;
}

const getInitialState = (answer?: string): UploadReducerState | undefined =>
	answer ? { fileName: answer, fileState: 'done', errors: [] } : undefined;

const Image: React.FC<Props> = ({ answer, disabled, property, assetId, assetTypeId }) => {
	const [isImageCaptionModalOpen, setIsImageCaptionModalOpen] = React.useState<boolean>(false);

	const reduxDispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [state, dispatch] = useUploadReducer(getInitialState(answer));
	const inspection = useSelector((s: State) => s.inspection.currentInspection);
	const assetType = useSelector((s: State) => assetTypesGet(s, assetTypeId));
	const assets = useSelector((s: State) => s.assets);
	const caption = useSelector((s: State) => getCaption(s, property.id));

	const presignedURL = React.useMemo(() => {
		if (assetId) {
			const asset = assets.byId[assetId];
			if (asset) {
				return asset.presignedURLs[property.id];
			}
		}
		return undefined;
	}, [assetId, assets, property]);

	const { mutate: uploadImage } = useUploadImageMutation({
		onMutate: () => dispatch({ type: UploadActions.UPLOAD_LOADING }),
		onSuccess: ({ file, assetId: imageAssetId, presignedURL: URL }) => {
			reduxDispatch(
				updateAssetFileSuccess({
					fileName: file.name,
					assetId: imageAssetId,
					propertyId: property.id,
					assetTypeId,
					presignedURL: URL,
				}),
			);
			dispatch({ type: UploadActions.UPLOAD_SUCCESS, fileName: file.name });
		},
		onError: () => dispatch({ type: UploadActions.UPLOAD_ERROR, errors: ['something went wrong'] }),
	});

	const { mutate: deleteImage } = useDeleteImageMutation({
		onMutate: () => dispatch({ type: UploadActions.UPLOAD_LOADING }),
		onSuccess: () => {
			if (assetId) {
				reduxDispatch(
					updateAssetFileSuccess({
						fileName: '',
						assetId,
						propertyId: property.id,
						assetTypeId,
					}),
				);
			}
			dispatch({ type: UploadActions.DELETE_SUCCESS });
		},
		onError: () => dispatch({ type: UploadActions.UPLOAD_ERROR, errors: ['Something went wrong'] }),
	});

	const { mutate: getImage } = useGetImageMutation({
		onSuccess: (blob) => downloadBlob(blob, state.fileName),
	});

	return (
		<>
			<UploadBox
				caption={caption}
				state={disabled ? 'disabled' : state.fileState}
				filename={state.fileName}
				onSelect={(file: File) => {
					rotateImage(file).then((blob) => {
						const newFile = new File([blob], file.name);
						uploadImage({
							file: newFile,
							assetId: assetId ?? uuid(),
							property,
							inspection,
							repeating: assetType.repeating,
						});
					});
				}}
				onDelete={() =>
					deleteImage({ inspection, property, assetId, repeating: assetType.repeating })
				}
				onDownload={() =>
					getImage({ inspection, property, assetId, repeating: assetType.repeating })
				}
				onEditCaption={() => setIsImageCaptionModalOpen(true)}
				errors={state.errors}
				fileType="image/jpeg, image/jpg, image/png"
				presignedURL={presignedURL}
			/>
			<ImageCaptionModal
				assetId={assetId}
				assetTypeId={assetTypeId}
				caption={caption}
				isOpen={isImageCaptionModalOpen}
				onClose={() => setIsImageCaptionModalOpen(false)}
				onError={() => {
					enqueueSnackbar('Failed to update image caption');
				}}
				onSuccess={() => {
					enqueueSnackbar('Image caption successfully updated');
					setIsImageCaptionModalOpen(false);
				}}
				propertyId={property.id}
				state={state}
			/>
		</>
	);
};

export default Image;
