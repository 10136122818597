import { Fragment } from 'react';
import { match, matchPath } from 'react-router-dom';

import MasterTemplateCreate from '@/pages/MasterTemplateCreate';
import MasterTemplates from '@/pages/MasterTemplates';
import Project from '@/pages/Project';
import ProjectCreate from '@/pages/ProjectCreate';
import Report from '@/pages/Report';
import ProjectPreview from '@/pages/ProjectPreview';
import Inspection from '@/pages/Inspection';
import BaseLayout from '@/layouts/Base';
import PageWithTopAndSideBars from '@/layouts/PageWithTopAndSideBars';
import Projects from '@/pages/Projects';
import Activities from '@/pages/Activities';
import Template from '@/pages/Template';
import Templates from '@/pages/Templates';
import User from '@/pages/User';
import ProjectImport from './pages/ProjectImport';
import InspectionRelatedFiles from './pages/InspectionRelatedFiles';
import InspectionURLs from './pages/InspectionURLs';
import InspectionFinish from './pages/InspectionFinish';

export const home = '/';
export const activities = '/activities';
export const masterTemplateCreate = '/master-templates/create';
export const masterTemplates = '/master-templates';
export const project = '/project/:projectId';
export const projectCreate = '/projectForm';
export const projectImport = '/projectImport';
export const projectInformation = '/project/:projectId/settings/:tab';
export const projectQuestions = '/project/:projectId/inspection/:inspectionId/category/:categoryId';
export const inspectionFinish = '/project/:projectId/inspection/:inspectionId/finish';
export const inspectionRelatedFiles = '/project/:projectId/inspection/:inspectionId/files';
export const inspectionURLs = '/project/:projectId/inspection/:inspectionId/urls';
export const projectReport = '/inspection/:inspectionId/report';
export const projectSettings = '/project/:projectId/settings';
export const projectSettingsEdit = '/project/:projectId/settings/edit';
export const projectSettingsEditName = '/project/:projectId/settings/edit?editName=true';
export const projectCompanyActivities = '/project/:projectId/inspection/:inspectionId/activities';
export const projectPreview = '/project/:projectId/preview';
export const study = '/study/:studyId';
export const template = '/templates/:templateId';
export const templateCreate = '/templates/create';
export const templates = '/templates';
export const templatesWithSelected = '/templates?templateId=:templateId';
export const userProfile = '/user/:userId';
export const userProfileEdit = '/user/:userId/edit';

interface Route {
	title: string;
	documentTitle?: string;
	path: string;
	component: React.ComponentType<any>;
	layout: React.ComponentType<any>;
}

export const routes: Route[] = [
	{
		documentTitle: 'Create Master Template',
		path: masterTemplateCreate,
		component: MasterTemplateCreate,
		layout: PageWithTopAndSideBars,
		title: 'Create Master Template',
	},
	{
		documentTitle: 'Master Templates',
		path: masterTemplates,
		component: MasterTemplates,
		layout: PageWithTopAndSideBars,
		title: 'Master Templates',
	},
	{
		title: 'projectReport',
		documentTitle: 'Report',
		path: projectReport,
		component: Report,
		layout: Fragment,
	},
	{
		title: 'Project preview',
		path: projectPreview,
		component: ProjectPreview,
		layout: PageWithTopAndSideBars,
	},
	{
		component: InspectionRelatedFiles,
		documentTitle: 'Inspection Related Files',
		layout: BaseLayout,
		path: inspectionRelatedFiles,
		title: 'Inspection Related Files',
	},
	{
		component: InspectionURLs,
		documentTitle: 'Inspection URLs',
		layout: BaseLayout,
		path: inspectionURLs,
		title: 'Inspection URLs',
	},
	{
		title: 'projectQuestions',
		documentTitle: 'Activity',
		path: projectQuestions,
		component: Inspection,
		layout: BaseLayout,
	},
	{
		title: 'finishInspection',
		documentTitle: 'Finish Inspection',
		path: inspectionFinish,
		component: InspectionFinish,
		layout: BaseLayout,
	},
	{
		title: 'projectImport',
		documentTitle: 'Project Import',
		path: projectImport,
		component: ProjectImport,
		layout: Fragment,
	},
	{
		title: 'projectCreate',
		documentTitle: 'Create project',
		path: projectCreate,
		component: ProjectCreate,
		layout: PageWithTopAndSideBars,
	},
	{
		title: 'project',
		documentTitle: 'Project',
		path: project,
		component: Project,
		layout: PageWithTopAndSideBars,
	},
	{
		component: Templates,
		documentTitle: 'Create Template',
		layout: PageWithTopAndSideBars,
		path: templateCreate,
		title: 'Create Template',
	},
	{
		component: Template,
		documentTitle: 'Template',
		layout: PageWithTopAndSideBars,
		path: template,
		title: 'Template',
	},
	{
		component: Templates,
		documentTitle: 'Template catalogue',
		layout: PageWithTopAndSideBars,
		path: templates,
		title: 'Template catalogue',
	},
	{
		title: 'user',
		documentTitle: 'User',
		path: userProfile,
		component: User,
		layout: PageWithTopAndSideBars,
	},
	{
		title: 'Activities',
		documentTitle: 'Activities',
		path: activities,
		component: Activities,
		layout: PageWithTopAndSideBars,
	},
	{
		title: 'Projects',
		documentTitle: 'Projects',
		path: home,
		component: Projects,
		layout: PageWithTopAndSideBars,
	},
];

export const buildRoute = (route: string, params: Record<string, any>): string => {
	let url = route;
	Object.entries(params).forEach(([key, value]) => {
		const toReplace = `:${key}`;
		url = url.replace(toReplace, value);
	});

	return url;
};

interface RouteMatch extends match {
	route: Route;
}

export const urlMatch = (path: string): RouteMatch | undefined => {
	// eslint-disable-next-line no-restricted-syntax
	for (const route of routes) {
		const matchedPath = matchPath(path, route.path);
		if (matchedPath) {
			return { ...matchedPath, route };
		}
	}
	return undefined;
};
