/* eslint-disable no-param-reassign */
import makeReducer from '@/store/makeReducer';
import { StateFileStorage as State, StateFileUploader } from '@/store/state';
import { AssetType } from '@/store/types';
import * as actions from '../actions';

const initialState: State = {
	substances: {
		state: 'empty',
		error: [],
		filename: '',
		scrollTo: undefined,
	},
	tanks: {
		state: 'disabled',
		error: [],
		filename: '',
		scrollTo: undefined,
	},
	assetTypes: {},
};

export default makeReducer(initialState, {
	[actions.companyActivitiesSubstancesUpload.type]: (draft, payload): void => {
		draft.substances.state = 'uploading';
		draft.substances.filename = payload.file.name;
	},
	[actions.companyActivitiesSubstancesDelete.type]: (draft): void => {
		if (draft.substances.state === 'error') {
			draft.substances.state = 'empty';
		} else {
			draft.substances.state = 'deleting';
			if (draft.tanks.state === 'empty' || draft.tanks.state === 'error') {
				draft.tanks.state = 'disabled';
			} else {
				draft.tanks.state = 'locked';
			}
		}
	},
	[actions.companyActivitiesSubstancesDeleteSuccess.type]: (draft): void => {
		draft.substances.state = 'empty';
		draft.substances.filename = '';
		if (draft.tanks.state === 'locked') {
			draft.tanks.state = 'disabled'; // Also delete tanks when substances are deleted
		}
	},
	[actions.companyActivitiesSubstancesDeleteError.type]: (draft, payload): void => {
		if (draft.substances.state === 'done' || draft.substances.state === 'deleting') {
			draft.substances.state = 'delete_error';
			draft.substances.error = [payload.message, ...payload.details];
			if (draft.tanks.state === 'locked') {
				draft.tanks.state = 'done';
			} else {
				draft.tanks.state = 'empty';
			}
		}
	},
	[actions.companyActivitiesSubstancesSuccess.type]: (draft): void => {
		draft.substances.state = 'done';
		draft.tanks.state = 'empty'; // Enable tanks when substances are done uploading
	},
	[actions.companyActivitiesSubstancesError.type]: (draft, payload): void => {
		draft.substances.state = 'error';
		draft.substances.error = [payload.message, ...payload.details];
	},
	[actions.companyActivitiesTanksUpload.type]: (draft, payload): void => {
		draft.tanks.state = 'uploading';
		draft.tanks.filename = payload.file.name;
		draft.substances.state = 'locked';
	},
	[actions.companyActivitiesTanksDelete.type]: (draft): void => {
		if (draft.tanks.state === 'error') {
			draft.tanks.state = 'empty';
		} else {
			draft.tanks.state = 'deleting';
		}
	},
	[actions.companyActivitiesTanksDeleteSuccess.type]: (draft): void => {
		draft.tanks.state = 'empty';
		draft.tanks.filename = '';
		draft.substances.state = 'done';
	},
	[actions.companyActivitiesTanksDeleteError.type]: (draft, payload): void => {
		if (draft.tanks.state === 'done' || draft.tanks.state === 'deleting') {
			draft.tanks.state = 'delete_error';
			draft.tanks.error = [payload.message, ...payload.details];
		}
	},
	[actions.companyActivitiesTanksSuccess.type]: (draft): void => {
		draft.tanks.state = 'done';
	},
	[actions.companyActivitiesTanksError.type]: (draft, payload): void => {
		draft.tanks.state = 'error';
		draft.tanks.error = [payload.message, ...payload.details];
		draft.substances.state = 'done';
	},
	[actions.companyActivitiesAssetsUpload.type]: (draft, payload): void => {
		draft.assetTypes[payload.assetTypeId].state = 'uploading';
		draft.assetTypes[payload.assetTypeId].filename = payload.file.name;
	},
	[actions.companyActivitiesAssetsDelete.type]: (draft, payload): void => {
		if (draft.assetTypes[payload.assetTypeId].state === 'error') {
			draft.assetTypes[payload.assetTypeId].state = 'empty';
		} else {
			draft.assetTypes[payload.assetTypeId].state = 'deleting';
		}
	},
	[actions.companyActivitiesAssetsDeleteSuccess.type]: (draft, payload): void => {
		draft.assetTypes[payload.assetTypeId].state = 'empty';
		draft.assetTypes[payload.assetTypeId].filename = '';
	},
	[actions.companyActivitiesAssetsDeleteError.type]: (draft, payload): void => {
		if (
			draft.assetTypes[payload].state === 'done' ||
			draft.assetTypes[payload].state === 'deleting'
		) {
			draft.assetTypes[payload].state = 'delete_error';
			draft.assetTypes[payload].filename = '';
			draft.tanks.error = ['Onbekende fout tijdens het verwijderen van de assets'];
		}
	},
	[actions.companyActivitiesAssetsSuccess.type]: (draft, payload): void => {
		draft.assetTypes[payload.assetTypeId].state = 'done';
		draft.assetTypes[payload.assetTypeId].scrollTo =
			payload.assets.length > 0 ? payload.assets[0].id : undefined;
	},
	[actions.companyActivitiesAssetsError.type]: (draft, payload): void => {
		draft.assetTypes[payload.assetTypeId].state = 'error';
		draft.assetTypes[payload.assetTypeId].error = [payload.message, ...payload.details];
	},
	[actions.projectLoad.type]: (draft): void => {
		draft.tanks.state = 'disabled';
		draft.substances.state = 'disabled';
	},
	[actions.inspectionLoadSuccess.type]: (draft, payload): void => {
		if (payload.substanceDataset && payload.tankDataset) {
			draft.substances.state = 'locked';
			draft.substances.filename = payload.substanceDataset.fileName;
			draft.tanks.state = 'done';
			draft.tanks.filename = payload.tankDataset.fileName;
		} else if (payload.substanceDataset) {
			draft.substances.state = 'done';
			draft.substances.filename = payload.substanceDataset.fileName;
			draft.tanks.state = 'empty';
			draft.tanks.filename = '';
		} else {
			draft.substances.state = 'empty';
			draft.substances.filename = '';
			draft.tanks.state = 'disabled';
			draft.tanks.filename = '';
		}
		const fileAssetTypes = Object.values(payload.assetLibrary.assetTypesById).filter(
			(it) => it.repeating,
		);
		const { assetIdsByAssetTypeId } = payload.mutableAssets;
		draft.assetTypes = fileAssetTypes.reduce(
			(acc: Record<string, StateFileUploader>, assetType: AssetType) => {
				const assetIds = assetIdsByAssetTypeId[assetType.id];
				acc[assetType.id] = {
					state: assetIds.length > 0 ? 'done' : 'empty',
					error: [],
					filename: payload.assetFileNames[assetType.id],
					scrollTo: undefined,
				};
				return acc;
			},
			{},
		);
	},
	[actions.csvUploadScrollComplete.type]: (draft, payload): void => {
		draft.assetTypes[payload.assetTypeId].scrollTo = undefined;
	},
});
