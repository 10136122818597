import { ApiInspectionDto, Loader } from 'kes-common';
import React, { useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ProjectQuestions from '@/pages/ProjectQuestions';
import State from '@/store/state';
import { surveyCategoryIds } from '@/selectors';
import { KaasContextProvider } from '@/kaas/KaasContext';

const Inspection = () => {
	const history = useHistory();
	const location = useLocation();
	const { projectId, inspectionId, categoryId } = useParams<Record<string, string>>();

	const loaded = useSelector<State, boolean>((state) => state.inspection.loaded);
	const error = useSelector<State, string | null>((state) => state.inspection.error);
	const currentInspection = useSelector<State, ApiInspectionDto | undefined>(
		(state) => state.inspection.currentInspection,
	);
	const categoryIds = useSelector<State, ReturnType<typeof surveyCategoryIds>>((state) =>
		surveyCategoryIds(state),
	);

	useEffect(() => {
		if (
			categoryId === 'load' &&
			currentInspection?.assetLibrary &&
			loaded &&
			currentInspection.id === inspectionId
		) {
			const hideAppBar = new URLSearchParams(location.search).get('hideAppBar') || '0';
			history.replace(
				`/project/${projectId}/inspection/${inspectionId}/category/${categoryIds[0]}?hideAppBar=${hideAppBar}`,
			);
		}
	}, [location, projectId, inspectionId, categoryId, categoryIds, currentInspection, loaded]);

	if (currentInspection) {
		return (
			<KaasContextProvider inspectionId={inspectionId}>
				<ProjectQuestions />
			</KaasContextProvider>
		);
	}

	if (error) {
		return <p>Something went wrong loading the activity</p>;
	}

	return <Loader />;
};

export default Inspection;
