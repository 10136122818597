import { acquireToken as _acquireToken } from 'kes-common';
import React from 'react';

import auth0Client from '@/authentication/auth0';
import msalInstance from '@/authentication/msal';
import { backendUrl, MSAL_CLIENT_ID, TEST_USERS_ENABLED } from '@/constants';

type DownloadScriptHandler = () => void;

interface DownloadScriptProps {
	templateId: string;
	assetLibraryId: string;
	renderChildren: (downloadScript: DownloadScriptHandler) => React.ReactNode;
}

/**
 * Downloads the scaffolded script for the published assetLibraryId of a template
 */
const DownloadScript: React.FC<DownloadScriptProps> = ({
	templateId,
	assetLibraryId,
	renderChildren,
}): JSX.Element => {
	const acquireToken = React.useCallback(
		() =>
			_acquireToken({
				auth0Client,
				hasTestUsersEnabled: TEST_USERS_ENABLED,
				msalClientId: MSAL_CLIENT_ID,
				msalInstance,
			}),
		[],
	);

	const hiddenForm = React.useRef<HTMLFormElement>(null);
	const hiddenInput = React.useRef<HTMLInputElement>(null);
	const hiddenInputForTestUser = React.useRef<HTMLInputElement>(null);

	const url = `${backendUrl}study/${templateId}/asset-library/${assetLibraryId}`;

	const onDownloadClick = React.useCallback(async () => {
		const accessToken = await acquireToken();
		hiddenInput.current?.setAttribute('value', accessToken || '');
		if (TEST_USERS_ENABLED) {
			const userFromLocalStorage = localStorage.getItem('Test-User-Id');
			if (userFromLocalStorage) {
				hiddenInputForTestUser.current?.setAttribute('value', userFromLocalStorage);
			}
		}
		hiddenForm.current?.submit();
	}, [acquireToken, hiddenForm, hiddenInput, hiddenInputForTestUser]);

	const children = React.useMemo(() => renderChildren(onDownloadClick), [onDownloadClick]);

	return (
		<form ref={hiddenForm} method="post" target="_self" action={url}>
			<input ref={hiddenInput} name="access_token" type="hidden" />
			{TEST_USERS_ENABLED && <input ref={hiddenInputForTestUser} name="test_user" type="hidden" />}
			{children}
		</form>
	);
};

export default DownloadScript;
