import TextField from '@mui/material/TextField';
import React from 'react';

import SingleSelectChoice from './SingleSelectChoice';
import { Field } from './types';

const SingleSelect: React.FC<Field> = ({
	'data-testid': dataTestId,
	property,
	setFieldValue,
	value = '',
}) => {
	const [localValue, setLocalValue] = React.useState<string>(value);
	const handleChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const newValue = event.target.value;
			setLocalValue(newValue);
			setFieldValue(newValue);
		},
		[setFieldValue],
	);
	return (
		<TextField
			data-testid={dataTestId}
			fullWidth
			name={property.name}
			variant="standard"
			onChange={handleChange}
			select
			SelectProps={{ native: true }}
			value={localValue}
		>
			<option value="">Select one</option>
			{property.choiceIds.map((choiceId) => (
				<SingleSelectChoice choiceId={choiceId} />
			))}
		</TextField>
	);
};

export default SingleSelect;
