import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { ApiMasterTemplate } from 'kes-common';
import React from 'react';

import ContextMenu from './ContextMenu';

interface MasterTemplateProps {
	masterTemplate: ApiMasterTemplate;
	refetch(): void;
}

const MasterTemplate: React.FC<MasterTemplateProps> = ({ masterTemplate, refetch }) => (
	<ListItem
		disablePadding
		secondaryAction={<ContextMenu masterTemplate={masterTemplate} refetch={refetch} />}
	>
		<ListItemButton href={masterTemplate.sharepointUrl} rel="noopener noreferrer" target="_blank">
			<ListItemText primary={masterTemplate.name} secondary={masterTemplate.description} />
		</ListItemButton>
	</ListItem>
);

export default MasterTemplate;
