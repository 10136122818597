import { ApiProjectDto, ApiProjectStudy } from 'kes-common';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { inspectionsRequest } from '../../api';

interface MutationOptions {
	projectId: ApiProjectDto['id'];
	templates: ApiProjectStudy[];
}

const useProjectAssignTemplates = (
	options?: UseMutationOptions<unknown, unknown, MutationOptions>,
) =>
	useMutation(
		(mutationOptions) =>
			inspectionsRequest(mutationOptions.projectId, mutationOptions.templates).then((response) =>
				response.expectSuccess(),
			),
		options,
	);
export default useProjectAssignTemplates;
