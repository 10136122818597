import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { ApiServiceDto, ApiStudyDto } from 'kes-common';
import React from 'react';

export interface TemplateChipsProps {
	onBusinessLineClick?(businessLineName: string): void;
	onLabelClick?(label: string): void;
	onServiceClick?(service: ApiServiceDto): void;
	template: ApiStudyDto;
}

const TemplateChips: React.FC<TemplateChipsProps> = ({
	onBusinessLineClick,
	onLabelClick,
	onServiceClick,
	template,
}) => (
	<Stack direction="row" flexWrap="wrap" spacing={1}>
		{template.isPrivate && <Chip color="warning" label="Private" size="small" />}

		{template.businessLineNames.length > 0 &&
			template.businessLineNames.map((businessLineName) => (
				<Tooltip key={businessLineName} title={`Filter by business line: ${businessLineName}`}>
					<Chip
						clickable
						color="primary"
						label={businessLineName}
						onClick={onBusinessLineClick ? () => onBusinessLineClick(businessLineName) : undefined}
						size="small"
					/>
				</Tooltip>
			))}

		{template.services.length > 0 &&
			template.services.map((service) => (
				<Tooltip
					key={service.id}
					title={`Filter by service: ${service.marketName || 'All markets'}: ${
						service.serviceName
					}`}
				>
					<Chip
						clickable
						color="secondary"
						label={`${service.marketName || 'All markets'}: ${service.serviceName}`}
						onClick={onServiceClick ? () => onServiceClick(service) : undefined}
						size="small"
					/>
				</Tooltip>
			))}

		{template.labels.length > 0 &&
			template.labels.map((label) => (
				<Tooltip key={label} title={`Filter by label: ${label}`}>
					<Chip
						clickable
						label={label}
						onClick={onLabelClick ? () => onLabelClick(label) : undefined}
						size="small"
					/>
				</Tooltip>
			))}
	</Stack>
);

export default TemplateChips;
