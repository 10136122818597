import React from 'react';
import { Alert } from '@mui/material';

export interface AlertNodataProps {
	message?: string;
}
/**
 * Renders an alert component when React Query returns no data, typically when an empty list is received.
 */
function AlertNoData({ message }: AlertNodataProps): JSX.Element {
	return <Alert severity="info">{message || "We couldn't find any data"}</Alert>;
}

export default AlertNoData;
