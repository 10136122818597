import React from 'react';
import { Marker } from 'react-map-gl';
import pointSelected from '@/assets/point_selected.svg';
import { ExtendedLocation } from '@/components/Question/Types/Location/MapModal';
import pointRed from '@/assets/point_red.svg';
import { parseLatLng } from '@/components/Question/Types/Location/location.utils';
import * as Styled from '../Location.styles';

const MARKER_OFFSET = -8;

const CustomMapboxMarker: React.FC<ExtendedLocation> = ({
	id,
	active,
	onClick,
	name,
	address,
	pointString,
}) => {
	const handleClick = React.useCallback(() => {
		if (onClick) {
			onClick({ active, address, id, name, pointString });
		}
	}, [active, address, id, name, onClick, pointString]);

	return (
		<Marker
			offsetTop={MARKER_OFFSET}
			offsetLeft={MARKER_OFFSET}
			longitude={parseLatLng(pointString).lng}
			latitude={parseLatLng(pointString).lat}
			captureClick
		>
			<Styled.Point onClick={handleClick} src={active ? pointSelected : pointRed} />
		</Marker>
	);
};

export default CustomMapboxMarker;
