import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { ApiPublishTemplateLibrary, ApiStudyDto, ApiStudyTemplates } from 'kes-common';
import React from 'react';
import { useMutation } from '@tanstack/react-query';

import { studyPublish } from '@/net/api';

export interface TemplatePublishDialogProps {
	isOpen: boolean;
	onClose(): void;
	onSuccess(templateVersions: ApiStudyTemplates): void;
	template: ApiStudyDto;
}

const TemplatePublishDialog: React.FC<TemplatePublishDialogProps> = ({
	isOpen,
	onClose,
	onSuccess,
	template,
}) => {
	const publishMutation = useMutation(
		(publishStudyVersion: ApiPublishTemplateLibrary) =>
			studyPublish(template.id, publishStudyVersion),
		{
			onSuccess: (data) => {
				if (data.status === 200) {
					onSuccess(data.result);
				}
			},
		},
	);

	const formik = useFormik<ApiPublishTemplateLibrary>({
		initialValues: {
			changelogUrl: '',
			publishComment: '',
		},
		onSubmit: (values) => {
			publishMutation.mutate(values, {
				onSettled: () => formik.setSubmitting(false),
			});
		},
	});

	const handleClose = React.useCallback(() => {
		formik.resetForm();
		onClose();
	}, [formik, onClose]);

	return (
		<Dialog fullWidth maxWidth="sm" onClose={handleClose} open={isOpen}>
			<form onSubmit={formik.handleSubmit}>
				<DialogTitle>
					Publish version {template.draftVersion} of {template.name}
				</DialogTitle>
				<DialogContent>
					<Typography>
						Your template will be visible and available to everyone in the company. Older versions
						of your template will be made unavailable automatically.
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button disabled={publishMutation.isLoading} onClick={handleClose} variant="text">
						Cancel
					</Button>
					<Button color="primary" disabled={formik.isSubmitting} type="submit" variant="contained">
						Publish
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default TemplatePublishDialog;
