import React, { useState } from 'react';
import { Alert, Button, Chip, Container, Paper, Stack, Tab, Typography } from '@mui/material';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';

import ActivitiesTab, { ActivitiesTabProps } from './ActivitiesTab';
import DocumentsTab, { DocumentsTabProps } from './DocumentsTab';

import { colors, renderArrayLength } from './utils';
import QuestionsTab, { QuestionsTabProps } from './QuestionsTab';

interface AssistPanelProps {
	assist: () => void;
	isLoadingAssist: boolean;

	close: () => void; // close modal

	activitiesTabProps: ActivitiesTabProps;
	documentsTabProps: DocumentsTabProps;
	questionsTabProps: QuestionsTabProps;
}

function AssistPanel({
	isLoadingAssist,
	assist,
	close,
	activitiesTabProps,
	documentsTabProps,
	questionsTabProps,
}: AssistPanelProps): JSX.Element {
	// Tab state managment
	const [tabOpen, setTabOpen] = useState<string>('0');
	const handleChangeTabOpen = (_event: React.SyntheticEvent, newValue: string) => {
		setTabOpen(newValue);
	};

	return (
		<Container sx={{ pt: 2, backgroundColor: colors.background }}>
			<Stack direction="row" alignItems="center" justifyContent="space-between">
				<Typography variant="h6">Welcome to the AI Assistant.</Typography>
				<Chip size="small" label="Beta" color="success" variant="outlined" />
			</Stack>

			<Typography pb={2} variant="body2" color="gray">
				To get suggested answers we will need some files or Kes reports from older/other projects.
				Upload files that are related to your project.
			</Typography>

			<Paper sx={{ pb: 2, backgroundColor: colors.tabs }}>
				<TabContext value={tabOpen}>
					<TabList aria-label="tabs" onChange={handleChangeTabOpen}>
						<Tab
							label={`Questions ${renderArrayLength(questionsTabProps.selectedQuestions)}`}
							value="0"
						/>
						<Tab label={`Files ${renderArrayLength(documentsTabProps.selectedFiles)}`} value="1" />
						<Tab
							label={`Reports ${renderArrayLength(activitiesTabProps.data?.relatedIds)}`}
							value="2"
						/>
					</TabList>

					<Container sx={{ overflowY: 'scroll', minHeight: '50vh' }}>
						<TabPanel value="0">
							<QuestionsTab {...questionsTabProps} />
						</TabPanel>
						<TabPanel value="1">
							<DocumentsTab {...documentsTabProps} />
						</TabPanel>
						<TabPanel value="2">
							<ActivitiesTab {...activitiesTabProps} />
						</TabPanel>
					</Container>
				</TabContext>
			</Paper>

			{documentsTabProps.fileBeingUploaded.length > 0 && (
				<Stack direction="column" alignItems="flex-end" spacing={1} mt={2}>
					<Alert style={{ width: '40%' }} severity="info">
						Some files are still being uploaded
					</Alert>
				</Stack>
			)}
			{documentsTabProps.fileBeingUploaded.length === 0 &&
				((documentsTabProps.selectedFiles.length === 0 &&
					activitiesTabProps.data?.relatedIds.length === 0) ||
					questionsTabProps.selectedQuestions.length === 0) && (
					<Stack direction="column" alignItems="flex-end" spacing={1} mt={2}>
						<Alert severity="info">
							Select at least a question and a file or report to proceed
						</Alert>
					</Stack>
				)}

			<Stack direction="row-reverse" spacing={2} mt={3}>
				<LoadingButton
					onClick={() => {
						assist();
						close();
					}}
					loading={isLoadingAssist}
					disabled={
						documentsTabProps.fileBeingUploaded.length > 0 ||
						(documentsTabProps.selectedFiles.length === 0 &&
							activitiesTabProps.data?.relatedIds.length === 0) ||
						questionsTabProps.selectedQuestions.length === 0
					}
					variant="contained"
				>
					Assist
				</LoadingButton>
				<Button variant="outlined" onClick={close}>
					Cancel
				</Button>
			</Stack>
			<Stack direction="column" alignItems="flex-end" spacing={1} my={1}>
				<Typography variant="caption" sx={{ color: 'text.secondary' }}>
					*Assist can make mistakes. Consider checking important informations.
				</Typography>
			</Stack>
		</Container>
	);
}

export default AssistPanel;
