import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useQueryClient } from '@tanstack/react-query';
import { ApiProjectInspectionDto } from 'kes-common';
import { useSnackbar } from 'notistack';
import React from 'react';

import AddMemberForm from './AddMemberForm';
import MembersList from './MembersList';

interface ActivityMembersDialogProps {
	activity: ApiProjectInspectionDto;
	isOpen: boolean;
	onClose(): void;
	onMemberAddError?(): void;
	onMemberAddSuccess?(): void;
	onMemberRemoveError?(): void;
	onMemberRemoveSuccess?(): void;
}

const ActivityMembersDialog: React.FC<ActivityMembersDialogProps> = ({
	activity,
	isOpen,
	onClose,
	onMemberAddError,
	onMemberAddSuccess,
	onMemberRemoveError,
	onMemberRemoveSuccess,
}) => {
	const [expandedPanel, setExpandedPanel] = React.useState<number>(0);

	const queryClient = useQueryClient();
	const { enqueueSnackbar } = useSnackbar();

	const onDialogClose = React.useCallback(() => {
		onClose();
		setExpandedPanel(0);
	}, [onClose, setExpandedPanel]);

	const handleMemberAddError = React.useCallback(() => {
		if (onMemberAddError) {
			onMemberAddError();
		}
		enqueueSnackbar('There was an error adding the activity member');
	}, [enqueueSnackbar, onMemberAddError]);
	const handleMemberAddSuccess = React.useCallback(() => {
		if (onMemberAddSuccess) {
			onMemberAddSuccess();
		}
		queryClient.invalidateQueries(['activity-members', activity.id]);
		setExpandedPanel(0);
		enqueueSnackbar('Activity member added sucessfully');
	}, [enqueueSnackbar, queryClient, onMemberAddSuccess, setExpandedPanel]);

	return (
		<Dialog onClose={onDialogClose} open={isOpen}>
			<DialogTitle>Activity inspectors for {activity.studyName}</DialogTitle>
			<DialogContent>
				<Accordion
					disableGutters
					elevation={0}
					expanded={expandedPanel === 0}
					onChange={() => setExpandedPanel(0)}
				>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>Activity inspectors</AccordionSummary>
					<AccordionDetails>
						<MembersList
							activityId={activity.id}
							onMemberRemoveError={onMemberRemoveError}
							onMemberRemoveSuccess={onMemberRemoveSuccess}
						/>
					</AccordionDetails>
				</Accordion>

				<Accordion
					disableGutters
					elevation={0}
					expanded={expandedPanel === 1}
					onChange={() => setExpandedPanel(1)}
				>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						Add activity inspector
					</AccordionSummary>
					<AccordionDetails>
						<AddMemberForm
							activityId={activity.id}
							onError={handleMemberAddError}
							onSuccess={handleMemberAddSuccess}
						/>
					</AccordionDetails>
				</Accordion>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={onDialogClose} variant="text">
					Done
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ActivityMembersDialog;
