import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';

export const Button = styled(LoadingButton)`
	height: 56px;
`;

export const TextInputContainer = styled('div')`
	grid-gap: 16px;
	display: grid;
	grid-template-columns: 1fr 200px;
`;
