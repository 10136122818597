import Grid from '@mui/material/Grid';
import { NameAvatar, ApiAuthorizedInspectionDto } from 'kes-common';
import React from 'react';

interface Props {
	users: ApiAuthorizedInspectionDto['users'];
}

const InspectionUsersList: React.FC<Props> = ({ users }) => {
	if (users.length === 0) {
		return null;
	}
	return (
		<Grid container justifyContent="flex-end" spacing={1}>
			{users.map((user) => (
				<Grid item key={user.userId}>
					<NameAvatar isEmail={Boolean(user.name)} name={user.name || user.email} />
				</Grid>
			))}
		</Grid>
	);
};

export default InspectionUsersList;
