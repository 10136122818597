import AttachFileIcon from '@mui/icons-material/AttachFile';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

interface RelatedFilesProps {
	onClick(): void;
}

const RelatedFiles: React.FC<RelatedFilesProps> = ({ onClick }) => (
	<Tooltip title="Manage files related to this answer, such as proofs or additional information">
		{/* The <span> is because a <Tooltip /> doesn't work on disabled elements */}
		<span>
			<MenuItem onClick={onClick}>
				<ListItemIcon>
					<AttachFileIcon />
				</ListItemIcon>
				<ListItemText primary="Related files" />
			</MenuItem>
		</span>
	</Tooltip>
);

export default RelatedFiles;
