import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import React, { FC } from 'react';

import { Choice, MULTIPLE_SELECT_SEPERATOR, Property } from '../../../store/types';
import Choices from './internal/Choices';

interface Props {
	answer: string | undefined;
	hasOtherOption: boolean;
	onChange(payload: string | undefined, otherOptionAnswer?: boolean): void;
	choiceIds: Choice['id'][];
	disabled?: boolean;
	otherOption: boolean;
	property: Property;
}

function toggleChoice(
	answer: Props['answer'],
	onChange: Props['onChange'],
	selectedAnswer: string,
	otherOption: boolean,
): void {
	const split = answer ? answer.split(MULTIPLE_SELECT_SEPERATOR).map((a) => a.trim()) : [];

	const uuidPattern =
		/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
	const isSelectedAnswerUUID = uuidPattern.test(selectedAnswer);
	const isLastItemUUID = uuidPattern.test(split[split.length - 1]);

	// This is disgusting.
	// The backend endpoint design forces me into this shituation.
	if (selectedAnswer.trim() === split[split.length - 1] && !isLastItemUUID) {
		onChange(undefined, true);
	}

	if (otherOption === true || !isSelectedAnswerUUID) {
		if (split.slice(-1)[0] === selectedAnswer.trim()) {
			return onChange(split.slice(0, -1).join(MULTIPLE_SELECT_SEPERATOR), false);
		}
		if (isLastItemUUID) {
			return onChange([...split, selectedAnswer].join(MULTIPLE_SELECT_SEPERATOR), true);
		}
		if (!selectedAnswer.trim()) {
			return onChange(split.slice(0, -1).join(MULTIPLE_SELECT_SEPERATOR), true);
		}
		return onChange([...split.slice(0, -1), selectedAnswer].join(MULTIPLE_SELECT_SEPERATOR), true);
	}

	if (answer === selectedAnswer) {
		// Special case, 1 answer selected, and the clicked entry is our answer
		return onChange(undefined, false);
	}

	const index = split.indexOf(selectedAnswer);
	if (index < 0) {
		// Insert our new answer to the array;
		if (isLastItemUUID || !answer) {
			split.push(selectedAnswer);
		} else {
			const otherValue = split.slice(-1)[0];
			split[split.length - 1] = selectedAnswer;
			split.push(otherValue);
		}
	} else {
		// Delete answer
		split.splice(index, 1);
	}

	return onChange(split.join(MULTIPLE_SELECT_SEPERATOR), false);
}

function answerSelected(answer: Props['answer'], selectedAnswer: string): boolean {
	return !!answer && answer.includes(selectedAnswer);
}

const MultipleSelect: FC<Props> = ({
	answer,
	hasOtherOption,
	onChange,
	choiceIds,
	disabled,
	otherOption,
	property,
}): JSX.Element => (
	<Choices
		toggleChoice={(selectedAnswer, otherOptionAnswer): void => {
			toggleChoice(answer, onChange, selectedAnswer || '', otherOptionAnswer);
		}}
		answerSelected={(selectedAnswer): boolean => answerSelected(answer, selectedAnswer)}
		hasOtherOption={hasOtherOption}
		icon={<CheckBoxOutlineBlank />}
		iconSelected={<CheckBox />}
		choiceIds={choiceIds}
		disabled={disabled}
		otherOption={otherOption}
		multiple
		answer={otherOption ? answer?.split(',').slice(-1)[0] : null}
		property={property}
	/>
);

export default MultipleSelect;
