import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useQueryClient } from '@tanstack/react-query';
import { ApiMasterTemplate, useUser } from 'kes-common';
import { useSnackbar } from 'notistack';
import React from 'react';

import DeleteDialog from './DeleteDialog';

interface ContextMenuProps {
	masterTemplate: ApiMasterTemplate;
	refetch(): void;
}

const ContextMenu: React.FC<ContextMenuProps> = ({ masterTemplate, refetch }) => {
	const [anchorElement, setAnchorElement] = React.useState<HTMLElement | null>(null);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState<boolean>(false);

	const queryClient = useQueryClient();
	const { enqueueSnackbar } = useSnackbar();
	const { user } = useUser();

	const onClose = React.useCallback(() => setAnchorElement(null), [setAnchorElement]);
	const onOpen: React.MouseEventHandler<HTMLButtonElement> = React.useCallback(
		(event) => {
			setAnchorElement(event.currentTarget);
		},
		[setAnchorElement],
	);

	const onDeleteDialogClose = React.useCallback(
		() => setIsDeleteDialogOpen(false),
		[setIsDeleteDialogOpen],
	);
	const onDeleteDialogOpen = React.useCallback(() => {
		onClose();
		setIsDeleteDialogOpen(true);
	}, [onClose, setIsDeleteDialogOpen]);
	const onDeleteError = React.useCallback(() => {
		enqueueSnackbar(
			'There was an error while trying to delete the master template. Please try again.',
		);
	}, [enqueueSnackbar]);
	const onDeleteSuccess = React.useCallback(() => {
		queryClient.invalidateQueries(['master-templates']);
		refetch();
		onDeleteDialogClose();
		enqueueSnackbar('The master template has been deleted successfully');
	}, [enqueueSnackbar, onDeleteDialogClose, queryClient, refetch]);

	return (
		<>
			<IconButton disabled={user.user.id !== masterTemplate.createdBy} onClick={onOpen}>
				<MoreVertIcon />
			</IconButton>
			<Menu anchorEl={anchorElement} onClose={onClose} open={Boolean(anchorElement)}>
				<MenuItem onClick={onDeleteDialogOpen}>Delete</MenuItem>
			</Menu>

			<DeleteDialog
				isOpen={isDeleteDialogOpen}
				masterTemplate={masterTemplate}
				onClose={onDeleteDialogClose}
				onError={onDeleteError}
				onSuccess={onDeleteSuccess}
			/>
		</>
	);
};

export default ContextMenu;
