import { ApiRuleSetDto } from 'kes-common';

import * as actions from '@/store/actions';
import makeReducer from '@/store/makeReducer';
import { initialUnorderedState, makeUnorderedRepository } from '@/store/repository';

const repository = makeUnorderedRepository<ApiRuleSetDto>();

export default makeReducer(initialUnorderedState<ApiRuleSetDto>(), {
	[actions.inspectionLoadSuccess.type]: (draft, payload): void => {
		repository.replaceAll(draft, payload.assetLibrary.ruleSetsById, (entity) => entity);
	},
});
