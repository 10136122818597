import React from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonChecked from '@mui/icons-material/RadioButtonChecked';

import * as Styled from './OtherChoice.styled';

interface OtherChoiceProps {
	selected?: boolean;
	disabled?: boolean;
	onSelect: (answer: string | null, otherOptionEnabled: boolean) => void;
	onChangeOtherAnswer: (answer: string | null, otherOptionEnabled: boolean) => void;
	answer?: string | null;
	multiple?: boolean;
}

const OtherChoice: React.FC<OtherChoiceProps> = ({
	onSelect,
	answer,
	onChangeOtherAnswer,
	selected,
	disabled,
	multiple = false,
}) => (
	<Styled.OtherChoiceButton
		role="button"
		onClick={() => {
			onSelect(answer ?? null, !selected);
		}}
		disabled={disabled}
	>
		{multiple && (selected ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />)}
		{!multiple && (selected ? <RadioButtonChecked /> : <RadioButtonUnchecked />)}
		<Styled.InnerChoice>
			Other:
			<Styled.TextField
				onClick={(e) => {
					e.stopPropagation();
				}}
				disabled={disabled}
				value={answer || ''}
				onChange={(value) => {
					onChangeOtherAnswer(value, true);
				}}
			/>
		</Styled.InnerChoice>
	</Styled.OtherChoiceButton>
);

export default OtherChoice;
