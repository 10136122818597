import { Button, DialogActions, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';

interface CongratsDialogProps {
	show: boolean;
	onClose: () => void;
}

const CongratsDialog: React.FC<CongratsDialogProps> = ({ show, onClose }) => (
	<Dialog maxWidth="sm" fullWidth open={show}>
		<DialogTitle>You created a project in Kes</DialogTitle>
		<DialogContent>
			<Typography>
				Congrats! You&apos;ve created a project on Kes! In this page, you can
				<ul>
					<li>
						<strong>Add activities</strong> to your project using the button in the top right corner
					</li>
					<li>
						<strong>Add workorder IDs</strong> to added activities.
					</li>
					<li>
						<strong>Assign members</strong> to activities in the project.
					</li>
					<li>
						<strong>Preview your document</strong> sample.
					</li>
				</ul>
				If you want to practice using Kes, go to Kes training.
			</Typography>
		</DialogContent>
		<DialogActions>
			<Button variant="contained" autoFocus onClick={onClose}>
				Close
			</Button>
		</DialogActions>
	</Dialog>
);

export default CongratsDialog;
