import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { ApiAuthorizeUserForStudy, ApiStudyDto, ApiStudyUserDto } from 'kes-common';
import React from 'react';
import { useMutation } from '@tanstack/react-query';
import * as yup from 'yup';

import { studyRoleAdd } from '@/net/api';

type FormValues = Omit<ApiAuthorizeUserForStudy, 'roles'>;

const emailRegexp =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(?!\.)(\w*\.)?rhdhv\.com$/;

interface AddUserProps {
	onSuccess(user: ApiStudyUserDto): void;
	template: ApiStudyDto;
}

const AddUser: React.FC<AddUserProps> = ({ onSuccess, template }) => {
	const userAdd = useMutation((values: FormValues) =>
		studyRoleAdd(template.id, {
			email: values.email,
			roles: ['STUDY_ADMINISTRATOR', 'STUDY_SUGGESTED_INSPECTOR'],
		}),
	);
	const formik = useFormik<FormValues>({
		initialValues: {
			email: '',
		},
		onSubmit: (values) => {
			userAdd.mutate(values, {
				onSettled: () => formik.setSubmitting(false),
				onSuccess: (data) => {
					if (data.status === 200) {
						onSuccess(data.result);
					}
				},
			});
		},
		validationSchema: yup.object().shape({
			email: yup
				.string()
				.email('Please enter a valid email address')
				.matches(emailRegexp, 'Please enter an RHDHV email address'),
		}),
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			<Grid container spacing={2}>
				<Grid item xs>
					<TextField
						error={formik.touched.email && Boolean(formik.errors.email)}
						fullWidth
						helperText={
							(formik.touched.email && formik.errors.email) ||
							'Email should end in @rhdhv.com or @subdomain.rhdhv.com'
						}
						label="Email address"
						margin="normal"
						name="email"
						onBlur={formik.handleBlur}
						onChange={formik.handleChange}
						placeholder="email@rhdhv.com"
						value={formik.values.email}
						variant="standard"
					/>
				</Grid>
				<Grid alignItems="center" display="flex" item xs="auto">
					<Button disabled={formik.isSubmitting} type="submit" variant="text">
						Add
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};

export default AddUser;
