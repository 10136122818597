import { History } from 'history';
import {
	ApiAuthorizedInspectionDto,
	ApiAuthorizedProjectDto,
	ApiError,
	ApiInspectionDto,
	ApiProjectUpdateDto,
	ApiViewProjectDto,
	ApiPointValue,
	ApiAsset,
	ApiProjectAPIError,
	ApiProperty,
	ApiFlag,
	ApiFile,
} from 'kes-common';
import { makeAction } from './utils';
import {
	Asset,
	AssetType,
	AssetTypeId,
	InspectionId,
	InspectionUserBody,
	ProjectId,
	Property,
	UUID,
} from './types'; /* Actions */

/* Actions */
enum ActionTypes {
	ROUTER_UPDATE_PATH = '@@kes/ROUTER/UPDATE_PATH',
	ROUTER_UPDATE_TITLE = '@@kes/ROUTER/UPDATE_TITLE',
	COMPANY_ACTIVITIES_SUBSTANCES_UPLOAD = '@@kes/COMPANY_ACTIVITIES/SUBSTANCES/UPLOAD',
	COMPANY_ACTIVITIES_SUBSTANCES_DELETE = '@@kes/COMPANY_ACTIVITIES/SUBSTANCES/DELETE',
	COMPANY_ACTIVITIES_SUBSTANCES_DELETE_SUCCESS = '@@kes/COMPANY_ACTIVITIES/SUBSTANCES/DELETE_SUCCESS',
	COMPANY_ACTIVITIES_SUBSTANCES_DELETE_ERROR = '@@kes/COMPANY_ACTIVITIES/SUBSTANCES/DELETE_ERROR',
	COMPANY_ACTIVITIES_SUBSTANCES_SUCCESS = '@@kes/COMPANY_ACTIVITIES/SUBSTANCES/SUCCESS',
	COMPANY_ACTIVITIES_SUBSTANCES_ERROR = '@@kes/COMPANY_ACTIVITIES/SUBSTANCES/ERROR',
	COMPANY_ACTIVITIES_TANKS_UPLOAD = '@@kes/COMPANY_ACTIVITIES/TANKS/UPLOAD',
	COMPANY_ACTIVITIES_TANKS_DELETE = '@@kes/COMPANY_ACTIVITIES/TANKS/DELETE',
	COMPANY_ACTIVITIES_TANKS_DELETE_SUCCESS = '@@kes/COMPANY_ACTIVITIES/TANKS/DELETE_SUCCESS',
	COMPANY_ACTIVITIES_TANKS_DELETE_ERROR = '@@kes/COMPANY_ACTIVITIES/TANKS/DELETE_ERROR',
	COMPANY_ACTIVITIES_TANKS_SUCCESS = '@@kes/COMPANY_ACTIVITIES/TANKS/SUCCESS',
	COMPANY_ACTIVITIES_TANKS_ERROR = '@@kes/COMPANY_ACTIVITIES/TANKS/ERROR',
	COMPANY_ACTIVITIES_ASSETS_UPLOAD = '@@kes/COMPANY_ACTIVITIES/ASSETS/UPLOAD',
	COMPANY_ACTIVITIES_ASSETS_DELETE = '@@kes/COMPANY_ACTIVITIES/ASSETS/DELETE',
	COMPANY_ACTIVITIES_ASSETS_DELETE_SUCCESS = '@@kes/COMPANY_ACTIVITIES/ASSETS/DELETE_SUCCESS',
	COMPANY_ACTIVITIES_ASSETS_DELETE_ERROR = '@@kes/COMPANY_ACTIVITIES/ASSETS/DELETE_ERROR',
	COMPANY_ACTIVITIES_ASSETS_SUCCESS = '@@kes/COMPANY_ACTIVITIES/ASSETS/SUCCESS',
	COMPANY_ACTIVITIES_ASSETS_ERROR = '@@kes/COMPANY_ACTIVITIES/ASSETS/ERROR',
	COMPANY_ACTIVITIES_PGS_UPDATED = '@@kes/COMPANY_ACTIVITIES/PGP_UPDATED',
	CSV_UPLOAD_SCROLL_COMPLETE = '@@kes/CSV_UPLOAD/SCROLL_COMPLETE',

	FLAG_CREATE = '@@kes/FLAG/CREATE',
	FLAG_CREATE_SUCCESS = '@@kes/FLAG/CREATE_SUCCESS',
	FLAG_DELETE = '@@kes/FLAG/DELETE',
	FLAG_DELETE_EMPTY_ROWS = '@@kes/FLAG/DELETE_EMPTY_ROWS',

	MY_INSPECTIONS_LOAD = '@@kes/MY_INSPECTIONS/LOAD',
	MY_INSPECTIONS_LOAD_SUCCESS = '@@kes/MY_INSPECTIONS/LOAD_SUCCESS',
	MY_INSPECTIONS_LOAD_ERROR = '@@kes/MY_INSPECTIONS/LOAD_ERROR',
	MY_INSPECTIONS_LOAD_REFRESH = '@@kes/MY_INSPECTIONS/LOAD_REFRESH',

	PROJECT_OVERVIEW_LOAD = '@@kes/PROJECT/LOAD_OVERVIEW',
	PROJECT_OVERVIEW_LOAD_SUCCESS = '@@kes/PROJECT/LOAD_OVERVIEW_SUCCESS',
	PROJECT_OVERVIEW_LOAD_ERROR = '@@kes/PROJECT/LOAD_OVERVIEW_ERROR',
	PROJECT_OVERVIEW_LOAD_REFRESH = '@@kes/PROJECT/LOAD_OVERVIEW_REFRESH',
	PROJECT_CREATE = '@@kes/PROJECT/CREATE',
	PROJECT_CREATE_SUCCESS = '@@kes/PROJECT/CREATE_SUCCESS',
	PROJECT_CREATE_ERROR = '@@kes/PROJECT/CREATE_ERROR',
	PROJECT_UPDATE = '@@kes/PROJECT/UPDATE',
	PROJECT_UPDATE_SUCCESS = '@@kes/PROJECT/UPDATE_SUCCESS',
	PROJECT_UPDATE_ERROR = '@@kes/PROJECT/UPDATE_ERROR',
	PROJECT_DELETE = '@@kes/PROJECT/DELETE',
	PROJECT_DELETE_SUCCESS = '@@kes/PROJECT/DELETE_SUCCESS',
	PROJECT_DELETE_ERROR = '@@kes/PROJECT/DELETE_ERROR',
	PROJECT_LOAD = '@@kes/PROJECT/LOAD',
	PROJECT_LOAD_SUCCESS = '@@kes/PROJECT/LOAD_SUCCESS',
	PROJECT_LOAD_ERROR = '@@kes/PROJECT/LOAD_ERROR',
	PROJECT_LOAD_REFRESH = '@@kes/PROJECT/LOAD_REFRESH',
	PROJECT_FATAL_ERROR = '@@kes/PROJECT/FATAL_ERROR',
	PROJECT_CONTACT_SAVE = '@@kes/PROJECT/CONTACT_SAVE',
	PROJECT_CONTACT_ERROR = '@@kes/PROJECT/CONTACT_ERROR',
	PROJECT_CONTACT_KVK_NUMBER = '@@kes/PROJECT_CONTACT/KVK_NUMBER',
	PROJECT_CONTACT_KVK_NAME = '@@kes/PROJECT_CONTACT/KVK_NAME',
	PROJECT_CONTACT_PERSON_NAME = '@@kes/PROJECT_CONTACT/PERSON_NAME',
	PROJECT_CONTACT_PERSON_FUNCTION = '@@kes/PROJECT_CONTACT/PERSON_FUNCTION',
	PROJECT_CONTACT_PERSON_EMAIL = '@@kes/PROJECT_CONTACT/PERSON_EMAIL',
	PROJECT_CONTACT_PERSON_PHONE = '@@kes/PROJECT_CONTACT/PERSON_PHONE',
	PROJECT_CONTACT_LOCATION_STREETNAME = '@@kes/PROJECT_CONTACT/LOCATION_STREETNAME',
	PROJECT_CONTACT_LOCATION_STREETNUMBER = '@@kes/PROJECT_CONTACT/LOCATION_STREETNUMBER',
	PROJECT_CONTACT_LOCATION_ZIPCODE = '@@kes/PROJECT_CONTACT/LOCATION_ZIPCODE',
	PROJECT_CONTACT_LOCATION_CITY = '@@kes/PROJECT_CONTACT/LOCATION_CITY',
	PROJECT_CONTACT_LOCATION_COUNTRY = '@@kes/PROJECT_CONTACT/LOCATION_COUNTRY',
	ASSET_CREATE = '@@kes/ASSET/CREATE',
	ASSET_CREATE_SAVE = '@@kes/ASSET/CREATE_SAVE',
	ASSET_ANSWER = '@@kes/ASSET/ANSWER',
	ASSET_ANSWER_ERROR = '@@kes/ASSET/ANSWER_ERROR',
	ASSET_DELETE = '@@kes/ASSET/DELETE',
	ASSET_DELETE_SUCCESS = '@@kes/ASSET/DELETE_SUCCESS',
	ASSET_DELETE_ERROR = '@@kes/ASSET/DELETE_ERROR',
	ASSET_ID_UPDATE = '@@kes/ASSET/ID_UPDATE',
	ASSET_UPLOAD_FILE_SUCCESS = '@@kes/ASSET/UPLOAD/SUCCESS',
	ASSET_SET_OPEN = '@@kes/ASSET/SET_OPEN',

	SET_RELATIONSHIP_PROPERTY_ID = '@@kes/RELATIONSHIP/SET_RELATIONSHIP_PROPERTY_ID',

	INSPECTION_ASSETS_DELETE = '@@kes/INSPECTION/ASSETS/DELETE',

	INSPECTION_ASSET_PROPERTY_RELATED_FILE_DELETE = '@@kes/INSPECTION/ASSET_PROPERTY_RELATED_FILE/DELETE',
	INSPECTION_ASSET_PROPERTY_RELATED_FILE_UPLOAD = '@@kes/INSPECTION/ASSET_PROPERTY_RELATED_FILE/UPLOAD',
	INSPECTION_PROPERTY_RELATED_FILE_DELETE = '@@kes/INSPECTION/PROPERTY_RELATED_FILE/DELETE',
	INSPECTION_PROPERTY_RELATED_FILE_UPLOAD = '@@kes/INSPECTION/PROPERTY_RELATED_FILE/UPLOAD',
	INSPECTION_RELATED_FILE_DELETE = '@@kes/INSPECTION/RELATED_FILE/DELETE',
	INSPECTION_RELATED_FILE_UPLOAD = '@@kes/INSPECTION/RELATED_FILE/UPLOAD',

	INSPECTION_EXTERNAL_URLS_UPDATE = '@@kes/INSPECTION/EXTERNAL_URLS/UPDATE',

	INSPECTION_LOAD = '@@kes/INSPECTION/LOAD',
	INSPECTION_LOAD_SUCCESS = '@@kes/INSPECTION/LOAD_SUCCESS',
	INSPECTION_LOAD_ERROR = '@@kes/INSPECTION/LOAD_ERROR',

	INSPECTION_DELETE = '@@kes/INSPECTION/DELETE',
	INSPECTION_DELETE_SUCCESS = '@@kes/INSPECTION/DELETE_SUCCESS',
	INSPECTION_DELETE_ERROR = '@@kes/INSPECTION/DELETE_ERROR',

	INSPECTION_IMAGE_CAPTION_SAVE = '@@kes/INSPECTION/IMAGE_CAPTION_SAVE',

	INSPECTION_ROLE_CREATE_SUCCESS = '@@kes/INSPECTION/ROLE_CREATE_SUCCESS',
	INSPECTION_ROLE_DELETE_SUCCESS = '@@kes/INSPECTION/ROLE_DELETE_SUCCESS',

	SAVE_STARTING = '@@kesBuilder/SAVE/STARTING',
	SAVE_DONE = '@@kesBuilder/SAVE/DONE',
	SAVE_SAVED = '@@kesBuilder/SAVE/SAVED',
	SAVE_OFFLINE = '@@kesBuilder/SAVE/OFFLINE',
	SAVE_ERROR = '@@kesBuilder/SAVE/ERROR',
	SAVE_VALIDATION_ERROR = '@@kesBuilder/SAVE/VALIDATION_ERROR',

	// Locations
	RELOAD_LOCATIONS = '@@kes/UI/RELOAD_LOCATIONS',
}

// The actions use "double function calls", because this helps typescript with the type inferince

/* router to store */
export const routerUpdatePath = makeAction<{
	params: Record<string, string>;
	path: string;
}>()(ActionTypes.ROUTER_UPDATE_PATH);
export const routerUpdateTitle = makeAction<string>()(ActionTypes.ROUTER_UPDATE_TITLE);

/* company activities */
// Substances
export const companyActivitiesSubstancesUpload = makeAction<{
	inspectionId: InspectionId;
	file: File;
}>()(ActionTypes.COMPANY_ACTIVITIES_SUBSTANCES_UPLOAD);
export const companyActivitiesSubstancesDelete = makeAction<ProjectId>()(
	ActionTypes.COMPANY_ACTIVITIES_SUBSTANCES_DELETE,
);
export const companyActivitiesSubstancesDeleteSuccess = makeAction()(
	ActionTypes.COMPANY_ACTIVITIES_SUBSTANCES_DELETE_SUCCESS,
);
export const companyActivitiesSubstancesDeleteError = makeAction<{
	message: string;
	details: string[];
}>()(ActionTypes.COMPANY_ACTIVITIES_SUBSTANCES_DELETE_ERROR);
export const companyActivitiesSubstancesSuccess = makeAction()(
	ActionTypes.COMPANY_ACTIVITIES_SUBSTANCES_SUCCESS,
);
export const companyActivitiesSubstancesError = makeAction<{
	message: string;
	details: string[];
}>()(ActionTypes.COMPANY_ACTIVITIES_SUBSTANCES_ERROR);

// Tanks
export const companyActivitiesTanksUpload = makeAction<{
	inspectionId: InspectionId;
	file: File;
}>()(ActionTypes.COMPANY_ACTIVITIES_TANKS_UPLOAD);
export const companyActivitiesTanksDelete = makeAction<ProjectId>()(
	ActionTypes.COMPANY_ACTIVITIES_TANKS_DELETE,
);
export const companyActivitiesTanksDeleteSuccess = makeAction()(
	ActionTypes.COMPANY_ACTIVITIES_TANKS_DELETE_SUCCESS,
);
export const companyActivitiesTanksDeleteError = makeAction<{
	message: string;
	details: string[];
}>()(ActionTypes.COMPANY_ACTIVITIES_TANKS_DELETE_ERROR);
export const companyActivitiesTanksSuccess = makeAction()(
	ActionTypes.COMPANY_ACTIVITIES_TANKS_SUCCESS,
);
export const companyActivitiesTanksError = makeAction<{
	message: string;
	details: string[];
}>()(ActionTypes.COMPANY_ACTIVITIES_TANKS_ERROR);

// Assets
export const companyActivitiesAssetsUpload = makeAction<{
	inspectionId: InspectionId;
	assetTypeId: AssetTypeId;
	file: File;
}>()(ActionTypes.COMPANY_ACTIVITIES_ASSETS_UPLOAD);
export const companyActivitiesAssetsDelete = makeAction<{
	inspectionId: InspectionId;
	assetTypeId: AssetTypeId;
	assetIds: string[];
}>()(ActionTypes.COMPANY_ACTIVITIES_ASSETS_DELETE);
export const companyActivitiesAssetsDeleteSuccess = makeAction<{
	assetTypeId: AssetTypeId;
	assetIds: string[];
}>()(ActionTypes.COMPANY_ACTIVITIES_ASSETS_DELETE_SUCCESS);
export const companyActivitiesAssetsDeleteError = makeAction<AssetTypeId>()(
	ActionTypes.COMPANY_ACTIVITIES_ASSETS_DELETE_ERROR,
);
export const companyActivitiesAssetsSuccess = makeAction<{
	assetTypeId: AssetTypeId;
	assets: ApiAsset[];
}>()(ActionTypes.COMPANY_ACTIVITIES_ASSETS_SUCCESS);
export const csvUploadScrollComplete = makeAction<{
	assetTypeId: AssetTypeId;
}>()(ActionTypes.CSV_UPLOAD_SCROLL_COMPLETE);
export const companyActivitiesAssetsError = makeAction<{
	message: string;
	details: string[];
	assetTypeId: AssetTypeId;
}>()(ActionTypes.COMPANY_ACTIVITIES_ASSETS_ERROR);

export const flagCreate = makeAction<{
	assetId: Asset['id'];
	assetTypeId: AssetTypeId;
	inspectionId: InspectionId;
	flagAssetTypeId: AssetTypeId;
}>()(ActionTypes.FLAG_CREATE);
export const flagCreateSuccess = makeAction<ApiFlag>()(ActionTypes.FLAG_CREATE_SUCCESS);
export const flagDelete = makeAction<{
	flagId: ApiFlag['id'];
}>()(ActionTypes.FLAG_DELETE);
export const flagDeleteEmptyRows = makeAction<{
	assetId: Asset['id'];
	flag: AssetType;
}>()(ActionTypes.FLAG_DELETE_EMPTY_ROWS);

export const myInspectionsLoad = makeAction()(ActionTypes.MY_INSPECTIONS_LOAD);
export const myInspectionsSuccess = makeAction<ApiAuthorizedInspectionDto[]>()(
	ActionTypes.MY_INSPECTIONS_LOAD_SUCCESS,
);
export const myInspectionsError = makeAction<ApiError>()(ActionTypes.MY_INSPECTIONS_LOAD_ERROR);

/* Project */
// Overview
export const projectOverviewLoad = makeAction()(ActionTypes.PROJECT_OVERVIEW_LOAD);
export const projectOverviewSuccess = makeAction<ApiAuthorizedProjectDto[]>()(
	ActionTypes.PROJECT_OVERVIEW_LOAD_SUCCESS,
);
export const projectOverviewError = makeAction<ApiError>()(ActionTypes.PROJECT_OVERVIEW_LOAD_ERROR);
export const projectOverviewRefresh = makeAction()(ActionTypes.PROJECT_OVERVIEW_LOAD);

// Create
export const projectCreate = makeAction<{
	createProject: ApiProjectUpdateDto;
	history: History;
}>()(ActionTypes.PROJECT_CREATE);
export const projectCreateSuccess = makeAction<ApiAuthorizedProjectDto>()(
	ActionTypes.PROJECT_CREATE_SUCCESS,
);
export const projectCreateError = makeAction<ApiError | ApiProjectAPIError>()(
	ActionTypes.PROJECT_CREATE_ERROR,
);

// Update
export const projectUpdate = makeAction<{
	projectId: string;
	project: ApiProjectUpdateDto;
	history: History;
	onComplete?(): void;
	redirectRoute: string;
}>()(ActionTypes.PROJECT_UPDATE);
export const projectUpdateSuccess = makeAction<{
	projectId: string;
	projectDetails: ApiProjectUpdateDto;
}>()(ActionTypes.PROJECT_UPDATE_SUCCESS);
export const projectUpdateError = makeAction<ApiError | ApiProjectAPIError>()(
	ActionTypes.PROJECT_UPDATE_ERROR,
);

export const projectFatalError = makeAction<Error>()(ActionTypes.PROJECT_FATAL_ERROR);

// Delete
export const projectDelete = makeAction<string>()(ActionTypes.PROJECT_DELETE);
export const projectDeleteSuccess = makeAction<string>()(ActionTypes.PROJECT_DELETE_SUCCESS);
export const projectDeleteError = makeAction<string>()(ActionTypes.PROJECT_DELETE_ERROR);

// Get
export const projectLoad = makeAction<ProjectId>()(ActionTypes.PROJECT_LOAD);
export const projectLoadSuccess = makeAction<ApiViewProjectDto>()(ActionTypes.PROJECT_LOAD_SUCCESS);
export const projectLoadError = makeAction<ApiError>()(ActionTypes.PROJECT_LOAD_ERROR);
export const projectLoadRefresh = makeAction<boolean>()(ActionTypes.PROJECT_LOAD_REFRESH);

export const assetCreate = makeAction<{
	assetId: Asset['id'];
	assetTypeId: AssetType['id'];
	applicable?: boolean;
	otherOption?: boolean;
}>()(ActionTypes.ASSET_CREATE);
export const assetCreateAndSave = makeAction<{
	assetId: Asset['id'];
	assetTypeId: AssetType['id'];
	applicable?: boolean;
	otherOption?: boolean;
}>()(ActionTypes.ASSET_CREATE_SAVE);

// Answer asset actions
export const assetAnswer = makeAction<{
	answer: string | null;
	assetId: Asset['id'];
	assetTypeId: AssetType['id'];
	propertyId: Property['id'];
	noteText: string | null;
	applicable: boolean;
	otherOption: boolean;
	location: ApiPointValue[] | null;
}>()(ActionTypes.ASSET_ANSWER);
export const assetAnswerError = makeAction<Error>()(ActionTypes.ASSET_ANSWER_ERROR);

// Asset delete actions
export const assetDelete = makeAction<{
	assetId: UUID;
	assetTypeId: UUID;
	owningAssetId?: UUID;
	owningPropertyId?: UUID;
}>()(ActionTypes.ASSET_DELETE);
export const assetDeleteSuccess = makeAction<string>()(ActionTypes.ASSET_DELETE_SUCCESS);
export const assetDeleteError = makeAction<ApiError>()(ActionTypes.ASSET_DELETE_ERROR);
export const assetIdUpdate = makeAction<{ idNew: ApiAsset['id']; idOld: ApiAsset['id'] }>()(
	ActionTypes.ASSET_ID_UPDATE,
);
export const assetSetIsOpen = makeAction<{ assetId: string; isOpen: boolean }>()(
	ActionTypes.ASSET_SET_OPEN,
);

export const deleteAssetsAndImages = makeAction<{
	inspectionId: InspectionId;
}>()(ActionTypes.INSPECTION_ASSETS_DELETE);

export const setRelationshipPropertyId = makeAction<string | null>()(
	ActionTypes.SET_RELATIONSHIP_PROPERTY_ID,
);

// Inspections
export const inspectionAssetPropertyRelatedFileDelete = makeAction<{
	assetId: Asset['id'];
	propertyId: Property['id'];
}>()(ActionTypes.INSPECTION_ASSET_PROPERTY_RELATED_FILE_DELETE);

export const inspectionAssetPropertyRelatedFileUpload = makeAction<{
	assetId: Asset['id'];
	file: ApiFile;
	propertyId: Property['id'];
}>()(ActionTypes.INSPECTION_ASSET_PROPERTY_RELATED_FILE_UPLOAD);

export const inspectionPropertyRelatedFileDelete = makeAction<{
	assetId: Asset['id'];
	propertyId: Property['id'];
	fileId: ApiFile['fileId'];
}>()(ActionTypes.INSPECTION_PROPERTY_RELATED_FILE_DELETE);

export const inspectionPropertyRelatedFileUpload = makeAction<{
	assetId: Asset['id'];
	file: ApiFile;
	propertyId: Property['id'];
}>()(ActionTypes.INSPECTION_PROPERTY_RELATED_FILE_UPLOAD);

export const inspectionRelatedFileDelete = makeAction<{ fileId: ApiFile['fileId'] }>()(
	ActionTypes.INSPECTION_RELATED_FILE_DELETE,
);
export const inspectionRelatedFileUpload = makeAction<{
	fileId: ApiFile['fileId'];
	fileName: ApiFile['name'];
	processed: ApiFile['processed'];
}>()(ActionTypes.INSPECTION_RELATED_FILE_UPLOAD);

export const inspectionExternalURLsUpdate = makeAction<ApiInspectionDto['urls']>()(
	ActionTypes.INSPECTION_EXTERNAL_URLS_UPDATE,
);

export const inspectionLoad = makeAction<string>()(ActionTypes.INSPECTION_LOAD);
export const inspectionLoadSuccess = makeAction<ApiInspectionDto>()(
	ActionTypes.INSPECTION_LOAD_SUCCESS,
);
export const inspectionLoadError = makeAction<ApiError>()(ActionTypes.INSPECTION_LOAD_ERROR);

export const inspectionDelete = makeAction<string>()(ActionTypes.INSPECTION_DELETE);
export const inspectionDeleteSuccess = makeAction()(ActionTypes.INSPECTION_DELETE_SUCCESS);
export const inspectionDeleteError = makeAction<ApiError>()(ActionTypes.INSPECTION_DELETE_ERROR);

export const inspectionImageCaptionSave = makeAction<{
	assetId: ApiAsset['id'];
	caption: string;
	propertyId: ApiProperty['id'];
}>()(ActionTypes.INSPECTION_IMAGE_CAPTION_SAVE);

export const inspectionUserCreateSuccess = makeAction<InspectionUserBody>()(
	ActionTypes.INSPECTION_ROLE_CREATE_SUCCESS,
);
export const inspectionUserDeleteSuccess = makeAction<InspectionUserBody>()(
	ActionTypes.INSPECTION_ROLE_DELETE_SUCCESS,
);

export const saveStarting = makeAction()(ActionTypes.SAVE_STARTING);
export const saveDone = makeAction()(ActionTypes.SAVE_DONE);
export const saveError = makeAction<Error>()(ActionTypes.SAVE_ERROR);

export const updateAssetFileSuccess = makeAction<{
	fileName: string;
	assetId: string;
	propertyId: string;
	assetTypeId: string;
	presignedURL?: string;
}>()(ActionTypes.ASSET_UPLOAD_FILE_SUCCESS);

export const reloadLocations = makeAction<{
	assetTypeId: UUID;
	propertyId: UUID;
	assetId: UUID;
	locations: ApiPointValue[];
}>()(ActionTypes.RELOAD_LOCATIONS);
