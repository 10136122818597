import FlagIcon from '@mui/icons-material/Flag';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { ApiFlag } from 'kes-common';
import React from 'react';
import { useSelector } from 'react-redux';

import { flagsGetByAssetAndFlag } from '@/selectors/flags';
import State from '@/store/state';
import { Asset, AssetType } from '@/store/types';

interface ButtonFlagProps {
	assetId?: Asset['id'];
	flag: AssetType;
	isSingular: boolean;
	onClick(): void;
}

const ButtonFlag: React.FC<ButtonFlagProps> = ({ assetId, flag, isSingular, onClick }) => {
	const flags = useSelector<State, ApiFlag[]>((state) =>
		assetId ? flagsGetByAssetAndFlag(state, assetId, flag.id) : [],
	);

	if (isSingular) {
		return (
			<Badge badgeContent={flags.length} color="primary">
				<Button
					color="secondary"
					data-testid={`flag__open--${flag.id}`}
					onClick={onClick}
					startIcon={<FlagIcon />}
					variant="text"
				>
					{flag.name}
				</Button>
			</Badge>
		);
	}

	return (
		<MenuItem data-testid={`flag__open--${flag.id}`} onClick={onClick}>
			<Typography variant="inherit" noWrap>
				{flag.name}
				<Chip color="secondary" label={flags.length} size="small" sx={{ marginLeft: 1 }} />
			</Typography>
		</MenuItem>
	);
};

export default ButtonFlag;
