/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { throttle } from 'lodash';
import Stack from '@mui/material/Stack';
import { TextField, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import {
	fetchMasterProjects,
	getErrorMessage,
	isMasterProject,
	MasterProject,
	MasterProjects,
} from '@/components/ProjectImport/model';

type NotFoundOption = `NOTFOUND`;

type OptionType = MasterProject | NotFoundOption;

const isOptionEqualToValue = (option: OptionType, value: OptionType): boolean =>
	isMasterProject(option) && isMasterProject(value) ? option.id === value.id : true;

interface SearchProjectsProps {
	onError: (msg: string) => void;
	onNotFound: () => void;
	projectToImport: MasterProject | null;
	setProjectToImport: (project: MasterProject | null) => void;
}
const SearchProjects: React.FC<SearchProjectsProps> = ({
	onError,
	onNotFound,
	projectToImport,
	setProjectToImport,
}): JSX.Element => {
	const [isSearchMode, setIsSearchMode] = React.useState<boolean>(false);
	const [popperOpen, setPopperOpen] = useState(false);
	const [projectQuery, setProjectQuery] = useState('');
	const [masterProjects, setMasterProjects] = useState<MasterProjects>([]);

	const searchMasterProject = useCallback(
		throttle(async (query: string) => {
			try {
				const projects = await fetchMasterProjects(query);
				setMasterProjects(projects);
			} catch (e) {
				onError(getErrorMessage(e));
			}
		}, 1000),
		[],
	);

	useEffect(() => {
		searchMasterProject(projectQuery);
	}, [projectQuery]);

	return (
		<Autocomplete
			value={projectToImport}
			open={projectQuery.length >= 3 && popperOpen}
			onOpen={() => setPopperOpen(true)}
			onClose={() => setPopperOpen(false)}
			popupIcon={null}
			onBlur={() => {
				setIsSearchMode(false);
			}}
			onChange={(_, newProjectToImport) => {
				if (newProjectToImport === null || isMasterProject(newProjectToImport)) {
					if (newProjectToImport !== null) {
						setIsSearchMode(false);
					}
					setProjectQuery('');
					setProjectToImport(newProjectToImport);
				}
			}}
			noOptionsText={<Typography>No options found</Typography>}
			inputValue={isSearchMode ? projectQuery : projectToImport?.name || ''}
			onInputChange={(_, newProjectQuery, reason) => {
				if (reason !== 'reset') {
					setIsSearchMode(true);
					setProjectQuery(newProjectQuery);
				}
			}}
			getOptionLabel={(option) => (isMasterProject(option) ? option.name : option)}
			isOptionEqualToValue={isOptionEqualToValue}
			filterOptions={(x) => x} // Filtering is done server-side
			options={[...masterProjects, 'NOTFOUND' as NotFoundOption]}
			renderInput={(params) => (
				<TextField
					{...params}
					multiline // See https://github.com/mui-org/material-ui/issues/29961
					sx={{
						'& .MuiInputBase-root': {
							paddingRight: '39px!important', // Reclaim space of removed open icon
						},
					}}
					label="Master project id or name"
					InputProps={{
						...params.InputProps,
						endAdornment:
							!isSearchMode && projectToImport ? (
								<>
									<Chip label={projectToImport.id} sx={{ width: 85, p: 1, m: 0 }} />
									{params.InputProps.endAdornment}
								</>
							) : undefined,
					}}
				/>
			)}
			renderOption={(props, option) => (
				<li {...props}>
					{isMasterProject(option) ? (
						<Stack
							direction="row"
							spacing={2}
							justifyContent="space-between"
							alignItems="center"
							width="100%"
							pr={1}
						>
							<span>{option.name}</span>
							{option.id && (
								<Chip label={option.id} sx={{ width: 85, p: 1, m: 0, flexShrink: 0 }} />
							)}
						</Stack>
					) : (
						<span
							data-testid="button-create-by-name"
							tabIndex={0}
							role="button"
							onClick={onNotFound}
						>
							<em>Cannot find your project? Click here</em>
						</span>
					)}
				</li>
			)}
			data-testid="input-project-search"
			// @ts-ignore
			ListboxProps={{ 'data-testid': 'listbox-project-search-results' }}
		/>
	);
};

export default SearchProjects;
