import React from 'react';
import Button from '@mui/material/Button';
import MUIChip, { ChipProps } from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import styled, { css, keyframes } from 'styled-components';
import { NavLink as ReactRouterNavLink } from 'react-router-dom';
import { Theme } from '@mui/material/styles';

type ChipSpan = React.ComponentType<ChipProps<'span', { component: 'span' }>>;

export const Chip: ChipSpan = styled(MUIChip)`
	margin: -${({ theme }) => theme.spacing(0.5)} ${({ theme }) => theme.spacing(2)} 0;
`;

export const Root = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 296px;
	padding: 16px 0;
	overflow: auto;
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
`;

export const List = styled.ul`
	padding: 0 8px;
	margin: 0;
	list-style-type: none;
	width: 296px;
	flex: 1;
`;

export const ListTitle = styled(Typography)`
	margin: 0 0 ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(4.5)};
`;

export const Version = styled(Typography)`
	color: #6f7379;
	padding: 0 0 0 28px;
	margin-top: 16px;
`;

export const DownloadButton = styled(Button)`
	font-size: 13px;
	font-weight: 600;
	width: 100%;
	justify-content: flex-start;
	margin: 8px 0;
	padding: 8px 24px;
	svg {
		margin-right: 16px;
	}
` as ReactRouterNavLink;

const flashAnimation = (theme: Theme) => keyframes`
	from { background-color: #fff}
	to { background-color: ${theme.palette.primary.main} }
`;

export const NavLink = styled(ReactRouterNavLink)`
	margin: 4px 10px 4px 10px;
	display: flex;
	height: 40px;
	align-items: center;
	border-radius: 5px;
	text-decoration: none;
	color: ${({ theme }) => theme.palette.text.primary};
	cursor: pointer;
	padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(3)};
	text-overflow: ellipsis;

	&.active {
		background-color: ${({ theme }) => theme.palette.primary.main};
		color: white;
		p {
			font-weight: 600;
		}
	}

	&:hover {
		color: #222222;
		background-color: #e8eaec;
	}
`;

export const CategoryNavLink = styled(NavLink)<{ flash: boolean }>`
	animation: ${({ flash, theme }) =>
		flash ? css`0.5s ease-out 0s 2 alternate ${flashAnimation(theme)}` : 'none'};
`;

export const ListItemText = styled.p`
	width: 100%;
	height: 100%;
	font-weight: 400;
	text-overflow: ellipsis;
	display: block;
	white-space: nowrap;
	overflow: hidden;
`;
