import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiInspectionDto } from 'kes-common';
import React from 'react';

import Delete from './Delete';
import Run from './Run';
import Upload from './Upload';

interface ScriptFileProps {
	inspection: ApiInspectionDto;
}

const ScriptFile: React.FC<ScriptFileProps> = ({ inspection }) => {
	const activityScript = inspection.scripts.find((s) => s.type === 'ACTIVITY');
	const templateScript = inspection.scripts.find((s) => s.type === 'TEMPLATE');

	return (
		<Box marginY={1}>
			<Box alignItems="center" display="flex" justifyContent="center">
				<Typography
					component="p"
					fontWeight="bold"
					marginRight={1}
					textAlign="center"
					variant="overline"
				>
					Script file
				</Typography>
				<Tooltip
					placement="right"
					title="A Python script file that will run against the data from this activity."
				>
					<HelpIcon color="action" fontSize="small" />
				</Tooltip>
			</Box>

			<List dense>
				{activityScript ? (
					<ListItem
						sx={{ paddingRight: 10 }}
						secondaryAction={
							<>
								<Run inspectionId={inspection.id} script={activityScript} />
								<Delete inspection={inspection} script={activityScript} />
							</>
						}
					>
						<ListItemText
							primary={<Typography noWrap>{activityScript.fileName}</Typography>}
							secondary="Activity script"
						/>
					</ListItem>
				) : (
					<ListItem>
						<Upload inspection={inspection} />
					</ListItem>
				)}

				{templateScript && (
					<ListItem
						sx={{ paddingRight: 10 }}
						secondaryAction={
							<>
								<Run inspectionId={inspection.id} script={templateScript} />
								<IconButton edge="end" aria-label="Delete" disabled>
									<DeleteIcon />
								</IconButton>
							</>
						}
					>
						<ListItemText
							primary={<Typography noWrap>{templateScript.fileName}</Typography>}
							secondary="Template script"
						/>
					</ListItem>
				)}
			</List>
		</Box>
	);
};

export default ScriptFile;
