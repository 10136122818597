import Typography from '@mui/material/Typography';
import React, { ReactNode } from 'react';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { UploadBoxProps } from '@/components/UploadBox/index';
import * as Styled from '@/components/UploadBox/UploadBox.styles';

const UploadBoxBase: React.FC<{
	caption?: string;
	filename: UploadBoxProps['filename'];
	children: ReactNode;
	disabled?: boolean;
	presignedURL?: string;
}> = ({ caption, children, disabled, filename, presignedURL }) => (
	<Styled.RootNormal disabled={disabled}>
		{!presignedURL && <InsertDriveFileIcon />}
		{presignedURL && <Styled.Thumbnail alt={filename} src={presignedURL} />}
		<Styled.Text>
			<span>{filename}</span>
			{caption && (
				<>
					<br />
					<Typography color="grey.600" variant="subtitle1">
						{caption}
					</Typography>
				</>
			)}
		</Styled.Text>
		{children}
	</Styled.RootNormal>
);

export default UploadBoxBase;
