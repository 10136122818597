import { Editor } from '@tinymce/tinymce-react';
import { debounce } from 'lodash';
import React from 'react';
import 'tinymce';
import 'tinymce/themes/silver';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/noneditable';
import 'tinymce/icons/default/icons';
import 'tinymce/skins/ui/oxide/skin.css';

interface RichTextProps {
	answer?: string;
	onChange(payload: string | undefined): void;
}

const RichText: React.FC<RichTextProps> = ({ answer, onChange }) => {
	const [value, setValue] = React.useState<string>(answer || '');
	const onChangeDebounced = React.useMemo(() => debounce(onChange, 500), [onChange]);

	const handleChange = React.useCallback(
		(newValue: string) => {
			setValue(newValue);
			onChangeDebounced(newValue);
		},
		[onChangeDebounced],
	);

	return (
		<Editor
			value={value}
			onEditorChange={handleChange}
			init={{
				height: '400px',
				width: '100%',
				resize: false,
				menubar: false,
				statusbar: false,
				plugins: ['advlist autolink lists link', 'searchreplace table paste noneditable'],
				advlist_bullet_styles: 'default',
				advlist_number_styles: 'default,lower-alpha,lower-roman,upper-alpha,upper-roman',
				toolbar:
					'formatselect | bold italic underline | subscript superscript |' +
					'bullist numlist | removeformat | table | undo redo',
				table_clone_elements: 'strong em b i font h1 h2 h3 h4 h5 h6 p div',
				extended_valid_elements: 'td[style]',
				table_appearance_options: false,
				table_advtab: false,
				table_cell_advtab: false,
				table_row_advtab: false,
				table_header_type: 'sectionCells',
				table_toolbar:
					'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | tablerowheader',
			}}
		/>
	);
};

export default RichText;
