/* eslint-disable no-param-reassign */
import { ApiAsset } from 'kes-common';
import { mapValues } from 'lodash';

import makeReducer from '@/store/makeReducer';
import { makeUnorderedRepository } from '@/store/repository';
import { Asset, MULTIPLE_SELECT_SEPERATOR, UUID } from '@/store/types';

import * as actions from '../actions';

const repository = makeUnorderedRepository<Asset>();

export const { initialState } = repository;

const getPopulatedAsset = (
	assetId: string,
	valueStringByPropertyId: Record<string, string>,
	asset?: Partial<Asset>,
): Asset => ({
	filesByPropertyId: {},
	id: assetId,
	valueStringByPropertyId,
	captionsByPropertyId: {},
	notesByPropertyId: {},
	applicableByPropertyId: {},
	otherValueEnabledByPropertyId: {},
	location: {},
	isOpen: true,
	presignedURLs: {},
	...asset,
});

const apiAssetToAsset = (entity: ApiAsset): Asset => ({
	filesByPropertyId: entity.filesByPropertyId,
	id: entity.id,
	valueStringByPropertyId: mapValues(
		entity.valuesByPropertyId,
		(value): string => value.valueString,
	),
	applicableByPropertyId: mapValues(entity.valuesByPropertyId, (value) => value.applicable),
	captionsByPropertyId: mapValues(entity.captionsByPropertyId, (value) => value),
	otherValueEnabledByPropertyId: mapValues(entity.valuesByPropertyId, (value) => value.other),
	notesByPropertyId: entity.notesByPropertyId,
	location: mapValues(entity.valuesByPropertyId, (value) => value.locations ?? []),
	isOpen: false,
	presignedURLs: mapValues(entity.valuesByPropertyId, (value) => value.presignedURL ?? undefined),
});

const deleteAsset = (
	draft: typeof initialState,
	payload: {
		assetId: UUID;
		assetTypeId: UUID;
		owningAssetId?: UUID;
		owningPropertyId?: UUID;
	},
) => {
	repository.delete(draft, payload.assetId);
};

export default makeReducer(initialState, {
	[actions.assetAnswer.type](draft, payload) {
		repository.modify(draft, payload.assetId, (item): void => {
			const split = payload.answer
				? payload.answer.split(MULTIPLE_SELECT_SEPERATOR).map((a) => a.trim())
				: [];

			const uuidPattern =
				/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
			const isLastItemUUID = uuidPattern.test(split[split.length - 1]);

			item.applicableByPropertyId[payload.propertyId] = payload.applicable;
			if (payload.answer === null && !payload.otherOption) {
				delete item.valueStringByPropertyId[payload.propertyId];
				delete item.otherValueEnabledByPropertyId[payload.propertyId];
			} else {
				item.valueStringByPropertyId[payload.propertyId] = payload.answer ? payload.answer : '';
				item.otherValueEnabledByPropertyId[payload.propertyId] =
					Boolean(payload.answer) && !isLastItemUUID && payload.otherOption;
			}
			if (payload.noteText === null) {
				delete item.notesByPropertyId[payload.propertyId];
			} else {
				item.notesByPropertyId[payload.propertyId] = payload.noteText;
			}
		});
	},

	[actions.assetCreate.type](draft, payload) {
		repository.upsert(draft, getPopulatedAsset(payload.assetId, {}));
	},
	[actions.assetCreateAndSave.type](draft, payload) {
		repository.upsert(draft, getPopulatedAsset(payload.assetId, {}));
	},
	[actions.assetIdUpdate.type](draft, payload) {
		const assetCurrent: Asset = draft.byId[payload.idOld];
		const assetNew: Asset = { ...assetCurrent, id: payload.idNew };
		repository.delete(draft, payload.idOld);
		repository.upsert(draft, assetNew);
	},
	[actions.inspectionImageCaptionSave.type]: (draft, payload): void => {
		repository.modify(draft, payload.assetId, (item) => {
			item.captionsByPropertyId[payload.propertyId] = payload.caption;
		});
	},
	[actions.assetDelete.type]: (draft, payload): void => {
		deleteAsset(draft, payload);
	},

	[actions.inspectionAssetPropertyRelatedFileDelete.type]: (draft, payload): void => {
		delete draft.byId[payload.assetId].filesByPropertyId[payload.propertyId];
	},
	[actions.inspectionAssetPropertyRelatedFileUpload.type]: (draft, payload): void => {
		const files = draft.byId[payload.assetId].filesByPropertyId[payload.propertyId];
		if (files) {
			files.push(payload.file);
		} else {
			draft.byId[payload.assetId].filesByPropertyId[payload.propertyId] = [payload.file];
		}
	},
	[actions.inspectionPropertyRelatedFileDelete.type]: (draft, payload): void => {
		const files = draft.byId[payload.assetId].filesByPropertyId[payload.propertyId];
		draft.byId[payload.assetId].filesByPropertyId[payload.propertyId] = files.filter(
			(file) => file.fileId !== payload.fileId,
		);
	},
	[actions.inspectionPropertyRelatedFileUpload.type]: (draft, payload): void => {
		const files = draft.byId[payload.assetId].filesByPropertyId[payload.propertyId];
		if (files) {
			files.push(payload.file);
		} else {
			draft.byId[payload.assetId].filesByPropertyId[payload.propertyId] = [payload.file];
		}
	},

	[actions.inspectionLoadSuccess.type]: (draft, payload): void => {
		// Mapping
		repository.replaceAll(draft, payload.mutableAssets.assetsById, apiAssetToAsset);
	},
	[actions.updateAssetFileSuccess.type]: (draft, payload) => {
		const assetExists = !!draft.byId[payload.assetId];
		if (!assetExists) {
			const asset = getPopulatedAsset(
				payload.assetId,
				{ [payload.propertyId]: payload.fileName },
				{
					presignedURLs: payload.presignedURL ? { [payload.propertyId]: payload.presignedURL } : {},
				},
			);
			repository.upsert(draft, asset);
		} else {
			repository.modify(draft, payload.assetId, (item): void => {
				item.valueStringByPropertyId[payload.propertyId] = payload.fileName;
				if (payload.presignedURL) {
					item.presignedURLs = {
						...item.presignedURLs,
						[payload.propertyId]: payload.presignedURL,
					};
				}
			});
		}
	},

	[actions.reloadLocations.type]: (draft, payload) => {
		repository.modify(draft, payload.assetId, (item): void => {
			item.location[payload.propertyId] = payload.locations;
		});
	},

	[actions.companyActivitiesAssetsSuccess.type]: (draft, payload): void => {
		payload.assets.forEach((asset) => {
			repository.upsert(draft, apiAssetToAsset(asset));
		});
	},
	[actions.companyActivitiesAssetsDeleteSuccess.type]: (draft, payload): void => {
		payload.assetIds.forEach((id) => repository.delete(draft, id));
	},
	[actions.assetSetIsOpen.type]: (draft, payload): void => {
		repository.modify(draft, payload.assetId, (item) => {
			item.isOpen = payload.isOpen;
		});
	},
});
