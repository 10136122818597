import React from 'react';

import ImageComponent from '@/components/Question/Types/Image';

import { Field } from './types';

const Image: React.FC<Field> = ({ asset, assetTypeId, property, value = '' }) => (
	<ImageComponent answer={value} assetId={asset.id} assetTypeId={assetTypeId} property={property} />
);

export default Image;
