import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import MUIListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { ApiExternalUrl, ApiInspectionDto, useGoogleTagManager } from 'kes-common';
import React from 'react';

import useInspectionExternalURLs from '@/net/reactQuery/mutations/useInspectionExternalURLs';

interface ListItemProps {
	inspection: ApiInspectionDto;
	onError(): void;
	onSuccess(urls: ApiExternalUrl[]): void;
	url: ApiExternalUrl;
}

const ListItem: React.FC<ListItemProps> = ({ inspection, onError, onSuccess, url }) => {
	const { isLoading, mutate } = useInspectionExternalURLs(inspection.id);
	const { trackCustomEvent } = useGoogleTagManager();

	const onDelete = React.useCallback(() => {
		trackCustomEvent({
			event: 'related_link_remove',
			inspectionId: inspection.id,
			name: url.name,
			url: url.url,
		});
		const newURLs = inspection.urls.filter((inspectionURL) => inspectionURL.url !== url.url);
		mutate({ links: newURLs }, { onError, onSuccess: () => onSuccess(newURLs) });
	}, [mutate, trackCustomEvent, url]);

	return (
		<MUIListItem
			secondaryAction={
				<IconButton disabled={isLoading || url.default === true} onClick={onDelete}>
					<DeleteIcon />
				</IconButton>
			}
		>
			<ListItemButton component="a" href={url.url} rel="noopener noreferrer" target="_blank">
				<ListItemText primary={<Typography color="primary">{url.name}</Typography>} />
			</ListItemButton>
		</MUIListItem>
	);
};

export default ListItem;
