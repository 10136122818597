import BallotIcon from '@mui/icons-material/Ballot';
import GridOnIcon from '@mui/icons-material/GridOn';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { assetTypesGet, surveyAssetListsGet } from '@/selectors';
import { assetAnswer } from '@/store/actions';
import State from '@/store/state';
import { AssetType, Property, SurveyAsset, SurveyAssetList, UUID } from '@/store/types';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FlagEditor from '@/components/FlagEditor';
import AssetTypeMenu from '@/components/Question/AssetTypeMenu';
import AssetRenderer from './Asset';
import classes from './AssetType.module.css';
import NoteModal from './NoteModal';
import RepeatingAssetList from './RepeatingAssetList';
import RepeatingAssetTable from './RepeatingAssetTable';

enum RepeatingAssetTypeRenderer {
	ACCORDION = 'ACCORDION',
	TABLE = 'TABLE',
}

interface Props {
	assetTypeId: UUID;
}

const AssetTypeRenderer: FC<Props> = ({ assetTypeId }): JSX.Element | null => {
	const [repeatingAssetTypeRenderer, setRepeatingAssetTypeRenderer] =
		React.useState<RepeatingAssetTypeRenderer>(RepeatingAssetTypeRenderer.TABLE);

	const dispatch = useDispatch();
	const assetType = useSelector<State, AssetType>((state) => assetTypesGet(state, assetTypeId));

	const assetList = useSelector<State, SurveyAssetList>((state) =>
		surveyAssetListsGet(state, assetTypeId),
	);

	if (!assetType.survey) {
		throw new Error('non-activity asset type found in survey');
	}

	const [isNoteModalOpen, setNoteModalOpen] = useState(false);
	const [selectedPropertyForNote, setSelectedPropertyForNote] = useState<Property | null>(null);
	const [selectedAssetForNote, setSelectedAssetForNote] = useState<SurveyAsset | null>(null);

	const [isMenuOpen, setMenuOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>();

	const editNote: (e: React.MouseEvent<HTMLElement>) => void = (e) => {
		e.stopPropagation();
		setMenuOpen(false);
		setAnchorEl(null);
		setNoteModalOpen(true);
	};

	const deleteNote: (e: React.MouseEvent<HTMLElement>) => void = (e) => {
		e.stopPropagation();
		if (selectedAssetForNote && selectedAssetForNote.id && selectedPropertyForNote) {
			dispatch(
				assetAnswer({
					answer: selectedAssetForNote.valuesByPropertyId[selectedPropertyForNote.id],
					propertyId: selectedPropertyForNote.id,
					assetId: selectedAssetForNote.id,
					assetTypeId,
					noteText: null,
					applicable: selectedAssetForNote.applicableByPropertyId[selectedPropertyForNote.id],
					otherOption:
						selectedAssetForNote.otherOptionEnabledByPropertyId[selectedPropertyForNote.id],
					location: selectedAssetForNote.location[selectedPropertyForNote.id],
				}),
			);
			setMenuOpen(false);
			setAnchorEl(null);
			setSelectedAssetForNote(null);
			setSelectedPropertyForNote(null);
		}
	};

	const openNoteModal = (property: Property, asset: SurveyAsset): void => {
		setNoteModalOpen(true);
		setSelectedPropertyForNote(property);
		setSelectedAssetForNote(asset);
	};

	const onNoteOverflowClicked = (
		newAnchorEl: HTMLButtonElement | null,
		property: Property,
		asset: SurveyAsset,
	) => {
		setAnchorEl(newAnchorEl);
		setMenuOpen(true);
		setSelectedAssetForNote(asset);
		setSelectedPropertyForNote(property);
	};

	const contextMenu = (): JSX.Element => (
		<Menu
			anchorEl={anchorEl}
			keepMounted
			open={isMenuOpen}
			onClose={(): void => {
				setMenuOpen(false);
				setAnchorEl(null);
				setSelectedAssetForNote(null);
				setSelectedPropertyForNote(null);
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
		>
			<MenuItem onClick={editNote}>
				<ListItemIcon>
					<Edit fontSize="small" />
				</ListItemIcon>
				<ListItemText primary="Edit" />
			</MenuItem>
			<MenuItem onClick={deleteNote}>
				<ListItemIcon>
					<Delete fontSize="small" />
				</ListItemIcon>
				<ListItemText primary="Delete" />
			</MenuItem>
		</Menu>
	);

	return (
		<div className={classes.root}>
			{contextMenu()}
			{selectedAssetForNote && selectedPropertyForNote && (
				<NoteModal
					assetTypeId={assetList.assetTypeId}
					asset={selectedAssetForNote}
					property={selectedPropertyForNote}
					isModalOpen={isNoteModalOpen}
					setIsModalOpen={(open: boolean): void => {
						if (open) {
							setNoteModalOpen(true);
						} else {
							setSelectedPropertyForNote(null);
							setSelectedAssetForNote(null);
							setNoteModalOpen(false);
						}
					}}
				/>
			)}
			<div className={classes.topSide}>
				<Typography color="primary" variant="h6">
					{assetType.name}
				</Typography>
				<div>
					{!assetType.repeating && (
						<FlagEditor assetId={assetList.assets[0].id || undefined} assetTypeId={assetTypeId} />
					)}
					{assetType.repeating && (
						<>
							<ButtonGroup
								color="secondary"
								size="small"
								sx={{ verticalAlign: 'middle' }}
								variant="contained"
							>
								<Tooltip title="Display/Capture this Repeating Asset as a table">
									<Button
										data-testid="repeating-asset-renderer__table"
										onClick={() => setRepeatingAssetTypeRenderer(RepeatingAssetTypeRenderer.TABLE)}
										variant={
											repeatingAssetTypeRenderer === RepeatingAssetTypeRenderer.TABLE
												? 'contained'
												: 'outlined'
										}
									>
										<GridOnIcon />
									</Button>
								</Tooltip>
								<Tooltip title="Display/Capture this Repeating Asset in a form">
									<Button
										data-testid="repeating-asset-renderer__accordion"
										onClick={() =>
											setRepeatingAssetTypeRenderer(RepeatingAssetTypeRenderer.ACCORDION)
										}
										variant={
											repeatingAssetTypeRenderer === RepeatingAssetTypeRenderer.ACCORDION
												? 'contained'
												: 'outlined'
										}
									>
										<BallotIcon />
									</Button>
								</Tooltip>
							</ButtonGroup>
							<AssetTypeMenu
								assetType={assetType}
								assetIds={assetList.assets.flatMap((asset) => (asset.id ? [asset.id] : []))}
							/>
						</>
					)}
				</div>
			</div>

			{assetType.info && (
				<Typography gutterBottom sx={{ margin: 3, marginTop: 2 }}>
					<em>{assetType.info}</em>
				</Typography>
			)}

			{!assetType.repeating && (
				<AssetRenderer
					// there is always at least one asset, since the surveyAssetListsGet selector ensures that all
					// non-repeating assets types have at least a single survey asset
					asset={assetList.assets[0]}
					assetTypeId={assetList.assetTypeId}
					openNoteModal={openNoteModal}
					onNoteOverflowClicked={onNoteOverflowClicked}
				/>
			)}

			{assetType.repeating && (
				<>
					{repeatingAssetTypeRenderer === RepeatingAssetTypeRenderer.ACCORDION && (
						<RepeatingAssetList
							assetList={assetList}
							openNoteModal={openNoteModal}
							onNoteOverflowClicked={onNoteOverflowClicked}
							assetTypeId={assetTypeId}
						/>
					)}
					{repeatingAssetTypeRenderer === RepeatingAssetTypeRenderer.TABLE && (
						<RepeatingAssetTable surveyAssetList={assetList} />
					)}
				</>
			)}
		</div>
	);
};

export default AssetTypeRenderer;
