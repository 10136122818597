import { AutoAwesome } from '@mui/icons-material';
import { Chip, CircularProgress, Link, Tooltip, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useParams } from 'react-router-dom';

import useAssistFeedback from '@/net/reactQuery/mutations/useAssistFeedback';

import useKaasContext from './useKaasContext';
import useKaasPropertyAssist from './useKaasPropertyAssist';
import { retrieverTypes } from './utils';

interface KaasSuggestionChipsProps {
	disabled?: boolean;
	kaas?: ReturnType<typeof useKaasPropertyAssist>;
	onChange(payload: string | undefined): void;
}

const KaasSuggestionChips: React.FC<KaasSuggestionChipsProps> = ({
	disabled,
	kaas,
	onChange,
}: KaasSuggestionChipsProps) => {
	const { feedbackCount, filesPerTraceId, setAnswers, setFeedbackCount, setFilesPerTraceId } =
		useKaasContext();
	const { inspectionId } = useParams<Record<string, string>>();
	const { enqueueSnackbar } = useSnackbar();
	const theme = useTheme();

	const assistFeedbackMutation = useAssistFeedback(inspectionId, {
		onError: () => {
			enqueueSnackbar('Failed to give feedback. Please try again.');
		},
		onSuccess: (response, variables) => {
			if (response.answers && variables.feedbackType === 'ThumbsDown') {
				const [answer] = response.answers;
				setFeedbackCount((currentFeedbackCount) => {
					const newFeedbackCount =
						currentFeedbackCount[kaas!.suggestedAnswers.id] === 3
							? 1
							: currentFeedbackCount[kaas!.suggestedAnswers.id] + 1;
					return {
						...currentFeedbackCount,
						[kaas!.suggestedAnswers.id]: newFeedbackCount,
					};
				});
				setAnswers((currentAnswers) => ({ ...currentAnswers, [answer.id]: answer }));
				setFilesPerTraceId((currentFilesPerTraceId) => ({
					...currentFilesPerTraceId,
					[answer.traceId]: filesPerTraceId[kaas!.suggestedAnswers.traceId],
				}));
			}
		},
	});

	const onAnswerAccept = React.useCallback(
		(suggestion: string) => {
			onChange(suggestion);
			kaas!.trackKaasAcceptedAnswer(suggestion);
			// By the time we get here `kaas` will exist 100%.
			assistFeedbackMutation.mutate({
				feedbackType: 'ThumbsUp',
				files: filesPerTraceId[kaas!.suggestedAnswers.traceId],
				questions: [kaas!.suggestedAnswers.id],
				retrieverType: retrieverTypes[feedbackCount[kaas!.suggestedAnswers.id] || 1],
				traceId: kaas!.suggestedAnswers.traceId,
			});
		},
		[assistFeedbackMutation, kaas, onChange],
	);

	const onMarkNotUseful = React.useCallback(() => {
		// By the time we get here `kaas` will exist 100%.
		assistFeedbackMutation.mutate({
			feedbackType: 'ThumbsDown',
			files: filesPerTraceId[kaas!.suggestedAnswers.traceId],
			questions: [kaas!.suggestedAnswers.id],
			retrieverType: retrieverTypes[feedbackCount[kaas!.suggestedAnswers.id] || 1],
			traceId: kaas!.suggestedAnswers.traceId,
		});
	}, [assistFeedbackMutation, feedbackCount, kaas]);

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{kaas?.suggestedAnswers &&
				kaas.suggestedAnswers.numberOfAnswers > 0 &&
				kaas.suggestedAnswers.answer.map((suggestions) => (
					<React.Fragment key={suggestions}>
						<Tooltip
							title={
								kaas.suggestedAnswers.fileCitation === ''
									? ''
									: `Source ${kaas.suggestedAnswers.fileCitation}`
							}
							key={suggestions}
							slotProps={{
								popper: {
									modifiers: [
										{
											name: 'offset',
											options: {
												offset: [0, 27], // to avoid blocking the pressing of the button
											},
										},
									],
								},
							}}
						>
							<span>
								<Chip
									icon={<AutoAwesome fontSize="small" />}
									label={suggestions}
									disabled={disabled || assistFeedbackMutation.status === 'loading'}
									variant="filled"
									color="success"
									sx={{
										mt: 5,
										mr: 1,
									}}
									onClick={() => onAnswerAccept(suggestions)}
								/>
							</span>
						</Tooltip>
					</React.Fragment>
				))}
			<br />
			{kaas?.suggestedAnswers && kaas.suggestedAnswers.numberOfAnswers > 0 && (
				// eslint-disable-next-line jsx-a11y/anchor-is-valid
				<Link
					component="button"
					disabled={assistFeedbackMutation.status === 'loading'}
					onClick={onMarkNotUseful}
					type="button"
				>
					{assistFeedbackMutation.status === 'loading' && (
						<>
							<CircularProgress size={theme.typography.body1.fontSize} />
							&nbsp;
						</>
					)}
					These suggestions are not useful
				</Link>
			)}
		</>
	);
};

export default KaasSuggestionChips;
