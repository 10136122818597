import { StateFileStorage as State } from '@/store/state';
import makeSlicer from '@/utils/makeSlicer';

const rootSlicer = makeSlicer<State>();

export const substances = rootSlicer('substances');
export const tanks = rootSlicer('tanks');
export const assetTypes = rootSlicer('assetTypes');
export const pgsMappingReady = (state: State): boolean =>
	state.tanks.filename !== '' && state.substances.filename !== '';
