import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { useSelector } from 'react-redux';

import { assetTypesGet } from '@/selectors';
import State from '@/store/state';
import { Asset, AssetType, Property } from '@/store/types';

import FilesList from './FilesList';
import UploadFileButton from './UploadFileButton';

interface RelatedFilesDialogProps {
	assetId: Asset['id'] | null;
	assetTypeId: AssetType['id'];
	isOpen: boolean;
	onClose(): void;
	property: Property;
}

const RelatedFilesDialog: React.FC<RelatedFilesDialogProps> = ({
	assetId,
	assetTypeId,
	isOpen,
	onClose,
	property,
}) => {
	const assetType = useSelector<State, AssetType>((state) => assetTypesGet(state, assetTypeId));

	return (
		<Dialog onClose={onClose} open={isOpen}>
			<DialogTitle>Related files: {property.question || property.name}</DialogTitle>

			<DialogContent>
				<FilesList assetId={assetId} assetType={assetType} propertyId={property.id} />
			</DialogContent>

			<DialogActions>
				<Button color="primary" onClick={onClose} variant="outlined">
					Close
				</Button>
				<UploadFileButton assetId={assetId} assetType={assetType} property={property} />
			</DialogActions>
		</Dialog>
	);
};

export default RelatedFilesDialog;
