import { useMsal } from '@azure/msal-react';
import MUIAvatar from '@mui/material/Avatar';
import { ApiUserProfileDetails, getUserImageFromAD } from 'kes-common';
import React from 'react';

import msalInstance from '@/authentication/msal';

interface AvatarProps {
	name: ApiUserProfileDetails['name'];
}

const Avatar: React.FC<AvatarProps> = ({ name }) => {
	const { instance } = useMsal();
	const [image, setImage] = React.useState<string>('');

	React.useEffect(() => {
		getUserImageFromAD({
			account: instance.getActiveAccount()!,
			publicClientApplication: msalInstance,
		}).then((blob) => {
			if (blob) {
				const url = window.URL || window.webkitURL;
				const blobUrl = url.createObjectURL(blob);
				setImage(blobUrl);
			}
		});
	}, []);

	return (
		<MUIAvatar
			alt={name || undefined}
			src={image}
			sx={{
				backgroundColor: 'primary.dark',
				border: '3px solid white',
				height: 96,
				position: 'absolute',
				top: -72,
				width: 96,
			}}
		>
			{name?.split(' ').map((part) => part[0])}
		</MUIAvatar>
	);
};

export default Avatar;
