import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ApiPublishedStudyDto } from 'kes-common';
import React from 'react';
import Link from '@mui/material/Link';
import * as Styled from './AvailableStudy.styled';

interface AvailableStudyProps {
	study: ApiPublishedStudyDto;
	onAddInspectionToProject: (studyId: string) => void;
	onStudySelect: () => void;
}

const AvailableStudy: React.FC<AvailableStudyProps> = ({
	study,
	onAddInspectionToProject,
	onStudySelect,
}) => {
	const formattedDate = new Intl.DateTimeFormat('en-GB').format(new Date(study.lastPublished));
	return (
		<Styled.AvailableStudyContainer>
			<Styled.AvailableStudyMetaContainer>
				{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
				<Link
					variant="subtitle1"
					component="button"
					onClick={onStudySelect}
					sx={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}
					underline="hover"
					data-testid={`action-open-inspection-preview-${study.name.toLocaleLowerCase()}`}
				>
					{study.name}
				</Link>
				<Typography>
					<em>{study.type === 'WORD' ? 'Word' : 'Kes'}</em>
				</Typography>
				<Tooltip title={study.description || ''}>
					<Typography gutterBottom noWrap textOverflow="ellipsis" variant="body2">
						{study.description}
					</Typography>
				</Tooltip>
				<Typography variant="body1">
					{study.number.toString().padStart(4, '0')}
					<Typography color="grey.700" sx={{ marginLeft: 1 }} variant="caption">
						Last published: {formattedDate}
					</Typography>
				</Typography>
			</Styled.AvailableStudyMetaContainer>
			<Styled.CounterContainer>
				<Button
					color="primary"
					data-testid={`action-add-inspection-from-drawer-${study.name.toLocaleLowerCase()}`}
					onClick={() => onAddInspectionToProject(study.id)}
					variant="outlined"
					size="small"
				>
					add
				</Button>
			</Styled.CounterContainer>
		</Styled.AvailableStudyContainer>
	);
};

export default AvailableStudy;
