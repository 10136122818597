import React, { useState, ReactNode, useEffect } from 'react';
import { isEmpty } from 'lodash';

import useSelectedFilesKaas from '@/hooks/useSelectedFilesKaas';
import useSelectedQuestionsKaas from '@/hooks/useSelectedQuestionsKaas';

import KaasContext from './context';
import { Answers, FeedbackCount, FilesPerTraceId, KaasContextProps } from './types';

interface KaasContextProviderProps {
	children: ReactNode;
	inspectionId: string;
}

function KaasContextProvider({ children, inspectionId }: KaasContextProviderProps): JSX.Element {
	const [hasKaasAnswered, setHasKaasAnswered] = useState(false);
	const [answers, setAnswers] = useState<Answers>({});
	const [feedbackCount, setFeedbackCount] = React.useState<FeedbackCount>({});
	const [filesPerTraceId, setFilesPerTraceId] = React.useState<FilesPerTraceId>({});

	useEffect(() => {
		const storedFiles = localStorage.getItem('files_per_trace_id');
		if (storedFiles) {
			const storedFilesParsed = JSON.parse(storedFiles);
			setFilesPerTraceId(storedFilesParsed);
		}
	}, [setFilesPerTraceId]);

	useEffect(() => {
		if (isEmpty(answers)) {
			const storedAnswers = localStorage.getItem(`answers_${inspectionId}`);
			if (storedAnswers) {
				const storedAnswersParsed: Answers = JSON.parse(storedAnswers);
				setAnswers(storedAnswersParsed);
				setHasKaasAnswered(true);
			}
		}
	}, [answers, inspectionId, setAnswers, setFeedbackCount]);

	useEffect(() => {
		if (!isEmpty(answers)) {
			localStorage.setItem(`answers_${inspectionId}`, JSON.stringify(answers));
		}
	}, [inspectionId, answers]);

	useEffect(() => {
		setFeedbackCount((currentFeedbackCount) =>
			Object.values(answers).reduce((accumulator, answer) => {
				const currentAnswerFeedbackCount = currentFeedbackCount[answer.id];
				return { ...accumulator, [answer.id]: currentAnswerFeedbackCount || 1 };
			}, currentFeedbackCount),
		);
	}, [answers, setFeedbackCount]);

	useEffect(() => {
		localStorage.setItem('files_per_trace_id', JSON.stringify(filesPerTraceId));
	}, [filesPerTraceId]);

	const { selectedFiles, selectFile, pruneFiles } = useSelectedFilesKaas(inspectionId);
	const { selectedQuestions, selectQuestion, pruneQuestions } =
		useSelectedQuestionsKaas(inspectionId);

	const value = React.useMemo<KaasContextProps>(
		() => ({
			answers,
			feedbackCount,
			filesPerTraceId,
			hasKaasAnswered,
			inspectionId,
			pruneFiles,
			pruneQuestions,
			selectedFiles,
			selectedQuestions,
			selectFile,
			selectQuestion,
			setAnswers,
			setFeedbackCount,
			setFilesPerTraceId,
			setHasKaasAnswered,
		}),
		[
			answers,
			feedbackCount,
			filesPerTraceId,
			hasKaasAnswered,
			inspectionId,
			pruneFiles,
			pruneQuestions,
			selectedFiles,
			selectedQuestions,
			selectFile,
			selectQuestion,
			setAnswers,
			setFeedbackCount,
			setFilesPerTraceId,
			setHasKaasAnswered,
		],
	);

	return <KaasContext.Provider value={value}>{children}</KaasContext.Provider>;
}

// eslint-disable-next-line import/prefer-default-export
export { KaasContextProvider };
