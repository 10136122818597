import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useQueryClient } from '@tanstack/react-query';
import { ApiProjectInspectionDto, ApiInspectionUserDto, NameAvatar } from 'kes-common';
import { useSnackbar } from 'notistack';
import React from 'react';

import useActivityMemberRemove from '@/net/reactQuery/mutations/useActivityMemberRemove';

interface MembersListItemProps {
	activityId: ApiProjectInspectionDto['id'];
	onMemberRemoveError?(): void;
	onMemberRemoveSuccess?(): void;
	user: ApiInspectionUserDto;
}

const MembersListItem: React.FC<MembersListItemProps> = ({
	activityId,
	onMemberRemoveError,
	onMemberRemoveSuccess,
	user,
}) => {
	const hasName = React.useMemo<boolean>(() => Boolean(user.name), [user]);

	const queryClient = useQueryClient();
	const { enqueueSnackbar } = useSnackbar();

	const handleMemberRemoveError = React.useCallback(() => {
		if (onMemberRemoveError) {
			onMemberRemoveError();
		}
		enqueueSnackbar('There was an error removing this inspector from the activity');
	}, [enqueueSnackbar, onMemberRemoveError]);
	const handleMemberRemoveSuccess = React.useCallback(() => {
		if (onMemberRemoveSuccess) {
			onMemberRemoveSuccess();
		}
		queryClient.invalidateQueries(['activity-members', activityId]);
	}, [activityId, onMemberRemoveSuccess, queryClient]);

	const { mutate, status } = useActivityMemberRemove(activityId, user.userId, {
		onError: handleMemberRemoveError,
		onSuccess: handleMemberRemoveSuccess,
	});

	return (
		<ListItem
			disablePadding
			key={user.userId}
			secondaryAction={
				<IconButton disabled={status === 'loading'} onClick={() => mutate()}>
					<DeleteIcon />
				</IconButton>
			}
		>
			<ListItemButton>
				<ListItemAvatar>
					<NameAvatar isEmail={!hasName} name={user.name || user.email} />
				</ListItemAvatar>
				<ListItemText
					primary={hasName ? user.name : user.email}
					secondary={hasName ? user.email : undefined}
				/>
			</ListItemButton>
		</ListItem>
	);
};

export default MembersListItem;
