import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { ApiInspectionDto, ApiScript } from 'kes-common';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useDispatch } from 'react-redux';

import useInspectionScriptFileDelete from '@/net/reactQuery/mutations/useInspectionScriptFileDelete';
import { inspectionLoadSuccess } from '@/store/actions';

interface DeleteOptions {
	inspection: ApiInspectionDto;
	script: ApiScript;
}

const Delete: React.FC<DeleteOptions> = ({ inspection, script }) => {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const deleteInspectionScriptFile = useInspectionScriptFileDelete(inspection.id);

	const onDelete: React.MouseEventHandler<HTMLButtonElement> = React.useCallback(() => {
		deleteInspectionScriptFile.mutate(undefined, {
			onError: () => {
				enqueueSnackbar('Failed to run the script, please try again.');
			},
			onSuccess: () => {
				enqueueSnackbar(`Script file (${script.fileName}) successfully removed`);
				dispatch(
					inspectionLoadSuccess({
						...inspection,
						scripts: inspection.scripts.filter((s) => s !== script),
					}),
				);
			},
		});
	}, [dispatch, enqueueSnackbar, deleteInspectionScriptFile]);

	return (
		<Tooltip title="Delete the script file(s)">
			<IconButton edge="end" aria-label="Delete" onClick={onDelete}>
				<DeleteIcon color="secondary" />
			</IconButton>
		</Tooltip>
	);
};

export default Delete;
