import React from 'react';
import { Alert, Button } from '@mui/material';

interface AlertRefetchProps {
	refetch: () => void;
	message?: string;
}

/**
 * Renders an Alert component with a retry button
 * This component must be displayed used when React Query returns an error on fetch.
 */
function AlertRefetch({ refetch, message }: AlertRefetchProps): JSX.Element {
	return (
		<Alert
			severity="warning"
			action={
				<Button onClick={refetch} color="inherit" size="small">
					Retry
				</Button>
			}
		>
			{message || 'Something went wrong'}
		</Alert>
	);
}

export default AlertRefetch;
