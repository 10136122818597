import React from 'react';
import * as Styled from './AddExtraButton.styled';

type AddExtraButtonProps = {
	children: React.ReactNode;
	onClick: () => void;
	dataTestId?: string;
};

const AddExtraButton: React.FC<AddExtraButtonProps> = ({ onClick, children, dataTestId }) => (
	<Styled.AddAnotherAnswer role="button" color="primary" onClick={onClick} data-testid={dataTestId}>
		<Styled.CircleIcon /> {children}
	</Styled.AddAnotherAnswer>
);

export default AddExtraButton;
