import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { format, utcToZonedTime } from 'date-fns-tz';
import React from 'react';
import { useQuery } from '@tanstack/react-query';

import MicrosoftTeamsIcon from '@/assets/microsoft_teams.svg';
import { knowledgeGroupsGet, timezonesGet } from '@/net/api';

import Avatar from '../components/Avatar';
import { useUser } from '../context';

const BasicInformation: React.FC = () => {
	const { user } = useUser();
	const queryKnowledgeGroups = useQuery(['knowledgeGroups'], () => knowledgeGroupsGet());
	const queryTimezones = useQuery(['timezones'], () => timezonesGet());

	const userKnowledgeGroups = React.useMemo(() => {
		if (queryKnowledgeGroups.isSuccess && queryKnowledgeGroups.data.status === 200) {
			return queryKnowledgeGroups.data
				.expectSuccess()
				.filter((knowledgeGroup) =>
					user.userProfileDetails.knowledgeGroupIds.includes(knowledgeGroup.id),
				)
				.map((knowledgeGroup) => knowledgeGroup.name)
				.join(', ');
		}
		return '';
	}, [queryKnowledgeGroups, user]);

	const userTimeZone = React.useMemo(() => {
		if (queryTimezones.isSuccess && queryTimezones.data.status === 200) {
			return queryTimezones.data.result.find(
				(timeZone) => timeZone.zoneId === user.userProfileDetails.timeZoneId,
			);
		}
		return undefined;
	}, [queryTimezones, user]);

	return (
		<Card sx={{ marginBottom: 2, overflow: 'visible' }}>
			<CardContent sx={{ padding: 3, position: 'relative' }}>
				<Avatar name={user.userProfileDetails.name} />
				<Grid container sx={{ marginTop: 3 }}>
					<Grid item xs={12} lg={6}>
						<Box marginBottom={3}>
							<Typography color="grey.700" variant="overline">
								Name
							</Typography>
							<Typography data-testid="user__profile__name" variant="body1">
								{user.userProfileDetails.name}
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} lg={6}>
						<Box marginBottom={3}>
							<Typography color="grey.700" variant="overline">
								Email
							</Typography>
							<Typography data-testid="user__profile__email" variant="body1">
								{user.userProfileDetails.email}
							</Typography>
						</Box>
					</Grid>

					<Grid item xs={12} lg={6}>
						<Box marginBottom={3}>
							<Typography color="grey.700" variant="overline">
								Department
							</Typography>
							<Typography data-testid="user__profile__department" variant="body1">
								{user.userProfileDetails.department || '-'}
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} lg={6}>
						<Box marginBottom={3}>
							<Typography color="grey.700" variant="overline">
								Office
							</Typography>
							<Typography data-testid="user__profile__office" variant="body1">
								{user.userProfileDetails.officeLocation || '-'}
							</Typography>
						</Box>
					</Grid>

					<Grid item xs={12} lg={6}>
						<Box marginBottom={3}>
							<Typography color="grey.700" variant="overline">
								Employee number
							</Typography>
							<Typography data-testid="user__profile__employee-number" variant="body1">
								-
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} lg={6}>
						<Box marginBottom={3}>
							<Typography color="grey.700" variant="overline">
								Local time
							</Typography>
							<Typography data-testid="user__profile__local-time" variant="body1">
								{userTimeZone &&
									`${format(utcToZonedTime(new Date(), userTimeZone.offset), 'HH:mm')} (${
										userTimeZone.offset
									})`}
							</Typography>
						</Box>
					</Grid>

					<Grid item xs={12} lg={6}>
						<Box marginBottom={3}>
							<Typography color="grey.700" variant="overline">
								Job title
							</Typography>
							<Typography data-testid="user__profile__job-title" variant="body1">
								{user.userProfileDetails.jobTitle || '-'}
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} lg={6}>
						<Box marginBottom={3}>
							<Typography color="grey.700" variant="overline">
								Knowledge groups
							</Typography>
							<Typography data-testid="user__profile__knowledge-group" variant="body1">
								{userKnowledgeGroups}
							</Typography>
						</Box>
					</Grid>

					<Grid item xs={12}>
						<Box marginBottom={3}>
							<Typography color="grey.700" variant="overline">
								Skills and expertise
							</Typography>
							<Typography data-testid="user__profile__skills-expertise" variant="body1">
								{user.userProfileDetails.skillsAndExpertise || '-'}
							</Typography>
						</Box>
					</Grid>

					<Grid item xs={12}>
						<Typography color="grey.700" variant="overline">
							On the web
						</Typography>
						<Box>
							<Button
								data-testid="user__profile__link--microsoft-teams"
								href={`https://teams.microsoft.com/l/chat/0/0?users=${user.userProfileDetails.email}`}
								startIcon={
									<Icon>
										<img alt="Microsoft Teams" src={MicrosoftTeamsIcon} />
									</Icon>
								}
								sx={{ marginRight: 4 }}
							>
								Microsoft Teams
							</Button>
							{user.userProfileDetails.linkedInLink && (
								<Button
									data-testid="user__profile__link--linkedin"
									href={user.userProfileDetails.linkedInLink}
									startIcon={<LinkedInIcon />}
								>
									LinkedIn
								</Button>
							)}
						</Box>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

export default BasicInformation;
