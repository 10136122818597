import MUICollapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import styled from 'styled-components';

export const RepeatingAsset = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0;
	width: 100%;
	border-color: #aebad0; //dark-blue-200
	border-style: solid;
	border-width: 1px 1px 0 1px;

	&:nth-last-child(2) {
		border-width: 1px;
		border-bottom-right-radius: 4px;
		border-bottom-left-radius: 4px;
	}

	&:first-child {
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;
	}
`;

export const Expand = styled.div`
	background-color: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	cursor: pointer;
	padding-left: ${({ theme }) => theme.spacing(3)};
	padding-right: ${({ theme }) => theme.spacing(3)};
`;

export const Title = styled(Typography)`
	padding-top: ${({ theme }) => theme.spacing(2)};
	padding-bottom: ${({ theme }) => theme.spacing(2)};
	font-weight: 600;
	flex: 1;
`;

export const DeleteIcon = styled(DeleteOutlineIcon)`
	color: black;
`;

export const Collapse = styled(MUICollapse)`
	border-top: ${({ isOpen }: { isOpen: boolean }) => (isOpen ? 'solid 1px #aebad0' : '')};
`;
