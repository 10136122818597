import { ApiInspectionDto } from 'kes-common';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { inspectionScriptFileUpload } from '../../api';

interface MutationOptions {
	file: Blob;
}

const useInspectionScriptFileUpload = (
	inspectionId: ApiInspectionDto['id'],
	options?: UseMutationOptions<unknown, unknown, MutationOptions>,
) =>
	useMutation(
		(mutationOptions) =>
			inspectionScriptFileUpload(inspectionId, mutationOptions).then((response) =>
				response.expectSuccess(),
			),
		options,
	);

export default useInspectionScriptFileUpload;
