import Typography from '@mui/material/Typography';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import styled from 'styled-components';

export const AddAnotherAnswer = styled(Typography)`
	font-weight: 500;
	cursor: pointer;
	display: flex;
	align-items: center;
`;

export const CircleIcon = styled(AddCircleOutline)`
	margin-right: ${({ theme }) => theme.spacing(1)};
`;
