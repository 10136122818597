import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import { renderArrayLength } from 'kes-common/src/components/AssistPanel/utils';

import { ApiInspectionDto, useGoogleTagManager, useUser } from 'kes-common';
import { buildRoute, inspectionRelatedFiles, inspectionURLs, projectReport } from '@/routes';
import { surveyCategoryIds } from '@/selectors';
import State from '@/store/state';
import { getAllRelatedFiles } from '@/selectors/files';
import DescriptionIcon from '@mui/icons-material/Description';
import React, { CSSProperties, FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { loaded } from '@/selectors/project';
import * as Styled from './SideBar.styles';
import CategoryElement from './CategoryElement';
import usePrevious from '../hooks/usePrevious';
import ButtonClearData from './ButtonClearData';
import ScriptFile from './ScriptFile';
import ButtonLinkInspectionComplete from './ButtonLinkInspectionComplete';

interface SidebarProps {
	onClose(): void;
}

const SideBar: FC<SidebarProps> = ({ onClose }) => {
	const { projectId } = useParams<Record<string, string>>();

	const categoryIds = useSelector<State, ReturnType<typeof surveyCategoryIds>>((state) =>
		surveyCategoryIds(state),
	);

	const previousCategoryIds = usePrevious(categoryIds);
	const newCategories = new Set(
		categoryIds.filter((categoryId) => previousCategoryIds?.indexOf(categoryId) === -1),
	);

	const inspection = useSelector<State, ApiInspectionDto | undefined>(
		(state) => state.inspection.currentInspection,
	);

	const relatedFiles = useSelector(getAllRelatedFiles);

	// Check if user is allow to download report
	const { user } = useUser();
	const allowReportDownload: boolean = useMemo(() => {
		const userId = user?.user.id;
		const users = inspection?.users;
		if (users) {
			const matchingUser = users.find((u) => u.userId === userId);
			if (matchingUser?.allowReportDownload === false) return false;
		}
		return true;
	}, [inspection, user]);
	const reportButtonStyle: CSSProperties = allowReportDownload
		? {}
		: { pointerEvents: 'none', opacity: 0.3 };

	const isInspectionLoaded = useSelector<State, boolean>((state) => loaded(state.inspection));

	const { trackCustomEvent } = useGoogleTagManager();

	if (!inspection || !isInspectionLoaded) {
		return null;
	}

	const categoryListElements = categoryIds.map(
		(categoryId): JSX.Element => (
			<CategoryElement
				flash={newCategories.has(categoryId)}
				key={categoryId}
				categoryId={categoryId}
				onClick={onClose}
				projectId={projectId}
			/>
		),
	);

	return (
		<Styled.Root>
			<Styled.List>
				<Styled.ListTitle color="primary.dark" variant="subtitle1">
					Categories
				</Styled.ListTitle>
				{categoryListElements}
			</Styled.List>
			<Box px={0} sx={{ width: '296px' }}>
				<Divider sx={{ marginY: 1 }} />
				<Button
					color="primary"
					component={Link}
					fullWidth
					to={buildRoute(inspectionRelatedFiles, { inspectionId: inspection.id, projectId })}
					variant="text"
				>
					Related files {renderArrayLength(relatedFiles, 2)}
				</Button>
				<Button
					color="primary"
					component={Link}
					fullWidth
					to={buildRoute(inspectionURLs, { inspectionId: inspection.id, projectId })}
					variant="text"
				>
					Related links {renderArrayLength(inspection.urls)}
				</Button>
				<Divider sx={{ marginY: 1 }} />
				<ScriptFile inspection={inspection} />
				<Divider sx={{ marginY: 1 }} />
				<ButtonClearData inspectionId={inspection.id} onClose={onClose} />
				<ButtonLinkInspectionComplete inspectionId={inspection.id} projectId={projectId} />
				<Divider sx={{ marginY: 1 }} />
				{inspection.sharepointUrl && (
					<Button
						color="secondary"
						fullWidth
						href={inspection.sharepointUrl}
						startIcon={<DescriptionIcon />}
						target="_blank"
						variant="text"
					>
						Open Word Report
					</Button>
				)}

				{!inspection.sharepointUrl && (
					<Tooltip
						title={
							allowReportDownload
								? null
								: 'The administrator disabled your ability to view/download the report'
						}
						followCursor
					>
						<span>
							<Styled.DownloadButton
								component={Link}
								// RdJ: Open in new tab because we need to auto submit a form in Report.tsx
								target="_blank"
								to={`${buildRoute(projectReport, {
									inspectionId: inspection.id,
								})}?variant=html`}
								color="secondary"
								data-testid="download-styled-report"
								onClick={() =>
									trackCustomEvent({ event: 'activity-report-viewed', variant: 'html' })
								}
								style={reportButtonStyle}
							>
								<DescriptionIcon />
								View Report online
							</Styled.DownloadButton>
							<Styled.DownloadButton
								component={Link}
								// RdJ: Open in new tab because we need to auto submit a form in Report.tsx
								target="_blank"
								to={buildRoute(projectReport, {
									inspectionId: inspection.id,
								})}
								color="secondary"
								data-testid="download-report"
								onClick={() =>
									trackCustomEvent({ event: 'activity-report-viewed', variant: 'word' })
								}
								style={reportButtonStyle}
							>
								<DescriptionIcon />
								Download Report
							</Styled.DownloadButton>

							<Styled.DownloadButton
								component={Link}
								// RdJ: Open in new tab because we need to auto submit a form in Report.tsx
								target="_blank"
								to={`${buildRoute(projectReport, {
									inspectionId: inspection.id,
								})}?variant=experimental`}
								color="secondary"
								data-testid="download-styled-report"
								onClick={() =>
									trackCustomEvent({ event: 'activity-report-viewed', variant: 'word-styled' })
								}
								style={reportButtonStyle}
							>
								<DescriptionIcon />
								Download styled report (Experimental)
							</Styled.DownloadButton>
						</span>
					</Tooltip>
				)}

				<Styled.Version variant="body1">
					Template version: {inspection?.assetLibrary.version}
				</Styled.Version>
			</Box>
		</Styled.Root>
	);
};

export default SideBar;
