import UploadBoxBase from '@/components/UploadBox/UploadBase';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { UploadBoxProps } from '@/components/UploadBox';

const UploadBoxDeleting = ({ filename }: Pick<UploadBoxProps, 'filename'>): JSX.Element => (
	<UploadBoxBase filename={filename}>
		<CircularProgress size={24} />
	</UploadBoxBase>
);

export default UploadBoxDeleting;
