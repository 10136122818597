/* eslint-disable no-param-reassign */
import { Reducer, Action, combineReducers } from 'redux';

import actionsReducer from './actions';
import changes from './changes';
import files from './files';
import flags from './flags';
import inspection from './inspection';
import ruleSets from './ruleSets';
import AppAction from '../appAction';
import State from '../state';
import router from './router';
import substanceStorage from './fileStorage';
import myInspections from './myInspections';
import project from './project';
import categories from './categories';
import assetTypes from './assetTypes';
import assets from './assets';
import assetLists from './assetLists';
import properties from './properties';
import choices from './choices';
import user from './user';
import ui from './ui';
import * as actions from '../actions';
import makeReducer from '../makeReducer';
import { makeUnorderedRepository } from '../repository';
import { Asset, AssetList } from '../types';

const combinedReducer = combineReducers<State, AppAction>({
	actions: actionsReducer,
	files,
	flags,
	ruleSets,
	router,
	substanceStorage,
	project,
	categories,
	assetTypes,
	assetLists,
	assets,
	myInspections,
	properties,
	choices,
	user,
	inspection,
	ui,
	changes,
});

const repositoryAssetLists = makeUnorderedRepository<AssetList>();
const repositoryAssets = makeUnorderedRepository<Asset>();

const crossSliceReducer = makeReducer(combinedReducer(undefined, { type: '@@INIT' }), {
	[actions.deleteAssetsAndImages.type]: (draft) => {
		Object.keys(draft.assets.byId).forEach((assetId) => {
			const assetReference = Object.values(draft.assetLists.byId).find((assetListItem) =>
				assetListItem.assets.includes(assetId),
			);
			if (assetReference) {
				repositoryAssetLists.modify(draft.assetLists, assetReference.id, (item) => {
					item.assets = [];
				});
				const assetType = draft.assetTypes.byId[assetReference.id];
				if (!assetType.fixed) {
					repositoryAssets.delete(draft.assets, assetId);
				}
			}
		});
	},
});

const rootReducer: Reducer<State, Action> = (
	state: State | undefined,
	action: AppAction,
): State => {
	const intermediateState = combinedReducer(state, action);
	return crossSliceReducer(intermediateState, action);
};

export default rootReducer;
