import MUICloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

export const CloseIcon = styled(MUICloseIcon)`
	margin-left: ${({ theme }) => theme.spacing(2)};
	cursor: pointer;
`;

export const QuestionWrapper = styled('div')`
	display: flex;
	align-items: center;
	margin-bottom: ${({ theme }) => theme.spacing(2)};
`;
