import Typography from '@mui/material/Typography';
import React, { ReactNode } from 'react';
import UploadBoxDone from '@/components/UploadBox/UploadBoxDone';
import { UploadBoxProps } from '@/components/UploadBox';
import * as Styled from '@/components/UploadBox/UploadBox.styles';
import UploadBoxEmpty from '../UploadBoxEmpty';

const UploadBoxError = (
	props: UploadBoxProps & {
		noDownload: boolean;
	},
): JSX.Element => {
	const { errors, filename } = props;
	const [message, ...details] = errors;

	return (
		<>
			{filename && <UploadBoxDone {...props} />}
			{!filename && <UploadBoxEmpty setError={() => {}} {...props} />}
			<Styled.RootError>
				<Styled.ErrorIcon />
				<Styled.Text>
					{message.split('\n').map(
						(line): ReactNode => (
							<Typography color="error" key={line} variant="body1">
								{line}
							</Typography>
						),
					)}
					<Styled.ErrorList>
						{details.map(
							(line): JSX.Element => (
								<Styled.ErrorListItem key={line}>{line}</Styled.ErrorListItem>
							),
						)}
					</Styled.ErrorList>
				</Styled.Text>
			</Styled.RootError>
		</>
	);
};

export default UploadBoxError;
