import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { ApiAuthorizedProjectDto, Loader, PageTitle, useFeatureFlag, useUser } from 'kes-common';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ProjectCard from '@/components/rhdhv/ProjectCard';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import { projectCreate, projectImport } from '@/routes';
import { projects as projectsSelector } from '@/selectors';
import { projectOverviewLoad } from '@/store/actions';
import State from '@/store/state';
import * as Styled from './index.styled';

const Projects: React.FC = () => {
	useDocumentTitle('Project Manager', 'Projects');

	const loaded = useSelector<State, boolean>((state) => state.project.loaded);
	const { user } = useUser();
	const dispatch = useDispatch();
	const history = useHistory();
	const featureProjectImport = useFeatureFlag({ name: 'PROJECT_IMPORT' });

	const projects = useSelector<State, ApiAuthorizedProjectDto[]>((state) =>
		[...projectsSelector(state)]
			.sort(
				(a: ApiAuthorizedProjectDto, b: ApiAuthorizedProjectDto): number =>
					new Date(a.project.createdOn).getTime() - new Date(b.project.createdOn).getTime(),
			)
			.reverse(),
	);

	const projectPlaceholderText = (): string =>
		`You are not part of any projects.${
			user.hasCreateProjectPrivilege ? ' Create one to start!' : ''
		}`;

	useEffect(() => {
		dispatch(projectOverviewLoad());
	}, []);

	return (
		<Container maxWidth="lg">
			<PageTitle
				actions={
					featureProjectImport ? (
						<Button
							size="small"
							variant="contained"
							color="primary"
							onClick={(): void => history.push(projectImport)}
							data-testid="action-import-project"
						>
							Create project
						</Button>
					) : (
						<Button
							size="small"
							variant="contained"
							color="primary"
							onClick={(): void => history.push(projectCreate)}
							data-testid="action-create-project"
						>
							Create
						</Button>
					)
				}
				title="Projects"
			/>
			<Box marginTop={2}>
				{!loaded && <Loader />}
				{projects.length > 0 ? (
					<Grid container spacing={2}>
						{projects.map((authorizedProject) => (
							<Grid
								item
								key={authorizedProject.project.id}
								md={6}
								lg={4}
								sm={12}
								xs={12}
								data-testid={authorizedProject.project.id}
							>
								<ProjectCard authorizedProject={authorizedProject} />
							</Grid>
						))}
					</Grid>
				) : (
					<Styled.EmptyProjectsContainer>
						<p>{projectPlaceholderText()}</p>{' '}
					</Styled.EmptyProjectsContainer>
				)}
			</Box>
		</Container>
	);
};

export default Projects;
