import UploadBoxBase from '@/components/UploadBox/UploadBase';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MenuItem } from '@mui/material';
import { UploadBoxProps } from '@/components/UploadBox';

interface UploadBoxDoneProps {
	caption?: string;
	disabled?: boolean;
	noDownload: boolean;
	onEditCaption?(): void;
	presignedURL?: string;
}

type Props = Pick<UploadBoxProps, 'filename' | 'onDelete' | 'onDownload'> & UploadBoxDoneProps;

const UploadBoxDone: React.FC<Props> = ({
	caption,
	filename,
	disabled,
	onDownload,
	onEditCaption,
	noDownload,
	onDelete,
	presignedURL,
}): JSX.Element => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLAnchorElement | HTMLButtonElement | null>(
		null,
	);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
		if (event) {
			setAnchorEl(event.currentTarget);
		}
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<UploadBoxBase
			caption={caption}
			filename={filename}
			disabled={disabled}
			presignedURL={presignedURL}
		>
			<IconButton
				data-testid="image-overflow"
				aria-label="more"
				aria-controls="download-menu"
				aria-haspopup="true"
				onClick={(event) => handleClick(event)}
				size="large"
			>
				<MoreVertIcon />
			</IconButton>

			<Menu id="download-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
				{!noDownload && (
					<MenuItem data-testid="file-download" onClick={onDownload}>
						Download
					</MenuItem>
				)}
				{onEditCaption && (
					<MenuItem
						onClick={() => {
							onEditCaption();
							handleClose();
						}}
					>
						Edit caption
					</MenuItem>
				)}
				<MenuItem data-testid="file-delete" onClick={onDelete}>
					Delete
				</MenuItem>
			</Menu>
		</UploadBoxBase>
	);
};

export default UploadBoxDone;
