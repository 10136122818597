import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { NameAvatar } from 'kes-common';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { buildRoute, userProfile } from '@/routes';

import * as Styled from './RoleListItem.styled';

export interface UserDto {
	userId: string;
	roles: ('INSPECTION_EDITOR' | 'PROJECT_ADMINISTRATOR')[];
	globalAdmin: boolean;
	name: string | null;
	email: string;
	status?: 'ACTIVE' | 'PENDING';
}

interface Props {
	user: UserDto;
	onRemoveClicked: (user: UserDto) => void;
	roleDescription: string;
	hasDeletePrivileges?: boolean;
}

const RoleListItem: React.FC<Props> = ({
	user,
	onRemoveClicked,
	roleDescription,
	hasDeletePrivileges = true,
}) => {
	const { email, name, status, userId } = user;

	const removeUser = async () => {
		await onRemoveClicked(user);
	};

	return (
		<Styled.RoleListItemContainer>
			<NameAvatar name={name || email} isEmail={!name} />
			<div>
				{name && (
					<Link
						color="primary.dark"
						component={RouterLink}
						to={buildRoute(userProfile, { userId })}
						underline="none"
					>
						{name} | {roleDescription}
					</Link>
				)}
				{email && <Styled.Email name={name}>{email}</Styled.Email>}
			</div>
			{status === 'PENDING' && (
				<Typography color="textSecondary" variant="subtitle1">
					Pending
				</Typography>
			)}
			{status === 'ACTIVE' && <span />}
			{user.userId !== 'rhdhv-admin-id' && hasDeletePrivileges && (
				<Styled.Button
					variant="text"
					onClick={removeUser}
					data-testid={`delete-user-${user.email}`}
				>
					<Styled.RemoveIcon fontSize="small" />
					Remove
				</Styled.Button>
			)}
		</Styled.RoleListItemContainer>
	);
};

export default RoleListItem;
