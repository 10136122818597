import { ApiError } from 'kes-common';
import { call, put } from 'redux-saga/effects';

import { myInspectionsList } from '@/net/api';
import * as actions from '@/store/actions';
import { Result } from '@/store/utils';

// eslint-disable-next-line import/prefer-default-export
export function* loadMyInspections() {
	try {
		const response: Result<typeof myInspectionsList> = yield call(myInspectionsList);
		yield put(actions.myInspectionsSuccess(response.expectSuccess()));
	} catch (error) {
		yield put(actions.myInspectionsError(error as ApiError));
	}
}
