/* eslint-disable @typescript-eslint/no-use-before-define */
import { ApiActionDto, ApiRuleDto } from 'kes-common';
import State from '@/store/state';
import { composeSelectors } from '@/store/utils';
import makeSlicer from '@/utils/makeSlicer';
import {
	Asset,
	AssetList,
	AssetType,
	Choice,
	Property,
	PropertyDependentCombinator,
	PropertyType,
	SurveyAsset,
	SurveyAssetList,
	UUID,
} from '@/store/types';
import { defaultMemoize } from 'reselect';
import unique from 'lodash/uniq';
import isNotApplicable from '@/utils/isNotApplicable';
import * as fileStorage from './fileStorage';
import * as project from './project';
import * as assets from './assets';
import * as properties from './properties';
import * as choices from './choices';
import * as categories from './categories';
import * as assetTypes from './assetTypes';
import * as assetLists from './assetLists';
import * as myInspections from './myInspections';

const rootSlicer = makeSlicer<State>();

const fileStorageSlice = rootSlicer('substanceStorage');
export const fileStorageSubstances = composeSelectors(fileStorageSlice, fileStorage.substances);
export const fileStorageTanks = composeSelectors(fileStorageSlice, fileStorage.tanks);
export const fileStorageAssetTypes = composeSelectors(fileStorageSlice, fileStorage.assetTypes);
export const substanceStoragePgsMappingReady = composeSelectors(
	fileStorageSlice,
	fileStorage.pgsMappingReady,
);

const projectSlice = rootSlicer('project');
export const projectCurrent = composeSelectors(projectSlice, project.currentProject);
export const projectLoaded = composeSelectors(projectSlice, project.loaded);
export const projects = composeSelectors(projectSlice, project.projects);
export const projectsLoaded = composeSelectors(projectSlice, project.projectsLoaded);

const choicesSlice = rootSlicer('choices');
export const choicesGet = composeSelectors(choicesSlice, choices.get);
export const choicesGetOrNull = composeSelectors(choicesSlice, choices.getOrNull);
export const choicesGetAll = composeSelectors(choicesSlice, choices.getAll);

const propertiesSlice = rootSlicer('properties');
export const propertiesGet = composeSelectors(propertiesSlice, properties.get);
export const propertiesGetOrNull = composeSelectors(propertiesSlice, properties.getOrNull);
export const propertiesGetAll = composeSelectors(propertiesSlice, properties.getAll);
export const propertiesGetAllByChoiceId = composeSelectors(
	propertiesSlice,
	properties.getAllByChoiceId,
);

const assetsSlice = rootSlicer('assets');
export const assetsGet = composeSelectors(assetsSlice, assets.get);
export const assetsGetOrNull = composeSelectors(assetsSlice, assets.getOrNull);
export const assetsGetAll = composeSelectors(assetsSlice, assets.getAll);

const assetTypesSlice = rootSlicer('assetTypes');
export const assetTypesGet = composeSelectors(assetTypesSlice, assetTypes.get);
export const assetTypesGetOrNull = composeSelectors(assetTypesSlice, assetTypes.getOrNull);
export const assetTypesGetAll = composeSelectors(assetTypesSlice, assetTypes.getAll);
export const assetTypesGetAllByPropertyId = composeSelectors(
	assetTypesSlice,
	assetTypes.getAllByPropertyId,
);
export const assetTypesGetFlags = composeSelectors(assetTypesSlice, assetTypes.getFlagAssetTypes);

const assetListsSlice = rootSlicer('assetLists');
export const assetListsGet = composeSelectors(assetListsSlice, assetLists.get);
export const assetListsGetOrNull = composeSelectors(assetListsSlice, assetLists.getOrNull);
export const assetListsGetAll = composeSelectors(assetListsSlice, assetLists.getAll);

const categoriesSlice = rootSlicer('categories');
export const categoriesGet = composeSelectors(categoriesSlice, categories.get);
export const categoriesGetOrNull = composeSelectors(categoriesSlice, categories.getOrNull);
export const categoriesGetAll = composeSelectors(categoriesSlice, categories.getAll);

const myInspectionsSlice = rootSlicer('myInspections');
export const myInspectionsGet = composeSelectors(myInspectionsSlice, myInspections.inspections);
export const myInspectionsLoaded = composeSelectors(myInspectionsSlice, myInspections.loaded);

function isChosen(parentAsset: Asset, parentProperty: Property, parentChoiceId: string): boolean {
	const valueString = parentAsset.valueStringByPropertyId[parentProperty.id];

	return valueString?.includes(parentChoiceId) ?? false;
}

function choiceActiveSingleParent(
	state: State,
	parentAssetList: AssetList,
	parentProperty: Property,
	parentChoiceId: string,
	ignoreRepeatingParent?: boolean,
	parentVisitedPropertyIds: Set<UUID> = new Set(),
): boolean {
	const parentAssetId = parentAssetList.assets.length > 0 ? parentAssetList.assets[0] : null;
	const parentAsset = parentAssetId ? assetsGetOrNull(state, parentAssetId) : null;

	if (!parentAsset) return false;

	if (isNotApplicable(parentAsset, parentProperty)) {
		return false;
	}

	if (
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		!propertyActive(
			state,
			parentProperty.id,
			parentAsset.id,
			ignoreRepeatingParent,
			parentVisitedPropertyIds,
		)
	)
		return false;

	return isChosen(parentAsset, parentProperty, parentChoiceId);
}

function choiceActiveRepeatingParent(
	state: State,
	parentAssetList: AssetList,
	parentProperty: Property,
	parentChoiceId: string,
	parentAssetId?: string,
	ignoreRepeatingParent?: boolean,
	parentVisitedPropertyIds: Set<UUID> = new Set(),
): boolean {
	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	if (ignoreRepeatingParent)
		return propertyActive(
			state,
			parentProperty.id,
			parentAssetId,
			ignoreRepeatingParent,
			parentVisitedPropertyIds,
		);

	if (!parentAssetId)
		throw new Error(
			'assetId must be specified to determine whether a property with a repeating parent asset type is active',
		);

	if (!parentAssetList.assets.includes(parentAssetId))
		throw new Error(
			'parent asset type must be the same as the asset type of the given asset for repeating assets',
		);

	if (parentAssetId) {
		const asset = assetsGet(state, parentAssetId);
		if (isNotApplicable(asset, parentProperty)) {
			return false;
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	if (
		!propertyActive(
			state,
			parentProperty.id,
			parentAssetId,
			ignoreRepeatingParent,
			parentVisitedPropertyIds,
		)
	)
		return false;

	const parentAsset = assetsGet(state, parentAssetId);

	return isChosen(parentAsset, parentProperty, parentChoiceId);
}

const getAmountPropertyValue = (
	state: State,
	propertyId: Property['id'],
	assetId?: Asset['id'],
): number | null => {
	const assetTypesByPropertyId = assetTypesGetAllByPropertyId(state);
	const parentAssetType = assetTypesByPropertyId[propertyId];

	if (parentAssetType.repeating) {
		if (!assetId) {
			throw new Error('assetId must be specified to get the value of a repeating asset');
		}
		const parentAsset = assetsGet(state, assetId);
		const rawValue = parentAsset.valueStringByPropertyId[propertyId];
		return rawValue ? parseInt(rawValue, 10) : null;
	}

	const parentAssetList = assetListsGet(state, parentAssetType.id);
	const parentAssetId = parentAssetList.assets.length > 0 ? parentAssetList.assets[0] : null;
	const parentAsset = parentAssetId ? assetsGetOrNull(state, parentAssetId) : null;

	if (parentAsset) {
		const rawValue = parentAsset.valueStringByPropertyId[propertyId];
		return rawValue ? parseInt(rawValue, 10) : null;
	}
	return null;
};

const findRuleById = (state: State, ruleId: ApiRuleDto['id']): ApiRuleDto | null => {
	const parentRuleSet = Object.values(state.ruleSets.byId).find((ruleSet) =>
		Object.values(ruleSet.rules).some((rule) => rule.id === ruleId),
	);
	if (parentRuleSet) {
		const ruleById = parentRuleSet.rules.find((rule) => rule.id === ruleId);
		return ruleById || null;
	}
	return null;
};

/**
 * Check whether a property is enabled by a rule that it is dependent on
 */
const propertyActiveByParentAction = (
	state: State,
	dependentPropertyId: Property['id'],
	parentActionId: ApiActionDto['id'],
	assetId?: Asset['id'],
	ignoreRepeatingParent?: boolean,
	parentVisitedPropertyIds: Set<UUID> = new Set(),
): boolean => {
	const action = state.actions.byId[parentActionId];
	const property = state.properties.byId[dependentPropertyId];
	if (action && property) {
		const parentProperty = state.properties.byId[action.propertyId];
		const rule = findRuleById(state, action.rule);

		const parentPropertyActive = propertyActive(
			state,
			parentProperty.id,
			assetId,
			ignoreRepeatingParent,
			parentVisitedPropertyIds,
		);
		if (ignoreRepeatingParent) {
			return parentPropertyActive;
		}
		if (!parentPropertyActive) {
			return false;
		}

		if (parentProperty && rule) {
			const parentPropertyValue = getAmountPropertyValue(state, parentProperty.id, assetId);
			if (parentPropertyValue !== null && rule.checkValue) {
				switch (rule.checkType) {
					case 'EQUALS':
						return parentPropertyValue === rule.checkValue;
					case 'MAX':
						return parentPropertyValue < rule.checkValue;
					case 'MIN':
						return parentPropertyValue > rule.checkValue;
					case 'RANGE': {
						return (
							!!rule.upperBound &&
							parentPropertyValue >= rule.checkValue &&
							parentPropertyValue <= rule.upperBound
						);
					}
					default:
						return false;
				}
			}
		}
	}
	return false;
};

/**
 * Check whether a property is active based on the rules it is dependent on
 */
const propertyActiveByParentActions = (
	state: State,
	propertyId: Property['id'],
	assetId?: Asset['id'],
	ignoreRepeatingParent?: boolean,
	parentVisitedPropertyIds: Set<UUID> = new Set(),
): boolean => {
	const property = state.properties.byId[propertyId];
	if (property.parentActionIds.length === 0) {
		return true;
	}
	return property.parentActionIds.slice(1).reduce<boolean>((accumulator, parentActionId) => {
		const active = propertyActiveByParentAction(
			state,
			propertyId,
			parentActionId,
			assetId,
			ignoreRepeatingParent,
			parentVisitedPropertyIds,
		);
		if (property.dependentCombinator === PropertyDependentCombinator.ANY) {
			return active || accumulator;
		}
		return active && accumulator;
	}, propertyActiveByParentAction(state, propertyId, property.parentActionIds[0], assetId, ignoreRepeatingParent, parentVisitedPropertyIds));
};

const propertyActiveByParentChoice = (
	state: State,
	propertyId: Property['id'],
	choiceId: Choice['id'],
	assetId?: UUID,
	ignoreRepeatingParent?: boolean,
	visitedPropertyIds: Set<UUID> = new Set(),
): boolean => {
	const assetTypesByPropertyId = assetTypesGetAllByPropertyId(state);
	const propertiesByChoiceId = propertiesGetAllByChoiceId(state);
	const property = state.properties.byId[propertyId];
	const parentProperty = propertiesByChoiceId[choiceId];
	if (visitedPropertyIds.has(parentProperty.id)) {
		throw new Error('Property dependencies are cyclic, this should never occur.');
	}
	const parentAssetType = assetTypesByPropertyId[parentProperty.id];
	const parentAssetList = assetListsGet(state, parentAssetType.id);
	const parentVisitedPropertyIds = new Set(visitedPropertyIds);

	parentVisitedPropertyIds.add(property.id);
	if (parentAssetType.repeating) {
		return choiceActiveRepeatingParent(
			state,
			parentAssetList,
			parentProperty,
			choiceId,
			assetId,
			ignoreRepeatingParent,
			parentVisitedPropertyIds,
		);
	}
	return choiceActiveSingleParent(
		state,
		parentAssetList,
		parentProperty,
		choiceId,
		ignoreRepeatingParent,
		parentVisitedPropertyIds,
	);
};

/**
 * Check whether a property is active based on the choices it is dependent on
 */
const propertyActiveByParentChoices = (
	state: State,
	propertyId: Property['id'],
	assetId?: UUID,
	ignoreRepeatingParent?: boolean,
	visitedPropertyIds: Set<UUID> = new Set(),
): boolean => {
	const property = state.properties.byId[propertyId];
	if (property.parentChoiceIds.length === 0) {
		return true;
	}
	return property.parentChoiceIds.slice(1).reduce<boolean>((accumulator, parentChoiceId) => {
		const active = propertyActiveByParentChoice(
			state,
			propertyId,
			parentChoiceId,
			assetId,
			ignoreRepeatingParent,
			visitedPropertyIds,
		);
		if (property.dependentCombinator === PropertyDependentCombinator.ANY) {
			return active || accumulator;
		}
		return active && accumulator;
	}, propertyActiveByParentChoice(state, propertyId, property.parentChoiceIds[0], assetId, ignoreRepeatingParent, visitedPropertyIds));
};

/**
 * Check whether a property is active. This is the case if all choices that it depends on (called the parent choices)
 * have been chosen for their respective properties and/or answers have been filled in for the rules
 * that it depends on (through parent actions (an action is a link between a rule and a property)).
 *
 * If allowRepeatingParent is set, parent choices for properties on repeating asset types are ignored and always
 * considered active. This is useful for CSV template generation.
 *
 * If rootPropertyId is set, we check that the parent property id is not equal to the root property. This allows us
 * to error on cyclic dependencies instead of overflowing the call stack.
 */
export function propertyActive(
	state: State,
	propertyId: UUID,
	assetId?: UUID,
	ignoreRepeatingParent?: boolean,
	visitedPropertyIds: Set<UUID> = new Set(),
): boolean {
	const property = state.properties.byId[propertyId];

	const byActions = propertyActiveByParentActions(
		state,
		propertyId,
		assetId,
		ignoreRepeatingParent,
		visitedPropertyIds,
	);
	const byChoices = propertyActiveByParentChoices(
		state,
		propertyId,
		assetId,
		ignoreRepeatingParent,
		visitedPropertyIds,
	);

	if (property.parentActionIds.length === 0) {
		return property.parentChoiceIds.length === 0 ? true : byChoices;
	}
	if (property.parentChoiceIds.length === 0) {
		return byActions;
	}

	if (property.dependentCombinator === PropertyDependentCombinator.ANY) {
		return byActions || byChoices;
	}
	return byActions && byChoices;
}

/**
 * Get an asset types by id, with properties filtered for only currently active properties.
 * The properties for an asset type are active if the choices on properties for non-repeating parents are active.
 * This is different from active properties for an individual asset instance, for which we also
 * consider parent properties on a repeating asset.
 */
export const assetTypesGetWithActivePropertiesOnly = defaultMemoize(
	(state: State, assetTypeId: UUID): AssetType => {
		const assetType = assetTypesGet(state, assetTypeId);
		return {
			...assetType,
			propertyIds: assetType.propertyIds.filter((propertyId) =>
				propertyActive(state, propertyId, undefined, true),
			),
		};
	},
);

/**
 * get a record of all asset types by id, with properties filtered for only currently active properties
 */
export const assetTypesGetAllWithActivePropertiesOnly = defaultMemoize(
	(state: State): Record<string, AssetType> => {
		const allAssetTypes = assetTypesGetAll(state);
		const acc: Record<string, AssetType> = {};
		return Object.values(allAssetTypes).reduce(
			(
				assetTypeValue: Record<string, AssetType>,
				assetType: AssetType,
			): Record<string, AssetType> => ({
				...assetTypeValue,
				[assetType.id]: assetTypesGetWithActivePropertiesOnly(state, assetType.id),
			}),
			acc,
		);
	},
);

/**
 * get a survey asset: a combination of the asset, and the list of active properties for that asset
 * when there are no active properties, there is no survey asset, so we return null
 */
export const surveyAssetsGetOrNull = defaultMemoize(
	(state: State, assetTypeId: string, assetId?: string): SurveyAsset | null => {
		const asset = assetId ? assetsGet(state, assetId) : undefined;
		const assetType = assetTypesGet(state, assetTypeId);
		const surveyPropertyIds = assetType.propertyIds.filter((propertyId: UUID): boolean => {
			const property = propertiesGet(state, propertyId);
			return (
				property.survey &&
				// Substance property types shouldn't show in the survey, since only Mi.License uses these,
				// and only as CSV uploads
				!(
					property.type === PropertyType.SINGLE_SUBSTANCE ||
					property.type === PropertyType.MULTI_SUBSTANCE
				) &&
				// Unnamed asset relationship properties should not show in the survey
				!(
					(property.type === PropertyType.SINGLE_ASSET_REFERENCE ||
						property.type === PropertyType.MULTI_ASSET_REFERENCE) &&
					property.identifyingProperty == null
				) &&
				propertyActive(state, propertyId, assetId)
			);
		});
		if (surveyPropertyIds.length === 0) return null;
		return {
			filesByPropertyId: asset?.filesByPropertyId ?? {},
			id: asset?.id ?? null,
			surveyPropertyIds,
			valuesByPropertyId: asset?.valueStringByPropertyId ?? {},
			notesByPropertyId: asset?.notesByPropertyId ?? {},
			applicableByPropertyId: asset?.applicableByPropertyId ?? {},
			otherOptionEnabledByPropertyId: asset?.otherValueEnabledByPropertyId ?? {},
			location: asset?.location ?? {},
		};
	},
);

/**
 * get the list of survey assets for a given asset type
 */
const surveyAssetListsGetOrNull = defaultMemoize(
	(state: State, assetTypeId: UUID): SurveyAssetList | null => {
		const assetList = assetListsGet(state, assetTypeId);
		const assetType = assetTypesGet(state, assetTypeId);
		let surveyAssets = assetList.assets.reduce(
			(acc: SurveyAsset[], assetId: UUID): SurveyAsset[] => {
				const surveyAsset = surveyAssetsGetOrNull(state, assetTypeId, assetId);
				if (surveyAsset) {
					acc.push(surveyAsset);
				}
				return acc;
			},
			[],
		);
		if (surveyAssets.length === 0 && !assetType.repeating) {
			const surveyAsset = surveyAssetsGetOrNull(state, assetTypeId);
			if (surveyAsset) surveyAssets = [surveyAsset];
			else return null;
		}
		if (surveyAssets.length === 0) {
			// check whether any properties can be activated within the repeating asset
			const activePropertyIds = assetTypesGetWithActivePropertiesOnly(
				state,
				assetType.id,
			).propertyIds;
			if (activePropertyIds.length === 0) {
				return null;
			}
		}
		return {
			assetTypeId,
			assets: surveyAssets,
		};
	},
);

export const surveyAssetListsGet = (state: State, assetTypeId: UUID): SurveyAssetList => {
	const surveyAssetList = surveyAssetListsGetOrNull(state, assetTypeId);
	if (!surveyAssetList)
		throw new Error('survey asset lists get was called for an asset type without survey assets.');
	else return surveyAssetList;
};

/**
 * select asset type ids that are included in the survey: id's for asset types that contain survey assets
 */
export const getSurveyAssetTypeIds = defaultMemoize((state: State): Set<AssetType['id']> => {
	const result: Set<AssetType['id']> = new Set();

	Object.values(state.assetTypes.byId).forEach((assetType) => {
		if (assetType.survey) {
			// Add asset type id to result if it has survey asset lists
			if (surveyAssetListsGetOrNull(state, assetType.id)) {
				result.add(assetType.id);
			}
		}
	});
	return result;
});

/**
 * select ordered list of category id's that are included in the survey: categories that contain survey asset types
 */
export const surveyCategoryIds = defaultMemoize((state: State): string[] => {
	const assetTypeIds = getSurveyAssetTypeIds(state);
	const result: string[] = [];

	state.categories.allIds.forEach((categoryId) => {
		const category = state.categories.byId[categoryId];
		let hasSurveyAssetTypes = false;
		category.assetTypeIds.forEach((assetTypeId) => {
			hasSurveyAssetTypes = hasSurveyAssetTypes || assetTypeIds.has(assetTypeId);
		});
		if (hasSurveyAssetTypes) result.push(categoryId);
	});
	return result;
});

export const assetsGetByAssetTypeId = defaultMemoize(
	(state: State, assetTypeId: UUID | undefined): Asset[] => {
		if (!assetTypeId) {
			return [];
		}
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const assetIds = unique(assetListsGet(state, assetTypeId).assets);
		return assetIds.map((id: Asset['id']) => assetsGet(state, id));
	},
);

// --------------------------

interface PropertyBreakdown {
	hasValue: boolean;
	isApplicable: boolean;
	isRequired: boolean;
}
const getPropertyBreakdown = (
	state: State,
	surveyAsset: SurveyAsset,
	propertyId: string,
): PropertyBreakdown => {
	const property = propertiesGet(state, propertyId);
	const location = surveyAsset.location[propertyId];
	const value = surveyAsset.valuesByPropertyId[propertyId];
	const hasValue = ![null, undefined, ''].includes(value) || (location && location.length > 0);
	// eslint-disable-next-line no-unneeded-ternary
	const isApplicable = surveyAsset.applicableByPropertyId[propertyId] === false ? false : true;
	const isRequired = property.required;
	return { hasValue, isApplicable, isRequired };
};

const answeredPercentageByAsset = (state: State, surveyAsset: SurveyAsset): [number, number] =>
	surveyAsset.surveyPropertyIds.reduce(
		(propertiesAccumulator, propertyId) => {
			const { hasValue, isApplicable } = getPropertyBreakdown(state, surveyAsset, propertyId);
			return [
				isApplicable && hasValue ? propertiesAccumulator[0] + 1 : propertiesAccumulator[0],
				isApplicable ? propertiesAccumulator[1] + 1 : propertiesAccumulator[1],
			];
		},
		[0, 0] as [number, number],
	);

const answeredPercentageByAssetTypeId = (state: State, assetTypeId: string): [number, number] => {
	const surveyAssets = surveyAssetListsGetOrNull(state, assetTypeId);
	if (!surveyAssets) {
		return [0, 0] as [number, number];
	}
	return surveyAssets.assets.reduce(
		(assetAccumulator, asset) => {
			const [propertiesAnswered, propertiesTotal] = answeredPercentageByAsset(state, asset);
			return [assetAccumulator[0] + propertiesAnswered, assetAccumulator[1] + propertiesTotal];
		},
		[0, 0] as [number, number],
	);
};

export const answeredPercentageByCategoryId = (state: State, categoryId: string) => {
	const category = state.categories.byId[categoryId];
	const [answered, total] = category.assetTypeIds.reduce(
		(accumulator, assetTypeId) => {
			const [assetAnswered, assetTotal] = answeredPercentageByAssetTypeId(state, assetTypeId);
			return [accumulator[0] + assetAnswered, accumulator[1] + assetTotal];
		},
		[0, 0] as [number, number],
	);
	if (total === 0) {
		return 100;
	}
	return Math.round((answered / total) * 100);
};

export const surveyAllRequiredFieldsCompleted = (state: State) =>
	Array.from(getSurveyAssetTypeIds(state))
		.flatMap((assetTypeId) => surveyAssetListsGet(state, assetTypeId))
		.flatMap((surveyAssetList) => surveyAssetList.assets)
		.flatMap((surveyAsset) =>
			surveyAsset.surveyPropertyIds.map((propertyId) =>
				getPropertyBreakdown(state, surveyAsset, propertyId),
			),
		)
		.filter((propertyBreakdown) => propertyBreakdown.isRequired)
		.every((propertyBreakdown) => !propertyBreakdown.isApplicable || propertyBreakdown.hasValue);

export const activityHasValues = (state: State): boolean =>
	Object.values(state.assetLists.byId).reduce((accumulator, assetTypeToAssets) => {
		const assetType = assetTypesGet(state, assetTypeToAssets.id);
		if (!assetType.fixed) {
			const assetTypeHasValues = assetTypeToAssets.assets.some((assetId) => {
				const asset = assetsGet(state, assetId);
				return Object.values(asset.valueStringByPropertyId).some((value) => value != null);
			});
			return accumulator || assetTypeHasValues;
		}
		return accumulator;
	}, false);
