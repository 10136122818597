/* eslint-disable no-param-reassign */
import makeReducer from '@/store/makeReducer';
import { StateProject as State } from '@/store/state';
import {
	ApiAuthorizedProjectDto,
	ApiError,
	ApiProjectAPIError,
	ApiViewProjectDto,
} from 'kes-common';
import { Draft } from '../utils';
import * as actions from '../actions';
import { InspectionUserBody } from '../types';

const initialState: State = {
	currentProject: '',
	loaded: false,
	state: 'ready',
	error: null,
	errorFull: null,
	fatal: false,
	projects: [],
	projectsLoaded: false,
};

function loadProject(projectState: Draft<State>, payload: ApiViewProjectDto): void {
	if (payload) {
		projectState.loaded = true;
		projectState.currentProject = payload.id;
		projectState.project = {
			id: payload.id,
			name: payload.projectDetails.name,
			projectNumber: payload.projectDetails.projectNumber,
			companyName: '',
			createdOn: new Date(payload.createdOn),
			hasAdminPrivileges: payload.hasAdminPrivileges,
			inspections: payload.inspections,
			projectDetails: payload.projectDetails,
			industries: payload.industries,
			testProject: payload.testProject,
		};
	}
}

function loadProjectsOverview(
	projectState: Draft<State>,
	payload: ApiAuthorizedProjectDto[],
): void {
	projectState.projects = payload;
	projectState.loaded = true;
	projectState.projectsLoaded = true;
}

function createProject(projectState: Draft<State>, payload: ApiAuthorizedProjectDto): void {
	projectState.error = null;
	projectState.projects.push(payload);
}

function projectError(projectState: Draft<State>, payload: ApiError | ApiProjectAPIError): void {
	projectState.error = payload.message;
}

function projectErrorFull(
	projectState: Draft<State>,
	payload: ApiError | ApiProjectAPIError,
): void {
	projectState.errorFull = payload;
}

function fatalProjectError(projectState: Draft<State>, payload: Error): void {
	projectState.error = payload.message;
	projectState.fatal = true;
}

function deleteProject(projectState: Draft<State>, payload: string): void {
	projectState.projects.splice(
		projectState.projects.findIndex(({ project }): boolean => project.id === payload),
		1,
	);
}

function addInspectorToInspection(projectState: Draft<State>, payload: InspectionUserBody): void {
	const currentInspection = projectState.project?.inspections.find(
		(inspection) => inspection.id === payload.inspectionId,
	);
	if (currentInspection) {
		currentInspection.users = [...currentInspection.users, payload.inspector];
	}
}

function removeInspectorFromInspection(
	projectState: Draft<State>,
	payload: InspectionUserBody,
): void {
	const currentInspection = projectState.project?.inspections.find(
		(inspection) => inspection.id === payload.inspectionId,
	);
	if (currentInspection) {
		currentInspection.users = currentInspection.users.filter(
			(user) => user.userId !== payload.inspector.userId,
		);
	}
}

export default makeReducer(initialState, {
	[actions.projectLoad.type](draft): void {
		draft.loaded = false;
	},
	[actions.projectOverviewLoad.type](draft): void {
		draft.loaded = false;
	},
	[actions.projectLoadSuccess.type]: loadProject,
	[actions.projectOverviewSuccess.type]: loadProjectsOverview,
	[actions.projectLoadRefresh.type](draft, forceReload): void {
		draft.loaded = !forceReload;
	},
	[actions.projectDeleteSuccess.type](draft, payload): void {
		deleteProject(draft, payload);
	},
	[actions.projectCreateSuccess.type](draft, payload): void {
		createProject(draft, payload);
	},

	[actions.projectUpdateSuccess.type](draft, payload): void {
		draft.error = null;
		const projectIndex = draft.projects.findIndex(
			({ project }): boolean => project.id === payload.projectId,
		);
		// this condition guards against errors when the test project is updated, since it is not in the list of
		// projects
		if (projectIndex !== -1) {
			draft.projects[projectIndex] = {
				...draft.projects[projectIndex],
				project: {
					...draft.projects[projectIndex].project,
					projectNumber: payload.projectDetails.projectNumber,
					name: payload.projectDetails.name,
				},
			};
		}
		if (draft.project && draft.project.id === payload.projectId) {
			draft.project.projectDetails = { ...draft.project.projectDetails, ...payload.projectDetails };
		}
	},
	[actions.projectUpdate.type](draft): void {
		draft.error = null;
	},
	[actions.projectCreate.type](draft): void {
		draft.error = null;
	},
	[actions.projectCreateError.type](draft, payload): void {
		projectError(draft, payload);
		projectErrorFull(draft, payload);
	},
	[actions.projectUpdateError.type](draft, payload): void {
		projectError(draft, payload);
		projectErrorFull(draft, payload);
	},

	[actions.projectFatalError.type](draft, payload): void {
		fatalProjectError(draft, payload);
	},
	[actions.routerUpdatePath.type](draft): void {
		draft.error = null;
		draft.fatal = false;
	},
	[actions.inspectionUserCreateSuccess.type](draft, payload): void {
		addInspectorToInspection(draft, payload);
	},
	[actions.inspectionUserDeleteSuccess.type](draft, payload): void {
		removeInspectorFromInspection(draft, payload);
	},
});
