import React from 'react';
import useInspectionRelatedFileGet from '@/net/reactQuery/mutations/useInspectionRelatedFileGet';
import Download from '@mui/icons-material/Download';
import { IconButton, ListItem, ListItemText } from '@mui/material';
import { ApiFile, downloadBlob } from 'kes-common';
import { useParams } from 'react-router';

interface Props {
	relatedFile: ApiFile;
}

const ListItemRelatedFileWithDownload: React.FC<Props> = ({ relatedFile }) => {
	const { inspectionId } = useParams<Record<string, string>>();

	const inspectionPropertyRelatedFileGetMutation = useInspectionRelatedFileGet(
		inspectionId,
		relatedFile.fileId,
		{
			onSuccess: async (response) => {
				const blob = await response.blob();
				downloadBlob(blob, relatedFile.name);
			},
		},
	);
	return (
		<ListItem
			key={relatedFile.fileId}
			secondaryAction={
				<IconButton
					disabled={inspectionPropertyRelatedFileGetMutation.isLoading}
					onClick={() => inspectionPropertyRelatedFileGetMutation.mutate()}
					aria-label="download"
				>
					<Download />
				</IconButton>
			}
		>
			<ListItemText primary={relatedFile.name} />
		</ListItem>
	);
};

export default ListItemRelatedFileWithDownload;
