import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Loader } from 'kes-common';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Route, Switch, useParams } from 'react-router-dom';

import { getUserProfileExtended } from '@/net/api';
import { userProfile, userProfileEdit } from '@/routes';

import { Provider as UserContextProvider } from './context';
import Edit from './Edit';
import Profile from './Profile';

const User: React.FC = () => {
	const { userId } = useParams<{ userId: string }>();
	const { data, isFetching, refetch } = useQuery(['user', userId], () =>
		getUserProfileExtended(userId),
	);

	return (
		<Container maxWidth="lg">
			{isFetching && <Loader />}
			{data?.status !== 200 && (
				<>
					<Typography>Could not fetch the user profile. Please try again.</Typography>
					<Button color="primary" onClick={() => refetch()} variant="contained">
						Retry
					</Button>
				</>
			)}
			{data?.status === 200 && (
				<UserContextProvider refetch={refetch} value={data.expectSuccess()}>
					<Switch>
						<Route component={Edit} exact path={userProfileEdit} />
						<Route component={Profile} exact path={userProfile} />
					</Switch>
				</UserContextProvider>
			)}
		</Container>
	);
};

export default User;
