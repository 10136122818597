import React from 'react';

interface UploadScriptProps {
	uploadFile: (file: File) => void;
	renderChildren: (uploadScript: () => void) => React.ReactNode;
}

const UploadScript: React.FC<UploadScriptProps> = ({ uploadFile, renderChildren }) => {
	const uploadInput = React.useRef<HTMLInputElement>(null);

	const onClick = React.useCallback(() => {
		if (uploadInput.current) {
			uploadInput.current.click();
		}
	}, [uploadInput.current]);

	const onUpload: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
		(event) => {
			if (event.currentTarget.files && event.currentTarget.files.length > 0) {
				uploadFile(event.currentTarget.files[0]);
			}
		},
		[uploadFile],
	);

	const children = React.useMemo(() => renderChildren(onClick), [onClick, renderChildren]);

	return (
		<>
			<input onChange={onUpload} ref={uploadInput} style={{ display: 'none' }} type="file" />
			{children}
		</>
	);
};

export default UploadScript;
