import AddLinkIcon from '@mui/icons-material/AddLink';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import StorageIcon from '@mui/icons-material/Storage';
import Link from '@mui/material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { ApiAuthorizedProjectDto, ApiProjectDto, useFeatureFlag } from 'kes-common';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router-dom';

import ProjectCopyDialog from '@/components/ProjectCopyDialog';
import ProjectDeleteDialog from '@/components/ProjectDeleteDialog';
import useFetchAccessString from '@/hooks/useFetchAccessString';
import {
	buildRoute,
	project as projectRoute,
	projectSettingsEdit,
	projectSettingsEditName,
} from '@/routes';

interface ProjectMenuProps {
	anchorElement: HTMLElement | null;
	onClose(): void;
	project: ApiProjectDto;
}

const ProjectMenu: React.FC<ProjectMenuProps> = ({ anchorElement, onClose, project }) => {
	const [isProjectCopyDialogOpen, setIsProjectCopyDialogOpen] = React.useState<boolean>(false);
	const [isProjectDeleteDialogOpen, setIsProjectDeleteDialogOpen] = React.useState<boolean>(false);

	const featureProjectShareData = useFeatureFlag({ name: 'PROJECT_SHARE_DATA' });
	const { fetchAccessString } = useFetchAccessString();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();

	const handleClose = React.useCallback(
		(event: React.MouseEvent | React.KeyboardEvent) => {
			event.stopPropagation();
			onClose();
		},
		[onClose],
	);

	const handleProjectDataAccess = React.useCallback(
		(event: React.MouseEvent<HTMLLIElement>) => {
			event.stopPropagation();
			fetchAccessString(project.id);
		},
		[fetchAccessString, project],
	);

	const handleProjectEdit = React.useCallback(
		(event: React.MouseEvent<HTMLLIElement>) => {
			event.stopPropagation();
			history.push(buildRoute(projectSettingsEdit, { projectId: project.id }));
		},
		[history, project],
	);

	const handleProjectEditName = React.useCallback(
		(event: React.MouseEvent<HTMLLIElement>) => {
			event.stopPropagation();
			history.push(buildRoute(projectSettingsEditName, { projectId: project.id }));
		},
		[history, project],
	);

	const handleProjectCopyDialogClose = React.useCallback(() => {
		setIsProjectCopyDialogOpen(false);
	}, [setIsProjectCopyDialogOpen]);

	const handleProjectCopyDialogOpen = React.useCallback(() => {
		setIsProjectCopyDialogOpen(true);
		onClose();
	}, [onClose, setIsProjectCopyDialogOpen]);

	const handleProjectCopySuccess = React.useCallback(
		(newProject: ApiAuthorizedProjectDto) => {
			enqueueSnackbar('Project copied successfully');
			history.push(buildRoute(projectRoute, { projectId: newProject.project.id }));
		},
		[enqueueSnackbar, history],
	);

	const handleProjectDeleteDialogClose = React.useCallback(() => {
		setIsProjectDeleteDialogOpen(false);
	}, [setIsProjectDeleteDialogOpen]);

	const handleProjectDeleteDialogOpen = React.useCallback(() => {
		setIsProjectDeleteDialogOpen(true);
		onClose();
	}, [onClose, setIsProjectDeleteDialogOpen]);

	const handleProjectDeleteSuccess = React.useCallback(() => {
		enqueueSnackbar(`The ${project.name} project was deleted successfully.`);
	}, [enqueueSnackbar]);

	return (
		<>
			<Menu anchorEl={anchorElement} onClose={handleClose} open={Boolean(anchorElement)}>
				<MenuItem onClick={handleProjectEditName}>
					<ListItemIcon>
						<AddLinkIcon />
					</ListItemIcon>
					<ListItemText>Link to ABW</ListItemText>
				</MenuItem>

				<MenuItem onClick={handleProjectEdit}>
					<ListItemIcon>
						<EditIcon />
					</ListItemIcon>
					<ListItemText>Edit</ListItemText>
				</MenuItem>

				<MenuItem onClick={handleProjectCopyDialogOpen}>
					<ListItemIcon>
						<ContentCopyIcon />
					</ListItemIcon>
					<ListItemText>Copy Project</ListItemText>
				</MenuItem>

				{featureProjectShareData && (
					<Tooltip
						placement="bottom-start"
						disableFocusListener
						title={
							<>
								Get access to your project&apos;s data via Tableau etc.{' '}
								<Link
									color="inherit"
									href="https://kes.support.royalhaskoningdhv.com/en/support/solutions/articles/77000529441-how-to-get-your-data-in-tableau-powerbi-excel-etc-"
									target="_blank"
								>
									Learn more.
								</Link>
							</>
						}
					>
						<MenuItem onClick={handleProjectDataAccess}>
							<ListItemIcon>
								<StorageIcon />
							</ListItemIcon>
							<ListItemText>Access Data</ListItemText>
						</MenuItem>
					</Tooltip>
				)}

				<MenuItem data-testid="action-project-delete" onClick={handleProjectDeleteDialogOpen}>
					<ListItemIcon>
						<DeleteIcon />
					</ListItemIcon>
					<ListItemText>Delete</ListItemText>
				</MenuItem>
			</Menu>

			<ProjectCopyDialog
				isOpen={isProjectCopyDialogOpen}
				onClose={handleProjectCopyDialogClose}
				onSuccess={handleProjectCopySuccess}
				projectId={project.id}
				projectName={project.name}
			/>

			<ProjectDeleteDialog
				isOpen={isProjectDeleteDialogOpen}
				onClose={handleProjectDeleteDialogClose}
				onSuccess={handleProjectDeleteSuccess}
				projectId={project.id}
				projectName={project.name}
			/>
		</>
	);
};

export default ProjectMenu;
