import { useLocalStorage, useUser } from 'kes-common';
import React from 'react';

import FiltersContext, { defaultFilters } from './context';
import { FilterKey, Filters } from './types';

interface FiltersProviderProps {
	children: React.ReactNode;
}

const FiltersProvider: React.FC<FiltersProviderProps> = ({ children }) => {
	const { getItem, setItem } = useLocalStorage();
	const { user } = useUser();

	const [filters, setFilters] = React.useState<Filters>({
		businessLine: getItem(`templates-filter__${user.user.id}__businessLine`) || '',
		label: getItem(`templates-filter__${user.user.id}__label`) || '',
		name: getItem(`templates-filter__${user.user.id}__name`) || '',
		ownTemplatesOnly: getItem(`templates-filter__${user.user.id}__ownTemplatesOnly`) || false,
		service: getItem(`templates-filter__${user.user.id}__service`) || '',
	});

	const setFilterValue = React.useCallback(
		(key: FilterKey, value: boolean | string) => {
			setItem(`templates-filter__${user.user.id}__${key}`, value);
			setFilters({ ...filters, [key]: value });
		},
		[filters, setFilters, user],
	);

	const clearFilters = React.useCallback(() => {
		(Object.keys(defaultFilters) as FilterKey[]).forEach((key) => {
			setItem(`templates-filter__${user.user.id}__${key}`, defaultFilters[key]);
		});
		setFilters(defaultFilters);
	}, [setFilters]);

	const providerValue = React.useMemo(
		() => ({
			clearFilters,
			filters,
			setFilterValue,
		}),
		[clearFilters, filters, setFilterValue],
	);

	return <FiltersContext.Provider value={providerValue}>{children}</FiltersContext.Provider>;
};

export default FiltersProvider;
