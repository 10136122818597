import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { ApiCopyStudy, ApiStudyDto } from 'kes-common';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useMutation } from '@tanstack/react-query';
import * as yup from 'yup';

import { studyCopy } from '@/net/api';

export interface TemplateCopyDialogProps {
	isOpen: boolean;
	onClose(): void;
	onSuccess(templateId: string): void;
	template: ApiStudyDto;
}

interface FormValues {
	name: ApiStudyDto['name'];
}

const TemplateCopyDialog: React.FC<TemplateCopyDialogProps> = ({
	isOpen,
	onClose,
	onSuccess,
	template,
}) => {
	const { enqueueSnackbar } = useSnackbar();
	const copyTemplate = useMutation((study: ApiCopyStudy) => studyCopy(study), {
		onSuccess: (response) => {
			if (response.status === 200) {
				onSuccess(response.result.id);
			} else {
				enqueueSnackbar('Failed to copy the template, please try again');
			}
		},
		onError: () => {
			enqueueSnackbar('Failed to copy the template, please try again');
		},
	});

	const formik = useFormik<FormValues>({
		initialValues: {
			name: `${template.name} - Copy`,
		},
		onSubmit: (values) => {
			copyTemplate.mutate(
				{
					builderProject: null,
					study: { ...template, name: values.name },
				},
				{
					onSettled: () => formik.setSubmitting(false),
				},
			);
		},
		validationSchema: yup.object().shape({
			name: yup.string().required('You must enter a template name'),
		}),
	});

	return (
		<Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
			<form onSubmit={formik.handleSubmit}>
				<DialogTitle>Copy Template</DialogTitle>
				<DialogContent>
					<TextField
						fullWidth
						label="New Template Name"
						name="name"
						onBlur={formik.handleBlur}
						onChange={formik.handleChange}
						value={formik.values.name}
						variant="standard"
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose} variant="text">
						Cancel
					</Button>
					<Button color="primary" disabled={formik.isSubmitting} type="submit" variant="contained">
						Save
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default TemplateCopyDialog;
