import CheckIcon from '@mui/icons-material/Check';
import Button from '@mui/material/Button';
import { ApiInspectionDto, ApiProjectDto } from 'kes-common';
import React from 'react';
import { useSelector } from 'react-redux';
import { buildRoute, inspectionFinish } from '@/routes';
import { surveyAllRequiredFieldsCompleted } from '@/selectors';
import { Link } from 'react-router-dom';

interface ButtonLinkInspectionCompleteProps {
	inspectionId: ApiInspectionDto['id'];
	projectId: ApiProjectDto['id'];
}

/** Navigate to the finish inspection page */
const ButtonLinkInspectionComplete: React.FC<ButtonLinkInspectionCompleteProps> = ({
	inspectionId,
	projectId,
}) => {
	const allRequiredFieldsCompleted = useSelector(surveyAllRequiredFieldsCompleted);
	const route = buildRoute(inspectionFinish, { projectId, inspectionId });
	return (
		<Button
			color="secondary"
			disabled={!allRequiredFieldsCompleted}
			fullWidth
			startIcon={<CheckIcon />}
			to={route}
			component={Link}
		>
			Complete activity
		</Button>
	);
};

export default ButtonLinkInspectionComplete;
