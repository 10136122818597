import Typography from '@mui/material/Typography';
import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import withThrottle from '@/components/hoc/withThrottle';
import withUnwrappedEvent from '@/components/hoc/withUnwrappedEvent';
import TextField from '@mui/material/TextField';
import { ApiClientDto } from 'kes-common';

import * as Styled from './ProjectForms.styled';

interface Props {
	abwLinked: boolean;
	client: ApiClientDto;
	setClient: (client: ApiClientDto) => void;
	industries: string[];
	onSubmit: () => void;
	buttons: JSX.Element;
}

const ClientFormTextField = withThrottle(withUnwrappedEvent(TextField));

ClientFormTextField.defaultProps = {
	fullWidth: true,
	variant: 'outlined',
};

const ClientForm: React.FC<Props> = ({
	abwLinked,
	client,
	setClient,
	onSubmit,
	buttons,
	industries,
}) => (
	<form
		onSubmit={(e): void => {
			e.preventDefault();
			onSubmit();
		}}
	>
		<Styled.FormGroup>
			<ClientFormTextField
				autoFocus
				data-testid="input-client-company-name"
				disabled={abwLinked}
				label="Company name"
				value={client.companyName || ''}
				onChange={(companyName): void => setClient({ ...client, companyName })}
				required
			/>
			<Autocomplete
				fullWidth
				data-testid="input-client-industry"
				disabled={abwLinked}
				value={client.industry}
				options={industries}
				renderInput={(params) => (
					<TextField
						data-testid={`input-client-industry-${params.id}`}
						{...params}
						label="Industry"
						variant="outlined"
					/>
				)}
				onChange={(event, industry: string | null): void => setClient({ ...client, industry })}
			/>
			<ClientFormTextField
				data-testid="input-client-chamber-of-commerce-number"
				disabled={abwLinked}
				label="Chamber of Commerce number"
				value={client.cocNumber || ''}
				onChange={(cocNumber): void => setClient({ ...client, cocNumber })}
			/>
			<ClientFormTextField
				label="Website URL"
				data-testid="input-client-website-url"
				disabled={abwLinked}
				value={client.websiteUrl || ''}
				onChange={(websiteUrl): void => setClient({ ...client, websiteUrl })}
			/>
			<Typography variant="h5">Contact information</Typography>
			<ClientFormTextField
				data-testid="input-client-contact-person-name"
				disabled={abwLinked}
				label="Contact person name"
				value={client.contactPersonName || ''}
				onChange={(contactPersonName): void => setClient({ ...client, contactPersonName })}
			/>
			<ClientFormTextField
				label="Role"
				data-testid="input-client-contact-person-role"
				disabled={abwLinked}
				value={client.contactPersonRole || ''}
				onChange={(contactPersonRole): void => setClient({ ...client, contactPersonRole })}
			/>
			<ClientFormTextField
				label="E-mail"
				data-testid="input-client-contact-person-email"
				disabled={abwLinked}
				value={client.contactPersonEmail || ''}
				onChange={(contactPersonEmail): void => setClient({ ...client, contactPersonEmail })}
			/>
			<ClientFormTextField
				label="Phone number"
				data-testid="input-client-contact-person-phone-number"
				disabled={abwLinked}
				value={client.contactPersonPhoneNumber || ''}
				onChange={(contactPersonPhoneNumber): void =>
					setClient({ ...client, contactPersonPhoneNumber })
				}
			/>
			<Typography variant="h5">Client location</Typography>
			<Styled.FormRow>
				<ClientFormTextField
					data-testid="input-client-location-street"
					disabled={abwLinked}
					label="Street"
					value={client.location.streetName || ''}
					onChange={(streetName): void =>
						setClient({ ...client, location: { ...client.location, streetName } })
					}
				/>
				<ClientFormTextField
					fullWidth={false}
					data-testid="input-client-location-street-number"
					disabled={abwLinked}
					label="Number"
					value={client.location.houseNumber || ''}
					onChange={(houseNumber): void =>
						setClient({ ...client, location: { ...client.location, houseNumber } })
					}
				/>
			</Styled.FormRow>
			<Styled.FormRow>
				<ClientFormTextField
					data-testid="input-client-location-postal-code"
					disabled={abwLinked}
					label="Postal code"
					value={client.location.postalCode || ''}
					onChange={(postalCode): void =>
						setClient({ ...client, location: { ...client.location, postalCode } })
					}
				/>
				<ClientFormTextField
					data-testid="input-client-location-city"
					disabled={abwLinked}
					label="City"
					value={client.location.city || ''}
					onChange={(city): void =>
						setClient({ ...client, location: { ...client.location, city } })
					}
				/>
			</Styled.FormRow>
			<ClientFormTextField
				data-testid="input-client-location-country"
				disabled={abwLinked}
				label="Country"
				value={client.location.country || ''}
				onChange={(country): void =>
					setClient({ ...client, location: { ...client.location, country } })
				}
			/>

			<Styled.ButtonsContainer>{buttons}</Styled.ButtonsContainer>
		</Styled.FormGroup>
	</form>
);

export default ClientForm;
