import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import React from 'react';

interface AssetWrapperProps {
	children: React.ReactNode;
	isContained: boolean;
}

const AssetWrapper: React.FC<AssetWrapperProps> = ({ children, isContained }) => {
	if (!isContained) {
		// eslint-disable-next-line react/jsx-no-useless-fragment
		return <>{children}</>;
	}
	return (
		<Box marginBottom={2}>
			<Card>
				<CardContent>{children}</CardContent>
			</Card>
		</Box>
	);
};

export default AssetWrapper;
