import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import { PageTitle } from 'kes-common';
import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { buildRoute, home, projectSettingsEditName } from '@/routes';
import AddInspectionDrawer from './AddInspectionDrawer';
import InspectionList from './InspectionList';
import { useProject } from '../context';
import CongratsDialog from './CongratsDialog';
import HeaderActions from './HeaderActions';

const Overview: React.FC = () => {
	const [isAddInspectionDrawerOpen, setIsAddInspectionDrawerOpen] = React.useState<boolean>(false);
	const history = useHistory();
	const { search } = useLocation();
	const { project } = useProject();

	const [isCongratsOpen, setCongratsOpen] = useState(search.search('created') !== -1);

	const onAddInspection = React.useCallback(() => setIsAddInspectionDrawerOpen(true), []);

	return (
		<>
			<PageTitle
				actions={<HeaderActions onAddInspection={onAddInspection} />}
				breadcrumbs={[{ title: 'Projects', url: home }, { title: project.projectDetails.name }]}
				title={
					<>
						<Tooltip
							title={project.projectDetails.abwLinked ? 'Linked to ABW' : 'Not linked to ABW'}
						>
							<IconButton
								component={Link}
								size="small"
								to={buildRoute(projectSettingsEditName, { projectId: project.id })}
							>
								<CircleIcon
									color={project.projectDetails.abwLinked ? 'success' : 'action'}
									fontSize="inherit"
								/>
							</IconButton>
						</Tooltip>
						{project.projectDetails.name}
					</>
				}
			/>
			<Typography color="textSecondary" variant="body2">
				Master Project Number:{' '}
				{project.projectDetails.masterProjectId || project.projectDetails.projectNumber}
			</Typography>

			<InspectionList inspections={project.inspections} onLinkClick={onAddInspection} />

			{/* All the dialogs below */}
			{project.hasAdminPrivileges && (
				<AddInspectionDrawer
					open={isAddInspectionDrawerOpen}
					setOpen={setIsAddInspectionDrawerOpen}
				/>
			)}

			<CongratsDialog
				show={isCongratsOpen}
				onClose={() => {
					setCongratsOpen(false);
					// reset query params
					const queryParams = new URLSearchParams(search);
					queryParams.delete('created');
					history.replace({ search: queryParams.toString() });
				}}
			/>
		</>
	);
};

export default Overview;
