import { ApiAsset, ApiFlag } from 'kes-common';

import State from '@/store/state';

export const flagsGetByAssetAndFlag = (
	state: State,
	assetId: ApiAsset['id'],
	flagAssetTypeId: ApiFlag['flagAssetType'],
): ApiFlag[] => {
	const flags = Object.values(state.flags.byId).filter(
		(flag) => flag.asset === assetId && flag.flagAssetType === flagAssetTypeId,
	);
	return flags;
};

export const flagsGetByFlag =
	(flagAssetTypeId: ApiFlag['flagAssetType']) =>
	(state: State): ApiFlag[] => {
		const flags = Object.values(state.flags.byId).filter(
			(flag) => flag.flagAssetType === flagAssetTypeId,
		);
		return flags;
	};
