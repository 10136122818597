import React from 'react';
import { TITLE_PREFIX } from '@/constants';

const useDocumentTitle = (...titles: string[]) => {
	React.useEffect(() => {
		document.title = [TITLE_PREFIX, ...titles].join(' | ');
	}, [titles]);
};

export default useDocumentTitle;
