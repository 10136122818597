import { ApiRelatedLinks, ApiInspectionDto } from 'kes-common';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { inspectionExternalUrls } from '../../api';

const useInspectionExternalURLs = (
	inspectionId: ApiInspectionDto['id'],
	options?: UseMutationOptions<unknown, unknown, ApiRelatedLinks>,
) =>
	useMutation(
		(mutationOptions) =>
			inspectionExternalUrls(inspectionId, mutationOptions).then((response) =>
				response.expectSuccess(),
			),
		options,
	);

export default useInspectionExternalURLs;
