import { ApiInspectionDto } from 'kes-common';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useDispatch } from 'react-redux';

import useInspectionScriptFileUpload from '@/net/reactQuery/mutations/useInspectionScriptFileUpload';
import { inspectionLoadSuccess } from '@/store/actions';
import UploadScript from '@/components/UploadScript';

interface InspectionProps {
	inspection: ApiInspectionDto;
}

const Upload: React.FC<InspectionProps> = ({ inspection }) => {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const uploadInspectionScriptFile = useInspectionScriptFileUpload(inspection.id);

	const onUpload = React.useCallback(
		(file: File) =>
			uploadInspectionScriptFile.mutate(
				{ file },
				{
					onError: () => {
						enqueueSnackbar('Failed to upload the script file, please try again');
					},
					onSuccess: () => {
						dispatch(
							inspectionLoadSuccess({
								...inspection,
								scripts: inspection.scripts.concat([{ fileName: file.name, type: 'ACTIVITY' }]),
							}),
						);
						enqueueSnackbar(`Script file (${file.name}) successfully uploaded.`);
					},
				},
			),
		[enqueueSnackbar, dispatch],
	);

	return (
		<UploadScript
			uploadFile={onUpload}
			renderChildren={(uploadFile) => (
				<Tooltip placement="right" title="Upload a script file to run against the current dataset">
					<Button fullWidth onClick={uploadFile} startIcon={<FileUploadIcon />}>
						Upload
					</Button>
				</Tooltip>
			)}
		/>
	);
};

export default Upload;
