import { ApiFile, ApiInspectionDto, ApiProperty } from 'kes-common';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { inspectionPropertyRelatedFileUpload } from '../../api';

interface MutationOptions {
	file: Blob | null;
	fileId: ApiFile['fileId'];
}

const useInspectionPropertyRelatedFileUpload = (
	inspectionId: ApiInspectionDto['id'],
	propertyId: ApiProperty['id'],
	options?: UseMutationOptions<string, unknown, MutationOptions>,
) =>
	useMutation(
		(mutationOptions) =>
			inspectionPropertyRelatedFileUpload(mutationOptions.fileId, propertyId, inspectionId, {
				file: mutationOptions.file,
			}).then((response) => response.expectSuccess()),
		options,
	);

export default useInspectionPropertyRelatedFileUpload;
