import FlagIcon from '@mui/icons-material/Flag';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import { ApiAssetType, useFeatureFlag } from 'kes-common';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { assetTypesGetFlags } from '@/selectors';
import { flagDeleteEmptyRows } from '@/store/actions';
import State from '@/store/state';
import { Asset, AssetType } from '@/store/types';

import ButtonFlag from './ButtonFlag';
import FlagEditor from './FlagEditor';

interface FlagEditorsProps {
	assetId?: Asset['id'];
	assetTypeId: ApiAssetType['id'];
}

const FlagEditors: React.FC<FlagEditorsProps> = ({ assetId, assetTypeId }) => {
	const [anchorElement, setAnchorElement] = React.useState<HTMLElement | null>(null);

	const flags = useSelector<State, AssetType[]>(assetTypesGetFlags);

	const dispatch = useDispatch();
	const flagsFeatureFlag = useFeatureFlag({ name: 'FLAGS' });

	const handleMenuClose = React.useCallback(() => {
		setAnchorElement(null);
	}, [setAnchorElement]);

	const handleMenuOpen = React.useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			event.stopPropagation();
			setAnchorElement(event.currentTarget);
		},
		[setAnchorElement],
	);

	const flagsList = React.useMemo(
		() => flags.filter((flag) => flag.propertyIds.length > 0),
		[flags],
	);
	const isMenuOpen = React.useMemo(() => Boolean(anchorElement), [anchorElement]);

	const [selectedFlag, setSelectedFlag] = React.useState<AssetType | null>(null);

	const closeDialog = React.useCallback(() => {
		if (assetId && selectedFlag) {
			dispatch(flagDeleteEmptyRows({ assetId, flag: selectedFlag }));
		}
		setSelectedFlag(null);
	}, [assetId, dispatch, selectedFlag, setSelectedFlag]);

	if (!flagsFeatureFlag) {
		return null;
	}

	if (!flagsFeatureFlag) {
		return null;
	}

	return (
		<>
			<Grid container columnSpacing={2}>
				<Grid item>
					{flagsList.length === 1 && (
						<ButtonFlag
							assetId={assetId}
							flag={flagsList[0]}
							isSingular
							onClick={() => {
								handleMenuClose();
								setSelectedFlag(flagsList[0]);
							}}
						/>
					)}

					{flagsList.length > 1 && (
						<>
							<Button
								color="secondary"
								onClick={handleMenuOpen}
								startIcon={<FlagIcon />}
								variant="text"
							>
								Flags
							</Button>
							<Menu
								anchorEl={anchorElement}
								anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
								onClose={handleMenuClose}
								open={isMenuOpen}
								transformOrigin={{ vertical: 'top', horizontal: 'right' }}
							>
								{flagsList.map((flag) => (
									<ButtonFlag
										assetId={assetId}
										flag={flag}
										isSingular={false}
										key={flag.id}
										onClick={() => {
											handleMenuClose();
											setSelectedFlag(flag);
										}}
									/>
								))}
							</Menu>
						</>
					)}
				</Grid>

				<Grid item>
					<Tooltip title="Flags provide additional meta information about your answer(s).">
						<IconButton edge="end" size="small">
							<HelpOutlineOutlinedIcon />
						</IconButton>
					</Tooltip>
				</Grid>
			</Grid>

			{selectedFlag && (
				<FlagEditor
					assetId={assetId}
					assetTypeId={assetTypeId}
					closeDialog={closeDialog}
					flag={selectedFlag}
				/>
			)}
		</>
	);
};

export default FlagEditors;
