import { keyframes, styled } from '@mui/material/styles';

const appear = keyframes`
   0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
`;

export const IdAndNameContainer = styled('div')`
	margin: auto 8px;
`;

export const P = styled('p')`
	margin: 0;
	padding: 0;
`;

export const TestUserContainer = styled('div')<{ selected: boolean }>`
	display: flex;
	font-size: 11px;
	font-weight: 600;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
	background-color: ${({ selected }) => (selected ? '#3498db' : '#fff')};
	color: ${({ selected }) => (selected ? '#fff' : '#000')};
	padding: 8px;
	width: 400px;
	border-radius: 15px;
	margin: 4px;
`;

export const TestUsersContainer = styled('div')`
	display: flex;
	flex-direction: column;
	animation: ${appear} 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
`;

export const TestUserSelectorContainer = styled('div')`
	position: fixed;
	right: 0;
	top: 30%;
	display: flex;
	background-color: #fff7e6bb;
	border-radius: 15px;
	z-index: 100;
	padding: 8px;
`;
