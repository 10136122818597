import { AssetType } from '@/store/types';
import { Box, IconButton, LinearProgress, Menu, MenuItem, Snackbar } from '@mui/material';
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	companyActivitiesAssetsDelete,
	companyActivitiesAssetsSuccess,
	companyActivitiesAssetsUpload,
	csvUploadScrollComplete,
} from '@/store/actions';
import { useParams } from 'react-router';
import { MoreVert, Close } from '@mui/icons-material';
import { getCsvTemplates } from '@/utils/csvUpload';
import {
	assetTypesGetWithActivePropertiesOnly,
	choicesGetAll,
	fileStorageAssetTypes,
	propertiesGetAll,
} from '@/selectors';
import { getFeatureFlags } from '@/selectors/project';
import { useGoogleTagManager } from 'kes-common';
import MUIAlert from '@mui/material/Alert';
import Alert from '@/components/rhdhv/Alert';
import State from '@/store/state';

interface Props {
	assetType: AssetType;
	assetIds: string[];
}

const AssetTypeMenu: FC<Props> = ({ assetType, assetIds }): JSX.Element | null => {
	const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | HTMLButtonElement | null>(null);
	const [successCanBeOpen, setSuccessCanBeOpen] = useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

	const choices = useSelector(choicesGetAll);
	const properties = useSelector(propertiesGetAll);
	const featureFlags = useSelector(getFeatureFlags);
	const fileStorageState = useSelector(fileStorageAssetTypes);

	const { inspectionId } = useParams<Record<string, string>>();
	const dispatch = useDispatch();
	const { trackCustomEvent } = useGoogleTagManager();
	const inputRef = useRef<HTMLInputElement>(null);

	const assetFileStorageState = fileStorageState[assetType.id];
	const scrollToAssetId = assetFileStorageState.scrollTo;
	const assetTypeWithActivePropertiesOnly = useSelector<State, AssetType>((state) =>
		assetTypesGetWithActivePropertiesOnly(state, assetType.id),
	);

	useEffect(() => {
		if (scrollToAssetId) {
			const element = document.getElementById(`${scrollToAssetId}-repeating-header`);
			if (element) {
				// calculate offset with magic constant because scrollIntoView does not play nicely with our sticky header
				const offset = element.offsetTop - 124;
				const content = document.getElementById('base-content')!;
				content.scrollTo({ top: offset, behavior: 'smooth' });
				element.style.backgroundColor = '#BADEFE';

				setTimeout(() => {
					element.style.backgroundColor = 'transparent';
				}, 4000);
			}
			dispatch(csvUploadScrollComplete({ assetTypeId: assetType.id }));
		}
	}, [scrollToAssetId]);

	const [errorMessage, ...errorDetails] = assetFileStorageState.error;

	const inputId = `${assetType.name}-csv-upload`;

	const openMenu = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const closeMenu = () => setAnchorEl(null);

	const open = Boolean(anchorEl);

	const deleteAll = () => {
		dispatch(
			companyActivitiesAssetsDelete({
				inspectionId,
				assetTypeId: assetType.id,
				assetIds,
			}),
		);
		trackCustomEvent({ event: 'ra_all_rows_removed' });
		closeMenu();
		setDeleteDialogOpen(false);
	};

	const downloadCsvTemplate = () => {
		getCsvTemplates(
			[assetTypeWithActivePropertiesOnly],
			properties,
			choices,
			featureFlags?.milicense || false,
		);
		trackCustomEvent({ event: 'ra_template_dld' });
		closeMenu();
	};

	const openSuccess = () => {
		setSuccessCanBeOpen(true);
	};

	const closeSuccess = () => {
		setSuccessCanBeOpen(false);
	};

	const uploadFile = (event: ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target;
		if (files) {
			const file = files[0];
			dispatch(
				companyActivitiesAssetsUpload({
					inspectionId,
					assetTypeId: assetType.id,
					file,
				}),
			);
			closeMenu();
			openSuccess();
			if (inputRef.current) {
				inputRef.current.value = '';
			}
		}
	};

	const closeError = () => {
		closeSuccess();
		dispatch(companyActivitiesAssetsSuccess({ assetTypeId: assetType.id, assets: [] }));
	};

	return (
		<>
			<IconButton onClick={openMenu} size="large">
				<MoreVert />
			</IconButton>
			<input
				id={inputId}
				type="file"
				onChange={uploadFile}
				accept=".csv"
				ref={inputRef}
				style={{ display: 'none' }}
			/>
			<Menu id="simple-menu" open={open} onClose={closeMenu} anchorEl={anchorEl}>
				<MenuItem onClick={downloadCsvTemplate}>Download Template</MenuItem>
				{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
				<label htmlFor={inputId}>
					<MenuItem>Upload CSV File</MenuItem>
				</label>
				<MenuItem onClick={() => setDeleteDialogOpen(true)}>Delete all</MenuItem>
			</Menu>
			<Snackbar open={assetFileStorageState.state === 'error'} onClose={closeError}>
				<MUIAlert
					severity="error"
					action={
						<IconButton size="small" aria-label="close" color="inherit" onClick={closeError}>
							<Close fontSize="small" />
						</IconButton>
					}
				>
					{errorMessage}
					<ul>
						{errorDetails.map((detailMessage: string) => (
							<li>{detailMessage}</li>
						))}
					</ul>
				</MUIAlert>
			</Snackbar>
			<Snackbar open={assetFileStorageState.state === 'uploading'}>
				<MUIAlert severity="info">
					Uploading CSV file
					<Box width="300px;" mt="10px;">
						<LinearProgress value={0} data-testid="uploading" />
					</Box>
				</MUIAlert>
			</Snackbar>
			<Snackbar
				open={successCanBeOpen && assetFileStorageState.state === 'done'}
				onClose={closeSuccess}
			>
				<MUIAlert
					severity="success"
					action={
						<IconButton size="small" aria-label="close" color="inherit" onClick={closeSuccess}>
							<Close fontSize="small" />
						</IconButton>
					}
				>
					CSV Upload complete
				</MUIAlert>
			</Snackbar>
			<Alert
				open={deleteDialogOpen}
				onClose={(): void => {
					setDeleteDialogOpen(false);
				}}
				title={`Delete all items for ${assetType.name}?`}
				buttonCloseText="Cancel"
				buttonApproveText="Delete"
				onApprove={deleteAll}
			/>
		</>
	);
};

export default AssetTypeMenu;
