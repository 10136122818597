import DoneIcon from '@mui/icons-material/Done';
import CircularProgress from '@mui/material/CircularProgress';
import { buildRoute, projectQuestions } from '@/routes';
import { answeredPercentageByCategoryId, categoriesGet } from '@/selectors';
import State from '@/store/state';
import { Category, ProjectId } from '@/store/types';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import * as Styled from './SideBar.styles';

interface CategoryElementProps {
	projectId: ProjectId;
	categoryId: string;
	flash: boolean;
	onClick(): void;
}

const CategoryElement = ({
	projectId,
	categoryId,
	flash,
	onClick,
}: CategoryElementProps): JSX.Element | null => {
	const flashingState = useRef(flash);
	const category = useSelector<State, Category>((state) => categoriesGet(state as any, categoryId));
	const inspectionId = useSelector<State, string | undefined>(
		(state) => state.inspection.currentInspection?.id,
	);
	useEffect(() => {
		flashingState.current = flashingState.current || flash;
	}, [flash]);

	const percentage = useSelector<State, number>((state) =>
		answeredPercentageByCategoryId(state, categoryId),
	);

	if (!inspectionId) {
		return null;
	}
	return (
		<Styled.CategoryNavLink
			exact
			flash={flashingState.current}
			data-testid={`category-item-${categoryId}`}
			onClick={onClick}
			to={buildRoute(projectQuestions, {
				projectId,
				inspectionId,
				categoryId: category.id,
			})}
		>
			<Styled.ListItemText>{category.name}</Styled.ListItemText>
			{percentage === 100 && <DoneIcon />}
			{percentage < 100 && (
				<CircularProgress color="inherit" size={20} value={percentage} variant="determinate" />
			)}
		</Styled.CategoryNavLink>
	);
};

export default CategoryElement;
