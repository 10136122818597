import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Loader, PageTitle } from 'kes-common';
import React from 'react';

import useDocumentTitle from '@/hooks/useDocumentTitle';
import { useTemplates } from '@/net/reactQuery/queries';
import { home } from '@/routes';

import { Provider as FiltersProvider } from './Filters/context';
import List from './List';
import TemplateCreateButton from './TemplateCreateButton';

const Templates: React.FC = () => {
	useDocumentTitle('Project Manager', 'Templates');

	const { data: templates, isError, isLoading, refetch } = useTemplates();

	return (
		<Container maxWidth="lg">
			<PageTitle
				actions={<TemplateCreateButton />}
				breadcrumbs={[{ title: 'Home', url: home }, { title: 'Templates' }]}
				title="Templates"
			/>
			{isError && (
				<Card>
					<CardContent>
						<Typography data-testid="templates__error" gutterBottom>
							Something went wrong
						</Typography>
						<Button data-testid="templates__error__retry" onClick={() => refetch()}>
							Retry
						</Button>
					</CardContent>
				</Card>
			)}
			{isLoading && <Loader />}
			{!isError && !isLoading && templates && (
				<Box marginTop={4} paddingBottom={2}>
					<FiltersProvider>
						<List refetchTemplates={refetch} templates={templates} />
					</FiltersProvider>
				</Box>
			)}
		</Container>
	);
};

export default Templates;
