import React, { FC, ReactEventHandler } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
} from '@mui/material';
import classes from './index.module.css';

interface Props {
	open: boolean;
	onClose: ReactEventHandler;
	title: string;
	text?: string;
	buttonCloseText: string;
	buttonApproveText: string;
	onApprove: ReactEventHandler;
}

const Alert: FC<Props> = ({
	open,
	onClose,
	title,
	text,
	buttonCloseText,
	buttonApproveText,
	onApprove,
}): JSX.Element => (
	<Dialog
		open={open}
		onClose={onClose}
		aria-labelledby="alert-dialog-title"
		aria-describedby="alert-dialog-description"
		classes={{ paper: classes.paper }}
	>
		<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
		{text && (
			<DialogContent>
				<DialogContentText id="alert-dialog-description" className={classes.mainText}>
					{text}
				</DialogContentText>
			</DialogContent>
		)}
		<DialogActions>
			<Button onClick={onClose} color="primary" data-testid="alert-cancel">
				<span className={classes.buttonText}>{buttonCloseText}</span>
			</Button>
			<Button data-testid="alert-approve" onClick={onApprove} color="primary" autoFocus>
				<span className={classes.buttonText}>{buttonApproveText}</span>
			</Button>
		</DialogActions>
	</Dialog>
);

export default Alert;
