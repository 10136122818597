import { ApiViewProjectDto } from 'kes-common';
import React from 'react';

import ProjectContext from './context';

interface ProjectProviderProps {
	children: React.ReactNode;
	refetch: (options?: any) => any;
	value: ApiViewProjectDto;
}

const ProjectProvider: React.FC<ProjectProviderProps> = ({ children, refetch, value }) => {
	const [project, setProject] = React.useState<ApiViewProjectDto>(value);
	const providerValue = React.useMemo(
		() => ({ project, refetchProject: refetch, setProject }),
		[project, refetch, setProject],
	);
	React.useEffect(() => {
		if (value !== project) {
			setProject(value);
		}
	}, [value]);

	return <ProjectContext.Provider value={providerValue}>{children}</ProjectContext.Provider>;
};

export default ProjectProvider;
