import MUITableCell from '@mui/material/TableCell';
import { debounce } from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';

import { Asset, AssetType, Property, PropertyType } from '@/store/types';

import { Decimal, Field, Image, MultipleSelect, RichText, SingleSelect, String } from './Fields';

const types: Record<PropertyType, React.ComponentType<Field>> = {
	[PropertyType.DATE]: () => null,
	[PropertyType.DECIMAL]: Decimal,
	[PropertyType.IMAGE]: Image,
	[PropertyType.LOCATIONS]: () => null,
	[PropertyType.MULTI_ASSET_REFERENCE]: () => null,
	[PropertyType.MULTI_SELECT]: MultipleSelect,
	[PropertyType.MULTI_SUBSTANCE]: () => null,
	[PropertyType.RICH_TEXT]: RichText,
	[PropertyType.SINGLE_ASSET_REFERENCE]: () => null,
	[PropertyType.SINGLE_SELECT]: SingleSelect,
	[PropertyType.SINGLE_SUBSTANCE]: () => null,
	[PropertyType.STRING]: String,
};

interface TableCellProps {
	asset: Asset;
	assetType: AssetType;
	onPropertyAnswer(answer: string, property: Property, asset: Asset, assetType: AssetType): void;
	property: Property;
	value: string;
}

const TableCell: React.FC<TableCellProps> = ({
	asset,
	assetType,
	onPropertyAnswer,
	property,
	value,
}) => {
	const { inspectionId } = useParams<Record<string, string>>();

	const Component = React.useMemo<React.ComponentType<Field>>(
		() => types[property.type],
		[property],
	);

	const handlePropertyAnswer = React.useCallback(
		debounce((answer: string) => {
			onPropertyAnswer(answer, property, asset, assetType);
		}, 250),
		[asset, assetType, property],
	);

	return (
		<MUITableCell>
			<Component
				asset={asset}
				assetTypeId={assetType.id}
				inspectionId={inspectionId}
				property={property}
				setFieldValue={handlePropertyAnswer}
				value={value}
			/>
		</MUITableCell>
	);
};

export default TableCell;
