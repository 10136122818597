import React, { FC } from 'react';
import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import { MULTIPLE_SELECT_SEPERATOR, Property } from '../../../store/types';
import Assets from './internal/AssetChoices';

interface Props {
	answer: string | undefined;
	onChange(payload: string | undefined): void;
	property: Property;
}

function toggleChoice(
	answer: Props['answer'],
	onChange: Props['onChange'],
	selectedAnswer: string,
): void {
	if (answer === selectedAnswer) {
		// Special case, 1 answer selected, and the clicked entry is our answer
		return onChange(undefined);
	}
	const split = answer ? answer.split(MULTIPLE_SELECT_SEPERATOR).map((a): string => a.trim()) : [];
	const index = split.indexOf(selectedAnswer);
	if (index < 0) {
		// Insert our new answer to the array;
		split.push(selectedAnswer);
	} else {
		// Delete answer
		split.splice(index, 1);
	}
	return onChange(split.join(MULTIPLE_SELECT_SEPERATOR));
}

function answerSelected(answer: Props['answer'], selectedAnswer: string): boolean {
	return !!answer && answer.includes(selectedAnswer);
}

const MultipleAssets: FC<Props> = ({ answer, onChange, property }): JSX.Element => (
	<Assets
		toggleChoice={(selectedAnswer): void => toggleChoice(answer, onChange, selectedAnswer)}
		answerSelected={(selectedAnswer): boolean => answerSelected(answer, selectedAnswer)}
		icon={<CheckBoxOutlineBlank />}
		iconSelected={<CheckBox />}
		property={property}
	/>
);

export default MultipleAssets;
