import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useQueryClient } from '@tanstack/react-query';
import { ApiStudyDto } from 'kes-common';
import { useSnackbar } from 'notistack';
import React from 'react';

import TemplateForm from '@/components/TemplateForm';
import { KES_BUILDER_URL } from '@/constants';

interface TemplateCreateDraweProps {
	isOpen: boolean;
	onClose(): void;
	templateCreateType: ApiStudyDto['type'];
}

const TemplateCreateDrawer: React.FC<TemplateCreateDraweProps> = ({
	isOpen,
	onClose,
	templateCreateType,
}) => {
	const queryClient = useQueryClient();
	const { enqueueSnackbar } = useSnackbar();

	const onSuccess = React.useCallback(
		(template: ApiStudyDto) => {
			enqueueSnackbar('Template successfully created');
			queryClient.invalidateQueries(['templates']);
			window.location.href =
				template.type === 'WORD'
					? template.sharepointUrl || '#'
					: `${KES_BUILDER_URL}${template.id}`;
		},
		[enqueueSnackbar, queryClient],
	);

	return (
		<Drawer anchor="right" onClose={onClose} open={isOpen}>
			<Box sx={{ padding: 3, maxWidth: '100vw', width: '500px' }}>
				<Box display="flex" justifyContent="space-between" marginBottom={2}>
					<Typography variant="h6">
						Create ({templateCreateType === 'WORD' ? 'Word' : 'Kes'}) Template
					</Typography>
					<IconButton onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</Box>
				<TemplateForm
					formHeaderInfo={
						<Alert severity="info">
							Creating a template is the first step towards document automation. Templates are the
							basis for creating surveys and reports. Your users will be able to find and add your
							template to their projects after you&apos;ve published it. Let&apos;s get started.
						</Alert>
					}
					onSuccess={onSuccess}
					submitButtonLabel="Create"
					templateCreateType={templateCreateType}
				/>
			</Box>
		</Drawer>
	);
};

export default TemplateCreateDrawer;
