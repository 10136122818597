const isRHDHVEmail = (email: string): string => {
	const emailRegexp =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(?!\.)(\w*\.)?rhdhv\.com$/;
	if (emailRegexp.test(email)) {
		return '';
	}
	return 'Email should end in @rhdhv.com or @subdomain.rhdhv.com';
};

export default isRHDHVEmail;
