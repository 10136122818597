import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import { ApiProjectInspectionDto } from 'kes-common';
import React from 'react';
import { useSelector } from 'react-redux';

import State from '@/store/state';

import Link from '@mui/material/Link';
import InspectionCard from './InspectionCard';

interface InspectionListProps {
	inspections: ApiProjectInspectionDto[];
	onLinkClick(): void;
}

const InspectionList: React.FC<InspectionListProps> = ({ inspections, onLinkClick }) => {
	const error = useSelector<State, string | null>((state) => state.inspection.error);
	return (
		<div>
			{inspections.length === 0 && (
				<Box marginTop={2}>
					<Alert severity="info">
						No activities have been added to this project yet.{' '}
						{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
						<Link color="inherit" onClick={onLinkClick} sx={{ cursor: 'pointer' }}>
							Why not add some
						</Link>
					</Alert>
				</Box>
			)}
			{inspections.length > 0 && (
				<Box marginTop={2}>
					<Grid container spacing={2}>
						{/* We need to slice the array because Immer.js freezes the object and sorting the frozen object returns an error.
						For more info check: https://stackoverflow.com/questions/53420055/error-while-sorting-array-of-objects-cannot-assign-to-read-only-property-2-of/53420326 */}
						{inspections.slice().map((inspection) => (
							<Grid item key={inspection.id} lg={4} sm={6} xs={12}>
								<InspectionCard inspection={inspection} />
							</Grid>
						))}
					</Grid>
				</Box>
			)}
			<Snackbar open={Boolean(error)}>
				<Alert severity="error">{error}</Alert>
			</Snackbar>
		</div>
	);
};

export default InspectionList;
