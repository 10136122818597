const createBlobUrl = (blob: Blob, fileType: string) => {
	const windowUrl = window.URL || window.webkitURL;
	const blobUrl = new Blob([blob], { type: fileType });
	const url = windowUrl.createObjectURL(blobUrl);
	return url;
};

const revokeObjectUrl = (blobUrl: string) => {
	const windowUrl = window.URL || window.webkitURL;
	windowUrl.revokeObjectURL(blobUrl);
};

// Creates fake a tag, which triggers download when clicked
const downloadFile = (blobUrl: string, fileName: string) => {
	const element = document.createElement('a');
	element.setAttribute('href', blobUrl);
	element.setAttribute('download', fileName);
	element.style.display = 'none';
	document.body.appendChild(element);
	element.click();
	document.body.removeChild(element);
};

const getFileTypeFromFileName = (fileName: string): string => {
	const fileExtension = fileName.split('.').pop();
	switch (fileExtension) {
		case 'pdf':
			return 'application/pdf';
		case 'doc':
			return 'application/msword';
		case 'docx':
			return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
		case 'xlsx':
			return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		default:
			return 'image/png';
	}
};

const downloadBlob = (blob: Blob, fileName: string): void => {
	const fileType = getFileTypeFromFileName(fileName);
	const url = createBlobUrl(blob, fileType);
	downloadFile(url, fileName);
	revokeObjectUrl(url);
};

export const getUrl = (blob: Blob, fileName: string): string => {
	const fileType = getFileTypeFromFileName(fileName);
	return createBlobUrl(blob, fileType);
};

export default downloadBlob;
