import React, { FC } from 'react';

import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';

import useKaasPropertyAssist from '@/kaas/useKaasPropertyAssist';

import { usePropertyIdContext } from '@/kaas/PropertyIdContext';

import KaasSuggestionChips from '@/kaas/KaasSuggestionChips';
import CustomInput from './CustomInput';

interface DateProps {
	answer: string | undefined;
	disabled?: boolean;
	onChange(payload: string | undefined): void;
	placeholder?: string;

	// eslint-disable-next-line react/no-unused-prop-types
	kaas?: ReturnType<typeof useKaasPropertyAssist>;
}

const DateType: React.FC<DateProps> = ({ answer, disabled, onChange, placeholder, kaas }) => {
	const handleChange: ReactDatePickerProps['onChange'] = React.useCallback(
		(date) => {
			if (date) {
				const newDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
				onChange(newDate.toISOString());
			}
		},
		[onChange],
	);

	const selected = React.useMemo<Date | undefined>(
		() => (answer ? new Date(answer) : undefined),
		[answer],
	);

	const value = React.useMemo<string>(() => {
		if (answer) {
			const date = new Date(answer);
			return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
		}
		return '';
	}, [answer]);

	return (
		<>
			<ReactDatePicker
				customInput={<CustomInput disabled={disabled} placeholder={placeholder} />}
				onChange={handleChange}
				selected={selected}
				value={value}
			/>
			<KaasSuggestionChips onChange={onChange} kaas={kaas} disabled={disabled} />
		</>
	);
};

export const DateTypeWithSuggestion: FC<DateProps> = ({
	answer,
	disabled,
	onChange,
	placeholder,
}) => {
	const kaas = useKaasPropertyAssist(usePropertyIdContext());

	return (
		<DateType
			answer={answer}
			onChange={onChange}
			placeholder={placeholder}
			disabled={disabled}
			kaas={kaas}
		/>
	);
};

export default DateType;
