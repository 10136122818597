import { Action, AnyAction } from 'redux';
import { Draft as ImmerDraft, produce } from 'immer';
import { defaultMemoize } from 'reselect';

export interface StoreAction extends Action<string> {
	// Use any payload here, as the actual actions differ between the types

	payload: any;
}

export type TypeConstant = string;

export type Payload<A extends ActionCreator<any, any>> = A extends ActionCreator<infer P, any>
	? P
	: never;

export type ActionDispatcher<A extends ActionCreator<any, any>> = A extends ActionCreator<void, any>
	? (payload?: void) => void
	: (payload: Payload<A>) => void;

export type ActionDispatchers<M extends { [key: string]: ActionCreator<any, any> }> = {
	[K in keyof M]: ActionDispatcher<M[K]>;
};

export interface SafeAction<P, T> extends Action<T> {
	payload: P;
}

export interface ActionCreator<P, T extends TypeConstant> {
	(payload: P): SafeAction<P, T>;
	type: T;
	toString(): string;
	makeHandlerWithKey<S, K extends keyof P>(
		handler: ActionHandler<S, P[K]>,
		key: K,
	): TypedActionHandler<S, P, T>;
	makeDraftedHandler<S>(handler: DraftHandler<S, P>): TypedActionHandler<S, P, T>;
	makeHandler<S>(handler: ActionHandler<S, P>): TypedActionHandler<S, P, T>;

	isInstanceOf(other: AnyAction): other is SafeAction<P, T>;
}

export function makeAction<P = void>(): <T extends TypeConstant>(type: T) => ActionCreator<P, T> {
	return <T extends TypeConstant>(type: T): ActionCreator<P, T> => {
		function executeAction(payload: P): SafeAction<P, T> {
			return {
				type,
				payload,
			};
		}
		executeAction.type = type;

		executeAction.toString = (): string => type;
		executeAction.makeHandlerWithKey = <S, K extends keyof P>(
			handler: ActionHandler<S, P[K]>,
			key: K,
		): TypedActionHandler<S, P, T> => {
			const func = (s: S, payload: P): S => handler(s, payload[key]);
			func.type = type;
			return func;
		};
		executeAction.makeHandler = <S>(handler: ActionHandler<S, P>): TypedActionHandler<S, P, T> => {
			const func = (s: S, payload: P): S => handler(s, payload);
			func.type = type;
			return func;
		};
		executeAction.makeDraftedHandler = <S>(
			handler: DraftHandler<S, P>,
		): TypedActionHandler<S, P, T> => {
			const func = (s: S, payload: P): S => produce(s, (draft): void => handler(draft, payload));
			func.type = type;
			return func;
		};
		executeAction.isInstanceOf = (other: AnyAction): other is SafeAction<P, T> =>
			other.type === type;
		return executeAction;
	};
}

export type DraftHandler<S, P> = (state: Draft<S>, payload: P) => void;

export type ActionHandler<S, P> = (state: S, payload: P) => S;

export interface TypedActionHandler<S, P, T extends TypeConstant> {
	(state: S, payload: P): S;
	type: T;
}

export function composeSelectors<A, B, C, D extends any[]>(
	root: (rootState: A) => B,
	subSelector: (substate: B, ...args: D) => C,
): (state: A, ...args: D) => C {
	const modifiedSubSelector = subSelector.length === 1 ? defaultMemoize(subSelector) : subSelector;
	return (state: A, ...args: D): C => modifiedSubSelector(root(state), ...args);
}

export type Writeable<T> = { -readonly [P in keyof T]-?: T[P] };
export type DeeplyWriteable<T> = {
	-readonly [P in keyof T]-?: T[P] extends object ? DeeplyWriteable<T[P]> : T[P];
};

export type Draft<T> = ImmerDraft<T>;

export type ExcludedKeys<T, U> = {
	[P in keyof T]: T[P] extends U ? never : P;
}[keyof T];
export type FilteredKeys<T, U> = {
	[P in keyof T]: T[P] extends U ? P : never;
}[keyof T];

// Makes all values partial, without making objects partial, so its still esy to access said properties
export type PartialValues<T> = {
	[Q in FilteredKeys<T, object>]-?: PartialValues<T[Q]>;
} & {
	[Q in ExcludedKeys<T, object>]+?: T[Q];
};

// Unwraps a function returning a promise into the raw type

export type Result<T extends (...args: any[]) => Promise<any>> = T extends (
	...args: any[]
) => Promise<infer R>
	? R
	: never;
