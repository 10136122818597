import RadioButtonChecked from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import React, { FC } from 'react';

import { Property } from '@/store/types';

import Assets from './internal/AssetChoices';

interface Props {
	answer: string | undefined;
	onChange(payload: string | undefined): void;
	property: Property;
}

function toggleChoice(
	answer: Props['answer'],
	onChange: Props['onChange'],
	selectedAnswer: string,
): void {
	if (answer === selectedAnswer) {
		return onChange(undefined);
	}
	return onChange(selectedAnswer);
}

function answerSelected(answer: Props['answer'], selectedAnswer: string): boolean {
	return answer === selectedAnswer;
}

const SingleAsset: FC<Props> = ({ answer, onChange, property }): JSX.Element => (
	<Assets
		toggleChoice={(selectedAnswer): void => toggleChoice(answer, onChange, selectedAnswer)}
		answerSelected={(selectedAnswer): boolean => answerSelected(answer, selectedAnswer)}
		icon={<RadioButtonUnchecked />}
		iconSelected={<RadioButtonChecked />}
		property={property}
	/>
);

export default SingleAsset;
