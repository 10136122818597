import DownloadIcon from '@mui/icons-material/Download';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { ApiFile, downloadBlob } from 'kes-common';
import React from 'react';
import { useParams } from 'react-router-dom';

import useInspectionRelatedFileGet from '@/net/reactQuery/mutations/useInspectionRelatedFileGet';

interface DownloadProps {
	onDownload(): void;
	relatedFile: ApiFile;
}

const Download: React.FC<DownloadProps> = ({ onDownload, relatedFile }) => {
	const { inspectionId } = useParams<Record<string, string>>();

	const inspectionPropertyRelatedFileGetMutation = useInspectionRelatedFileGet(
		inspectionId,
		relatedFile.fileId,
		{
			onSuccess: async (response) => {
				const blob = await response.blob();
				downloadBlob(blob, relatedFile.name);
				onDownload();
			},
		},
	);

	return (
		<Tooltip placement="left" title="Download a copy of the file to your device">
			{/* The <span> is because a <Tooltip /> doesn't work on disabled elements */}
			<span>
				<MenuItem
					disabled={inspectionPropertyRelatedFileGetMutation.isLoading}
					onClick={() => inspectionPropertyRelatedFileGetMutation.mutate()}
				>
					<ListItemIcon>
						<DownloadIcon />
					</ListItemIcon>
					<ListItemText primary="Download" />
				</MenuItem>
			</span>
		</Tooltip>
	);
};

export default Download;
