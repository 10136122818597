import { useSelector } from 'react-redux';
import State from '@/store/state';
import { useMemo } from 'react';
import { ApiError, ApiProjectAPIError } from 'kes-common';

export enum ProjectCreateErrorType {
	PROJECT_NUMBER_NOT_UNIQUE = 'Project number not unique',
	PROJECT_NAME_AND_COMPANY_NAME_NOT_UNIQUE = 'Combination of project and company name are not unique',
	PROJECT_CREATE_ERROR = 'Something went wrong creating your project',
}

export const checkError = (errorMessage: string | null) => {
	if (!errorMessage) return {};
	let step = 1;
	let errorType: ProjectCreateErrorType = ProjectCreateErrorType.PROJECT_CREATE_ERROR;

	if (errorMessage.toLowerCase().includes('Project with projectNumber'.toLowerCase())) {
		errorType = ProjectCreateErrorType.PROJECT_NUMBER_NOT_UNIQUE;
		step = 0;
	} else if (errorMessage.toLowerCase().includes('Project with name '.toLowerCase())) {
		errorType = ProjectCreateErrorType.PROJECT_NAME_AND_COMPANY_NAME_NOT_UNIQUE;
		step = 0;
	}

	return { step, errorMessage, errorType };
};

const useProjectStateError = () => {
	const error = useSelector<State, string | null>((state) => state.project.error);
	const errorFull = useSelector<State, ApiError | ApiProjectAPIError | null>(
		(state) => state.project.errorFull,
	);
	const fatal = useSelector<State, boolean>((state) => state.project.fatal);
	const errorDetails = useMemo(() => checkError(error), [error, fatal]);
	return { error, errorFull, fatal, errorDetails };
};

export default useProjectStateError;
