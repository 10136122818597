import { ApiMasterTemplate } from 'kes-common';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { masterTemplateDelete } from '../../api';

const useMasterTemplateDelete = (
	masterTemplateId: ApiMasterTemplate['id'],
	options?: UseMutationOptions,
) =>
	useMutation(
		() => masterTemplateDelete(masterTemplateId).then((response) => response.expectSuccess()),
		options,
	);

export default useMasterTemplateDelete;
