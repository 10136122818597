import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import MUITableRow from '@mui/material/TableRow';
import React from 'react';

import { Asset, AssetType, Property } from '@/store/types';

import TableRow from './TableRow';
import { RepeatingAssetTableRow } from './types';

interface RepeatingAssetTableProps {
	assetType: AssetType;
	onAssetCreate(assetType: AssetType): void;
	onAssetDelete(asset: Asset, assetType: AssetType): void;
	onPropertyAnswer(answer: string, property: Property, asset: Asset, assetType: AssetType): void;
	properties: Property[];
	rows: RepeatingAssetTableRow[];
}

const RepeatingAssetTable: React.FC<RepeatingAssetTableProps> = ({
	assetType,
	onAssetCreate,
	onAssetDelete,
	onPropertyAnswer,
	properties,
	rows,
}) => {
	const hasParentAssetType = React.useMemo(
		() => rows.length > 0 && Boolean(rows[0].parentAssetId),
		[rows],
	);

	if (rows.length === 0) {
		return (
			<Alert
				action={
					<Button
						color="inherit"
						data-testid="form__button-add"
						onClick={() => onAssetCreate(assetType)}
					>
						Add
					</Button>
				}
				severity="info"
			>
				No rows of type &apos;{assetType.name}&apos; yet.
			</Alert>
		);
	}

	return (
		<>
			<TableContainer>
				<Table>
					<TableHead>
						<MUITableRow>
							{hasParentAssetType && <TableCell>Asset</TableCell>}
							{properties.map((property) => (
								<TableCell key={property.id}>{property.name}</TableCell>
							))}
							<TableCell padding="checkbox" />
						</MUITableRow>
					</TableHead>
					<TableBody>
						{rows.map((row) => (
							<TableRow
								assetType={assetType}
								hasParentAssetType={hasParentAssetType}
								key={row.assetId}
								onAssetDelete={onAssetDelete}
								onPropertyAnswer={onPropertyAnswer}
								properties={properties}
								row={row}
							/>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<Box marginTop={1}>
				<Button
					color="secondary"
					fullWidth
					onClick={() => onAssetCreate(assetType)}
					variant="contained"
				>
					Add row
				</Button>
			</Box>
		</>
	);
};

export default RepeatingAssetTable;
