interface ExternalLink {
	href: string;
	title: string;
}

export const helpLinks: ExternalLink[] = [
	{
		href: 'https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIzOTg2NTQwOTUzNiJ9/all',
		title: 'Yammer',
	},
	{
		href: 'https://teams.microsoft.com/l/team/19%3aa1c6d2fec2164a57b55be36744a16b8d%40thread.tacv2/conversations?groupId=41273629-039b-4297-979c-7447dcc712f1&amp;tenantId=15f996bf-aad1-451c-8d17-9b95d025eafc',
		title: 'Teams',
	},
	{ href: 'https://training.kes.rhdhv.io/', title: 'Training environment' },
	{ href: 'mailto:kes.support@royalhaskoningdhv.com', title: 'Contact (email)' },
	{ href: 'https://kes.support.royalhaskoningdhv.com/', title: 'Knowledgebase' },
];

export const legalLinks: ExternalLink[] = [
	{ href: 'https://global.royalhaskoningdhv.com/disclaimer', title: 'Disclaimer' },
];
