import React from 'react';
import ReactMapGL, { NavigationControl, ScaleControl } from 'react-map-gl';
import MAP_STYLE from '@/components/Question/Types/Location/map-style-basic.json';
import { MapProps } from '@/components/Question/Types/Location/Maps/index';

const MapboxMap: React.FC<MapProps> = ({
	onClickMap,
	interactive,
	viewport,
	width,
	height,
	setViewport,
	children,
}) => (
	<ReactMapGL
		{...viewport}
		onClick={(e) => onClickMap && interactive && onClickMap(e.lngLat[1], e.lngLat[0])}
		width={width || '100%'}
		height={height || '100%'}
		mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN!}
		mapStyle={MAP_STYLE}
		onViewportChange={setViewport}
	>
		{interactive && (
			<>
				<ScaleControl
					maxWidth={100}
					unit="metric"
					style={{
						right: 56,
						bottom: 24,
					}}
				/>

				<NavigationControl
					style={{
						right: 10,
						bottom: 24,
					}}
				/>
			</>
		)}

		{children}
	</ReactMapGL>
);

export default MapboxMap;
