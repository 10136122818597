import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { ApiAuthorizedProjectDto } from 'kes-common';
import { useSnackbar } from 'notistack';
import React from 'react';

import useProjectCopy from '@/net/reactQuery/mutations/useProjectCopy';

interface ProjectCopyDialogProps {
	isOpen: boolean;
	onClose(): void;
	onSuccess(project: ApiAuthorizedProjectDto): void;
	projectId: string;
	projectName: string;
}

const CopyProjectDialog: React.FC<ProjectCopyDialogProps> = ({
	isOpen,
	onClose,
	onSuccess,
	projectId,
	projectName,
}) => {
	const [includeActivities, setIncludeActivities] = React.useState<boolean>(false);
	const { mutateAsync: projectCopy } = useProjectCopy(projectId);
	const { enqueueSnackbar } = useSnackbar();
	const onProjectCopy = React.useCallback(() => {
		projectCopy({ includeActivities })
			.then((project) => {
				onSuccess(project);
				onClose();
			})
			.catch(() => {
				enqueueSnackbar('Failed to copy the project. Please try again.', {
					preventDuplicate: true,
				});
			});
	}, [includeActivities]);

	return (
		<Dialog onClose={onClose} open={isOpen}>
			<DialogTitle>Copy project: {projectName}</DialogTitle>
			<DialogContent>
				<Typography>
					Copy the <strong>{projectName}</strong> project. You can also choose to make copies of all
					project activities.
				</Typography>
				<FormGroup>
					<FormControlLabel
						control={
							<Checkbox
								checked={includeActivities}
								onChange={(event) => setIncludeActivities(event.target.checked)}
							/>
						}
						label="Also copy activities"
					/>
				</FormGroup>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button color="primary" onClick={onProjectCopy} variant="contained">
					Copy
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CopyProjectDialog;
