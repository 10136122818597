import { ApiStudyDto } from 'kes-common';
import React from 'react';

import TemplateContext from './context';

interface TemplateProviderProps {
	children: React.ReactNode;
	value: ApiStudyDto;
	refetch(): void;
}

const TemplateProvider: React.FC<TemplateProviderProps> = ({ children, value, refetch }) => {
	const [template, setTemplate] = React.useState<ApiStudyDto>(value);

	const providerValue = React.useMemo(
		() => ({ template, setTemplate, refetch }),
		[template, setTemplate],
	);
	React.useEffect(() => {
		if (value !== template) {
			setTemplate(value);
		}
	}, [value]);

	return <TemplateContext.Provider value={providerValue}>{children}</TemplateContext.Provider>;
};

export default TemplateProvider;
