import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router';
import * as yup from 'yup';

import useTemplateImport from '@/net/reactQuery/mutations/useTemplateImport';
import { buildRoute, template } from '@/routes';

interface FormValues {
	file: File | string;
	name: string;
}

interface TemplateImportDialogProps {
	isOpen: boolean;
	onClose(): void;
}

const TemplateImportDialog: React.FC<TemplateImportDialogProps> = ({ isOpen, onClose }) => {
	const uploadInput = React.useRef<HTMLInputElement>(null);

	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const queryTemplateImport = useTemplateImport();

	const {
		errors,
		handleBlur,
		handleChange,
		handleSubmit,
		isSubmitting,
		setFieldTouched,
		setFieldValue,
		touched,
		values,
	} = useFormik<FormValues>({
		initialValues: { file: '', name: '' },
		onSubmit: (formValues, { setSubmitting }) => {
			setSubmitting(true);
			queryTemplateImport.mutate(
				// the validation has passed, thus we are guaranteed that `file` is of type `File` here.
				{ file: formValues.file as File, name: formValues.name },
				{
					onError: () => {
						enqueueSnackbar('Failed to import Template, please try again.');
					},
					onSettled: () => {
						setSubmitting(false);
					},
					onSuccess: (newTemplate) => {
						enqueueSnackbar('Template imported successfully');
						history.push(buildRoute(template, { templateId: newTemplate.id }));
					},
				},
			);
		},
		validationSchema: yup.object().shape({
			file: yup
				.mixed<File>()
				.required('Please upload a file')
				.test(
					'name',
					'Only .json files are allowed',
					(value) => value && value.name.endsWith('.json'),
				)
				.test(
					'fileSize',
					'2Mb is the maximum allowed file size',
					(value: File) => value && value.size <= 2 * 1024 * 1024,
				),
			name: yup.string().required('You must enter a template name'),
		}),
	});

	const getFieldHelperText = React.useCallback(
		(fieldName: keyof FormValues, helperText: string): string => {
			if (touched[fieldName] && errors[fieldName]) {
				// somehow TypeScript is confused here even though I check the value above. Huh.
				return errors[fieldName]!;
			}
			return helperText;
		},
		[errors, touched],
	);

	return (
		<Dialog fullWidth maxWidth="md" open={isOpen} onClose={isSubmitting ? undefined : onClose}>
			<form onSubmit={handleSubmit}>
				<DialogTitle>Import Template</DialogTitle>
				<DialogContent>
					<TextField
						error={Boolean(touched.name && errors.name)}
						fullWidth
						helperText={getFieldHelperText('name', 'A new name for this template once imported')}
						label="Name"
						margin="normal"
						name="name"
						onBlur={handleBlur}
						onChange={handleChange}
						value={values.name}
					/>
					<Grid container>
						<Grid item xs="auto">
							<FormControl margin="normal">
								<input
									onChange={(event) => {
										setFieldTouched('file');
										if (event.currentTarget.files && event.currentTarget.files.length > 0) {
											setFieldValue('file', event.currentTarget.files[0]);
										}
									}}
									ref={uploadInput}
									style={{ display: 'none' }}
									type="file"
								/>
								<Button
									fullWidth
									onClick={() => uploadInput.current?.click()}
									startIcon={<FileUploadIcon />}
								>
									Choose file
								</Button>
								<FormHelperText error={Boolean(touched.file && errors.file)}>
									{getFieldHelperText('file', 'Choose an exported Template .json file')}
								</FormHelperText>
							</FormControl>
						</Grid>
						<Grid alignItems="center" display="flex" item xs>
							{values.file instanceof File && (
								<Box alignItems="center" display="flex" marginLeft={3}>
									<CheckCircleIcon sx={{ marginRight: 1 }} />
									{values.file.name}
								</Box>
							)}
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button disabled={isSubmitting} onClick={onClose} type="button" variant="text">
						Cancel
					</Button>
					<Button disabled={isSubmitting} color="primary" type="submit" variant="contained">
						{isSubmitting ? 'Importing' : 'Import'}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default TemplateImportDialog;
