import React, { SetStateAction } from 'react';
import { mapboxEnabled } from '@/components/Question/Types/Location';
import LeafletMap from '@/components/Question/Types/Location/Maps/LeafletMap';
import { Bounds, ExtendedLocation } from '@/components/Question/Types/Location/MapModal';
import MapboxMap from '@/components/Question/Types/Location/Maps/MapboxMap';

export interface MapProps {
	children: React.ReactNode;
	onClickMap?: (lat: number, lng: number) => void;
	viewport: Bounds;
	currentLocation?: ExtendedLocation;
	setViewport?: React.Dispatch<SetStateAction<Bounds>>;
	width?: string;
	height?: string;
	interactive?: boolean;
}

const Map: React.FC<MapProps> = ({
	children,
	onClickMap,
	viewport,
	currentLocation,
	setViewport,
	width,
	height,
	interactive,
}) =>
	!mapboxEnabled ? (
		<LeafletMap
			interactive={interactive}
			width={width || '100%'}
			height={height || '100%'}
			onClickMap={(lat, lng) => interactive && onClickMap && onClickMap(lat, lng)}
			viewport={viewport}
			setViewport={setViewport}
			currentLocation={currentLocation}
		>
			{children}
		</LeafletMap>
	) : (
		<MapboxMap
			viewport={viewport}
			onClickMap={(lat, lng) => onClickMap && interactive && onClickMap(lat, lng)}
			width={width || '100%'}
			height={height || '100%'}
			setViewport={setViewport}
			interactive={interactive}
		>
			{children}
		</MapboxMap>
	);

export default Map;
