import React from 'react';
import { useSelector } from 'react-redux';
import State from '@/store/state';
import { User } from '@/store/types';
import isRHDHVEmail from '@/utils/isRHDHVEmail';

interface IsInternalUserProps {
	children: React.ReactNode;
}

const IsInternalUser: React.FC<IsInternalUserProps> = ({ children }) => {
	const user = useSelector<State, User | undefined>((state) => state.user.currentUser);
	if (!user || !!isRHDHVEmail(user?.email)) {
		return null;
	}

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{children}</>;
};

export default IsInternalUser;
