import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { ApiStudyDto } from 'kes-common';

import { studyImport } from '../../api';

interface MutationOptions {
	name: string;
	file: Blob;
}

const useTemplateImport = (options?: UseMutationOptions<ApiStudyDto, unknown, MutationOptions>) =>
	useMutation<ApiStudyDto, unknown, MutationOptions>(
		(mutationOptions) =>
			studyImport({ studyName: mutationOptions.name }, mutationOptions).then((response) =>
				response.expectSuccess(),
			),
		options,
	);

export default useTemplateImport;
