import Button from '@mui/material/Button';
import React from 'react';

import * as Styled from './UndoAction.styled';

interface Props {
	children?: React.ReactNode;
	undoAction: () => void;
}

const UndoAction: React.FC<Props> = ({ undoAction, children }) => (
	<Styled.Alert
		color="success"
		action={
			<Button onClick={undoAction} color="secondary" size="small">
				UNDO
			</Button>
		}
	>
		{children}
	</Styled.Alert>
);

export default UndoAction;
