import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useDispatch } from 'react-redux';

import useProjectDelete from '@/net/reactQuery/mutations/useProjectDelete';
import { projectDeleteSuccess } from '@/store/actions';

interface ProjectDeleteDialogProps {
	isOpen: boolean;
	onClose(): void;
	onSuccess(): void;
	projectId: string;
	projectName: string;
}

const ProjectDeleteDialog: React.FC<ProjectDeleteDialogProps> = ({
	isOpen,
	onClose,
	onSuccess,
	projectId,
	projectName,
}) => {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const deleteProject = useProjectDelete(projectId, {
		onError: () => {
			enqueueSnackbar(`Failed to delete project '${projectName}', please try again.`);
		},
		onSuccess: () => {
			dispatch(projectDeleteSuccess(projectId));
			if (onSuccess) {
				onSuccess();
			}
			onClose();
		},
	});

	return (
		<Dialog open={isOpen} onClose={onClose}>
			<DialogTitle>Delete &apos;{projectName}&apos;?</DialogTitle>
			<DialogContent>
				<Typography variant="body1">
					If you remove this project its data will become inaccessible.
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button
					color="primary"
					data-testid="alert-approve"
					onClick={() => deleteProject.mutate()}
					variant="contained"
				>
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ProjectDeleteDialog;
