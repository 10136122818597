import { ApiFile, ApiInspectionDto, ApiProperty } from 'kes-common';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { Asset } from '@/store/types';

import { inspectionAssetPropertyRelatedFileDelete } from '../../api';

const useInspectionAssetPropertyRelatedFileDelete = (
	inspectionId: ApiInspectionDto['id'],
	assetId: Asset['id'],
	propertyId: ApiProperty['id'],
	options?: UseMutationOptions<unknown, unknown, ApiFile['fileId']>,
) =>
	useMutation(
		(fileId: ApiFile['fileId']) =>
			inspectionAssetPropertyRelatedFileDelete(fileId, assetId, propertyId, inspectionId).then(
				(response) => response.expectSuccess(),
			),
		options,
	);

export default useInspectionAssetPropertyRelatedFileDelete;
