/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
	deleteImageForRepeatingAsset,
	deleteImageForSingularAsset,
	getImageForRepeatingAsset,
	getImageForSingularAsset,
	postImageForRepeatingAsset,
	postImageForSingularAsset,
} from '@/net/api';
import { ApiInspectionDto } from 'kes-common';
import { Property } from '@/store/types';
import { useMutation } from '@tanstack/react-query';

type UploadImageParams = {
	file: File;
	assetId: string;
	property: Property;
	inspection?: ApiInspectionDto;
	repeating: boolean;
	presignedURL?: string;
};

type UseUploadImageMutationProps = {
	onMutate: () => void;
	onSuccess: (params: UploadImageParams) => void;
	onError: () => void;
};
export const useUploadImageMutation = ({
	onMutate,
	onSuccess,
	onError,
}: UseUploadImageMutationProps) =>
	useMutation(
		({ file, assetId, property, inspection, repeating }: UploadImageParams) => {
			if (!inspection) {
				throw new Error('Activity is required');
			}
			return repeating
				? postImageForRepeatingAsset(assetId, property.id, inspection.id, { file })
				: postImageForSingularAsset(property.id, inspection.id, { file });
		},
		{
			onMutate,
			onSuccess: (response, mutationParams) => {
				const presignedURL = response.expectSuccess();
				onSuccess({ ...mutationParams, presignedURL });
			},
			onError,
		},
	);

type DeleteParams = {
	assetId: string | null;
	property: Property;
	inspection?: ApiInspectionDto;
	repeating: boolean;
};
type UseDeleteImageMutationProps = {
	onMutate: () => void;
	onSuccess: (params: DeleteParams) => void;
	onError: () => void;
};
export const useDeleteImageMutation = ({
	onMutate,
	onSuccess,
	onError,
}: UseDeleteImageMutationProps) =>
	useMutation(
		({ assetId, property, inspection, repeating }: DeleteParams) => {
			if (!inspection) {
				throw new Error('Activity is required');
			}
			if (!assetId) {
				throw new Error('AssetId is required');
			}
			return repeating
				? deleteImageForRepeatingAsset(assetId, property.id, inspection?.id ?? '')
				: deleteImageForSingularAsset(property.id, inspection?.id ?? '');
		},
		{
			onMutate,
			onSuccess: (_, mutationParams) => {
				onSuccess(mutationParams);
			},
			onError,
		},
	);

export const useGetImageMutation = ({ onSuccess }: { onSuccess: (blob: Blob) => void }) =>
	useMutation(
		({ assetId, property, inspection, repeating }: DeleteParams) => {
			if (!inspection) {
				throw new Error('Inspection is required');
			}
			if (!assetId) {
				throw new Error('AssetId is required');
			}
			return repeating
				? getImageForRepeatingAsset(assetId, property.id, inspection?.id ?? '')
				: getImageForSingularAsset(property.id, inspection?.id ?? '');
		},
		{
			onSuccess: async (response) => {
				const blobUrl = await response.expectSuccess().blob();
				onSuccess(blobUrl);
			},
		},
	);
