import * as actions from '@/store/actions';
import { SagaIterator } from '@redux-saga/core';
import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiError } from 'kes-common';
import { InspectionId } from '../store/types';
import { assetDelete, assetUpsert } from '../net/api';
import { Result } from '../store/utils';

function* createAsset(
	inspectionId: InspectionId,
	action: ReturnType<typeof actions.assetCreateAndSave>,
): SagaIterator {
	const { assetId, assetTypeId } = action.payload;
	yield call(assetUpsert, assetId, assetTypeId, inspectionId, {
		valueStringByPropertyId: {},
	});
}

export function* deleteAsset(
	inspectionId: InspectionId,
	action: ReturnType<typeof actions.assetDelete>,
): SagaIterator {
	try {
		const { assetId, assetTypeId } = action.payload;
		const response: Result<typeof assetDelete> = yield call(
			assetDelete,
			assetId,
			assetTypeId,
			inspectionId,
		);
		yield put(actions.assetDeleteSuccess(response.expectSuccess() && inspectionId));
	} catch (e) {
		yield put(actions.assetDeleteError(e as ApiError));
	}
}

function* assets(inspectionId: InspectionId | null): SagaIterator {
	if (inspectionId === null) {
		// This saga does not work on the project overview page
		return;
	}
	yield takeEvery(actions.assetCreateAndSave, createAsset, inspectionId);
	yield takeEvery(actions.assetDelete, deleteAsset, inspectionId);
}

export default assets;
