import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { FormikConfig, useFormik } from 'formik';
import { ApiExternalUrl, ApiInspectionDto, useGoogleTagManager } from 'kes-common';
import React from 'react';
import * as yup from 'yup';

import useInspectionExternalURLs from '@/net/reactQuery/mutations/useInspectionExternalURLs';

interface AddURLDialogProps {
	inspection: ApiInspectionDto;
	isOpen: boolean;
	onClose(): void;
	onError(): void;
	onSuccess(urls: ApiExternalUrl[]): void;
}

interface FormValues {
	name: string;
	url: string;
}

const AddURLDialog: React.FC<AddURLDialogProps> = ({
	inspection,
	isOpen,
	onClose,
	onError,
	onSuccess,
}) => {
	const { mutate } = useInspectionExternalURLs(inspection.id);
	const { trackCustomEvent } = useGoogleTagManager();

	const initialValues: FormValues = { name: '', url: '' };
	const onSubmit: FormikConfig<FormValues>['onSubmit'] = React.useCallback(
		(formValues, { resetForm, setSubmitting }) => {
			const newURLs: ApiExternalUrl[] = [
				...inspection.urls,
				{ default: null, name: formValues.name, url: formValues.url },
			];
			mutate(
				{ links: newURLs },
				{
					onError: () => {
						onError();
						setSubmitting(false);
					},
					onSuccess: () => {
						trackCustomEvent({
							event: 'related_link_add',
							inspectionId: inspection.id,
							name: formValues.name,
							url: formValues.url,
						});
						resetForm();
						onSuccess(newURLs);
						setSubmitting(false);
					},
				},
			);
		},
		[inspection, mutate, onError, onSuccess, trackCustomEvent],
	);
	const validationSchema = yup.object({
		name: yup.string().required('A name is required'),
		url: yup.string().required('The URL is required').url('Please enter a valid URL'),
	});

	const { errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values } =
		useFormik<FormValues>({
			initialValues,
			onSubmit,
			validationSchema,
		});

	return (
		<Dialog fullWidth maxWidth="sm" open={isOpen} onClose={onClose}>
			<form onSubmit={handleSubmit}>
				<DialogTitle>Add related link</DialogTitle>
				<DialogContent>
					<TextField
						error={Boolean(errors.name && touched.name)}
						fullWidth
						helperText={errors.name || 'Give the related link a human-readable name'}
						label="Name"
						name="name"
						onBlur={handleBlur}
						onChange={handleChange}
						value={values.name}
					/>
					<TextField
						error={Boolean(errors.url && touched.url)}
						fullWidth
						helperText={errors.url || 'Add a link to tools relevant to this project activity'}
						label="URL"
						name="url"
						onBlur={handleBlur}
						onChange={handleChange}
						value={values.url}
					/>
				</DialogContent>
				<DialogActions>
					<Button color="primary" disabled={isSubmitting} onClick={onClose} variant="text">
						Cancel
					</Button>
					<Button color="primary" disabled={isSubmitting} type="submit" variant="contained">
						Add
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default AddURLDialog;
