import React, { useEffect } from 'react';
import { ScaleControl, TileLayer, useMap, useMapEvents, ZoomControl } from 'react-leaflet';
import { ExtendedLocation } from '@/components/Question/Types/Location/MapModal';
import { parseLatLng } from '@/components/Question/Types/Location/location.utils';
import { MapProps } from '@/components/Question/Types/Location/Maps/index';
import * as Styled from '../Location.styles';

const LeafletMapInnerComponent: React.FC<MapProps> = ({
	children,
	onClickMap,
	currentLocation,
	interactive,
	setViewport,
}) => {
	const map = useMap();
	useMapEvents({
		click: (e) => {
			if (interactive && onClickMap) {
				onClickMap(e.latlng.lat, e.latlng.lng);
			}
		},
		drag: () => {
			if (setViewport) {
				setViewport({
					latitude: map.getCenter().lat,
					longitude: map.getCenter().lng,
					zoom: map.getZoom(),
				});
			}
		},
		zoomend: () => {
			if (setViewport) {
				setViewport({
					latitude: map.getCenter().lat,
					longitude: map.getCenter().lng,
					zoom: map.getZoom(),
				});
			}
		},
	});

	useEffect(() => {
		if (currentLocation) {
			const { lat, lng } = parseLatLng(currentLocation.pointString);
			map.flyTo([lat, lng]);
		}
	}, [currentLocation]);
	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{children}</>;
};

const LeafletMap: React.FC<
	MapProps & {
		currentLocation?: ExtendedLocation;
		width: string;
		height: string;
	}
> = ({
	viewport,
	children,
	onClickMap,
	currentLocation,
	width,
	height,
	interactive,
	setViewport,
}) => (
	<Styled.MapContainer
		key={interactive ? undefined : new Date().getTime()}
		width={width}
		height={height}
		center={[viewport.latitude, viewport.longitude]}
		zoom={viewport.zoom}
		scrollWheelZoom={interactive}
		dragging={interactive}
		zoomControl={false}
		id="leaflet-map"
	>
		{interactive && (
			<>
				<ScaleControl metric imperial={false} position="bottomright" />
				<ZoomControl position="bottomright" />
			</>
		)}

		<LeafletMapInnerComponent
			setViewport={setViewport}
			interactive={interactive}
			onClickMap={onClickMap}
			currentLocation={currentLocation}
			viewport={viewport}
		>
			<TileLayer
				attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>
			{children}
		</LeafletMapInnerComponent>
	</Styled.MapContainer>
);

export default LeafletMap;
