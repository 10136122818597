import React from 'react';

const ComponentDecorator = (
	decoratedHref: string,
	decoratedText: string,
	key: number,
): React.ReactNode => (
	<a href={decoratedHref} key={key} rel="noreferrer" target="_blank">
		{decoratedText}
	</a>
);

export default ComponentDecorator;
