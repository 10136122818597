import styled from 'styled-components';

export const AvailableStudyMetaContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 8px;
	overflow: hidden;
`;

export const CounterContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	svg {
		margin: 0 8px;
	}
	margin: 8px;
`;

export const AvailableStudyContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 100px;
	border-bottom: 0.5px solid lightgrey;
`;
