import React from 'react';
import { v4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';

import AssistPanel from 'kes-common/src/components/AssistPanel';
import { FileWithId } from 'kes-common/src/components/AssistPanel/utils';
import { QuestionsByCategoryAndAsset } from 'kes-common/src/components/AssistPanel/QuestionsTab';

import { inspectionRelatedFileUpload } from '@/store/actions';
import { Property, PropertyType } from '@/store/types';
import State from '@/store/state';

import useRelatedFileUpload from '@/net/reactQuery/mutations/useRelatedFileUpload';

import { useProjectAndActivities } from '@/net/reactQuery/queries';
import useRelatedActivityCreate from '@/net/reactQuery/mutations/useRelatedActivityCreate';
import useRelatedActivityDelete from '@/net/reactQuery/mutations/useRelatedActivityDelete';
import useRelatedActivityRegenerateReport from '@/net/reactQuery/mutations/useRelatedActivityRegenerateReport';
import { buildRoute, projectReport } from '@/routes';
import { isEmbeddable, setScrollbarToTop, splitFilesByType } from './utils';
import useKaasContext from './useKaasContext';
import DownloadButton from './DownloadButton';

interface Iprops {
	inspectionId: string; // needed to save the selected files in the browser memory
	handleClose: () => void; // to close the modal

	assist: () => void; // to run the assist
	isFetching: boolean; // to share the loading spinner between the modal and the button
}

// prettier-ignore
function KaasAssistPanelWrapper({ inspectionId, handleClose, assist, isFetching }: Iprops) : JSX.Element {

	// QUESTIONS TAB
	const questionsByCategoryAndAsset = useSelector<State,QuestionsByCategoryAndAsset>((state) => {
		const propertiesById = state.properties.byId;
		const categoriesById = state.categories.byId;
		const assetsById = state.assetTypes.byId;

		const filteredProperties: Record<string, Property> = {};
		const filteredCategories: QuestionsByCategoryAndAsset['categories'] = [];
		const filteredQuestions : QuestionsByCategoryAndAsset['questions'] = [];
		// Filter properties
		Object.keys(propertiesById).forEach((key) => {
		  const property = propertiesById[key];
		  if (
			[
				PropertyType.DECIMAL,
				PropertyType.MULTI_SELECT,
				PropertyType.SINGLE_SELECT,
				PropertyType.STRING,
				PropertyType.DATE,
			].includes(property.type) &&
			!property.fixed
		  ) {
			filteredProperties[key] = property;
			filteredQuestions.push({question: property.question, name: property.name,id: property.id});
		  }
		});
		Object.values(categoriesById).forEach((category) => {
		  if (!category.fixed && category.type !== 'FLAG') {
			const filteredAssets: { assetId: string, assetName: string, properties: Property[] }[] = [];

			// Filter assets and build asset objects
			category.assetTypeIds.forEach((assetId) => {
			  const asset = assetsById[assetId];
			  const assetProperties: Property[] = asset.propertyIds.map((propertyId) => propertiesById[propertyId]).filter((property) => property && filteredProperties[property.id]);
			  if (!asset.repeating && assetProperties.length > 0) {
				filteredAssets.push({
				  assetId: asset.id,
				  assetName: asset.name,
				  properties: assetProperties,
				});
			  }
			});

			// If there are filtered assets, add the category object
			if (filteredAssets.length > 0) {
			  filteredCategories.push({
				categoryName: category.name,
				categoryId: category.id,
				assets: filteredAssets,
			  });
			}
		  }
		});

		return {questions: filteredQuestions, categories: filteredCategories };
	  });


	// DOCUMENTS TAB
	// Handle the selection of the files
	const {embedded, notEmbedded} = useSelector((state: State)=> splitFilesByType(state.files))


	const { selectedFiles, selectFile, selectQuestion, selectedQuestions,pruneFiles,pruneQuestions} = useKaasContext();

	// Remove frome localstorage files that are not present in the backend anymore, there must be a better way to do this but it is what it is
	React.useEffect(() =>{
		const filesIdsFromBackend = embedded.map(file => file.fileId);

		if(selectedFiles.some(selectedFile => !filesIdsFromBackend.includes(selectedFile)) ){
			 pruneFiles(filesIdsFromBackend)
		}

		const questionsIdsFromBackend = questionsByCategoryAndAsset.questions.map(q => q.id)

		if(selectedQuestions.some(selectedQuestion=> !questionsIdsFromBackend.includes(selectedQuestion))){
			pruneQuestions(questionsIdsFromBackend)
		}

	},[embedded,questionsByCategoryAndAsset]);


	// Handle the file upload
	const dispatch = useDispatch();
	const [fileToBeUploaded, setFileToBeUploaded] = React.useState<FileWithId[] >([]);
	const [fileErrorState, setFileErrorState] = React.useState<FileWithId[]>([]);
	// used to add a "NEW" chip for freshly uploaded files
	const [newFiles, setNewFile] = React.useState<string[]>([]);
	// scrollbar refs to scroll to the top of the list when new files are uploaded
	const scrollbarEmbeddedListRef = React.useRef<HTMLDivElement>(null);
	const scrollbarNotEmbeddedListRef = React.useRef<HTMLDivElement>(null);
	const scrollbarUploadingListRef = React.useRef<HTMLDivElement>(null);
	const uploadMutation = useRelatedFileUpload(
		{ inspectionId },
		{
			onSuccess: (_, { file, fileId }) => {
				if (fileId === null || file?.name === undefined) return;
				const processed = isEmbeddable(file.name);
				dispatch(inspectionRelatedFileUpload({ fileName: file.name, fileId , processed}));
				if (processed){
					selectFile(fileId);
					setScrollbarToTop(scrollbarEmbeddedListRef)
				} else {
					setScrollbarToTop(scrollbarNotEmbeddedListRef)
				}
				setNewFile((prev)=> [...prev,fileId])

			},
			onError: (_, { file, fileId }) => {
				if (fileId === null || file === null) return;
				setFileErrorState((prev) => [...prev, {file, fileId} ]);

				// TODO: should set scrollbar 3 to be on the "error item" but since i use a single list for both errror and uploading i feel like calculating the height to scroll to is not worth it
			},
			onSettled: (_,__,{file, fileId}) => {
				if (fileId === null || file === null) return;
				setFileToBeUploaded((prev) => prev.filter((f) => f.fileId !== fileId));

			},
		},
	);
	// handler to call the mutation
	const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
	const fileList = event.target.files;
	if (fileList === null) return;
	for (const file of fileList) {
		const fileId = v4();
		setFileToBeUploaded((prev) => [...prev, {file,fileId}]);
		uploadMutation.mutate({ file, fileId});
		}
	setScrollbarToTop(scrollbarUploadingListRef);
	};



	// ACTIVITIES TAB
	const { data, isInitialLoading, isError, refetch } = useProjectAndActivities(inspectionId);
	const reportLink = (_inspectionId: string): string =>
		`${buildRoute(projectReport, { inspectionId: _inspectionId })}?variant=html`;
	const activityLink = (projectId: string, _inspectionId: string): string =>
		`/project/${projectId}/inspection/${_inspectionId}/category/load`;
	const queryClient = useQueryClient();
	const { mutate: createRelatedActivity } = useRelatedActivityCreate(inspectionId, {
		onSuccess: () => {
			queryClient.invalidateQueries(['project-list-and-inspections-filtered', inspectionId]);
			queryClient.invalidateQueries(['related-activities', inspectionId]);
		},
	});
	const { mutate: deleteRelatedActivity } = useRelatedActivityDelete(inspectionId, {
		onSuccess: () => {
			queryClient.invalidateQueries(['project-list-and-inspections-filtered', inspectionId]);
			queryClient.invalidateQueries(['related-activities', inspectionId]);
		},
	});
	const { mutate: regenerate } = useRelatedActivityRegenerateReport(inspectionId, {
		retry : 0, // this might take long time so if it fails don't retry
		onSuccess: () => {
			queryClient.invalidateQueries(['project-list-and-inspections-filtered', inspectionId]);
		},
	});




	return (
		<AssistPanel
			close={handleClose}
			assist={assist}
			isLoadingAssist={isFetching}

			questionsTabProps={{
				questionsByCategoryAndAsset,
				selectQuestion,
				selectedQuestions
			}}

			documentsTabProps={{
				embedded,
				notEmbedded,
				selectedFiles,
				selectFile,
				fileBeingUploaded:fileToBeUploaded,
				upload:handleFileUpload,
				DownloadButton,
				// assist props v
				fileErrorState,
				setFileErrorState,
				newFiles,
				scrollbarEmbeddedListRef,
				scrollbarNotEmbeddedListRef,
				scrollbarUploadingListRef,

			}}
			activitiesTabProps={{
				data,
				isInitialLoading,
				isError,
				refetch,
				reportLink,
				activityLink,
				regenerate: (id: string) => regenerate({relatedActivityId: id}),
				deleteRelatedActivity: (id: string) => deleteRelatedActivity({relatedActivityId: id}),
				createRelatedActivity: (id: string)=> createRelatedActivity({relatedActivityId: id})}}
		/>
	);
}

export default KaasAssistPanelWrapper;
