import { GeocodeQueryType, GeocodeRequest } from '@mapbox/mapbox-sdk/services/geocoding';

const mbxClient = require('@mapbox/mapbox-sdk');
const geocodingClient = require('@mapbox/mapbox-sdk/services/geocoding');

const baseClient = mbxClient({ accessToken: process.env.REACT_APP_MAPBOX_TOKEN! });
const geocodingService = geocodingClient(baseClient);

const isCoordinateRegex = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/g;

const geocodeTypes: GeocodeQueryType[] = [
	'address',
	'country',
	'region',
	'postcode',
	'district',
	'place',
	'neighborhood',
	'poi',
];

const geocode = async (keyword: string, proximity?: { lat: number; lng: number }) => {
	const geocodeParams: GeocodeRequest = {
		query: keyword,
		mode: 'mapbox.places',
		types: geocodeTypes,
	};
	if (proximity) {
		geocodeParams.proximity = [proximity.lng, proximity.lat];
	}
	const reverseGeocodeResponse = await geocodingService.forwardGeocode(geocodeParams).send();

	return reverseGeocodeResponse.body.features;
};

const reverseGeocode = async (lng: number, lat: number) => {
	const geocodeParams: GeocodeRequest = {
		query: [lng, lat],
		mode: 'mapbox.places',
		types: geocodeTypes,
	};
	const reverseGeocodeResponse = await geocodingService.reverseGeocode(geocodeParams).send();
	return reverseGeocodeResponse.body.features;
};

const search = async (keyword: string, proximity?: { lat: number; lng: number }) => {
	if (keyword.match(isCoordinateRegex)) {
		const [lat, lng] = keyword.split(',');
		return reverseGeocode(Number(lng), Number(lat));
	}
	return geocode(keyword, proximity);
};

const mapboxApi = {
	search,
};
export default mapboxApi;
