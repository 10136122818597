import UploadBoxBase from '@/components/UploadBox/UploadBase';
import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';
import { UploadBoxProps } from '@/components/UploadBox';
import Box from '@mui/material/Box';

const UploadBoxUploading = ({ filename }: Pick<UploadBoxProps, 'filename'>): JSX.Element => (
	<UploadBoxBase filename={filename}>
		<Box width="196px;" mr={2}>
			<LinearProgress value={0} data-testid="uploading" />
		</Box>
	</UploadBoxBase>
);

export default UploadBoxUploading;
