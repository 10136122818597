import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Button from '@mui/material/Button';
import { ApiInspectionDto, useFeatureFlag } from 'kes-common';
import React from 'react';
import { useSnackbar } from 'notistack';
import State from '@/store/state';
import { useSelector } from 'react-redux';
import { activityHasValues } from '@/selectors';
import ClearDataDialog from './ClearDataDialog';

interface ButtonClearDataProps {
	inspectionId: ApiInspectionDto['id'];
	onClose(): void;
}

const ButtonClearData: React.FC<ButtonClearDataProps> = ({ inspectionId, onClose }) => {
	const [isOpen, setIsOpen] = React.useState<boolean>(false);

	const CLEAR_ACTIVITY = useFeatureFlag({ name: 'CLEAR_ACTIVITY' });
	const { enqueueSnackbar } = useSnackbar();

	const deleteDialogClose = React.useCallback(() => setIsOpen(false), [setIsOpen]);

	const deleteDialogOpen = React.useCallback(() => {
		onClose();
		setIsOpen(true);
	}, [onClose, setIsOpen]);

	const onDelete = React.useCallback(() => {
		deleteDialogClose();
		enqueueSnackbar('Survey cleared succesfully.');
	}, [enqueueSnackbar, deleteDialogClose]);

	const onDeleteError = React.useCallback(() => {
		deleteDialogClose();
		enqueueSnackbar('There was an issue clearing the survey, please try again.');
	}, [deleteDialogClose, enqueueSnackbar]);

	const inspection = useSelector<State, ApiInspectionDto | undefined>(
		(state) => state.inspection.currentInspection,
	);

	const hasSharePointURL = Boolean(inspection?.sharepointUrl);

	const activityHasSomeValues = useSelector(activityHasValues);

	if (!CLEAR_ACTIVITY) {
		return null;
	}

	return (
		<>
			<Button
				disabled={hasSharePointURL || !activityHasSomeValues}
				color="secondary"
				fullWidth
				onClick={deleteDialogOpen}
				startIcon={<DeleteForeverIcon />}
			>
				Clear Data
			</Button>
			<ClearDataDialog
				inspectionId={inspectionId}
				isOpen={isOpen}
				onClose={deleteDialogClose}
				onDelete={onDelete}
				onError={onDeleteError}
			/>
		</>
	);
};

export default ButtonClearData;
