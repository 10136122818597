import { studyRoleUpdate } from '@/net/api';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { ApiStudyDto, ApiStudyUserDto, useUser } from 'kes-common';
import React from 'react';
import { useMutation } from '@tanstack/react-query';
import * as yup from 'yup';

interface FormValues {
	role: ('STUDY_ADMINISTRATOR' | 'STUDY_SUGGESTED_INSPECTOR')[];
}

interface RoleDropdownProps {
	template: ApiStudyDto;
	user: ApiStudyUserDto;
}

const RoleDropdown: React.FC<RoleDropdownProps> = ({ template, user }) => {
	const { user: loggedInUser } = useUser();
	const roleUpdate = useMutation((values: FormValues) =>
		studyRoleUpdate(template.id, { roles: values.role, userId: user.userId }),
	);

	const formik = useFormik<FormValues>({
		initialValues: { role: user.roles },
		onSubmit: (values) => {
			roleUpdate.mutate(values, {
				onSettled: () => {
					formik.setSubmitting(false);
				},
			});
		},
		validationSchema: yup.object().shape({
			role: yup
				.array()
				.of(yup.string().oneOf(['STUDY_ADMINISTRATOR', 'STUDY_SUGGESTED_INSPECTOR'])),
		}),
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			<TextField
				disabled={formik.isSubmitting || loggedInUser.user.id === user.userId}
				fullWidth
				name="role"
				onChange={(event) => {
					formik.handleChange(event);
					formik.submitForm();
				}}
				select
				SelectProps={{ multiple: true }}
				value={formik.values.role}
			>
				<MenuItem value="STUDY_ADMINISTRATOR">Administrator</MenuItem>
				<MenuItem value="STUDY_SUGGESTED_INSPECTOR">Suggested inspector</MenuItem>
			</TextField>
		</form>
	);
};

export default RoleDropdown;
