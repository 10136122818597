import { KES_ABW_CONNECTOR_URL } from '@/constants';
import { acquireToken, projectCreate } from '@/net/api';
import { ApiProjectUpdateDto, ApiProjectUserDto } from 'kes-common';
import * as yup from 'yup';
import { createClient, createProjectLocation } from '@/pages/projectModel';

export type MasterProject = yup.Asserts<typeof masterProjectApi>;

export type MasterProjects = yup.Asserts<typeof masterProjectsApi>;

export const isMasterProject = (maybe: unknown): maybe is MasterProject =>
	(maybe as MasterProject).id !== undefined && (maybe as MasterProject).name !== undefined;

const masterProjectApi = yup.object().shape({
	id: yup.string().required(),
	name: yup.string().required(),
});

const masterProjectsApi = yup.array().of(masterProjectApi).required();

export const emailToUser = (email: string): ApiProjectUserDto => ({
	email,
	name: null,
	roles: ['PROJECT_ADMINISTRATOR'],
	userId: '',
	globalAdmin: false,
});

export const fetchMasterProjects = async (query: string) => {
	const url = new URL('abw/master-project', KES_ABW_CONNECTOR_URL);
	url.searchParams.set('search', query);
	const token = await acquireToken();
	const httpOptions = {
		headers: { Authorization: `Bearer ${token}` },
	};
	const apiResponse = await fetch(url.toString(), httpOptions);
	if (!apiResponse.ok) {
		throw new Error('ABW service is behaving unexpectedly');
	}
	const apiPayload = await apiResponse.json();
	const queryResult = await masterProjectsApi.validate(apiPayload);
	return queryResult;
};

export const getErrorMessage = (e: any) => (e instanceof Error ? e.message : String(e));

export interface ProjectAlreadyExists {
	tag: 'ALREADY_EXISTS';
	masterProjectId: string;
	projectId: string;
}

export interface ProjectCreated {
	tag: 'CREATED';
	projectId: string;
}

export const createProject = async (
	projectToImport: MasterProject | string,
	templates: Set<string>,
	admins: string[],
): Promise<ProjectCreated | ProjectAlreadyExists> => {
	const sharedProps = {
		client: createClient(),
		projectNumber: '',
		location: createProjectLocation(),
		templates: [...templates],
		admins,
	};

	const projectDto: ApiProjectUpdateDto = isMasterProject(projectToImport)
		? {
				masterProjectId: projectToImport.id,
				name: projectToImport.name,
				...sharedProps,
		  }
		: {
				masterProjectId: null,
				name: projectToImport,
				...sharedProps,
		  };

	const createResult = await projectCreate(projectDto);
	if (!createResult.ok) {
		if (createResult.status === 409) {
			const { masterProjectId, projectId } = createResult.expect(409);
			return { tag: 'ALREADY_EXISTS', projectId, masterProjectId };
		}
		throw new Error(`Unexpected response from api: ${createResult.statusText}`);
	}

	const { project: newProject } = createResult.expectSuccess();
	return { tag: 'CREATED', projectId: newProject.id };
};
