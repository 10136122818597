import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { ApiAuthorizedProjectDto, ApiStudyDto } from 'kes-common';
import React from 'react';
import { Link } from 'react-router-dom';

import { useProjects } from '@/net/reactQuery/queries';
import { projectCreate } from '@/routes';
import useProjectAssignTemplates from '@/net/reactQuery/mutations/useProjectAssignTemplates';

export interface TemplateAssignDialogProps {
	isOpen: boolean;
	onClose(): void;
	onError(): void;
	onSuccess(): void;
	template: ApiStudyDto;
}

const TemplateAssignDialog: React.FC<TemplateAssignDialogProps> = ({
	isOpen,
	onClose,
	onError,
	onSuccess,
	template,
}) => {
	const [selectedProjectIds, setSelectedProjectIds] = React.useState<
		ApiAuthorizedProjectDto['project']['id'][]
	>([]);
	const { data, isError, isLoading, refetch } = useProjects();

	const assignMutation = useProjectAssignTemplates();

	const assign = React.useCallback(async () => {
		const assignmentPromises: Promise<any>[] = selectedProjectIds.map((selectedProjectId) =>
			assignMutation.mutateAsync({
				projectId: selectedProjectId,
				templates: [{ count: 1, studyId: template.id }],
			}),
		);
		try {
			await Promise.all(assignmentPromises);
			onClose();
			onSuccess();
		} catch (error) {
			onError();
		}
	}, [assignMutation, onClose, onError, onSuccess, selectedProjectIds, template]);

	const toggleProject = React.useCallback(
		(projectId: ApiAuthorizedProjectDto['project']['id']) => {
			if (selectedProjectIds.includes(projectId)) {
				setSelectedProjectIds(
					selectedProjectIds.filter((selectedProjectId) => selectedProjectId !== projectId),
				);
			} else {
				setSelectedProjectIds([...selectedProjectIds, projectId]);
			}
		},
		[selectedProjectIds, setSelectedProjectIds],
	);

	return (
		<Dialog onClose={onClose} open={isOpen}>
			<DialogTitle>Assign template to projects</DialogTitle>
			<DialogContent>
				<Typography gutterBottom>
					Assign the template ({template.name}) to projects you have access to.
				</Typography>
				{isError && (
					<Alert action={<Button onClick={() => refetch()}>Retry</Button>} severity="error">
						Failed to load the projects list.
					</Alert>
				)}
				{!isError && isLoading && (
					<>
						<Skeleton />
						<Skeleton />
						<Skeleton />
					</>
				)}
				{!isError && !isLoading && (
					<>
						{data.length === 0 && (
							<Alert
								action={
									<Button component={Link} to={projectCreate}>
										Create project
									</Button>
								}
								severity="info"
							>
								You do not have any projects.
							</Alert>
						)}
						{data.length > 0 && (
							<List>
								{data.map((project) => (
									<ListItem disablePadding key={project.project.id}>
										<ListItemButton onClick={() => toggleProject(project.project.id)}>
											<ListItemIcon>
												<Checkbox
													checked={selectedProjectIds.includes(project.project.id)}
													disableRipple
													edge="start"
													tabIndex={-1}
												/>
											</ListItemIcon>
											<ListItemText
												primary={project.project.name}
												secondary={project.project.masterProjectId}
											/>
										</ListItemButton>
									</ListItem>
								))}
							</List>
						)}
					</>
				)}
			</DialogContent>
			<DialogActions>
				<Button color="secondary" onClick={onClose} variant="text">
					Cancel
				</Button>
				<Button
					color="primary"
					disabled={selectedProjectIds.length === 0}
					onClick={assign}
					variant="contained"
				>
					Assign
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default TemplateAssignDialog;
