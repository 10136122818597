import { ApiFile } from 'kes-common';
import { defaultMemoize } from 'reselect';

import { makeUnorderedSelectors, UnorderedState } from '@/store/repository';
import State from '@/store/state';
import { Asset, Property, UUID } from '@/store/types';

const selector = makeUnorderedSelectors<Property>();

export const { get, getAll, getOrNull } = selector;

export const getAllByIds =
	(propertyIds: string[]) =>
	(state: State): Property[] => {
		const properties = propertyIds
			.map((propertyId) => state.properties.byId[propertyId])
			.filter((property): property is Property => !!property);
		return properties;
	};

export const getAllByChoiceId = defaultMemoize(
	(state: UnorderedState<Property>): Record<UUID, Property> => {
		const propertiesById = selector.getAll(state);
		return Object.values(propertiesById).reduce(
			(acc: Record<UUID, Property>, property: Property): Record<UUID, Property> => {
				property.choiceIds.forEach((choiceId) => {
					acc[choiceId] = property;
				});
				return acc;
			},
			{},
		);
	},
);

export const getCaption = (state: State, propertyId: string): string =>
	Object.values(state.assets.byId).reduce((accumulator, asset) => {
		if (asset.captionsByPropertyId[propertyId]) {
			return asset.captionsByPropertyId[propertyId];
		}
		return accumulator;
	}, '');

export const getAllRelatedFilesForProperty =
	(assetId: Asset['id'], propertyId: Property['id']) =>
	(state: State): ApiFile[] | undefined =>
		state.assets.byId[assetId].filesByPropertyId[propertyId];

export const getPropertiesForFile =
	(fileId: ApiFile['fileId']) =>
	(state: State): Property[] => {
		const propertyIds = Object.values(state.assets.byId).reduce<string[]>(
			(accumulator, asset) => [
				...accumulator,
				...Object.keys(asset.filesByPropertyId).filter((propertyId) =>
					asset.filesByPropertyId[propertyId].some((file) => file.fileId === fileId),
				),
			],
			[],
		);
		return getAllByIds(propertyIds)(state);
	};
