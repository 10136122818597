import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import React from 'react';

import {
	Badge,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Stack,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { AttachFile } from '@mui/icons-material';

import { getAllRelatedFilesForProperty } from '@/selectors/properties';
import RelatedFilesDialog from '@/components/RelatedFilesDialog';
import { Asset, AssetType, Property } from '@/store/types';

import AddNote from './AddNote';
import RelatedFiles from './RelatedFiles';
import ListItemRelatedFileWithDownload from './ListItemRelatedFileWithDownload';

interface PropertyContextMenuProps {
	assetId: Asset['id'] | null;
	assetTypeId: AssetType['id'];
	note?: string;
	onNoteModalOpen(property: Property): void;
	property: Property;
}

const PropertyContextMenu: React.FC<PropertyContextMenuProps> = ({
	assetId,
	assetTypeId,
	note,
	onNoteModalOpen,
	property,
}) => {
	const [anchorElement, setAnchorElement] = React.useState<HTMLElement | null>(null);
	const [isRelatedFilesDialogOpen, setIsRelatedFilesDialogOpen] = React.useState<boolean>(false);

	const onMenuClose = React.useCallback(() => setAnchorElement(null), [setAnchorElement]);
	const onRelatedFilesDialogClose = React.useCallback(
		() => setIsRelatedFilesDialogOpen(false),
		[setIsRelatedFilesDialogOpen],
	);
	const onRelatedFilesDialogOpen = React.useCallback(() => {
		onMenuClose();
		setIsRelatedFilesDialogOpen(true);
	}, [onMenuClose, setIsRelatedFilesDialogOpen]);

	const onMenuOpen: React.MouseEventHandler<HTMLButtonElement> = React.useCallback(
		(event) => {
			setAnchorElement(event.currentTarget);
		},
		[setAnchorElement],
	);

	const propertyRelatedFiles = useSelector(
		assetId === null ? () => undefined : getAllRelatedFilesForProperty(assetId, property.id),
	);

	const [isRelatedFilesDownloadOpen, setIsRelatedFilesDownloadOpen] =
		React.useState<boolean>(false);

	return (
		<>
			<Stack direction="row">
				{propertyRelatedFiles && propertyRelatedFiles.length > 0 && (
					<>
						<IconButton onClick={() => setIsRelatedFilesDownloadOpen(true)}>
							<Badge color="primary" variant="dot">
								<AttachFile fontSize="small" />
							</Badge>
						</IconButton>
						<Dialog
							onClose={() => setIsRelatedFilesDownloadOpen(false)}
							open={isRelatedFilesDownloadOpen}
						>
							<DialogTitle>Related files: {property.question || property.name}</DialogTitle>

							<DialogContent>
								{propertyRelatedFiles.map((relatedFile) => (
									<ListItemRelatedFileWithDownload relatedFile={relatedFile} />
								))}
							</DialogContent>

							<DialogActions>
								<Button
									color="primary"
									onClick={() => setIsRelatedFilesDownloadOpen(false)}
									variant="outlined"
								>
									Close
								</Button>
							</DialogActions>
						</Dialog>
					</>
				)}
				<IconButton onClick={onMenuOpen}>
					<MoreVertIcon />
				</IconButton>
			</Stack>

			<Menu anchorEl={anchorElement} onClose={onMenuClose} open={Boolean(anchorElement)}>
				<MenuList>
					<AddNote note={note} onNoteModalOpen={onNoteModalOpen} property={property} />
					<RelatedFiles onClick={onRelatedFilesDialogOpen} />
				</MenuList>
			</Menu>

			<RelatedFilesDialog
				assetId={assetId}
				assetTypeId={assetTypeId}
				isOpen={isRelatedFilesDialogOpen}
				onClose={onRelatedFilesDialogClose}
				property={property}
			/>
		</>
	);
};

export default PropertyContextMenu;
