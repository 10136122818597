import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { ApiMasterTemplate } from 'kes-common';
import React from 'react';

import useMasterTemplateDelete from '@/net/reactQuery/mutations/useMasterTemplateDelete';

interface DeleteDialogProps {
	isOpen: boolean;
	masterTemplate: ApiMasterTemplate;
	onClose(): void;
	onError(): void;
	onSuccess(): void;
}

const DeleteDialog: React.FC<DeleteDialogProps> = ({
	isOpen,
	masterTemplate,
	onClose,
	onError,
	onSuccess,
}) => {
	const masterTemplateDeleteQuery = useMasterTemplateDelete(masterTemplate.id);

	const onDelete = React.useCallback(async () => {
		try {
			await masterTemplateDeleteQuery.mutateAsync();
			onSuccess();
		} catch (error) {
			onError();
		}
	}, [masterTemplateDeleteQuery, onError, onSuccess]);

	return (
		<Dialog onClose={onClose} open={isOpen}>
			<DialogTitle>Delete master template?</DialogTitle>
			<DialogContent>
				<Typography>
					Are you sure you want to delete the master template &quot;{masterTemplate.name}&quot;?
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button color="secondary" onClick={onClose} variant="text">
					Cancel
				</Button>
				<Button color="primary" onClick={onDelete} variant="contained">
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteDialog;
